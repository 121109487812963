import moment from "moment";
import * as React from "react";

import { useQuery } from "react-query";
import type { ColumnsType } from "antd/lib/table";

import LeadsManageModal from "../Modal/ManageLeads";

import {
  Avatar,
  Button,
  Col,
  Popover,
  Result,
  Row,
  Space,
  Table,
  Tag,
  Typography,
} from "antd";
import LeadsViewModal from "../Modal/ShowLeadsDetails";

import { getAllLead } from "../Requests";

import CreateFollowupForm from "../Form/CreateFollowupForm";
import CreateFollowupModal from "../Modal/CreateFollowup";

import CreateQuotationModal from "../Modal/CreateQuotation";

import LeadTimelineDrawer from "./ShowLeadTimelineDrawer";
import AssignedPersonManageModal from "../Modal/assignedPerson";
import { getAccessAuthrorization } from "src/components/access";
import { s3Path } from "src/utils/s3Config/s3upload";
import { List } from "antd-mobile";
import { isMobile } from "react-device-detect";
import WhatsappMessage from "src/components/whatsapp";
import PhoneCall from "src/components/call";

interface DataType {
  id: number;
  name: any;
}
type DataIndex = keyof DataType;
const LeadsList: React.FC = () => {
  const [page, setPage] = React.useState(1);
  const [size, setSize] = React.useState(100);
  const [searchText, setSearchText] = React.useState("");
  const [searchedColumn, setSearchedColumn] = React.useState("");
  let accessAuth = getAccessAuthrorization("leads");

  const {
    isLoading,
    isError,
    data: response,
  } = useQuery(["lead"], () => getAllLead(), {
    refetchOnWindowFocus: false,

    keepPreviousData: true,
    staleTime: 5000,
  });

  if (isLoading || isError) {
    return <div>Loading</div>;
  }
  const { Text } = Typography;

  const column: ColumnsType<DataType> = [
    {
      title: "ENQUIY DATE",
      dataIndex: "enquiryDate",
      key: "enquiryDate",
      render: (text: any) => moment(text).format("DD-MMM-YYYY"),
    },

    {
      title: "PARTY",
      dataIndex: "partyId",
      key: "partyId",
      render: (_: any, id: any) => (
        <>
          <Text
            style={{
              fontSize: 18,
              fontWeight: "bold",
            }}
          >
            {id?.partyId?.personName}
          </Text>
          <br />
          <Text code type="danger">
            {id?.partyId?.companyName}
          </Text>
        </>
      ),
    },
    {
      title: "Source",
      dataIndex: "source",
      key: "source",
    },

    // .. Followup List..
    {
      title: "Follow up",
      dataIndex: "id",
      key: "id",
      render: (id: any) => (
        <Space size={10}>
          <CreateFollowupModal leadId={id} />
        </Space>
      ),
    },
    // ..Quotation List..
    // {
    //   title: " Quotation",
    //   dataIndex: "quotationId",
    //   key: "quotationId",
    //   render: (id: any) => (
    //     <Space size={10}>
    //       <CreateQuotationModal />
    //       {/* <FollowupViewModal id={id} /> */}
    //     </Space>
    //   ),
    // },

    //..assigned Person..
    {
      title: "Assigned Person",
      dataIndex: "id",
      key: "id",
      render: (_: any, i: any) => (
        <Space size={10}>
          <Avatar
            src={
              <img
                src={
                  i?.assignedPersonId?.partyId?.imageUrl &&
                  s3Path + i?.assignedPersonId?.partyId?.imageUrl
                }
                alt={i?.assignedPersonId?.partyId?.personName[0]}
              />
            }
          />
          {i.assignedPersonId ? (
            <Tag>{i.assignedPersonId.partyId.personName}</Tag>
          ) : (
            <AssignedPersonManageModal id={i.id} />
          )}
          {/* <CreateQuotationModal /> */}
          {/* <FollowupViewModal id={id} /> */}
        </Space>
      ),
    },

    {
      title: "Remark",
      dataIndex: "remarks",
      key: "remarks",
    },

    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (id: any) => (
        <Space size={10}>
          <LeadsViewModal id={id} />
          {/* <LeadsManageModal id={id} /> */}
          {accessAuth[0].includes("Edit") && <LeadsManageModal id={id} />}

          <LeadTimelineDrawer id={id} />
        </Space>
      ),
    },
  ];
  return (
    <>
      {!isError ? (
        <>
          {!isMobile && (
            <Table size="small" columns={column} dataSource={response} />
          )}
          {isMobile && <MobileLeadList data={response} />}
        </>
      ) : (
        <Result
          status="500"
          title="500"
          subTitle="Sorry, something went wrong."
          extra={<Button type="primary">Back Home</Button>}
        />
      )}
    </>
  );

  // return( <Table size="small" columns={column} dataSource={response} />);
};

export default LeadsList;
const { Text } = Typography;
const MobileLeadList: React.FC<{ data: any }> = ({ data }) => {
  console.log(data);
  return (
    <>
      <List style={{ padding: 0 }}>
        {/* <AutoSizer disableHeight> */}
        {data &&
          data.map((element: any, i: any) => (
            <List.Item
              style={{ padding: 0 }}
              key={element?.partyId?.personName}
              // prefix={<Avatar size="large" src={s3Path + element.imageUrl} />}
              description={
                <>
                  <Space.Compact direction="vertical">
                    <Popover
                      content={
                        <Row>
                          <PhoneCall phoneNumber={element?.partyId?.phoneNo} />
                          <WhatsappMessage
                            phoneNumber={element?.partyId?.phoneNo}
                            message={`Hey  ${element?.partyId?.personName}!`}
                          />
                        </Row>
                      }
                      trigger="click"
                      placement="bottom"
                    >
                      <Text
                        style={{
                          fontWeight: "bold",
                        }}
                        type="danger"
                        code
                      >
                        +91-{element?.partyId?.phoneNo}
                      </Text>
                    </Popover>
                    <Text>{element?.partyId?.email}</Text>

                    <Text type="secondary">
                      {element?.partyId?.companyName}
                    </Text>
                  </Space.Compact>
                </>
              }
            >
              <>
                <Row justify={"space-between"}>
                  <Col span={14}>
                    <Text style={{ fontSize: 20 }} strong>
                      {element?.partyId?.personName}
                    </Text>
                  </Col>
                  <Col span={10}>
                    <Space direction="horizontal">
                      {/* <LeadsViewModal id={element.id} /> */}
                      <CreateFollowupModal leadId={element.id} />

                      <LeadsManageModal id={element.id} />

                      {/* <LeadTimelineDrawer id={element.id} /> */}
                    </Space>
                  </Col>
                </Row>
              </>
            </List.Item>
          ))}
        {/* </AutoSizer> */}
      </List>
    </>
  );
};
