import * as React from "react";
import {
  Modal,
  Button,
  Tooltip,
  Empty,
  Pagination,
  Carousel,
  Skeleton,
} from "antd";
// import ManageTaskForm from "../Form/ManageTaskForm";
import { PaperClipOutlined } from "@ant-design/icons";
import type { PaginationProps } from "antd";
import { s3Path } from "src/utils/s3Config/s3upload";
const AttachmentModal: React.FC<{ data: any; i: number }> = ({ data, i }) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  // const [current, setCurrent] = React.useState(1);
  const [file, setFile] = React.useState<any>(undefined);
  // const onChange: PaginationProps["onChange"] = (page) => {
  //   console.log(page);
  //   setCurrent(page);
  // };
  const showModal = () => setIsModalVisible(true);

  const handleOk = () => setIsModalVisible(false);

  const handleCancel = () => setIsModalVisible(false);
  // console.log(data);
  React.useEffect(() => {
    // let blob = await fetch(url).then((r) => r.blob());

    const readFile = async () => {
      let blob = await fetch(s3Path + data).then((r) => r.blob());

      setFile(window.URL.createObjectURL(blob));
    };
    readFile();
  }, []);

  return (
    <>
      <Tooltip title="See All Attachment">
        <Button
          onClick={showModal}
          // type="primary"
          shape="circle"
          icon={<PaperClipOutlined />}
        >
          {i}
        </Button>
      </Tooltip>
      <Modal
        title="Attachments"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width={"100vw"}
      >
        {file ? (
          <object
            width={1000}
            height={500}
            data={file}
            type="application/pdf"
            // scrollbar
          >
            File Load Fails!
          </object>
        ) : (
          <Skeleton active />
        )}
        {/* <p>{data.length}</p>
        {data.length > 1 && (
          <object
            width={1000}
            height={500}
            data={data[2]}
            // type="application/pdf"
            // scrollbar
          >
            File Load Fails!
          </object>
        )} */}
        {/* <Carousel>
          <div>
            {data &&
              data.map((i: any, j: any) => {
                return (
                  <object width={750} height={500} data={i}>
                    File Load Fails!
                  </object>
                );
              })}
          </div>
        </Carousel> */}

        {/* {data.length < 2 ? (
          <Empty />
        ) : (
          data.map((i: any, j: any) => {
            return <Button onClick={() => setCurrent(j + 1)}>{j + 1}</Button>;
          })
          // <Pagination current={current} onChange={onChange} total={50} />
        )} */}
        {/* <ManageTaskForm id={id} /> */}
      </Modal>
    </>
  );
};

export default AttachmentModal;
