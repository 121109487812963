import { request } from "src/utils/services";

export const getAllActivePersonnel = async () => {
  const { data } = await request("admin.personnel.list.active");
  return data;
};

export const getAllPersonnel = async () => {
  const { data } = await request("admin.personnel.list");
  return data;
};

export const getPersonnelById = async (id: string) => {
  const { data } = await request("admin.personnel.id", { id });
  return data;
};
export const getPersonnelByIdDetail = async (id: string) => {
  const { data } = await request("admin.personnel.id.detail", { id });
  return data;
};
