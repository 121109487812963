import { request } from "../../../utils/services";

export const getAllActiveWork = async () => {
  const { data } = await request("admin.work.list.active");
  return data;
};

export const getAllWork = async () => {
  const { data } = await request("admin.work.list");
  return data;
};

export const getAllWorkWithDetails = async () => {
  const { data } = await request("admin.work.details");
  return data;
};

export const getWorkById = async (id: string) => {
  const { data } = await request("admin.work.id", { id });
  return data;
};

export const getWorkByIdDetail = async (id: string) => {
  const { data } = await request("admin.work.id.detail", { id });
  return data;
};

export const getWorkByIdWithAdminDetail = async (id: string) => {
  const { data } = await request("admin.work.admin.details.id", { id });
  return data;
};
