import { request } from "../../../utils/services";

export const getAllActiveCaseStudy = async () => {
  const { data } = await request("admin.caseStudy.list.active");
  return data;
};

export const getAllCaseStudy = async () => {
  const { data } = await request("admin.caseStudy.list");
  return data;
};

export const getCaseStudyById = async (id: string) => {
  const { data } = await request("admin.caseStudy.id", { id });
  return data;
};
export const getCaseStudyByWorkId = async (id: string) => {
  const { data } = await request("admin.caseStudy.by.workId", { id });
  return data;
};
