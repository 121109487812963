import { path } from "./path";

export const leadRoutes = [
  {
    method: "post",
    path: path + "/api/admin/saveLead",
    module: "lead",
    alias: "admin.lead.create",
    isSubDomain: true,
  },
  {
    method: "post",
    path: path + "/api/admin/saveBulkLead",
    module: "lead",
    alias: "admin.bulk.lead.create",
    isSubDomain: true,
  },
  {
    method: "put",
    path: path + "/api/admin/updateLead/:id",
    module: "lead",
    alias: "admin.lead.update",
    isSubDomain: true,
  },

  // {
  //   method: "get",
  //   path: path + "/api/admin/findAllActiveLead",
  //   module: "lead",
  //   alias: "admin.lead.list.active",
  //   isSubDomain: true,
  // },

  {
    method: "get",
    path: path + "/api/admin/findAllLead",
    module: "lead",
    alias: "admin.lead.list",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findOneLeadDetail/:id",
    module: "lead",
    alias: "admin.lead.id.detail",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findOneLead/:id",
    module: "lead",
    alias: "admin.lead.id",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findFollowupByLeadId/:id",
    module: "followup",
    alias: "admin.followup.lead.id",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findFollowupByWorkId/:id",
    module: "followup",
    alias: "admin.followup.work.id",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findLeadTimelineByLeadId/:id",
    module: "lead",
    alias: "admin.lead.timeline.id",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findAllLeadbyPartyId/:id",
    module: "lead",
    alias: "admin.lead.party.id",
    isSubDomain: true,
  },

  {
    method: "post",
    path: path + "/api/admin/saveFollowup",
    module: "followup",
    alias: "admin.followup.create",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findAllFollowup",
    module: "followup",
    alias: "admin.followup.list",
    isSubDomain: true,
  },
];
