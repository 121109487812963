import { path } from "./path";

export const taxInvoiceRoutes = [
  {
    method: "post",
    path: path + "/api/admin/createTaxInvoice",
    module: "taxInvoice",
    alias: "admin.taxInvoice.create",
    isSubDomain: true,
  },

  {
    method: "put",
    path: path + "/api/admin/updateTaxInvoice/:id",
    module: "taxInvoice",
    alias: "admin.taxInvoice.update",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllActiveTaxInvoice",
    module: "taxInvoice",
    alias: "admin.taxInvoice.list.active",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllTaxInvoice",
    module: "taxInvoice",
    alias: "admin.taxInvoice.list",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findOneTaxInvoice/:id",
    module: "taxInvoice",
    alias: "admin.taxInvoice.id",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findOneTaxInvoiceWithDetail/:id",
    module: "taxInvoice",
    alias: "admin.taxInvoice.id.with.detail",
    isSubDomain: true,
  },
];
