import * as React from "react";
import {
  Form,
  Button,
  Select,
  Col,
  Row,
  message,
  Typography,
  Card,
  Tag,
} from "antd";

import { InfoCircleOutlined } from "@ant-design/icons";

import { ManageAssignedTeamModalContext } from "../Modal/assignedTeam";
import LocalStorageService from "../../../../utils/local-storage";
import { useMutation, useQuery } from "react-query";
import { request } from "../../../../utils/services";
import { queryClient } from "../../../..";

import { getProjectById } from "../Requests";

import {
  getAllPersonnel,
  getPersonnelByIdDetail,
} from "src/modules/HR/Personnel/Requests";

const { Text, Title } = Typography;
const CreateAssignTeamForm: React.FC<{ id?: string }> = (props) => {
  const modalContext = React.useContext(ManageAssignedTeamModalContext);
  const [formLoading, setFormLoading] = React.useState(false);

  const [isTeamSelected, setIsTeamSelected] = React.useState(false);
  const [teamData, setTeamData] = React.useState<Object | any>({});
  const assignTeamMutation = useMutation(
    (values: any) => request("admin.project.update", { id: props.id }, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("project");
      },
    }
  );

  const {
    isLoading,
    data: project,
    error,
    isError,
  } = useQuery(
    ["projet", props.id],
    async () => getProjectById(props.id ? props.id : "0"),
    {
      refetchOnWindowFocus: false,
    }
  );
  const [isAssigned, setIsAssigned] = React.useState(
    project.personnelId ? true : false
  );

  const { data: person } = useQuery(
    ["peronnnel"],
    async () => getAllPersonnel(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const [form] = Form.useForm();
  const onFinish = async (values: any) => {
    values.updatedBy = LocalStorageService.getAccount().id;
    values.projectId = props.id;
    values.projcetStatus = "Assigned";

    setFormLoading(true);
    console.log(values);

    assignTeamMutation
      .mutateAsync(values)
      .then((payload: any) => {
        setFormLoading(false);
        message.success(payload.message);
        form.resetFields();
        modalContext.setIsModalVisible(false);
        setIsAssigned(true);
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e.message);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const mutateTeam = useMutation((id: string) => getPersonnelByIdDetail(id));
  const OnTeamSelect = (i: any) => {
    setIsTeamSelected(true);

    mutateTeam
      .mutateAsync(i)
      .then((payload) => {
        setTeamData(payload);
      })
      .catch((e) => {
        console.log(e, "error");
      });
  };

  React.useEffect(() => {
    if (project.personnelId) {
      OnTeamSelect(project.personnelId);
    }
  }, []);
  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={project}
    >
      <Form.Item
        label="Assigned Team"
        style={{ fontWeight: "bold" }}
        name="teamId"
        tooltip={{
          title: "Team ",
          icon: <InfoCircleOutlined />,
        }}
      >
        {isTeamSelected ? (
          <Card>
            <Row justify={"space-between"} align={"middle"}>
              <Title level={3}>{teamData?.partyId?.personName}</Title>
              <Col>
                <Row>
                  <Text code>{teamData.partyId?.phoneNo}</Text>
                  <Text code>{teamData.partyId?.address}</Text>
                </Row>
              </Col>
              {!isAssigned && (
                <Button
                  onClick={() => {
                    form.setFieldsValue({
                      personId: null,
                    });
                    setIsTeamSelected(false);
                  }}
                  type="dashed"
                >
                  Change Team
                </Button>
              )}
            </Row>
          </Card>
        ) : (
          <Select
            onSelect={OnTeamSelect}
            showSearch
            size="large"
            popupMatchSelectWidth
            placeholder="Select Team"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option!.children as unknown as string).includes(input)
            }
          >
            {person &&
              person?.map((item: any, i: number) => {
                return (
                  <Select.Option value={item?.id} key={i}>
                    <Row justify={"space-between"} align={"top"}>
                      <h4>{item?.partyId?.personName}</h4>
                      <Col>
                        <Row>
                          <Text code>{item.partyId?.phoneNo}</Text>
                          <Text code> {item.partyId?.address}</Text>
                        </Row>
                      </Col>
                    </Row>
                  </Select.Option>
                );
              })}
          </Select>
        )}
      </Form.Item>

      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Assign Team
        </Button>
      </Form.Item>
      {/* )} */}
    </Form>
  );
};
export default CreateAssignTeamForm;
