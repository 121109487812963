import { request } from "../../../utils/services";

export const getAllActiveTaxInvoice = async () => {
  const { data } = await request("admin.taxInvoice.list.active");
  return data;
};

export const getAllTaxInvoice = async () => {
  const { data } = await request("admin.taxInvoice.list");
  return data;
};

export const getTaxInvoiceById = async (id: string) => {
  const { data } = await request("admin.taxInvoice.id", { id });
  return data;
};

export const getOneTaxInvoiceWithDetail = async (id: string) => {
  const { data } = await request("admin.taxInvoice.id.with.detail", { id });
  return data;
};
export const getTaxInvoiceByLeadId = async (id: string) => {
  const { data } = await request("admin.performaInvoice.by.leadId", { id });
  return data;
};
