import {
  Avatar,
  Col,
  Row,
  Space,
  Tag,
  Typography,
  Badge,
  Card,
  Divider,
} from "antd";

import moment from "moment";
import React from "react";
import { useQuery } from "react-query";
import ReactTimeAgo from "react-time-ago";
import { imLogoIcon } from "src/constants/logo";
import { getAllFollowup } from "src/modules/Leads/Requests";
import { s3Path } from "src/utils/s3Config/s3upload";
const MobileFollowupList: React.FC = () => {
  const {
    isLoading,
    isError,
    data: response,
  } = useQuery(["followUp"], () => getAllFollowup(), {
    refetchOnWindowFocus: true,

    keepPreviousData: true,
    staleTime: 100,
  });
  return (
    response &&
    response?.map((item: any, i: any) => {
      return (
        <Badge.Ribbon
          text={item?.leadId?.leadStatus}
          style={{ marginRight: 8, fontSize: 10 }}
        >
          <Card
            size="small"
            // style={{ paddingRight: 8 }}
            //   onClick={() => {
            //     setTaskOpen(true);
            //     setTaskId(item?._id);
            //   }}
          >
            <div>
              <Row justify={"space-between"} align={"stretch"}>
                <Col span={4}>
                  <Space.Compact direction="vertical">
                    <Typography.Text
                      style={{ fontSize: 24, fontWeight: "bold" }}
                    >
                      {moment(item?.followupDate).format("DD")}
                    </Typography.Text>
                    <Typography.Text
                      style={{
                        fontSize: 16,
                        fontWeight: "bold",
                        color: "teal",
                      }}
                    >
                      {moment(item?.followupDate).format("MMM")}
                    </Typography.Text>
                    <Typography.Text
                      style={{
                        fontSize: 16,
                        color: "teal",
                      }}
                    >
                      {moment(item?.followupDate).format("YYYY")}
                    </Typography.Text>
                  </Space.Compact>
                </Col>

                <Col span={20}>
                  <Row>
                    <Typography.Text strong>
                      {item?.concernedPersonId?.name}
                    </Typography.Text>{" "}
                  </Row>
                  <Row>
                    {" "}
                    <Col span={16}>
                      <Typography.Text
                        type="secondary"
                        style={{ fontSize: 13 }}
                      >
                        {item?.concernedPersonId?.partyId?.companyName}
                      </Typography.Text>{" "}
                    </Col>
                    <Col span={8}>
                      <Typography.Text style={{ fontSize: 12 }}>
                        <ReactTimeAgo
                          date={item?.createdAt}
                          locale="en-IN"
                          // timeStyle="twitter"
                        />
                      </Typography.Text>
                    </Col>
                  </Row>
                  <Row>
                    <div
                      style={{
                        backgroundColor: "lightyellow",
                        borderRadius: 2,
                      }}
                    >
                      {item?.clientRemarks}
                    </div>
                  </Row>

                  <Row>
                    <Col>
                      <Tag style={{ fontSize: 10 }} color="green-inverse">
                        {item?.type}
                      </Tag>
                    </Col>
                    <Col>
                      <Tag color="purple-inverse">{item?.followupPurpose}</Tag>
                    </Col>
                    <Col>
                      <Tag color="geekblue-inverse" style={{ fontSize: 10 }}>
                        {moment(item?.nextFollowupDate).format("DD-MMM-YY")}
                      </Tag>
                    </Col>

                    {/* <ReactTimeAgo
                      date={item?.createdAt}
                      locale="en-IN"
                      // timeStyle="twitter"
                    /> */}
                    <Col>
                      {" "}
                      <Avatar
                        src={
                          <img
                            src={
                              item?.createdBy?.partyId?.imageUrl
                                ? s3Path + item?.createdBy?.partyId?.imageUrl
                                : imLogoIcon
                            }
                            alt={item?.createdBy?.partyId?.personName}
                          />
                        }
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Card>
          {/* <Modal
              open={taskOpen}
              width={"100%"}
              onCancel={() => setTaskOpen(!taskOpen)}
              onOk={() => setTaskOpen(!taskOpen)}
              //   onClose={() => setOpen(!open)}
            >
              <ShowMobileTaskDetailsForm id={item._id} />
            </Modal> */}
        </Badge.Ribbon>
      );
    })
  );
};

export default MobileFollowupList;
