import moment from "moment";
import * as React from "react";

import { useQuery } from "react-query";
import type { ColumnsType } from "antd/lib/table";

import { Descriptions, Space, Table, Tag, Typography, Image } from "antd";
import {
  FileExclamationOutlined,
  EyeTwoTone,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
import { getAllDeployment } from "../Requests";
import DeploymentViewModal from "../Modal/ShowDeploymentDetails";
import ManageDeploymentModal from "../Modal/ManageDeployment";
import { getAccessAuthrorization } from "src/components/access";
import { s3Path } from "src/utils/s3Config/s3upload";

interface DataType {
  id: number;
  name: any;
  iosLink: any;
  domainName: any;
  androidLink: any;
  pannel: any;
  caseStudy: any;
  maintainanceExpiryDate: any;
}
type DataIndex = keyof DataType;
const DeploymentList: React.FC = () => {
  const [passwordVisible, setPasswordVisible] = React.useState(false);

  let accessAuth = getAccessAuthrorization("deployment");

  const {
    isLoading,
    isError,
    data: response,
  } = useQuery(["deployment"], () => getAllDeployment(), {
    refetchOnWindowFocus: false,

    keepPreviousData: true,
    staleTime: 5000,
  });

  if (isLoading || isError) {
    return <div>Loading</div>;
  }
  const { Text } = Typography;
  const column: ColumnsType<DataType> = [
    {
      title: "Image",
      dataIndex: "logo",
      key: "logo",
      render: (text: any) =>
        text ? (
          <Image width={25} height={25} src={s3Path + text} />
        ) : (
          <Space.Compact direction="vertical">
            <FileExclamationOutlined
              style={{ marginLeft: 12, color: "GrayText" }}
            />
            <Typography.Text style={{ fontSize: 11, color: "GrayText" }}>
              No data
            </Typography.Text>
          </Space.Compact>

          // <Empty
          //   style={{ width: 25, height: 25 }}
          //   image={}
          // />
        ),
    },
    {
      title: "Domain Name/Expiry",
      dataIndex: "domainName",
      key: "domainName",
      render: (_: any, i: any) => (
        <Space>
          <Space.Compact direction="vertical">
            <Tag color="magenta">{i.domainName}</Tag>
            <Text code type="success">
              {moment(i?.domainExpiryDate).format("DD-MMM-YYYY")}
            </Text>
          </Space.Compact>
        </Space>
      ),
    },
    {
      title: "Server Name/Expiry",
      dataIndex: "id",
      key: "id",
      render: (_: any, i: any) => (
        <Space>
          <Space.Compact direction="vertical">
            <Tag color="magenta">{i.serverName}</Tag>
            <Text code type="success">
              {moment(i?.serverExpiryDate).format("DD-MMM-YYYY")}
            </Text>
          </Space.Compact>
        </Space>
      ),
    },
    {
      title: "DB Host/Name",
      dataIndex: "id",
      key: "id",
      render: (_: any, i: any) => (
        <Space>
          <Space.Compact direction="vertical">
            <Text code type="danger">
              Host: {i.dbHost}
            </Text>
            <Text code type="danger">
              Name: {i.dbName}
            </Text>
          </Space.Compact>
        </Space>
      ),
    },
    {
      title: "DB User/Pwd",
      dataIndex: "id",
      key: "id",
      render: (_: any, i: any) => (
        <Space>
          <Space.Compact direction="vertical">
            <Text code type="danger">
              User: {i.dbUsername}
            </Text>

            <Tag
              color="purple"
              icon={passwordVisible ? <EyeTwoTone /> : <EyeInvisibleOutlined />}
              onClick={() => setPasswordVisible((prevState) => !prevState)}
            >
              Pwd: {passwordVisible ? i.dbPassword : "************"}
            </Tag>
            {/* <Button
                size="small"
                shape="circle"
                type="ghost"
                icon={
                  passwordVisible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
                onClick={() => setPasswordVisible((prevState) => !prevState)}
              /> */}
          </Space.Compact>
        </Space>
      ),
    },

    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      render: (text: any) =>
        text ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">Inactive</Tag>
        ),
    },

    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (id: any) => (
        <Space size={10}>
          <DeploymentViewModal id={id} />

          {accessAuth[0].includes("Edit") && <ManageDeploymentModal id={id} />}
        </Space>
      ),
    },
  ];

  return (
    <Table
      rowKey={(record) => record.id}
      size="small"
      columns={column}
      dataSource={response}
      expandable={{
        expandedRowRender: (record) => (
          <>
            <Descriptions
              bordered
              layout="vertical"
              size="small"
              title={
                <Tag color="red">
                  Maintainance Expiry Date:{" "}
                  {moment(record.maintainanceExpiryDate).format("DD-MMM-YYYY")}
                </Tag>
              }
            >
              <Descriptions.Item label="iosLink:">
                {record.iosLink}
              </Descriptions.Item>
              <Descriptions.Item label="androidLink:">
                {record.androidLink}
              </Descriptions.Item>
              <Descriptions.Item label="Pannel">
                {record.pannel}
              </Descriptions.Item>

              <Descriptions.Item label="Case Study:" span={3}>
                {record.caseStudy}
              </Descriptions.Item>
            </Descriptions>
          </>
        ),
        rowExpandable: (record) => record.domainName !== "Not Expandable",
      }}
    />
  );
};

export default DeploymentList;
