import { request } from "../../../utils/services";

export const getAllActiveSEO = async () => {
  const { data } = await request("admin.seo.list.active");
  return data;
};

export const getAllSEO = async () => {
  const { data } = await request("admin.seo.list");
  return data;
};

export const getSEOById = async (id: string) => {
  const { data } = await request("admin.seo.id", { id });
  return data;
};
export const getSEOByWorkId = async (id: string) => {
  const { data } = await request("admin.seo.by.workId", { id });
  return data;
};
