import * as React from "react";
import {
  Form,
  Button,
  message,
  Row,
  Typography,
  Input,
  Col,
  Upload,
  Switch,
  Divider,
  Select,
  Avatar,
  DatePicker,
  InputNumber,
  Space,
  Card,
  Descriptions,
  Tag,
  Popover,
} from "antd";

import { request } from "../../../utils/services";
import { useMutation, useQuery } from "react-query";
import {
  InfoCircleOutlined,
  PlusOutlined,
  LoadingOutlined,
  DeleteOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import { queryClient } from "src";
import type { UploadChangeParam, UploadFile } from "antd/es/upload/interface";
import type { RcFile, UploadProps } from "antd/es/upload";

import TextArea from "antd/lib/input/TextArea";

import LocalStorageService from "src/utils/local-storage";
import { CreatePerfomaInvoiceModalContext } from "../Modal/CreatePerfomaInvoice";
import { s3Path, uploadFileToAWS } from "src/utils/s3Config/s3upload";
import CreatePartyModal from "src/modules/Party/Modal/CreateParty";
import {
  getAllActiveParty,
  getAllConcernedPerson,
} from "src/modules/Party/Requests";
import { imLogoIcon } from "src/constants/logo";
import CreateConcernedPersonModal from "src/modules/Party/Modal/concernedPerson";
import CreateLeadsModal from "src/modules/Leads/Modal/CreateLeads";
import { getAllLead } from "src/modules/Leads/Requests";

const { Text, Title } = Typography;
const CreatePerfomaInvoice: React.FC = () => {
  const modalContext = React.useContext(CreatePerfomaInvoiceModalContext);
  const [formLoading, setFormLoading] = React.useState(false);
  const [galley, setGallery] = React.useState<string>();
  const [loading, setLoading] = React.useState(false);
  const [totalAmount, setTotalAmount] = React.useState(0);
  const [quantity, setQuantity] = React.useState(1);
  const [isIGST, setIGST] = React.useState(true);
  // const [GST, setGST] = React.useState(18);
  const [totalTax, setTotalTax] = React.useState(0);
  const [totalTaxable, setTotalTaxable] = React.useState(0);
  const [imageUrl, setImageUrl] = React.useState<string>();
  const [selectedFile, setSelectedFile] = React.useState<RcFile>();

  const createPerfomaInvoiceMutation = useMutation(
    (values: any) => request("admin.perfomaInvoice.create", {}, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("perfomaInvoice");
      },
    }
  );

  const { data: party } = useQuery(["party"], async () => getAllActiveParty(), {
    refetchOnWindowFocus: false,
  });
  const { data: lead } = useQuery(["lead"], async () => getAllLead(), {
    refetchOnWindowFocus: false,
  });
  const getBase64 = (img: RcFile, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    // if (info.file.status === "uploading") {
    //   setLoading(true);
    //   return;
    // }
    setSelectedFile(info.file.originFileObj);
    if (info.file.status === "uploading") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj as RcFile, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
  };

  const onFinish = async (values: any) => {
    values.createdBy = LocalStorageService.getAccount().id;
    values.imageUrl = galley;
    setFormLoading(true);

    createPerfomaInvoiceMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The PerfomaInvoice has been successfully created.");
        form.resetFields();
        modalContext.setIsModalVisible(false);
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const [form] = Form.useForm();

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  let clientList = party.filter(
    (element: any) => element.partyType[0] === "Client"
  );
  let vendorList = party.filter(
    (element: any) => element.partyType[0] === "Vendor"
  );
  let leadList = party.filter(
    (element: any) => element.partyType[0] === "Lead Provider"
  );

  let partyList = [...clientList, ...vendorList, ...leadList];

  const handleTotal = () => {
    // console.log(values, "===>");
    // const rowsCopy = [...values.gr]
    let tmpQty: number = 0; //pkg;
    let tmpItemRate: number = 0;
    let tmpItemGst: number = 0; //paidAmt;
    // let tmpItemGstper: number = 0; //paidAmt;
    let tmpItemAmt: number = 0;
    let tmpItemDiscPrice: number = 0;
    let tmpItemTaxableAmt: number = 0; //paidAmt;
    let tmpTotalTaxableAmt: number = 0; //paidAmt;
    let tmpTotaltax: number = 0; //paidAmt;
    let totalAmt: number = 0; //toPayAmt;
    const { items } = form.getFieldsValue();

    items?.forEach((fieldGroup: any, index: number) => {
      if (
        fieldGroup &&
        (fieldGroup.quantity ||
          fieldGroup.rate ||
          fieldGroup.discount ||
          fieldGroup.taxable ||
          fieldGroup.amount)
      ) {
        tmpItemDiscPrice = fieldGroup.rate - fieldGroup.discount;
        tmpItemTaxableAmt = fieldGroup.quantity * tmpItemDiscPrice;
        tmpItemGst = fieldGroup.rate
          ? (tmpItemTaxableAmt * (fieldGroup.gstper ? fieldGroup.gstper : 18)) /
            100
          : 0;
        tmpItemAmt = fieldGroup.rate ? tmpItemTaxableAmt + tmpItemGst : 0;

        /**Set Form Fields**/
        items[index].taxable = tmpItemTaxableAmt;
        items[index].amount = tmpItemAmt;
        items[index].gst = tmpItemGst;
        items[index].sno = index + 1;
        /**Set Form Fields**/
        tmpTotalTaxableAmt = fieldGroup.taxable
          ? tmpTotalTaxableAmt + +fieldGroup.taxable
          : totalTaxable;
        tmpTotaltax = fieldGroup.gst ? tmpTotaltax + +fieldGroup.gst : totalTax;
        totalAmt = fieldGroup.amount
          ? totalAmt + +fieldGroup.amount
          : totalAmount;
      }
    });
    setTotalTax(tmpTotaltax);
    setTotalTaxable(tmpTotalTaxableAmt);
    setTotalAmount(totalAmt);
    form.setFieldsValue({ items: items });

    return totalAmount;
  };

  const content = [
    {
      label: "5%",
      value: 5,
    },
    { label: "12%", value: 12 },
    { label: "18%", value: 18 },
  ];
  // console.log(form.getFieldsValue());
  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onValuesChange={handleTotal}
    >
      <Space.Compact block>
        <Card style={{ width: "30%" }}>
          <Form.Item
            label="PO. No"
            style={{ fontWeight: "bold" }}
            name="poNumber"
            tooltip={{
              title: "poNumber",
              icon: <InfoCircleOutlined />,
            }}
            hasFeedback
          >
            <Input placeholder="Enter PO. Number" maxLength={50} />
          </Form.Item>
          <Form.Item
            label="Attach Lead"
            style={{ fontWeight: "bold" }}
            name="leadId"
            tooltip={{
              title: "Select Lead",
              icon: <InfoCircleOutlined />,
            }}
            hasFeedback
          >
            <Select
              //onChange={onPartySelect}
              showSearch
              placeholder="Select lead"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option!.children as unknown as string).includes(input)
              }
              dropdownRender={(menu) => (
                <>
                  <Row justify={"end"}>
                    <CreateLeadsModal />
                  </Row>
                  {menu}
                </>
              )}
            >
              {lead &&
                lead?.map((item: any, i: number) => {
                  return (
                    <Select.Option value={item.id} key={i}>
                      <Typography.Text strong>
                        {item?.seq}. {item?.title}
                      </Typography.Text>
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
          <Form.Item
            label="Invoice Date"
            style={{ fontWeight: "bold" }}
            name="date"
            tooltip={{
              title: "date",
              icon: <InfoCircleOutlined />,
            }}
            hasFeedback
          >
            <DatePicker format={"DD-MMM-YYYY"} />
          </Form.Item>
        </Card>
        <Card style={{ width: "35%" }}>
          <Form.Item
            label="Bill To"
            name="billTo"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "Bill To",
              icon: <InfoCircleOutlined />,
            }}
          >
            <Select
              //onChange={onPartySelect}
              showSearch
              placeholder="Select Bill to"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option!.children as unknown as string).includes(input)
              }
              dropdownRender={(menu) => (
                <>
                  <Row justify={"end"}>
                    <CreatePartyModal />
                  </Row>
                  {menu}
                </>
              )}
            >
              {partyList &&
                partyList?.map((item: any, i: number) => {
                  return (
                    <Select.Option value={item.id} key={i}>
                      <Avatar
                        src={
                          <img
                            src={
                              item.imageUrl
                                ? s3Path + item.imageUrl
                                : imLogoIcon
                            }
                            alt={item?.personName[0]}
                          />
                        }
                      />
                      <Typography.Text strong>
                        {item?.personName}-{item?.companyName}
                      </Typography.Text>
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Card>
        <Card style={{ width: "35%" }}>
          <Form.Item
            label="Ship To"
            name="shipTo"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "Party",
              icon: <InfoCircleOutlined />,
            }}
          >
            <Select
              //onChange={onPartySelect}
              showSearch
              placeholder="Select Ship To"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option!.children as unknown as string).includes(input)
              }
              dropdownRender={(menu) => (
                <>
                  <Row justify={"end"}>
                    <CreatePartyModal />
                  </Row>
                  {menu}
                </>
              )}
            >
              {partyList &&
                partyList?.map((item: any, i: number) => {
                  return (
                    <Select.Option value={item.id} key={i}>
                      <Avatar
                        src={
                          <img
                            src={
                              item.imageUrl
                                ? s3Path + item.imageUrl
                                : imLogoIcon
                            }
                            alt={item?.personName[0]}
                          />
                        }
                      />
                      <Typography.Text strong>
                        {item?.personName}-{item?.companyName}
                      </Typography.Text>
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Card>
      </Space.Compact>
      <Card>
        {" "}
        <Space.Compact>
          <Input
            style={{ fontWeight: "bold", width: "5%" }}
            disabled
            placeholder="Sno."
          />
          <Input
            style={{ fontWeight: "bold", width: "33%" }}
            disabled
            placeholder="Particular"
          />
          <Input
            style={{ fontWeight: "bold", width: "13%" }}
            disabled
            placeholder="HSN/SAC"
          />
          <Input
            style={{ fontWeight: "bold", width: "8%" }}
            disabled
            placeholder="Qty"
          />
          <Input
            style={{ fontWeight: "bold", width: "13%" }}
            disabled
            placeholder="Rate"
          />
          <Input
            style={{ fontWeight: "bold", width: 100 }}
            disabled
            placeholder="Discount"
          />
          <Input
            style={{ fontWeight: "bold", width: "13%" }}
            disabled
            placeholder="Taxable"
          />
          <Input
            style={{ fontWeight: "bold", width: "13%" }}
            disabled
            placeholder="GST"
          />
          <Input
            style={{ fontWeight: "bold", width: "13%" }}
            disabled
            placeholder="Amount"
          />
          <Input
            style={{ fontWeight: "bold", width: "2%" }}
            disabled
            placeholder=""
          />
        </Space.Compact>
        <Form.List name="items">
          {(fields, { add, remove }) => (
            <>
              <Space.Compact direction="vertical">
                {fields.map((field: any, index: number) => (
                  <Space.Compact>
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, curValues) =>
                        prevValues.area !== curValues.area ||
                        prevValues.sights !== curValues.sights
                      }
                    >
                      {() => (
                        <Form.Item
                          {...field}
                          initialValue={index + 1}
                          style={{ marginBottom: 0, width: "6%" }}
                          name={[field.name, "sno"]}
                        >
                          <Input placeholder="Sno. " disabled />
                        </Form.Item>
                      )}
                    </Form.Item>
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, curValues) =>
                        prevValues.area !== curValues.area ||
                        prevValues.sights !== curValues.sights
                      }
                    >
                      {() => (
                        <Form.Item
                          {...field}
                          name={[field.name, "particular"]}
                          style={{ marginBottom: 0, width: "35%" }}
                        >
                          <TextArea placeholder="Enter Paticular" />
                        </Form.Item>
                      )}
                    </Form.Item>
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, curValues) =>
                        prevValues.area !== curValues.area ||
                        prevValues.sights !== curValues.sights
                      }
                    >
                      {() => (
                        <Form.Item
                          // initialValue="xxxx"
                          {...field}
                          name={[field.name, "hsnCode"]}
                          style={{ marginBottom: 0, width: "14%" }}
                        >
                          <Input
                            maxLength={10}
                            placeholder="HSN/SAC"
                            // disabled
                          />
                        </Form.Item>
                      )}
                    </Form.Item>
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, curValues) =>
                        prevValues.area !== curValues.area ||
                        prevValues.sights !== curValues.sights
                      }
                    >
                      {() => (
                        <Form.Item
                          initialValue={quantity}
                          {...field}
                          name={[field.name, "quantity"]}
                          style={{ marginBottom: 0, width: "8%" }}
                        >
                          <InputNumber placeholder="Qty" min={1} max={99} />
                        </Form.Item>
                      )}
                    </Form.Item>
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, curValues) =>
                        prevValues.area !== curValues.area ||
                        prevValues.sights !== curValues.sights
                      }
                    >
                      {() => (
                        <Form.Item
                          initialValue={0}
                          {...field}
                          name={[field.name, "rate"]}
                          style={{ marginBottom: 0, width: "14%" }}
                        >
                          <Input placeholder="Rate " />
                        </Form.Item>
                      )}
                    </Form.Item>
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, curValues) =>
                        prevValues.area !== curValues.area ||
                        prevValues.sights !== curValues.sights
                      }
                    >
                      {() => (
                        <Form.Item
                          {...field}
                          initialValue={0}
                          name={[field.name, "discount"]}
                          style={{ marginBottom: 0, width: "14%" }}
                        >
                          <Input placeholder="Disc. " />
                        </Form.Item>
                      )}
                    </Form.Item>
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, curValues) =>
                        prevValues.area !== curValues.area ||
                        prevValues.sights !== curValues.sights
                      }
                    >
                      {() => (
                        <Form.Item
                          {...field}
                          initialValue={0}
                          name={[field.name, "taxable"]}
                          style={{ marginBottom: 0, width: "14%" }}
                        >
                          <Input placeholder="taxable Amount " />
                        </Form.Item>
                      )}
                    </Form.Item>
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, curValues) =>
                        prevValues.area !== curValues.area ||
                        prevValues.sights !== curValues.sights
                      }
                    >
                      {() => (
                        <Form.Item
                          {...field}
                          initialValue={0}
                          name={[field.name, "gst"]}
                          style={{ marginBottom: 0, width: "14%" }}
                        >
                          <Input
                            placeholder="gst "
                            suffix={
                              <Popover
                                placement="bottom"
                                content={content.map((element: any, i: any) => {
                                  return (
                                    <Row>
                                      <Button
                                        type="text"
                                        onClick={() => {
                                          var { items } = form.getFieldsValue();
                                          Object.assign(items[index], {
                                            gstper: element.value,
                                          });
                                          form.setFieldsValue({
                                            items: items,
                                          });
                                          // setGST(element.value);
                                          handleTotal();
                                        }}
                                      >
                                        {element.label}
                                      </Button>
                                    </Row>
                                  );
                                })}
                                trigger="hover"
                              >
                                <Tag bordered={false} color="gold">
                                  {form.getFieldsValue().items[index].gstper
                                    ? form.getFieldsValue().items[index].gstper
                                    : 18}
                                  %
                                </Tag>
                              </Popover>
                            }
                          />
                        </Form.Item>
                      )}
                    </Form.Item>
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, curValues) =>
                        prevValues.area !== curValues.area ||
                        prevValues.sights !== curValues.sights
                      }
                    >
                      {() => (
                        <Form.Item
                          {...field}
                          initialValue={0}
                          name={[field.name, "amount"]}
                          style={{ marginBottom: 0, width: "14%" }}
                        >
                          <Input placeholder="Amount " />
                        </Form.Item>
                      )}
                    </Form.Item>

                    <DeleteOutlined onClick={() => remove(field.name)} />
                  </Space.Compact>
                ))}

                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add Item
                  </Button>
                </Form.Item>
              </Space.Compact>
            </>
          )}
        </Form.List>
      </Card>

      {/* <h3>Personnel List</h3> */}

      <Space.Compact block>
        <Card style={{ width: "50%" }}>
          <Row>
            <Form.Item
              label="Reverse Charge"
              style={{ fontWeight: "bold" }}
              name="reverseCharge"
              tooltip={{
                title: "reverseCharge",
                icon: <InfoCircleOutlined />,
              }}
              valuePropName="checked"
              // rules={[{ required: true }]}
            >
              <Switch
                checkedChildren="Yes"
                unCheckedChildren="No"
                defaultChecked={false}
              />
            </Form.Item>
            <Form.Item
              label="Is IGST"
              style={{ fontWeight: "bold" }}
              name="isIGST"
              tooltip={{
                title: "isIGST",
                icon: <InfoCircleOutlined />,
              }}
              valuePropName="checked"
              // rules={[{ required: true }]}
            >
              <Switch
                onChange={() => setIGST(!isIGST)}
                checkedChildren="Yes"
                unCheckedChildren="No"
                defaultChecked
              />
            </Form.Item>
          </Row>
          <Form.Item
            label="Terms & Condition"
            style={{ fontWeight: "bold" }}
            name="terms"
            tooltip={{
              title: "terms",
              icon: <InfoCircleOutlined />,
            }}
            hasFeedback
          >
            <TextArea placeholder="Enter Terms & Condition" maxLength={150} />
          </Form.Item>
          {/* <Form.Item
            label="Status"
            style={{ fontWeight: "bold" }}
            name="isActive"
            tooltip={{
              title: "status",
              icon: <InfoCircleOutlined />,
            }}
            valuePropName="checked"
            // rules={[{ required: true }]}
          >
            <Switch
              checkedChildren="Active"
              unCheckedChildren="Inactive"
              defaultChecked
            />
          </Form.Item> */}
        </Card>
        <Card style={{ width: "50%" }}>
          <Descriptions bordered size="small">
            <Descriptions.Item
              label="Taxable Amount"
              labelStyle={{ fontWeight: "bold" }}
              span={3}
            >
              ₹ {totalTaxable}
            </Descriptions.Item>
            {isIGST ? (
              <Descriptions.Item
                label="IGST"
                labelStyle={{ fontWeight: "bold" }}
                span={3}
              >
                ₹ {totalTax}
              </Descriptions.Item>
            ) : (
              <>
                <Descriptions.Item
                  label="SGST"
                  labelStyle={{ fontWeight: "bold" }}
                  span={3}
                >
                  ₹ {totalTax / 2}
                </Descriptions.Item>
                <Descriptions.Item
                  label="CGST"
                  labelStyle={{ fontWeight: "bold" }}
                  span={3}
                >
                  ₹ {totalTax / 2}
                </Descriptions.Item>
              </>
            )}
            <Descriptions.Item
              label="Total Tax Amount"
              labelStyle={{ fontWeight: "bold" }}
              span={3}
            >
              ₹ {totalTax}
            </Descriptions.Item>
            <Descriptions.Item
              label="Grand Total"
              labelStyle={{ fontWeight: "bold" }}
              contentStyle={{ fontWeight: "bold" }}
              span={3}
            >
              ₹ {totalAmount}
            </Descriptions.Item>
          </Descriptions>
        </Card>
      </Space.Compact>

      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreatePerfomaInvoice;
