import { path } from "./path";

export const collectionRoutes = [
  {
    method: "post",
    path: path + "/api/admin/createCollection",
    module: "collection",
    alias: "admin.collection.create",
    isSubDomain: true,
  },

  {
    method: "put",
    path: path + "/api/admin/updateCollection/:id",
    module: "collection",
    alias: "admin.collection.update",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllActiveCollection",
    module: "collection",
    alias: "admin.collection.list.active",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllCollection",
    module: "collection",
    alias: "admin.collection.list",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findOneCollection/:id",
    module: "collection",
    alias: "admin.collection.id",
    isSubDomain: true,
  },
];
