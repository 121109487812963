import loadable from "@loadable/component";
// import { HomeOutlined } from "@ant-design/icons/es/icons/Wa";
var DynamicIcon = loadable(
  (props: any) =>
    import(`@ant-design/icons/es/icons/${props.type}.js`).catch(
      (err) => import(`@ant-design/icons/WarningOutlined.js`)
    ),
  {
    cacheKey: (props) => props.type,
  }
);

// DynamicIcon.preload();

export default DynamicIcon;
