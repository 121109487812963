import * as React from "react";

import { Modal, Button, Tooltip, Tag } from "antd";

import { DeploymentUnitOutlined, FlagOutlined } from "@ant-design/icons";
import ShowStoryDetailsForm from "src/modules/Story/Form/ShowStoryForm";
// import ShowStoryDetailsForm from "../Form/ShowStoryForm";
export const ShowStoryModalContext = React.createContext<{
  setIsModalVisible: (active: boolean) => void;
}>({
  setIsModalVisible: () => true,
});

const StoryDetailsViewModal: React.FC<{ data: any }> = ({ data }) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const showModal = () => setIsModalVisible(true);

  const handleOk = () => setIsModalVisible(false);

  const handleCancel = () => setIsModalVisible(false);

  return (
    <ShowStoryModalContext.Provider value={{ setIsModalVisible }}>
      <Tag color="red" onClick={() => setIsModalVisible(!isModalVisible)}>
        <DeploymentUnitOutlined /> {data.storyId[0]?.workId[0]?.title}
      </Tag>
      --
      <Tag color="green" onClick={() => setIsModalVisible(!isModalVisible)}>
        <FlagOutlined /> {data.storyId[0]?.title}
      </Tag>
      <Modal
        width={1000}
        title=" View Story Request Form"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <ShowStoryDetailsForm id={data.storyId[0]?._id} />
      </Modal>
    </ShowStoryModalContext.Provider>
  );
};

export default StoryDetailsViewModal;
