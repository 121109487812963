import { request } from "../../../utils/services";

export const getAllActiveCategory = async () => {
  const { data } = await request("admin.category.list.active");
  return data;
};

export const getAllCategory = async () => {
  const { data } = await request("admin.category.list");
  return data;
};

export const getCategoryById = async (id: string) => {
  const { data } = await request("admin.category.id", { id });
  return data;
};
export const getCategoryByType = async (type: string) => {
  const { data } = await request("admin.category.type", {
    type,
  });
  return data;
};
