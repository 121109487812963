import * as React from "react";

import { Button } from "antd";

import { PhoneOutlined } from "@ant-design/icons";

const PhoneCall: React.FC<{ phoneNumber: string }> = (props) => {
  return (
    <Button
      type="link"
      href={"tel:+91-" + props.phoneNumber}
      icon={
        <PhoneOutlined
          rotate={90}
          style={{ fontSize: "22px", color: "blue" }}
        />
      }
    />
  );
};

export default PhoneCall;
