import { request } from "../../../../utils/services";

export const getAllActiveAfterSales = async () => {
  const { data } = await request("admin.afterSales.list.active");
  return data;
};

export const getAllAfterSales = async () => {
  const { data } = await request("admin.afterSales.list");
  return data;
};

export const getAfterSalesById = async (id: string) => {
  const { data } = await request("admin.afterSales.id", { id });
  return data;
};

export const getAfterSalesByIddetail = async (id: string) => {
  const { data } = await request("admin.afterSales.id.detail", { id });
  return data;
};
