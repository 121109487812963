import { request } from "../../../utils/services";

export const getAllActiveProposal = async () => {
  const { data } = await request("admin.proposal.list.active");
  return data;
};

export const getAllProposal = async () => {
  const { data } = await request("admin.proposal.list");
  return data;
};

export const getProposalById = async (id: string) => {
  const { data } = await request("admin.proposal.id", { id });
  return data;
};

export const getOneProposalWithDetail = async (id: string) => {
  const { data } = await request("admin.proposal.id.with.detail", { id });
  return data;
};
export const getProposalByPartyId = async (id: string) => {
  const { data } = await request("admin.proposal.by.partyId", { id });
  return data;
};
