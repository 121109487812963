import { request } from "../../../utils/services";

export const getAllActiveQuotation = async () => {
  const { data } = await request("admin.quotation.list.active");
  return data;
};

export const getAllQuotation = async () => {
  const { data } = await request("admin.quotation.list");
  return data;
};

export const getQuotationById = async (id: string) => {
  const { data } = await request("admin.quotation.id", { id });
  return data;
};

export const getOneQuotationWithDetail = async (id: string) => {
  const { data } = await request("admin.quotation.id.with.detail", { id });
  return data;
};
export const getQuotationByPartyId = async (id: string) => {
  const { data } = await request("admin.quotation.by.partyId", { id });
  return data;
};
