import * as React from "react";

import { Modal, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import CreateCredential from "../Form/CreateCredentialForm";

export const CreateCredentialModalContext = React.createContext<{
  setIsModalVisible: (active: boolean) => void;
}>({
  setIsModalVisible: () => true,
});

const CreateCredentialModal: React.FC<{ id?: string }> = ({ id }) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <CreateCredentialModalContext.Provider value={{ setIsModalVisible }}>
      <Button
        size="small"
        onClick={showModal}
        type="primary"
        shape="round"
        style={{
          fontSize: 12,
          backgroundColor: "darkcyan",
        }}
        icon={<PlusOutlined />}
      >
        Add Credential
      </Button>
      <Modal
        title="Generate Credential"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        centered
        width={"90%"}
      >
        <CreateCredential id={id} />
      </Modal>
    </CreateCredentialModalContext.Provider>
  );
};

export default CreateCredentialModal;
