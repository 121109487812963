import {
  Avatar,
  Button,
  Card,
  Col,
  Divider,
  Row,
  Space,
  Tag,
  Typography,
} from "antd";
import * as React from "react";
import { useQuery } from "react-query";
import { getReplyByTag } from "src/modules/Reply/Requests";
import LocalStorageService from "src/utils/local-storage";
import parse from "html-react-parser";
import { s3Path } from "src/utils/s3Config/s3upload";
import ManageReplyForm from "src/modules/Reply/Form/ManageReplyForm";
import TaskViewModal from "src/modules/Task/Modal/ShowTaskDetails";
import ReactTimeAgo from "react-time-ago";

const MentionList: React.FC = () => {
  const [user, setUser] = React.useState(LocalStorageService.getAccount());
  const [isReply, setIsReply] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const {
    isLoading,
    isError,
    data: response,
  } = useQuery(["reply"], () => getReplyByTag(user.personnel.id), {
    refetchOnWindowFocus: false,

    keepPreviousData: true,
    staleTime: 5000,
  });
  // console.log(response);

  return (
    <Card>
      {response &&
        response.map((item: any, i: any) => {
          return (
            <>
              <Row align="top">
                <Col span={1}>
                  <Avatar
                    size={"large"}
                    src={
                      <img
                        src={s3Path + item?.createdBy?.partyId?.imageUrl}
                        alt={"R"}
                      />
                    }
                  />
                </Col>
                <Col style={{ paddingLeft: 8 }} span={20}>
                  <Space.Compact direction="vertical">
                    <Typography.Text strong>
                      {item?.createdBy?.name === user.name
                        ? "Me/MySelf "
                        : item?.createdBy?.name}
                      <Tag>
                        <ReactTimeAgo
                          date={item?.createdAt}
                          locale="en-IN"
                          // timeStyle="twitter"
                        />
                      </Tag>
                    </Typography.Text>
                    <Typography.Text>
                      {item?.comment && parse(item?.comment)}
                    </Typography.Text>
                  </Space.Compact>
                </Col>
                <Col span={3} style={{ paddingLeft: 20 }}>
                  <TaskViewModal id={item.taskId._id} />
                </Col>
              </Row>
              <Row justify={"end"}>
                <Button onClick={() => setIsReply(!isReply)}>
                  Comment/Reply
                </Button>
              </Row>
              {isReply && (
                <ManageReplyForm taskId={item.taskId._id} replyId={item.id} />
              )}
              <Divider />
            </>
          );
        })}
    </Card>
  );
};
export default MentionList;
