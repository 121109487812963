import * as React from "react";
import { Row, Col, Steps, Descriptions, Button } from "antd";
import { useQuery } from "react-query";

import { getPersonnelByIdDetail } from "../Requests";

const { Step } = Steps;

const ShowPersonnelDetailsForm: React.FC<{ id: string }> = (props) => {
  const { data: personnel } = useQuery(
    ["personnel", props.id],
    async () => getPersonnelByIdDetail(props.id),
    {
      refetchOnWindowFocus: false,

      staleTime: 5000,
    }
  );

  const persons = [
    {
      name: "Name",
      selector: "name",
      sortable: true,
      cell: (item: any) => <span>{item?.name}</span>,
    },
    {
      name: "Mobile No.",
      selector: "mobile",

      cell: (item: any) => <span>{"+91-" + item.phone_no}</span>,
    },
    {
      name: "Alt Contact No.",
      selector: "alt_contact",

      cell: (item: any) => <span>{"+91-" + item.alt_phone_no}</span>,
    },
    {
      name: "Email",
      selector: "email",

      cell: (item: any) => <span>{item.email}</span>,
    },
  ];

  const columns = [
    {
      name: "Parameter",
      selector: "parameter",
      sortable: true,
      cell: (item: any) => <span>{item?.title}</span>,
    },
    {
      name: "Range",
      selector: "Range",

      cell: (item: any) => (
        <span>
          {item.minRange +
            item.maxRangeUnit +
            "-" +
            item.maxRange +
            item.maxRangeUnit}
        </span>
      ),
    },
    {
      name: "Least Count",
      selector: "least_count",

      cell: (item: any) => <span>{item.leastCount + item.leastCountUnit}</span>,
    },
    {
      name: "Accuracy",
      selector: "accuracy",

      cell: (item: any) => <span>{item.accuracy + "%"}</span>,
    },
  ];

  return (
    <Row>
      <Col>
        <Descriptions title="Personal Details">
          <Descriptions.Item label="Name:">
            {personnel.partyId.personName}
          </Descriptions.Item>
          <Descriptions.Item label="Mobile:">
            +91-{personnel.partyId.phoneNo}
          </Descriptions.Item>
          <Descriptions.Item label="Email:">
            {personnel.partyId.email}
          </Descriptions.Item>
          <Descriptions.Item label="Gender:">
            {personnel.gender}
          </Descriptions.Item>
          <Descriptions.Item label="Aadhar No.:">
            {personnel.addharNo}
          </Descriptions.Item>
          <Descriptions.Item label="Pan No.:">
            {personnel.panNo}
          </Descriptions.Item>

          <Descriptions.Item label="Address">
            {personnel.partyId.address}
          </Descriptions.Item>
          <Descriptions.Item label="Address">
            {personnel.departmentId.departmentName}
          </Descriptions.Item>
          <Descriptions.Item label="Address">
            {personnel.designationId.designationName}
          </Descriptions.Item>
        </Descriptions>

        <Descriptions
          bordered
          title="Job Details"
          extra={<Button type="primary">Edit</Button>}
        >
          <Descriptions.Item label="Date of Joining">
            {personnel.jobDetailId.doj}
          </Descriptions.Item>
          <Descriptions.Item label="Qualification">
            {" "}
            {personnel.jobDetailId.qualification}
          </Descriptions.Item>
          <Descriptions.Item label="Experience">
            {" "}
            {personnel.jobDetailId.experience}
          </Descriptions.Item>
          <Descriptions.Item label="Employee type">
            {personnel.jobDetailId.empType}
          </Descriptions.Item>
          <Descriptions.Item label="Shift Time">
            {personnel.jobDetailId.shiftTime}
          </Descriptions.Item>
          <Descriptions.Item label="Job Type">
            {personnel.jobDetailId.jobType}
          </Descriptions.Item>
          <Descriptions.Item label="Bio EMP Card">
            {personnel.jobDetailId.bioEmpCode}
          </Descriptions.Item>
          <Descriptions.Item label="Provident A/C">
            {personnel.jobDetailId.pfAccNo}
          </Descriptions.Item>
          <Descriptions.Item label="ESIC No.">
            {personnel.jobDetailId.esicNo}
          </Descriptions.Item>
          {/* <Descriptions.Item label="Bank Details">
            Bank Name: SBI
            <br />
            Acount Holder Name: Manish Kumar
            <br />
            Branch Name: patna
            <br />
            Account No.: 123654789
            <br />
            IFSC : SBIN12365
            <br />
            UPID: 135987
            <br />
          </Descriptions.Item> */}
        </Descriptions>
        {/* <Descriptions title="skill" layout="vertical">
          <Descriptions.Item>Zhou Maomao</Descriptions.Item>
          <Descriptions.Item>1810000000</Descriptions.Item>
          <Descriptions.Item>1810000000</Descriptions.Item>
          <Descriptions.Item>1810000000</Descriptions.Item>
          <Descriptions.Item>Hangzhou, Zhejiang</Descriptions.Item>
          <Descriptions.Item>
            No. 18, Wantang Road, Xihu District, Hangzhou, Zhejiang, China
          </Descriptions.Item>
        </Descriptions> */}
      </Col>
    </Row>
  );
};

export default ShowPersonnelDetailsForm;
