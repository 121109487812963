import moment from "moment";
import * as React from "react";

import { useQuery } from "react-query";
import type { ColumnsType } from "antd/lib/table";

import { Space, Table, Tag } from "antd";

import { getAllAfterSales } from "../Requests";
import { getAccessAuthrorization } from "src/components/access";
import AfterSalesViewModal from "../Modal/ShowAfterSalesDetails";
import ManageAfterSalesModal from "../Modal/ManageAfterSales";

interface DataType {
  id: number;
  name: any;
}
type DataIndex = keyof DataType;
const AfterSalesList: React.FC = () => {
  const [page, setPage] = React.useState(1);
  const [size, setSize] = React.useState(100);
  const [searchText, setSearchText] = React.useState("");
  const [searchedColumn, setSearchedColumn] = React.useState("");
  let accessAuth = getAccessAuthrorization("afterSales");

  const {
    isLoading,
    isError,
    data: response,
  } = useQuery(["afterSales"], () => getAllAfterSales(), {
    refetchOnWindowFocus: false,

    keepPreviousData: true,
    staleTime: 5000,
  });

  if (isLoading || isError) {
    return <div>Loading</div>;
  }
  const column: ColumnsType<DataType> = [
    {
      title: "Project Name",
      dataIndex: "projectId",
      key: "projectId",
      render: (_: any, i: any) => (
        <Tag color="volcano-inverse">{i?.projectId?.projectName}</Tag>
      ),
    },
    {
      title: "Domain Name",
      dataIndex: "domainName",
      key: "domainName",
    },
    {
      title: "Server Name",
      dataIndex: "serverName",
      key: "serverName",
    },
    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      render: (text: any) =>
        text ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">Inactive</Tag>
        ),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (id: any) => (
        <Space size={10}>
          <AfterSalesViewModal id={id} />
          {/* <ManageAfterSalesModal id={id} /> */}
          {accessAuth[0].includes("Edit") && <ManageAfterSalesModal id={id} />}
        </Space>
      ),
    },
  ];

  return <Table size="small" columns={column} dataSource={response} />;
};

export default AfterSalesList;
