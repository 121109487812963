import * as React from "react";

import { Modal, Button, Tooltip } from "antd";
import ShowTaskDetailsForm from "../Form/ShowTaskDetailsForm";
import { EyeOutlined } from "@ant-design/icons";
import { isMobile } from "react-device-detect";
import ShowMobileTaskDetailsForm from "../Form/ShowMobileTaskDetailsForm";
export const ShowTaskDetailsModalContext = React.createContext<{
  setIsModalVisible: (active: boolean) => void;
}>({
  setIsModalVisible: () => true,
});

const TaskViewModal: React.FC<{ id: string }> = ({ id }) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const showModal = () => setIsModalVisible(true);

  const handleOk = () => setIsModalVisible(false);

  const handleCancel = () => setIsModalVisible(false);

  return (
    <ShowTaskDetailsModalContext.Provider value={{ setIsModalVisible }}>
      <Tooltip title="View Task Details">
        <Button
          onClick={showModal}
          type="primary"
          shape="round"
          icon={<EyeOutlined />}
        >
          Task Details
        </Button>
      </Tooltip>
      <Modal
        width={1000}
        // style={{ overflow: "auto" }}
        title=" View Task Request Form"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {isMobile ? (
          <ShowMobileTaskDetailsForm id={id} />
        ) : (
          <ShowTaskDetailsForm id={id} />
        )}
      </Modal>
    </ShowTaskDetailsModalContext.Provider>
  );
};

export default TaskViewModal;
