import * as React from 'react';
import { Link } from 'react-router-dom';
import { routeByAlias } from '../utils/routes'
import {querify} from "../utils/services";

interface TLink {
    children?: React.ReactNode,
    className?: string,
    title?: string,
    parameters?: any,
    query?: any,
    name: string
}

const RouteLink: React.FC<TLink> = ({ name, children, parameters, ...rest }: TLink) => {
    const routeLink = routeByAlias(name, parameters);
    if (routeLink) {
        let queryString = '';
        if (rest.query) {
            queryString = `?${querify(rest.query)}`;
        }
        return (
            <Link to={routeLink.path + queryString} {...rest}>
                {children}
            </Link>
        );
    }
    return (<span>{children}</span>)
};

export default RouteLink;
