import * as React from "react";
import {
  Row,
  Col,
  Statistic,
  Divider,
  Steps,
  Descriptions,
  Space,
  Typography,
  Badge,
  Card,
  Tag,
  Avatar,
  Button,
  message,
  Modal,
  Select,
  Image,
} from "antd";
import { useMutation, useQuery } from "react-query";
import { getTaskById } from "../Requests";
import LocalStorageService from "src/utils/local-storage";
import moment from "moment";
import { s3Path } from "src/utils/s3Config/s3upload";
import parse from "html-react-parser";
import CreateFollowupModal from "src/modules/Leads/Modal/CreateFollowup";
import WorkViewModal from "src/modules/Work/Modal/ShowWorkDetails";
import StoryViewModal from "src/modules/Story/Modal/ShowStoryDetails";
import imLogo from "src/assets/img/imlogo.png";
import ManageReplyForm from "src/modules/Reply/Form/ManageReplyForm";
import {
  DeploymentUnitOutlined,
  FlagOutlined,
  EditOutlined,
} from "@ant-design/icons";
import ShowWorkDetailsForm from "src/modules/Work/Form/ShowWorkForm";
import { request } from "src/utils/services";
import { queryClient } from "src";
import ReactTimeAgo from "react-time-ago";
import { forEach } from "lodash";
import AttachmentModal from "../Modal/AttachmentModal";

const ShowTaskDetailsForm: React.FC<{ id: string }> = (props) => {
  const [user, setUser] = React.useState(LocalStorageService.getAccount());

  const {
    isLoading,
    data: response,
    error,
    isError,
  } = useQuery(["TaskById", props.id], async () => getTaskById(props.id), {
    refetchOnWindowFocus: false,
  });
  // console.log(response);
  // console.log(props.id);
  return (
    <>
      <Badge.Ribbon
        text={response[0]?.type[0]?.title}
        color={response[0]?.type[0]?.color}
        style={{ marginRight: 50 }}
      >
        <Card size="small" style={{ marginRight: 50 }}>
          <Row>
            <Typography.Text code>
              {moment(response[0]?.dueDate).format("DD-MMM-YYYY")}
            </Typography.Text>{" "}
            <Typography.Text strong>{response[0]?.title}</Typography.Text>{" "}
            {response[0]?.leadId?.length > 0 && (
              <>
                <Typography.Text code>
                  {response[0]?.leadId[0]?.partyId[0].personName}
                </Typography.Text>

                {response[0]?.leadId[0]?.partyId[0].companyName && (
                  <Typography.Text code>
                    {response[0]?.leadId[0]?.partyId[0].companyName}
                  </Typography.Text>
                )}
                <Tag color="gold" style={{ marginLeft: 20 }}>
                  {response[0]?.leadId[0]?.leadStatus}
                </Tag>
              </>
            )}
            <Tag color="geekblue" style={{ marginLeft: 20 }}>
              {response[0].status}
            </Tag>
          </Row>
        </Card>
      </Badge.Ribbon>

      <ShowTaskData data={response[0]} />
    </>
  );
};

export default ShowTaskDetailsForm;

export const ShowTaskData: React.FC<{ data: any }> = ({ data }) => {
  const [user, setUser] = React.useState(LocalStorageService.getAccount());
  const [isReply, setIsReply] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const manageTaskMutation = useMutation(
    (values: any) => request("admin.task.update", { id: data._id }, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("task");
        queryClient.invalidateQueries("notification");
        queryClient.invalidateQueries("taskByPersonnel");
      },
    }
  );
  // const { data } = useQuery(
  //   ["TaskById", props.id],
  //   async () => getTaskById(props.id),
  //   {
  //     refetchOnWindowFocus: false,
  //   }
  // );

  const onFinish = async (values: any) => {
    values.taskId = data._id;

    values.updatedBy = user.id;
    //Notification
    values.notificationTitle = "Attn.! Task Assigned by you, Status Updated";
    values.notificationBody =
      "Task: " + data.title + "!  Click to view Changes in task";
    values.receiverId = [data.assignedBy];
    values.senderId = LocalStorageService.getAccount().personnel.id;
    manageTaskMutation
      .mutateAsync(values)
      .then(() => {
        message.success("The task status has been successfully updated.");
        setIsEdit(!isEdit);
      })
      .catch((e: any) => {
        message.error(e);
      });
  };

  // const [file, setFile] = React.useState([""]);

  // React.useEffect(() => {
  //   // let blob = await fetch(url).then((r) => r.blob());

  //   const readFile = async () => {
  //     var tmpFileList: [string] = [""];
  //     await data.attachment.forEach(async (attachment: string) => {
  //       let blob = await fetch(s3Path + attachment).then((r) => r.blob());
  //       tmpFileList.push(window.URL.createObjectURL(blob));
  //     });
  //     // setFile([...file, window.URL.createObjectURL(blob)]);
  //     setFile(tmpFileList);
  //   };
  //   readFile();
  // }, []);
  // console.log(file);

  return (
    <>
      <Row justify={"space-between"}>
        {data?.assignedPersonId && (
          <Col>
            Assigned To:
            <Avatar
              src={
                <img
                  src={
                    data?.assignedPersonId[0]?.party[0]?.imageUrl
                      ? s3Path + data?.assignedPersonId[0]?.party[0]?.imageUrl
                      : imLogo
                  }
                  alt={data?.assignedPersonId[0]?.party[0]?.personName}
                />
              }
            />
            <Tag>{data?.assignedPersonId[0]?.party[0]?.personName}</Tag>
          </Col>
        )}
        <Col>
          {" "}
          {data?.leadId?.length > 0 && (
            <>
              {" "}
              Take Followup
              <CreateFollowupModal leadId={data.leadId[0]._id} />
            </>
          )}
        </Col>
        <Col>
          {" "}
          {data.workId.length > 0 && (
            <>
              {" "}
              {/* View Work Details:
              <WorkViewModal id={data.workId} /> */}
              <Tag color="red" onClick={() => setOpen(true)}>
                <DeploymentUnitOutlined />
                {data.workId[0].title}
              </Tag>
              <Modal
                open={open}
                width={"80%"}
                onCancel={() => setOpen(!open)}
                onOk={() => setOpen(!open)}
              >
                <ShowWorkDetailsForm id={data.workId[0]._id} />{" "}
              </Modal>
            </>
          )}
        </Col>
        <Col>
          {" "}
          {data.storyId.length > 0 && (
            <>
              {" "}
              {/* View Story Details: */}
              {/* <StoryViewModal id={data.storyId} /> */}
              <Tag color="red" onClick={() => setOpen(!open)}>
                <DeploymentUnitOutlined /> {data.storyId[0]?.workId[0]?.title}
              </Tag>
              --
              <Tag color="green" onClick={() => setOpen(!open)}>
                <FlagOutlined /> {data.storyId[0]?.title}
              </Tag>
              <Modal
                open={open}
                width={"80%"}
                onCancel={() => setOpen(!open)}
                onOk={() => setOpen(!open)}
              >
                <ShowWorkDetailsForm id={data.storyId[0]?.workId[0]._id} />{" "}
              </Modal>
            </>
          )}
        </Col>

        {data?.createdBy && (
          <Col>
            Assigned By:
            <Avatar
              src={
                <img
                  src={
                    data?.createdBy[0]?.party[0]?.imageUrl
                      ? s3Path + data?.createdBy[0]?.party[0]?.imageUrl
                      : imLogo
                  }
                  alt={data?.createdBy[0]?.party[0]?.personName}
                />
              }
            />
            <Tag>{data?.createdBy[0]?.party[0]?.personName}</Tag>
          </Col>
        )}

        <Col>
          {!isEdit ? (
            <Tag
              color={
                data.status === "To Do"
                  ? "orange"
                  : data.status === "In Progress"
                  ? "blue"
                  : data.status === "On Hold"
                  ? "volcano"
                  : data.status === "In Review"
                  ? "cyan"
                  : "lime"
              }
            >
              {data?.status}
              <EditOutlined onClick={() => setIsEdit(!isEdit)} />
            </Tag>
          ) : (
            <Select
              placeholder="Select Task Status"
              defaultValue={data.status}
              size="small"
              onChange={(value) => onFinish({ status: value })}
            >
              <Select.Option value="To Do">To Do</Select.Option>
              <Select.Option value="In Progress">In Progress</Select.Option>
              <Select.Option value="On Hold">On Hold</Select.Option>
              <Select.Option value="In Review">In Review</Select.Option>
              <Select.Option value="Done">Completed</Select.Option>
            </Select>
          )}
        </Col>
      </Row>
      <Row justify={"space-between"} style={{ padding: 10 }}>
        <Steps
          direction="horizontal"
          size="small"
          style={{ padding: 10 }}
          current={
            data.status === "To Do"
              ? 0
              : data.status === "In Progress"
              ? 1
              : data.status === "On Hold"
              ? 2
              : data.status === "In Review"
              ? 3
              : 4
          }
          items={[
            { title: "To Do" },
            { title: "In Progress" },
            { title: "On Hold" },
            { title: "In Review" },
            { title: "Done" },
          ]}
        />
        <Col span={23} style={{ padding: 10 }}>
          {data.remarks && parse(data.remarks)}

          {/* <div>
          
            {file && (
              <object
                width={1000}
                height={500}
                data={file}
                // type="application/pdf"
                // scrollbar
              >
                File Load Fails!
              </object>
            )}
          </div> */}

          <Row>
            {data.reply &&
              data.reply.map((element: any, i: any) => {
                return (
                  <>
                    <Row align="top" style={{ width: "100%" }}>
                      <Col span={1}>
                        <Avatar
                          size={"large"}
                          src={
                            <img
                              src={
                                s3Path +
                                element?.createdBy[0]?.party[0]?.imageUrl
                              }
                              alt={"R"}
                            />
                          }
                        />
                      </Col>
                      <Col style={{ paddingLeft: 8 }} span={18}>
                        <Space.Compact direction="vertical">
                          <Typography.Text strong>
                            {element?.createdBy[0]?.name === user.name
                              ? "Me/MySelf "
                              : element?.createdBy[0]?.name}
                            <Tag>
                              <ReactTimeAgo
                                date={element?.createdAt}
                                locale="en-IN"
                                // timeStyle="twitter"
                              />
                            </Tag>
                          </Typography.Text>
                          <Typography.Text>
                            {element?.comment && parse(element?.comment)}
                          </Typography.Text>
                        </Space.Compact>
                      </Col>
                      <Col span={5}>
                        <Avatar.Group>
                          {element.tagto?.map((data: any) => (
                            <>
                              <Avatar
                                src={
                                  <img src={s3Path + data.imageUrl} alt="T" />
                                }
                              />
                            </>
                          ))}
                        </Avatar.Group>
                      </Col>
                    </Row>
                    <Divider />
                  </>
                );
              })}
          </Row>
          <Row justify={"end"}>
            <Button onClick={() => setIsReply(!isReply)}>Comment/Reply</Button>
          </Row>
          {isReply && <ManageReplyForm taskId={data._id} />}
        </Col>
        <Col span={1}>
          {/* {file.length > 0 && <AttachmentModal data={file} />} */}
          {data.attachment.map((ele: any, index: any) => (
            <AttachmentModal data={ele} i={index + 1} />
          ))}
        </Col>
      </Row>
    </>
  );
};
