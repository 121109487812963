import { path } from "./path";

export const taskTemplateRoutes = [
  {
    method: "post",
    path: path + "/api/admin/createTaskTemplate",
    module: "taskTemplate",
    alias: "admin.taskTemplate.create",
    isSubDomain: true,
  },

  {
    method: "put",
    path: path + "/api/admin/updateTaskTemplate/:id",
    module: "taskTemplate",
    alias: "admin.taskTemplate.update",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllActiveTaskTemplate",
    module: "taskTemplate",
    alias: "admin.taskTemplate.list.active",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllTaskTemplate",
    module: "taskTemplate",
    alias: "admin.taskTemplate.list",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findOneTaskTemplate/:id",
    module: "taskTemplate",
    alias: "admin.taskTemplate.id",
    isSubDomain: true,
  },
];
