import * as React from "react";
import {
  Form,
  Input,
  Button,
  message,
  Switch,
  Select,
  DatePicker,
  Row,
  Typography,
  Upload,
} from "antd";
import { request } from "src/utils/services";
import { useMutation, useQuery } from "react-query";
import {
  InfoCircleOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { queryClient } from "src";
import { CreateReplyModalContext } from "../Modal/CreateReply";
import { getAllActiveReply } from "../Requests";
import { getAllPersonnel } from "src/modules/HR/Personnel/Requests";
import { uploadFileToAWS } from "src/utils/s3Config/s3upload";
import {
  RcFile,
  UploadChangeParam,
  UploadFile,
  UploadProps,
} from "antd/es/upload";
import LocalStorageService from "src/utils/local-storage";
const CreateReplyForm: React.FC = () => {
  const modalContext = React.useContext(CreateReplyModalContext);
  const [formLoading, setFormLoading] = React.useState(false);
  // const [form] = Form.useForm();
  const [galley, setGallery] = React.useState<string>();
  const [loading, setLoading] = React.useState(false);
  const [imageUrl, setImageUrl] = React.useState<string>();
  const [selectedFile, setSelectedFile] = React.useState<RcFile>();

  const createReplyMutation = useMutation(
    (values: any) => request("admin.reply.create", {}, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("reply");
      },
    }
  );
  const { data: person } = useQuery(
    ["peronnnel"],
    async () => getAllPersonnel(),
    {
      refetchOnWindowFocus: false,
    }
  );
  const getBase64 = (img: RcFile, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    // if (info.file.status === "uploading") {
    //   setLoading(true);
    //   return;
    // }
    setSelectedFile(info.file.originFileObj);
    if (info.file.status === "uploading") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj as RcFile, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
  };

  const onFinish = async (values: any) => {
    setFormLoading(true);
    values.imageUrl = galley;
    values.createdBy = LocalStorageService.getAccount().id;
    values.status = "To Do";
    createReplyMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The reply has been successfully created.");
        modalContext.setIsModalVisible(false);
        form.resetFields();
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const [form] = Form.useForm();
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        label="Due Date"
        name="dueDate"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "Due Date",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: "Please Select Enquiry Date",
          },
        ]}
        hasFeedback
      >
        <DatePicker />
      </Form.Item>
      <Form.Item
        label="Title "
        name="title"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "Title",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: "Please enter Reply Title",
          },
          { message: "Please enter Reply Title" },
        ]}
        hasFeedback
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Type "
        name="type"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "Type",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: "Please enter Type Name",
          },
          { message: "Please enter Type Name" },
        ]}
        hasFeedback
      >
        <Select placeholder="Select Reply Type">
          <Select.Option value="Lead">Lead</Select.Option>
          <Select.Option value="WorkModule">WorkModule</Select.Option>
          <Select.Option value="TestingBug">TestingBug</Select.Option>
          <Select.Option value="DeployedBug">DeployedBug</Select.Option>
          <Select.Option value="Utilities">Utilities</Select.Option>
          <Select.Option value="Other">Other</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item
        label="Upload Image"
        style={{ fontWeight: "bold" }}
        name="imageUrl"
        valuePropName="checked"
      >
        <Upload
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          accept="image/png, image/jpeg"
          action={async (file) => {
            const response: any = await uploadFileToAWS(file, "reply");
            setGallery(response.key);
            return response.key;
          }}
          onChange={handleChange}
        >
          {imageUrl ? (
            <img src={imageUrl} alt="avatar" style={{ width: "100%" }} />
          ) : (
            uploadButton
          )}
        </Upload>
      </Form.Item>
      <Form.Item
        label="Assigned Person"
        style={{ fontWeight: "bold" }}
        name="assignedPersonId"
        tooltip={{
          title: "Person ",
          icon: <InfoCircleOutlined />,
        }}
      >
        <Select
          // onSelect={OnPersonSelect}
          showSearch
          size="large"
          popupMatchSelectWidth
          // style={{ minHeight: 150 }}
          placeholder="Select Person"
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option!.children as unknown as string).includes(input)
          }
        >
          {person &&
            person?.map((item: any, i: number) => {
              return (
                <Select.Option
                  value={item?.id}
                  key={i}
                  // disabled={item.availability === 0 ? true : false}
                >
                  <Row justify={"space-between"} align={"top"}>
                    <Typography.Text strong>
                      {item?.partyId?.personName}
                    </Typography.Text>
                    {/* <h4>{item?.partyId?.personName}</h4> */}

                    <Typography.Text code>
                      {item.partyId?.phoneNo}
                    </Typography.Text>
                    <Typography.Text code>
                      {" "}
                      {item.partyId?.address}
                    </Typography.Text>
                  </Row>
                </Select.Option>
              );
            })}
        </Select>
      </Form.Item>
      <Form.Item
        label="Value"
        name="value"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "value",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: "Please enter Type Name",
          },
          { message: "Please enter Type Name" },
        ]}
        hasFeedback
      >
        <Select placeholder="Allot Value">
          <Select.Option value="Penny">Penny</Select.Option>
          <Select.Option value="Silver">Silver</Select.Option>
          <Select.Option value="Gold">Gold</Select.Option>
          <Select.Option value="Platinum">Platinum</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        label="Remarks"
        name="remarks"
        style={{ fontWeight: "bold" }}
        // valuePropName="checked"
        // required
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Status"
        name="isActive"
        style={{ fontWeight: "bold" }}
        valuePropName="checked"
        required
      >
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          defaultChecked
        />
      </Form.Item>

      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreateReplyForm;
