import { TRoute } from "../../../utils/routes";
import DepartmentPageHome from "./Page/Home";

const BlogCommentRouter: TRoute[] = [
  {
    path: "/blogComment",
    name: "blogComment",
    title: "blogComment",
    component: DepartmentPageHome,
  },
];

export default BlogCommentRouter;
