import * as React from "react";
import {
  Form,
  Button,
  message,
  Row,
  Typography,
  Input,
  Col,
  Upload,
  Switch,
  Divider,
  Select,
  DatePicker,
} from "antd";

import { request } from "../../../../utils/services";
import { useMutation, useQuery } from "react-query";
import {
  InfoCircleOutlined,
  PlusOutlined,
  MinusCircleOutlined,
  UploadOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { queryClient } from "src";
import type { UploadChangeParam, UploadFile } from "antd/es/upload/interface";
import type { RcFile, UploadProps } from "antd/es/upload";

import TextArea from "antd/lib/input/TextArea";

import LocalStorageService from "src/utils/local-storage";
import { CreateDailyStandupModalContext } from "../Modal/CreateDailyStandup";

import { getAllActiveProject } from "../../Project/Requests";
import { getAllPersonnel } from "src/modules/HR/Personnel/Requests";
import { uploadFileToAWS } from "src/utils/s3Config/s3upload";

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
const { Text } = Typography;
const CreateDailyStandup: React.FC = () => {
  const modalContext = React.useContext(CreateDailyStandupModalContext);
  const [formLoading, setFormLoading] = React.useState(false);
  const [galley, setGallery] = React.useState<string>();
  const [loading, setLoading] = React.useState(false);

  const [imageUrl, setImageUrl] = React.useState<string>();
  const [selectedFile, setSelectedFile] = React.useState<RcFile>();
  const createDailyStandupMutation = useMutation(
    (values: any) => request("admin.dailyStandup.create", {}, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("dailyStandup");
      },
    }
  );
  const { data: project } = useQuery(
    ["project"],
    async () => getAllActiveProject(),
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: team } = useQuery(
    ["personnel"],
    async () => getAllPersonnel(),
    {
      refetchOnWindowFocus: false,
    }
  );
  const getBase64 = (img: RcFile, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    // if (info.file.status === "uploading") {
    //   setLoading(true);
    //   return;
    // }
    setSelectedFile(info.file.originFileObj);
    if (info.file.status === "uploading") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj as RcFile, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
  };

  const onFinish = async (values: any) => {
    values.createdBy = LocalStorageService.getAccount().id;
    // values.partyId = party[values.partyId].id;
    values.problemFacedUrl = galley;

    setFormLoading(true);

    createDailyStandupMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The DailyStandup has been successfully created.");
        form.resetFields();
        modalContext.setIsModalVisible(false);
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const [form] = Form.useForm();
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}
    >
      <Form.Item
        label="Project"
        name="projectId"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "Project",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: "Please Select Project Detail",
          },
          { whitespace: true },
          // { min: 3 },
        ]}
        hasFeedback
      >
        <Select
          // onChange={onPartySelect}
          showSearch
          placeholder="Select Project"
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option!.children as unknown as string).includes(input)
          }
        >
          {project &&
            project?.map((item: any, i: number) => {
              return (
                <Select.Option value={item.id} key={i}>
                  {item?.projectName} | {item?.projectStatus}
                </Select.Option>
              );
            })}
        </Select>
      </Form.Item>
      <Form.Item
        label="Which module you work on?"
        name="moduleWorkOn"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "moduleWorkOn",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: "Please enter which module you work on?",
          },
          { whitespace: true },
          { min: 3 },
        ]}
        hasFeedback
      >
        <Input placeholder="Enter which module you work on?" />
      </Form.Item>

      <Form.Item
        label="Problem Faced Image"
        name="problemFacedUrl"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "problemFacedUrl",
          icon: <InfoCircleOutlined />,
        }}
        hasFeedback
      >
        <Upload
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          accept="image/png, image/jpeg"
          action={async (file) => {
            const response: any = await uploadFileToAWS(file, "dailyStandup");
            setGallery(response.key);
            return response.key;
          }}
          onChange={handleChange}
        >
          {imageUrl ? (
            <img src={imageUrl} alt="avatar" style={{ width: "100%" }} />
          ) : (
            uploadButton
          )}
        </Upload>
      </Form.Item>
      <Form.Item
        label="Problem Faced Detail"
        name="problemFacedDetail"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "Problem Faced Detail",
          icon: <InfoCircleOutlined />,
        }}
        hasFeedback
        rules={[
          {
            required: true,
            message: "Please enter detail what you problem faced?",
          },
          { whitespace: true },
          { min: 3 },
        ]}
      >
        <TextArea placeholder="Enter detail what you problem faced?" />
      </Form.Item>
      <Form.Item
        label="Forward Problem Team"
        name="fowardProblemId"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "Forward Problem",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: "Please Select Team",
          },
          { whitespace: true },
          // { min: 3 },
        ]}
        hasFeedback
      >
        <Select
          // onChange={onPartySelect}
          showSearch
          placeholder="Select Team"
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option!.children as unknown as string).includes(input)
          }
        >
          {team &&
            team?.map((item: any, i: number) => {
              return (
                <Select.Option value={item.id} key={i}>
                  {item?.partyId?.personName}|{" "}
                  {item?.designationId?.designationName}
                </Select.Option>
              );
            })}
        </Select>
      </Form.Item>
      <Form.Item
        label="Status"
        style={{ fontWeight: "bold" }}
        name="status"
        tooltip={{
          title: "status",
          icon: <InfoCircleOutlined />,
        }}
        rules={[{ required: true }]}
      >
        <Input placeholder="Enter Status" />
      </Form.Item>

      <Form.Item
        label="Remarks"
        name="remarks"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "Remarks",
          icon: <InfoCircleOutlined />,
        }}
        hasFeedback
      >
        <TextArea placeholder="Enter  Remarks" />
      </Form.Item>

      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreateDailyStandup;
