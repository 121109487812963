import { request } from "../../../utils/services";

export const getAllActiveDeployment = async () => {
  const { data } = await request("admin.deployment.list.active");
  return data;
};

export const getAllDeployment = async () => {
  const { data } = await request("admin.deployment.list");
  return data;
};

export const getDeploymentById = async (id: string) => {
  const { data } = await request("admin.deployment.id", { id });
  return data;
};

export const getDeploymentByIdDetail = async (id: string) => {
  const { data } = await request("admin.deployment.id.detail", { id });
  return data;
};
export const getDeploymentByWorkId = async (id: string) => {
  const { data } = await request("admin.deployment.by.workId", { id });
  return data;
};
