import { path } from "./path";

export const personnelRoutes = [
  {
    method: "post",
    path: path + "/api/admin/savePersonnel",
    module: "personnel",
    alias: "admin.personnel.create",
    isSubDomain: true,
  },

  {
    method: "put",
    path: path + "/api/admin/updatePersonnel/:id",
    module: "personnel",
    alias: "admin.personnel.update",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllActivePersonnel",
    module: "personnel",
    alias: "admin.personnel.list.active",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllPersonnel",
    module: "personnel",
    alias: "admin.personnel.list",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findOnePersonnel/:id",
    module: "personnel",
    alias: "admin.personnel.id",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findOnePersonnelDetail/:id",
    module: "personnel",
    alias: "admin.personnel.id.detail",
    isSubDomain: true,
  },
];
