import * as React from "react";
import {
  Descriptions,
  Badge,
  Card,
  Row,
  Image,
  Space,
  Typography,
  Col,
  Avatar,
  Divider,
} from "antd";
import { useQuery } from "react-query";
import {
  getAllConcernedPersonByPartyId,
  getBankDetailsByPartyId,
  getPartyById,
} from "../Requests";
import {
  ProfileOutlined,
  PhoneOutlined,
  HomeOutlined,
  GlobalOutlined,
  MailOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { s3Path } from "../../../utils/s3Config/s3upload";
import { getUserByPartyId } from "src/modules/User/Requests";
import Meta from "antd/es/card/Meta";
// import { getUserByPartyId } from "../../User/Requests";
const { Text } = Typography;
const ShowPartyDetailsForm: React.FC<{ id: string }> = (props) => {
  const { data: party } = useQuery(
    ["party", props.id],
    async () => getPartyById(props.id),
    {
      refetchOnWindowFocus: false,

      staleTime: 5000,
    }
  );
  const {
    //isLoading,
    data: user,
    //error,
    //isError,
  } = useQuery(["user", props.id], async () => getUserByPartyId(props.id), {
    refetchOnWindowFocus: false,
    // enabled: false,
  });
  const {
    //isLoading,
    data: concernedPerson,
    //error,
    //isError,
  } = useQuery(
    ["bankDetails", props.id],
    async () => getAllConcernedPersonByPartyId(props.id),
    {
      refetchOnWindowFocus: false,
      // enabled: false,
    }
  );
  const {
    //isLoading,
    data: bankDetails,
    //error,
    //isError,
  } = useQuery(
    ["bankDetails", props.id],
    async () => getBankDetailsByPartyId(props.id),
    {
      refetchOnWindowFocus: false,
      // enabled: false,
    }
  );

  return (
    <Card>
      <Row gutter={20} justify="space-between">
        <Image src={s3Path + party.imageUrl} height={150} width={150} />

        <Space>
          <Space.Compact direction="horizontal">
            {party?.partyType.map((element: any) => (
              <Text type="success" code>
                {element}
              </Text>
            ))}
            {/* <Text type="warning" code>
              {party?.classification}
            </Text> */}
          </Space.Compact>
        </Space>
      </Row>
      <br />
      {user.length > 0 ? (
        <Card>
          <Descriptions
            title="User Info"
            // extra={<ManageUserDetailsModal id={party.id} />}
          >
            <Descriptions.Item label="User Name">
              {user[0].username}
            </Descriptions.Item>
            <Descriptions.Item label="Roles">
              {user[0]?.roles.map((element: any) => (
                <Text type="success" code>
                  {element}
                </Text>
              ))}
            </Descriptions.Item>
          </Descriptions>
        </Card>
      ) : (
        <></>
      )}
      <br />
      <Card>
        <Descriptions
          title="Basic Info"
          // extra={<PartyManageModal id={party.id} />}
        >
          <Descriptions.Item label="Name">{party.personName}</Descriptions.Item>
          <Descriptions.Item label="Company">
            {party.companyName}
          </Descriptions.Item>
          <Descriptions.Item label="Mobile">
            +91-{party.phoneNo}
          </Descriptions.Item>
          <Descriptions.Item label="Email">{party.email}</Descriptions.Item>
          <Descriptions.Item label="Website">{party.website}</Descriptions.Item>
          <Descriptions.Item label="GST">{party.gstInNo}</Descriptions.Item>
          <Descriptions.Item label="Address">{party.address}</Descriptions.Item>
          {/* <Descriptions.Item label="Location" span={2}>
            <Text code>
              {party.localId.localityName}-{party.localId.block}-
              {party.localId.district}-{party.localId.pinCode}-
              {party.localId.state}
            </Text>
          </Descriptions.Item> */}
        </Descriptions>
        {/* <Divider orientation="center"> Concerned Person detail</Divider> */}
        <Row gutter={[11, 11]}>
          {concernedPerson &&
            concernedPerson?.map((item: any, i: number) => {
              return (
                <Col span={12}>
                  <Card bordered={true}>
                    <Meta
                      avatar={<Avatar size="large" icon={<UserOutlined />} />}
                      title={item?.name}
                      description={
                        <Col>
                          {item.designation}
                          <br />
                          {item.mobile}
                          <br />
                          {item.email}
                        </Col>
                      }
                    />
                  </Card>
                </Col>
              );
            })}
        </Row>
      </Card>
      <br />
      {bankDetails.length > 0 ? (
        <Card>
          <Descriptions
            title="Bank Details"
            // extra={<ManageBankDetailsModal id={party.id} />}
          >
            <Descriptions.Item label="Account No ">
              {bankDetails[0].accNo}
            </Descriptions.Item>
            <Descriptions.Item label="Acc. Holder Name">
              {bankDetails[0].accHolderName}
            </Descriptions.Item>
            <Descriptions.Item label="UPI ID">
              {bankDetails[0].upiId}
            </Descriptions.Item>
            <Descriptions.Item label="IFSC Code">
              {bankDetails[0].ifscCode}
            </Descriptions.Item>
            <Descriptions.Item label="Bank Name">
              {bankDetails[0].bankName}
            </Descriptions.Item>
            <Descriptions.Item label="Branch">
              {bankDetails[0].branch}
            </Descriptions.Item>
          </Descriptions>
        </Card>
      ) : (
        <></>
      )}
    </Card>
  );
};

export default ShowPartyDetailsForm;
