import { path } from "./path";

export const blogCommentRoutes = [
  {
    method: "post",
    path: path + "/api/admin/createBlogComment",
    module: "blogComment",
    alias: "admin.blogComment.create",
    isSubDomain: true,
  },

  {
    method: "put",
    path: path + "/api/admin/updateBlogComment/:id",
    module: "blogComment",
    alias: "admin.blogComment.update",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllActiveBlogComment",
    module: "blogComment",
    alias: "admin.blogComment.list.active",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllBlogComment",
    module: "blogComment",
    alias: "admin.blogComment.list",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findOneBlogComment/:id",
    module: "blogComment",
    alias: "admin.blogComment.id",
    isSubDomain: true,
  },
];
