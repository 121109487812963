import * as React from "react";
import {
  Form,
  Input,
  InputNumber,
  Button,
  message,
  Switch,
  Select,
  Col,
  Row,
  Checkbox,
  DatePicker,
} from "antd";
import { request } from "../../../utils/services";
import { useMutation } from "react-query";
import {
  InfoCircleOutlined,
  BankOutlined,
  HomeOutlined,
  MobileOutlined,
  GlobalOutlined,
  MailOutlined,
  AuditOutlined,
  UserOutlined,
  PushpinOutlined,
} from "@ant-design/icons";
import { queryClient } from "../../../../src";
import { CreateConcernedPersonModalContext } from "../Modal/concernedPerson";
import LocalStorageService from "../../../utils/local-storage";
const CreateConcernedPersonForm: React.FC<{ partyId?: string }> = (props) => {
  const modalContext = React.useContext(CreateConcernedPersonModalContext);
  const [formLoading, setFormLoading] = React.useState(false);

  const createConcernedPersonMutation = useMutation(
    (values: any) => request("admin.concernedperson.create", {}, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("concernedperson");
      },
    }
  );

  const onFinish = async (values: any) => {
    setFormLoading(true);
    values.createdBy = LocalStorageService.getAccount().id;

    values.partyId = props.partyId;

    createConcernedPersonMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The concernedPerson has been successfully created.");
        form.resetFields();
        modalContext.setIsModalVisible(false);
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const [form] = Form.useForm();
  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}
    >
      <Form.Item
        label="Name"
        name="name"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "name",
          icon: <InfoCircleOutlined />,
        }}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Mobile No"
        name="mobile"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "mobile",
          icon: <InfoCircleOutlined />,
        }}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Email"
        style={{ fontWeight: "bold" }}
        name="email"
        // valuePropName="checked"
      >
        <Input />
      </Form.Item>

      <Col flex={1}>
        <Form.Item
          label="Designation"
          name="designation"
          style={{ fontWeight: "bold" }}
          tooltip={{
            title: "designation",
            icon: <InfoCircleOutlined />,
          }}
        >
          <Input />
        </Form.Item>
      </Col>
      {/* </Row> */}

      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreateConcernedPersonForm;
