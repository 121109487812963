import * as React from "react";
import { Form, Input, Button, message, Switch } from "antd";
import { request } from "src/utils/services";
import { useMutation } from "react-query";
import { InfoCircleOutlined } from "@ant-design/icons";
import { queryClient } from "src";
import { CreateDepartmentModalContext } from "../Modal/CreateDepartment";
import LocalStorageService from "src/utils/local-storage";

const CreateDepartmentForm: React.FC = () => {
  const modalContext = React.useContext(CreateDepartmentModalContext);
  const [formLoading, setFormLoading] = React.useState(false);
  const [form] = Form.useForm();
  const createDepartmentMutation = useMutation(
    (values: any) => request("admin.department.create", {}, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("department");
      },
    }
  );

  const onFinish = async (values: any) => {
    values.createdBy = LocalStorageService.getAccount().id;
    setFormLoading(true);

    createDepartmentMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The department has been successfully created.");
        form.resetFields();
        modalContext.setIsModalVisible(false);
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        label="Department Name"
        name="departmentName"
        tooltip={{
          title: "Department Name",
          icon: <InfoCircleOutlined />,
        }}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Status"
        name="isActive"
        valuePropName="checked"
        required
      >
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          defaultChecked
        />
      </Form.Item>

      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreateDepartmentForm;
