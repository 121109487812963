import { path } from "./path";

export const proposalRoutes = [
  {
    method: "post",
    path: path + "/api/admin/createProposal",
    module: "proposal",
    alias: "admin.proposal.create",
    isSubDomain: true,
  },

  {
    method: "put",
    path: path + "/api/admin/updateProposal/:id",
    module: "proposal",
    alias: "admin.proposal.update",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllActiveProposal",
    module: "proposal",
    alias: "admin.proposal.list.active",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllProposal",
    module: "proposal",
    alias: "admin.proposal.list",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findOneProposal/:id",
    module: "proposal",
    alias: "admin.proposal.id",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findOneProposalWithDetail/:id",
    module: "proposal",
    alias: "admin.proposal.id.with.detail",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findAllProposalByPartyId/:id",
    module: "proposal",
    alias: "admin.proposal.by.partyId",
  },
];
