import { TRoute } from "../../utils/routes";
import UserPageHome from "./Page/Home";

const UserRouter: TRoute[] = [
  {
    path: "/user",
    name: "user",
    title: "User",
    component: UserPageHome,
  },
];

export default UserRouter;
