import { TRoute } from "../../../utils/routes";
import PersonnelPageHome from "./Page/Home";

const PersonnelRouter: TRoute[] = [
  {
    path: "/personnel",
    name: "personnel",
    title: "Personnel",
    component: PersonnelPageHome,
  },
];

export default PersonnelRouter;
