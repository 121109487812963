import { TRoute } from "src/utils/routes";
import DepartmentPageHome from "./Page/Home";

const QuotationRouter: TRoute[] = [
  {
    path: "/quotation",
    name: "quotation",
    title: "quotation",
    component: DepartmentPageHome,
  },
];

export default QuotationRouter;
