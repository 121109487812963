import * as React from "react";
import {
  Form,
  Button,
  message,
  Row,
  Typography,
  Input,
  Col,
  Upload,
  Switch,
  Divider,
  Radio,
  Select,
  DatePicker,
  Tag,
  Avatar,
} from "antd";

import { request } from "../../../utils/services";
import { useMutation, useQuery } from "react-query";
import {
  InfoCircleOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { queryClient } from "src";
import type { UploadFile } from "antd/es/upload/interface";
import type { RcFile, UploadProps } from "antd/es/upload";

import TextArea from "antd/lib/input/TextArea";

import LocalStorageService from "src/utils/local-storage";
import { CreateWorkModalContext } from "../Modal/CreateWork";
import { values } from "lodash";
import { getAllActiveParty } from "src/modules/Party/Requests";
import {
  getAllActiveTechnology,
  getAllTechnology,
} from "src/modules/technology/Requests";
import {
  getAllActivePersonnel,
  getAllPersonnel,
} from "src/modules/HR/Personnel/Requests";
import ReactQuill from "react-quill";
import { Editor } from "@tinymce/tinymce-react";
import { tinyMCE } from "src/utils/keys";
import {
  deleteFileToAWS,
  s3Path,
  uploadFileToAWS,
} from "src/utils/s3Config/s3upload";
import CreatePartyModal from "src/modules/Party/Modal/CreateParty";
import { imLogoIcon } from "src/constants/logo";
import { getAllLead } from "../../Leads/Requests";
import CreateLeadsModal from "../../Leads/Modal/CreateLeads";

const { Text } = Typography;
const CreateWork: React.FC = () => {
  const modalContext = React.useContext(CreateWorkModalContext);
  const [formLoading, setFormLoading] = React.useState(false);
  const [remarks, setRemarks] = React.useState<string>();
  const [imageUrl, setImageUrl] = React.useState<string[]>([]);
  const [editorkey, seteditorkey] = React.useState(4);

  const createWorkMutation = useMutation(
    (values: any) => request("admin.work.create", {}, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("work");
      },
    }
  );

  const { data: party } = useQuery(["party"], async () => getAllActiveParty(), {
    refetchOnWindowFocus: false,
  });
  const { data: technology } = useQuery(
    ["technology"],
    async () => getAllActiveTechnology(),
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: lead } = useQuery(["lead"], async () => getAllLead(), {
    refetchOnWindowFocus: false,
  });
  const { data: personnel } = useQuery(
    ["personnel"],
    async () => getAllPersonnel(),
    {
      refetchOnWindowFocus: false,
    }
  );
  const onFinish = async (values: any) => {
    values.createdBy = LocalStorageService.getAccount().id;
    values.description = remarks;
    values.attachment = imageUrl;
    //Notification Values
    values.notificationTitle = "Wake up! You are added to team in New Work";
    values.notificationBody =
      "Be Prepare , Responsibility is at your Shoulder!  Click to view work details";
    values.receiverId = values.teamId;
    values.senderId = LocalStorageService.getAccount().personnel.id;
    setFormLoading(true);

    createWorkMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The Work has been successfully created.");
        form.resetFields();
        cleareditor();
        modalContext.setIsModalVisible(false);
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const [form] = Form.useForm();

  // const modules = React.useMemo(
  //   () => ({
  //     toolbar: {
  //       container: [
  //         [{ font: [] }],
  //         [{ header: [1, 2, 3, 4, 5, 6, false] }],
  //         ["bold", "italic", "underline", "strike"],
  //         [{ color: [] }, { background: [] }],
  //         [{ script: "sub" }, { script: "super" }],
  //         ["blockquote", "code-block"],
  //         [{ list: "ordered" }, { list: "bullet" }],

  //         [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
  //         [{ direction: "rtl" }],
  //         [{ size: ["small", false, "large", "huge"] }],
  //         ["link", "image", "video"],
  //         ["clean"],
  //       ],

  //       //  handlers: {
  //       //    image: handleClick,
  //       //  },
  //       history: {
  //         delay: 500,
  //         maxStack: 100,
  //         userOnly: true,
  //       },
  //     },
  //   }),
  //   []
  // );
  const options = [
    { label: "Project", value: "Project" },
    { label: "Product", value: "Product" },
    { label: "Other", value: "Other" },
  ];
  const statusOptions = [
    {
      value: "New",
      label: "New",
    },
    {
      value: "In Progress",
      label: "In Progress",
    },
    {
      value: "On Hold",
      label: "On Hold",
    },
    {
      value: "Testing",
      label: "Testing",
    },
    {
      value: "Buged",
      label: "Buged",
    },
    {
      value: "Deployed",
      label: "Deployed",
    },
    {
      value: "Cancelled",
      label: "Cancelled",
    },
    {
      value: "Ticket Open",
      label: "Ticket Open",
    },
    {
      value: "Ticket Closed",
      label: "Ticket Closed",
    },
  ];
  let clientList = party.filter(
    (element: any) => element.partyType[0] === "Client"
  );
  let vendorList = party.filter(
    (element: any) => element.partyType[0] === "Vendor"
  );
  let leadList = party.filter(
    (element: any) => element.partyType[0] === "Lead Provider"
  );

  let partyList = [...clientList, ...vendorList, ...leadList];
  const handleEditorChange = (content: any, editor: any) => {
    // console.log("Content was updated:", content);
    setRemarks(content);
  };

  const cleareditor = () => {
    const newkey = editorkey * 43;
    seteditorkey(newkey);
  };

  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}
    >
      <Row align="middle" justify="space-between">
        <Form.Item
          // label="Type"
          style={{ fontWeight: "bold" }}
          name="workType"
        >
          <Radio.Group options={options} />
        </Form.Item>
      </Row>
      <Row gutter={20}>
        <Col flex={3} span={8}>
          <Form.Item
            label="Select Lead"
            name="leadId"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "leadId",
              icon: <InfoCircleOutlined />,
            }}
            rules={
              [
                // {
                //   required: true,
                //   message: "Please Select  Party",
                // },
              ]
            }
            hasFeedback
          >
            <Select
              //onChange={onPartySelect}
              showSearch
              placeholder="Select Lead"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option!.children as unknown as string).includes(input)
              }
              dropdownRender={(menu) => (
                <>
                  <Row justify={"end"}>
                    <CreateLeadsModal />
                  </Row>
                  {menu}
                </>
              )}
            >
              {lead &&
                lead?.map((item: any, i: number) => {
                  return (
                    <Select.Option value={item.id} key={i}>
                      <Typography.Text strong>{item?.title}</Typography.Text>
                      <Avatar
                        src={
                          <img
                            src={
                              item?.partyId?.imageUrl
                                ? s3Path + item?.partyId?.imageUrl
                                : imLogoIcon
                            }
                            alt={item?.partyId?.personName[0]}
                          />
                        }
                      />
                      <Typography.Text code>
                        {item?.partyId.personName}-{item?.partyId?.companyName}
                      </Typography.Text>
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Col>
        <Col flex={3} span={16}>
          <Form.Item
            label="Select Party"
            name="partyId"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "partyId",
              icon: <InfoCircleOutlined />,
            }}
            rules={
              [
                // {
                //   required: true,
                //   message: "Please Select  Party",
                // },
              ]
            }
            hasFeedback
          >
            <Select
              //onChange={onPartySelect}
              showSearch
              placeholder="Select Party"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option!.children as unknown as string).includes(input)
              }
              dropdownRender={(menu) => (
                <>
                  <Row justify={"end"}>
                    <CreatePartyModal />
                  </Row>
                  {menu}
                </>
              )}
            >
              {partyList &&
                partyList?.map((item: any, i: number) => {
                  return (
                    <Select.Option value={item.id} key={i}>
                      <Avatar
                        src={
                          <img
                            src={
                              item.imageUrl
                                ? s3Path + item.imageUrl
                                : imLogoIcon
                            }
                            alt={item?.personName[0]}
                          />
                        }
                      />
                      <Typography.Text strong>
                        {item?.personName}-{item?.companyName}
                      </Typography.Text>
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Col>
        <Col flex={3} span={4}>
          <Form.Item
            label="Start Date"
            name="startDate"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "start Date",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please Select Start Date",
              },
            ]}
            hasFeedback
          >
            <DatePicker />
          </Form.Item>
        </Col>
        <Col flex={3} span={4}>
          <Form.Item
            label="End Date"
            name="endDate"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "End Date",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please Select End Date",
              },
            ]}
            hasFeedback
          >
            <DatePicker />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col flex={3} span={12}>
          <Form.Item
            label="Title"
            name="title"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "Title",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please enter title",
              },
              { whitespace: true },
              { min: 3 },
            ]}
            hasFeedback
          >
            <Input
              placeholder="Enter Tilte"
              // addonBefore={<ToolOutlined className="site-form-item-icon" />}
            />
          </Form.Item>
        </Col>
        <Col flex={3} span={12}>
          <Form.Item
            label="Select Technology"
            name="technologyId"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "technology",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please Select technology",
              },
            ]}
            hasFeedback
          >
            <Select mode="multiple" placeholder="Please Select technology">
              {technology &&
                technology?.map((item: any, i: number) => {
                  return (
                    <Select.Option value={item?.id} key={i}>
                      {item?.title}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={20}>
        <Col flex={3} span={12}>
          <Form.Item
            label="Work Status"
            name="workStatus"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "work Status",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please Select Work status",
              },
            ]}
            hasFeedback
          >
            <Select placeholder="Select Work Status" options={statusOptions} />
          </Form.Item>
        </Col>
        <Col flex={3} span={12}>
          <Form.Item
            label="Select Team"
            name="teamId"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "team",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please Select team",
              },
            ]}
            hasFeedback
          >
            <Select mode="multiple" placeholder="Please Select team">
              {personnel &&
                personnel?.map((item: any, i: number) => {
                  return (
                    <Select.Option
                      value={item?.id}
                      key={i}
                      // disabled={item.availability === 0 ? true : false}
                    >
                      <Row justify={"space-between"} align={"middle"}>
                        <Typography.Text strong>
                          {item?.partyId?.personName}
                        </Typography.Text>

                        <Avatar
                          src={
                            <img
                              src={s3Path + item.partyId.imageUrl}
                              alt={item?.partyId?.personName[0]}
                            />
                          }
                        />
                      </Row>
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      {/* <Form.Item
        label="Description"
        name="description"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "description",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: " please enter description",
          },
        ]}
        hasFeedback
      >
        <ReactQuill theme="snow" modules={modules} />
      </Form.Item>
      <Form.Item>
        <Upload
          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          listType="picture-card"
          fileList={fileList}
          onPreview={handlePreview}
          onChange={handleChange}
        >
          {fileList.length >= 8 ? null : uploadButton}
        </Upload>
      </Form.Item> */}
      <Editor
        key={editorkey}
        apiKey={tinyMCE.apiKey}
        // onInit={(evt, editor) => (editorRef.current = editor)}
        init={{
          plugins: tinyMCE.plugins,
          toolbar: tinyMCE.toolbar,
          tinycomments_mode: "embedded",
          tinycomments_author: "Author name",
          // mergetags_list: [
          //   { value: "First.Name", title: "First Name" },
          //   { value: "Email", title: "Email" },
          // ],

          paste_data_images: true,

          ai_request: (request: any, respondWith: any) =>
            respondWith.string(() =>
              Promise.reject("See docs to implement AI Assistant")
            ),
        }}
        // initialValue="Welcome to TinyMCE!"
        onEditorChange={handleEditorChange}
        // outputFormat="html"
      />

      <Row justify={"space-between"}>
        <Form.Item
          label="Attachment"
          style={{ fontWeight: "bold" }}
          name="attachment"
          valuePropName="checked"
        >
          <Upload
            accept="application/pdf,application/msword, image/png, image/jpeg, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            maxCount={4}
            multiple
            listType="picture"
            action={async (file) => {
              const response: any = await uploadFileToAWS(file, "task").catch(
                (err: any) => message.error(err?.toString())
              );
              setImageUrl([...imageUrl, response.key]);
              return response.key;
            }}
            beforeUpload={(fileList) => {
              console.log("FILE", fileList.size);
              return fileList.size / 1024 / 1024 <= 2 ? true : false;
            }}
            onRemove={(e) => {
              if (e?.error?.url) {
                deleteFileToAWS(e.error.url).then((value) =>
                  message.success(value)
                );
                var file = imageUrl.filter((item) => item !== e.error.url);
                setImageUrl(file);
              }
            }}
          >
            {imageUrl.length < 4 && (
              <Button icon={<UploadOutlined />}>Attachment</Button>
            )}
          </Upload>
        </Form.Item>

        <Form.Item
          label="Status"
          style={{ fontWeight: "bold" }}
          name="isActive"
          tooltip={{
            title: "status",
            icon: <InfoCircleOutlined />,
          }}
          valuePropName="checked"
          // rules={[{ required: true }]}
        >
          <Switch
            checkedChildren="Active"
            unCheckedChildren="Inactive"
            defaultChecked
          />
        </Form.Item>
      </Row>

      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreateWork;
