import { path } from "./path";

export const designationRoutes = [
  {
    method: "post",
    path: path + "/api/admin/saveDesignation",
    module: "designation",
    alias: "admin.designation.create",
    isSubDomain: true,
  },

  {
    method: "put",
    path: path + "/api/admin/updateDesignation/:id",
    module: "designation",
    alias: "admin.designation.update",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllActiveDesignation",
    module: "designation",
    alias: "admin.designation.list.active",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllDesignation",
    module: "designation",
    alias: "admin.designation.list",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findOneDesignation/:id",
    module: "designation",
    alias: "admin.designation.id",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findAllDesignationByDepartmentId/:id",
    module: "designation",
    alias: "admin.designation.department.id",
    isSubDomain: true,
  },
];
