import { TRoute } from "../../utils/routes";
import CompanyPageHome from "./Page/Home";

const CompanyRouter: TRoute[] = [
  {
    path: "/company",
    name: "company",
    title: "Company",
    component: CompanyPageHome,
  },
];

export default CompanyRouter;
