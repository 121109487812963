import { TRoute } from "../../../utils/routes";
import DepartmentPageHome from "./Page/Home";

const BlogRouter: TRoute[] = [
  {
    path: "/blog",
    name: "blog",
    title: "blog",
    component: DepartmentPageHome,
  },
];

export default BlogRouter;
