import * as React from "react";
import {
  Form,
  Button,
  message,
  Row,
  Typography,
  Input,
  Col,
  Upload,
  Switch,
  Divider,
  Select,
  DatePicker,
} from "antd";

import { request } from "../../../../utils/services";
import { useMutation, useQuery } from "react-query";
import { InfoCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { queryClient } from "src";
import type { UploadFile } from "antd/es/upload/interface";
import type { RcFile, UploadProps } from "antd/es/upload";

import TextArea from "antd/lib/input/TextArea";

import LocalStorageService from "src/utils/local-storage";
import { CreateAfterSalesModalContext } from "../Modal/CreateAfterSales";
import { getAllActiveParty } from "src/modules/Party/Requests";
import { getAllActiveProject } from "../../Project/Requests";

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
const { Text } = Typography;
const CreateAfterSales: React.FC = () => {
  const modalContext = React.useContext(CreateAfterSalesModalContext);
  const [formLoading, setFormLoading] = React.useState(false);
  const createAfterSalesMutation = useMutation(
    (values: any) => request("admin.afterSales.create", {}, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("afterSales");
      },
    }
  );
  const { data: project } = useQuery(
    ["project"],
    async () => getAllActiveProject(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const onFinish = async (values: any) => {
    values.createdBy = LocalStorageService.getAccount().id;
    // values.partyId = party[values.partyId].id;
    setFormLoading(true);

    createAfterSalesMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The AfterSales has been successfully created.");
        form.resetFields();
        modalContext.setIsModalVisible(false);
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const [form] = Form.useForm();
  const [previewOpen, setPreviewOpen] = React.useState(false);
  const [previewImage, setPreviewImage] = React.useState("");
  const [previewTitle, setPreviewTitle] = React.useState("");
  const [fileList, setFileList] = React.useState<UploadFile[]>([]);
  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };
  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) =>
    setFileList(newFileList);

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}
    >
      {/* <Row gutter={20}>
        <Col flex={3} span={12}> */}
      <Form.Item
        label="Project Detail"
        name="projectId"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "Project Detail",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: "Please Select Project Detail",
          },
        ]}
        hasFeedback
      >
        <Select
          // onChange={onPartySelect}
          showSearch
          placeholder="Select Project"
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option!.children as unknown as string).includes(input)
          }
        >
          {project &&
            project?.map((item: any, i: number) => {
              return (
                <Select.Option value={item.id} key={i}>
                  {item?.projectName}
                </Select.Option>
              );
            })}
        </Select>
      </Form.Item>
      {/* </Col>
        <Col flex={3} span={12}> */}
      <Form.Item
        label="Domain Name"
        name="domainName"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "Domain Name",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: "Please Enter Domain Name",
          },
          { whitespace: true },
          { min: 3 },
        ]}
        hasFeedback
      >
        <Input placeholder="Enter Domain Name" />
      </Form.Item>
      <Form.Item
        label="Server Name"
        name="serverName"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "Server Name",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: "Please Enter Server Name",
          },
          { whitespace: true },
          { min: 3 },
        ]}
        hasFeedback
      >
        <Input placeholder="Enter Server Name" />
      </Form.Item>
      <Row gutter={24}>
        <Col flex={2} span={8}>
          <Form.Item
            label="Domain Expiry Date"
            name="domainExpiryDate"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "Domain Expiry Date",
              icon: <InfoCircleOutlined />,
            }}
            hasFeedback
          >
            <DatePicker />
          </Form.Item>
        </Col>
        <Col flex={2} span={8}>
          <Form.Item
            label="Server Expiry Date"
            name="serverExpiryDate"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "Server Expiry Date",
              icon: <InfoCircleOutlined />,
            }}
            hasFeedback
          >
            <DatePicker />
          </Form.Item>
        </Col>
        <Col flex={2} span={8}>
          <Form.Item
            label="Maintainance Expiry Date"
            name="maintainanceExpiryDate"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "Maintainance Expiry Date",
              icon: <InfoCircleOutlined />,
            }}
            hasFeedback
          >
            <DatePicker />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col flex={2} span={12}>
          <Form.Item
            label="Status"
            style={{ fontWeight: "bold" }}
            name="isActive"
            tooltip={{
              title: "status",
              icon: <InfoCircleOutlined />,
            }}
            valuePropName="checked"
            // rules={[{ required: true }]}
          >
            <Switch
              checkedChildren="Active"
              unCheckedChildren="Inactive"
              defaultChecked
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreateAfterSales;
