import { TRoute } from "src/utils/routes";
import DepartmentPageHome from "./Page/Home";

const StoryRouter: TRoute[] = [
  {
    path: "/story",
    name: "story",
    title: "story",
    component: DepartmentPageHome,
  },
];

export default StoryRouter;
