import { path } from "./path";

export const profileRoutes = [
  {
    method: "post",
    path: path + "/api/admin/saveProfile",
    module: "profile",
    alias: "admin.profile.create",
    isSubDomain: true,
  },

  {
    method: "put",
    path: path + "/api/admin/updateProfile/:id",
    module: "profile",
    alias: "admin.profile.update",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllActiveProfile",
    module: "profile",
    alias: "admin.profile.list.active",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllProfile",
    module: "profile",
    alias: "admin.profile.list",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findOneProfile/:id",
    module: "profile",
    alias: "admin.profile.id",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findDataByIfscCode/:ifsc",
    module: "profile",
    alias: "admin.profile.ifsc",
    isSubDomain: true,
  },
];
