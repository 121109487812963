import * as React from "react";
import { Form, Input, Button, message, Switch, Select } from "antd";
import { request } from "src/utils/services";
import { useMutation } from "react-query";
import { InfoCircleOutlined } from "@ant-design/icons";
import { queryClient } from "src";
import { CreateDesignationModalContext } from "../Modal/CreateDesignation";

import { useQuery } from "react-query";

import { getAllActiveDepartment } from "../../Department/Requests";
import LocalStorageService from "src/utils/local-storage";

const CreateDesignationForm: React.FC = () => {
  const modalContext = React.useContext(CreateDesignationModalContext);
  const [formLoading, setFormLoading] = React.useState(false);
  const [form] = Form.useForm();

  const createDesignationMutation = useMutation(
    (values: any) => request("admin.designation.create", {}, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("designation");
      },
    }
  );
  const { data: department } = useQuery(
    ["department"],
    async () => getAllActiveDepartment(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const onFinish = async (values: any) => {
    values.createdBy = LocalStorageService.getAccount().id;
    setFormLoading(true);
    createDesignationMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The designation has been successfully created.");
        form.resetFields();

        modalContext.setIsModalVisible(false);
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}
    >
      <Form.Item
        label="Department Name"
        name="departmentId"
        tooltip={{
          title: "Department Name",
          icon: <InfoCircleOutlined />,
        }}
        rules={[{ required: true }]}
      >
        <Select>
          {department &&
            department?.map((item: any, i: number) => {
              return (
                <Select.Option value={item?.id} key={i}>
                  {item?.departmentName}
                </Select.Option>
              );
            })}
        </Select>
      </Form.Item>
      <Form.Item
        label="Designation"
        name="designationName"
        tooltip={{
          title: "designation",
          icon: <InfoCircleOutlined />,
        }}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Status"
        name="isActive"
        valuePropName="checked"
        required
      >
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          defaultChecked
        />
      </Form.Item>

      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreateDesignationForm;
