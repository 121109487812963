import { Empty, Row } from "antd";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../components/Card";
import RolesList from "../Component/Home/List";
import CreateRolesModal from "../Modal/CreateRoles";
import { getAccessAuthrorization } from "src/components/access";

const RolesPageHome: React.FC<RouteComponentProps> = () => {
  let accessAuth = getAccessAuthrorization("roles");

  return (
    <React.Fragment>
      <Card>
        <CardHeader>
          <CardHeaderToolbar>
            <Row justify="space-between">
              <h2>Roles List</h2>
              {/* <CreateRolesModal /> */}
              {accessAuth[0].includes("Create") && <CreateRolesModal />}
            </Row>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          {/* <RolesList /> */}
          {accessAuth[0].includes("View") ? (
            <RolesList />
          ) : (
            <Empty
              image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
              imageStyle={{ height: 60 }}
              description={<span>You are Not Authorised!</span>}
            />
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default withRouter(RolesPageHome);
