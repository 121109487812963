import moment from "moment";
import * as React from "react";

import { useQuery } from "react-query";
import type { ColumnsType } from "antd/lib/table";

import { Space, Table, Tag, Image, Empty } from "antd";

import { getAllTestimonial } from "../Requests";
import { getAccessAuthrorization } from "src/components/access";
import TestimonialViewModal from "../Modal/ShowTestimonialDetails";
import ManageTestimonialModal from "../Modal/ManageTestimonial";
import { s3Path } from "src/utils/s3Config/s3upload";

interface DataType {
  id: number;
  name: any;
}
type DataIndex = keyof DataType;
const TestimonialList: React.FC = () => {
  let accessAuth = getAccessAuthrorization("testimonial");

  const {
    isLoading,
    isError,
    data: response,
  } = useQuery(["gallery"], () => getAllTestimonial(), {
    refetchOnWindowFocus: false,

    keepPreviousData: true,
    staleTime: 5000,
  });

  if (isLoading || isError) {
    return <div>Loading</div>;
  }
  const column: ColumnsType<DataType> = [
    {
      title: "Image",
      dataIndex: "imageUrl",
      key: "iamgeUrl",
      render: (text: any) =>
        text ? (
          <Image width={100} height={100} src={s3Path + text} />
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      render: (text: any) =>
        text ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">Inactive</Tag>
        ),
    },

    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (id: any) => (
        <Space size={10}>
          <TestimonialViewModal id={id} />
          {/* <ManageTestimonialModal id={id} /> */}
          {accessAuth[0].includes("Edit") && <ManageTestimonialModal id={id} />}
        </Space>
      ),
    },
  ];

  return <Table size="small" columns={column} dataSource={response} />;
};

export default TestimonialList;
