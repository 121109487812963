import { request } from "../../../utils/services";

export const getAllActiveCandidate = async () => {
  const { data } = await request("admin.candidate.list.active");
  return data;
};

export const getAllCandidate = async () => {
  const { data } = await request("admin.candidate.list");
  return data;
};

export const getCandidateById = async (id: string) => {
  const { data } = await request("admin.candidate.id", { id });
  return data;
};
