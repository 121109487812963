import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "src/components/Card";

import { Empty, Row } from "antd";
import CreatePurchaseOrderModal from "../Modal/CreatePurchaseOrder";
import PurchaseOrderList from "../Component/List";
import { getAccessAuthrorization } from "src/components/access";

const PurchaseOrderPageHome: React.FC<RouteComponentProps> = () => {
  let accessAuth = getAccessAuthrorization("purchaseOrder");

  return (
    <React.Fragment>
      <Card>
        <CardHeader>
          <CardHeaderToolbar>
            <Row justify="space-between">
              <h2>Purchase Order List</h2>
              {/* <CreatePurchaseOrderModal /> */}
              {accessAuth[0].includes("Create") && <CreatePurchaseOrderModal />}
            </Row>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          {/* <PurchaseOrderList /> */}
          {accessAuth[0].includes("View") ? (
            <PurchaseOrderList />
          ) : (
            <Empty
              image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
              imageStyle={{ height: 60 }}
              description={<span>You are Not Authorised!</span>}
            />
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default withRouter(PurchaseOrderPageHome);
