import { request } from "../../../utils/services";

export const getAllActiveTaskType = async () => {
  const { data } = await request("admin.taskType.list.active");
  return data;
};

export const getAllTaskType = async () => {
  const { data } = await request("admin.taskType.list");
  return data;
};

export const getTaskTypeById = async (id: string) => {
  const { data } = await request("admin.taskType.id", { id });
  return data;
};
export const getTaskTypeByType = async (type: string) => {
  const { data } = await request("admin.taskType.type", {
    type,
  });
  return data;
};
