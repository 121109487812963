import * as React from "react";

import CreateDepartmentForm from "../Form/CreateDepartmentForm";
import { Modal, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";

export const CreateDepartmentModalContext = React.createContext<{
  setIsModalVisible: (active: boolean) => void;
}>({
  setIsModalVisible: () => true,
});

const CreateDepartmentModal: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <CreateDepartmentModalContext.Provider value={{ setIsModalVisible }}>
      <Button
        onClick={showModal}
        type="primary"
        shape="round"
        icon={<PlusOutlined />}
      >
        Add Department
      </Button>
      <Modal
        title="Create Department"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        centered
      >
        <CreateDepartmentForm />
      </Modal>
    </CreateDepartmentModalContext.Provider>
  );
};

export default CreateDepartmentModal;
