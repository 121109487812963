import { path } from "./path";

export const replyRoutes = [
  {
    method: "post",
    path: path + "/api/admin/createReply",
    module: "reply",
    alias: "admin.reply.create",
    isSubDomain: true,
  },

  {
    method: "put",
    path: path + "/api/admin/updateReply/:id",
    module: "reply",
    alias: "admin.reply.update",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllActiveReply",
    module: "reply",
    alias: "admin.reply.list.active",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllReply",
    module: "reply",
    alias: "admin.reply.list",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findOneReply/:id",
    module: "reply",
    alias: "admin.reply.id",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findReplyByPersonnel/:id",
    module: "reply",
    alias: "admin.reply.personnel.id",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findReplyByTaskId/:id",
    module: "reply",
    alias: "admin.reply.task.id",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findReplyByTagTo/:id",
    module: "reply",
    alias: "admin.reply.tag.id",
    isSubDomain: true,
  },
];
