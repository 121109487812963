import { path } from "./path";

export const technologyRoutes = [
  {
    method: "post",
    path: path + "/api/admin/createTechnology",
    module: "technology",
    alias: "admin.technology.create",
    isSubDomain: true,
  },

  {
    method: "put",
    path: path + "/api/admin/updateTechnology/:id",
    module: "technology",
    alias: "admin.technology.update",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllActiveTechnology",
    module: "technology",
    alias: "admin.technology.list.active",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllTechnology",
    module: "technology",
    alias: "admin.technology.list",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findOneTechnology/:id",
    module: "technology",
    alias: "admin.technology.id",
    isSubDomain: true,
  },
];
