import {
  Avatar,
  Badge,
  Card,
  Col,
  Collapse,
  CollapseProps,
  Modal,
  Row,
  Segmented,
  Space,
  Tabs,
  Tag,
  Typography,
} from "antd";

import moment from "moment";
import * as React from "react";
import { useQuery } from "react-query";
import { getAccessAuthrorization } from "src/components/access";

import {
  getTaskAssignedBy,
  getTaskByPersonnel,
} from "src/modules/Task/Requests";
import LocalStorageService from "src/utils/local-storage";

import { s3Path } from "src/utils/s3Config/s3upload";

import CreateTaskModal from "src/modules/Task/Modal/CreateTask";

import ReactTimeAgo from "react-time-ago";
import TaskStatusManageModal from "src/modules/Task/Modal/ManageTaskStatus";
import imLogo from "src/assets/img/imlogo.png";

import { ShowTaskData } from "src/modules/Task/Form/ShowTaskDetailsForm";
import ShowWorkDetailsForm from "src/modules/Work/Form/ShowWorkForm";
import { DeploymentUnitOutlined, FlagOutlined } from "@ant-design/icons";
import ShowMobileTaskDetailsForm from "src/modules/Task/Form/ShowMobileTaskDetailsForm";

const TodoMobileList: React.FC<{ id: string }> = (props) => {
  const [user, setUser] = React.useState(LocalStorageService.getAccount());
  const [isReply, setIsReply] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [taskOpen, setTaskOpen] = React.useState(false);
  const [taskId, setTaskId] = React.useState(undefined);

  const [assignedByUser, setAssignedByUser] = React.useState(false);

  let accessAuth = getAccessAuthrorization("task");

  const {
    isLoading,
    isError,
    data: task,
  } = useQuery(["task"], () => getTaskByPersonnel(props.id), {
    refetchOnWindowFocus: true,

    keepPreviousData: true,
    staleTime: 100,
  });
  const {
    //  isLoading,
    //  isError,
    data: taskByPersonnel,
  } = useQuery(["taskByPersonnel"], () => getTaskAssignedBy(user.id), {
    refetchOnWindowFocus: true,

    keepPreviousData: true,
    staleTime: 100,
  });

  // const genExtra = (id: any) => <TaskStatusManageModal id={id} />;

  const getItemList = (list: any, status?: any) => {
    let filteredList = list.filter((element: any) => element.status === status);
    let tempList = status ? filteredList : list;
    const ItemList =
      tempList &&
      tempList.map((item: any, i: any) => {
        return (
          <Badge.Ribbon
            color={item?.type[0]?.color}
            text={item?.type[0]?.title}
            style={{ marginRight: 8, fontSize: 12 }}
          >
            <Card
              size="small"
              style={{ paddingRight: 8 }}
              onClick={() => {
                setTaskOpen(true);
                setTaskId(item?._id);
              }}
            >
              <div>
                <Row justify={"space-between"} align={"stretch"}>
                  <Col span={4}>
                    <Space.Compact direction="vertical">
                      <Typography.Text
                        style={{ fontSize: 24, fontWeight: "bold" }}
                      >
                        {moment(item?.dueDate).format("DD")}
                      </Typography.Text>
                      <Typography.Text
                        style={{
                          fontSize: 16,
                          fontWeight: "bold",
                          color: "teal",
                        }}
                      >
                        {moment(item?.dueDate).format("MMM")}
                      </Typography.Text>
                      <Typography.Text style={{ fontSize: 16, color: "teal" }}>
                        {moment(item?.dueDate).format("YYYY")}
                      </Typography.Text>
                    </Space.Compact>
                  </Col>
                  <Col span={20}>
                    <Row>
                      <Typography.Text strong style={{ width: "60vw" }}>
                        {item?.title}
                      </Typography.Text>{" "}
                    </Row>
                    <Row>
                      {" "}
                      {item.storyId.length > 0 && (
                        <>
                          {" "}
                          {/* View Story Details: */}
                          {/* <StoryViewModal id={data.storyId} /> */}
                          <Tag color="red" onClick={() => setOpen(!open)}>
                            <DeploymentUnitOutlined />{" "}
                            {item.storyId[0]?.workId[0]?.title}
                          </Tag>
                          --
                          <Tag color="green" onClick={() => setOpen(!open)}>
                            <FlagOutlined /> {item.storyId[0]?.title}
                          </Tag>
                          {/* <Modal
                            open={open}
                            width={"80%"}
                            onCancel={() => setOpen(!open)}
                            onOk={() => setOpen(!open)}
                          >
                            <ShowWorkDetailsForm
                              id={item.storyId[0]?.workId[0]._id}
                            />{" "}
                          </Modal> */}
                        </>
                      )}
                    </Row>
                    {item?.leadId?.length > 0 && (
                      <Row>
                        <Typography.Text code>
                          {item?.leadId[0]?.partyId[0].personName}
                        </Typography.Text>

                        {item?.leadId[0]?.partyId[0].companyName && (
                          <Typography.Text code>
                            {item?.leadId[0]?.partyId[0].companyName}
                          </Typography.Text>
                        )}
                        <Tag color="gold" style={{ marginLeft: 20 }}>
                          {item?.leadId[0]?.leadStatus}
                        </Tag>
                      </Row>
                    )}
                    <Row justify={"space-between"} align={"bottom"}>
                      <Tag color="geekblue" style={{ marginLeft: 20 }}>
                        {item.status}
                      </Tag>
                      <ReactTimeAgo
                        date={item.createdAt}
                        locale="en-IN"
                        // timeStyle="twitter"
                      />
                      {assignedByUser ? (
                        <Col>
                          <Avatar
                            src={
                              <img
                                src={
                                  item?.assignedPersonId[0]?.party[0]?.imageUrl
                                    ? s3Path +
                                      item?.assignedPersonId[0]?.party[0]
                                        ?.imageUrl
                                    : imLogo
                                }
                                alt={
                                  item?.assignedPersonId[0]?.party[0]
                                    ?.personName
                                }
                              />
                            }
                          />
                          {/* <Tag>{item?.createdBy[0]?.party[0]?.personName}</Tag> */}
                        </Col>
                      ) : (
                        item?.createdBy.length > 0 && (
                          <Col>
                            <Avatar
                              src={
                                <img
                                  src={
                                    item?.createdBy[0]?.party[0]?.imageUrl
                                      ? s3Path +
                                        item?.createdBy[0]?.party[0]?.imageUrl
                                      : imLogo
                                  }
                                  alt={item?.createdBy[0]?.party[0]?.personName}
                                />
                              }
                            />
                          </Col>
                        )
                      )}
                    </Row>
                  </Col>
                </Row>
              </div>
            </Card>
            {/* <Modal
              open={taskOpen}
              width={"100%"}
              onCancel={() => setTaskOpen(!taskOpen)}
              onOk={() => setTaskOpen(!taskOpen)}
              //   onClose={() => setOpen(!open)}
            >
              <ShowMobileTaskDetailsForm id={item._id} />
            </Modal> */}
          </Badge.Ribbon>
        );
      });

    return ItemList;
  };

  let allList = assignedByUser
    ? getItemList(taskByPersonnel)
    : getItemList(task);
  let todoList = assignedByUser
    ? getItemList(taskByPersonnel, "To Do")
    : getItemList(task, "To Do");
  let inProcessList = assignedByUser
    ? getItemList(taskByPersonnel, "In Progress")
    : getItemList(task, "In Progress");
  let onHoldList = assignedByUser
    ? getItemList(taskByPersonnel, "On Hold")
    : getItemList(task, "On Hold");
  let inReviewList = assignedByUser
    ? getItemList(taskByPersonnel, "In Review")
    : getItemList(task, "In Review");
  let completedList = assignedByUser
    ? getItemList(taskByPersonnel, "Done")
    : getItemList(task, "Done");
  // let allList = getItemList(task);
  // const operations = <CreateTaskModal />;

  return (
    <>
      {/* <Row justify={"end"}>
        <CreateTaskModal />
      </Row> */}
      {taskId && (
        <Modal
          open={taskOpen}
          width={"100%"}
          onCancel={() => setTaskOpen(!taskOpen)}
          onOk={() => setTaskOpen(!taskOpen)}
          //   onClose={() => setOpen(!open)}
        >
          <ShowMobileTaskDetailsForm id={taskId} />
        </Modal>
      )}
      <Tabs
        size="small"
        // tabBarExtraContent={
        //   <Space direction="vertical">
        //     <Segmented
        //       options={[
        //         { value: "For Me", label: "For Me" },

        //         { value: "By Me", label: "By Me" },
        //       ]}
        //       onChange={(value) =>
        //         setAssignedByUser(value === "By Me" ? true : false)
        //       }
        //     />
        //     <CreateTaskModal />
        //   </Space>
        // }
        defaultActiveKey="To Do"
        // tabPosition="right"
        items={[
          {
            key: "To Do",
            label: (
              <Badge count={todoList?.length} offset={[12, 0]} color="orange">
                To Do
              </Badge>
            ),
            children: (
              <div style={{ height: "70vh", overflow: "auto" }}>
                {" "}
                {todoList}
                {/* <Collapse accordion items={todoList} expandIconPosition="end" /> */}
              </div>
            ),
          },
          {
            key: "In Progress",
            label: (
              <Badge
                count={inProcessList?.length}
                offset={[12, 0]}
                color="blue"
              >
                In Progress
              </Badge>
            ),
            children: (
              <div style={{ height: "70vh", overflow: "auto" }}>
                {inProcessList}
              </div>
            ),
          },
          {
            key: "On Hold",
            label: (
              <Badge
                count={onHoldList?.length}
                offset={[12, 0]}
                color="volcano"
              >
                On Hold
              </Badge>
            ),
            children: (
              <div style={{ height: "70vh", overflow: "auto" }}>
                {onHoldList}
              </div>
            ),
          },
          {
            key: "In Review",
            label: (
              <Badge count={inReviewList?.length} offset={[12, 0]} color="cyan">
                In Review
              </Badge>
            ),
            children: (
              <div style={{ height: "70vh", overflow: "auto" }}>
                {inReviewList}
              </div>
            ),
          },
          {
            key: "Completed",
            label: (
              <Badge
                count={completedList?.length}
                offset={[12, 0]}
                color="lime"
              >
                Completed
              </Badge>
            ),
            children: (
              <div style={{ height: "70vh", overflow: "auto" }}>
                {completedList}
              </div>
            ),
          },
          {
            key: "All Tasks",
            label: (
              <Badge count={allList?.length} offset={[12, 0]} color="purple">
                All Tasks
              </Badge>
            ),
            children: (
              <div style={{ height: "70vh", overflow: "auto" }}>{allList}</div>
            ),
          },
        ]}
      />
      {/* <Table size="small" columns={column} dataSource={task} /> */}
    </>
  );
};

export default TodoMobileList;
