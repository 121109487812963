import { path } from "./path";

export const performaInvoiceRoutes = [
  {
    method: "post",
    path: path + "/api/admin/createPerformaInvoice",
    module: "perfomaInvoice",
    alias: "admin.perfomaInvoice.create",
    isSubDomain: true,
  },

  {
    method: "put",
    path: path + "/api/admin/updatePerformaInvoice/:id",
    module: "perfomaInvoice",
    alias: "admin.perfomaInvoice.update",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllActivePerformaInvoice",
    module: "perfomaInvoice",
    alias: "admin.perfomaInvoice.list.active",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllPerformaInvoice",
    module: "perfomaInvoice",
    alias: "admin.perfomaInvoice.list",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findOnePerformaInvoice/:id",
    module: "perfomaInvoice",
    alias: "admin.perfomaInvoice.id",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findOnePerformaInvoiceWithDetail/:id",
    module: "perfomaInvoice",
    alias: "admin.perfomaInvoice.id.with.detail",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findAllPerformaInvoiceByLeadId/:id",
    module: "perfomaInvoice",
    alias: "admin.perfomaInvoice.by.leadId",
  },
];
