import { TRoute } from "../../utils/routes";
import DepartmentPageHome from "./Page/Home";

const TimelineRouter: TRoute[] = [
  {
    path: "/timeline",
    name: "timeline",
    title: "timeline",
    component: DepartmentPageHome,
  },
];

export default TimelineRouter;
