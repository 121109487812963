import * as React from "react";
import {
  Descriptions,
  Card,
  Divider,
  Row,
  Tag,
  Col,
  Typography,
  Space,
  Empty,
  Image,
  Collapse,
} from "antd";
import { useQuery } from "react-query";
import { getStoryById, getStoryByIdWithDetails } from "../Requests";
import Table, { ColumnsType } from "antd/es/table";
import moment from "moment";
import { s3Path } from "src/utils/s3Config/s3upload";
import StoryListByWorkId, {
  ModuleCollaspe,
} from "src/modules/Work/Component/StoryListByWorkId";
import parse from "html-react-parser";
import StoryViewModal from "../Modal/ShowStoryDetails";

const { Text } = Typography;

const ShowStoryDetailsForm: React.FC<{ id: string }> = (props) => {
  const { data: story } = useQuery(
    ["storyWithDetails", props.id],
    async () => getStoryByIdWithDetails(props.id),
    {
      refetchOnWindowFocus: false,

      staleTime: 5000,
    }
  );

  return (
    <>
      <Typography.Title level={4}>
        {story?.storyId?.storyId?.title}--{story?.storyId?.title}--
        {story?.title}
      </Typography.Title>
      <Descriptions
        bordered
        size="small"
        layout="vertical"
        title="Story Detail"
      >
        <Descriptions.Item label="Title">{story.title}</Descriptions.Item>
        {/* <Descriptions.Item label="Parent Story">
          <Collapse
            items={[
              {
                key: "1",
                label: story?.storyId?.title,
                children: (
                  <Descriptions bordered size="small" layout="vertical">
                    
                    <Descriptions.Item label="Description">
                      {story?.storyId?.description &&
                        parse(story?.storyId?.description)}
                    </Descriptions.Item>
                  </Descriptions>
                ),
              },
            ]}
          />
        </Descriptions.Item> */}
        <Descriptions.Item label="Description">
          {story?.description && parse(story?.description)}
        </Descriptions.Item>
      </Descriptions>
      {story.storyId && (
        <>
          {" "}
          <Divider />
          <Descriptions
            bordered
            size="small"
            layout="vertical"
            title="Parent Story Detail"
          >
            <Descriptions.Item label="Title">
              {story.storyId.title}
            </Descriptions.Item>

            <Descriptions.Item label="Description">
              {story?.storyId?.description &&
                parse(story?.storyId?.description)}
            </Descriptions.Item>
          </Descriptions>
        </>
      )}
      {story?.storyId?.storyId && (
        <>
          {" "}
          <Divider />
          <Descriptions
            bordered
            size="small"
            layout="vertical"
            title="Parent's Parent Story Detail"
          >
            <Descriptions.Item label="Title">
              {story?.storyId?.storyId.title}
            </Descriptions.Item>

            <Descriptions.Item label="Description">
              {story?.storyId?.storyId.description &&
                parse(story?.storyId?.storyId.description)}
            </Descriptions.Item>
          </Descriptions>
        </>
      )}

      <Descriptions bordered size="small" layout="vertical" title="Work Detail">
        <Descriptions.Item label="Title">
          {story.workId.title} <Tag color="red">{story.workId.workStatus}</Tag>
        </Descriptions.Item>
        <Descriptions.Item label="Work Type">
          <Typography.Text code type="success">
            {story.workId.workType}
          </Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item label="Start to End Date">
          <Typography.Text code>
            {moment(story.workId.startDate).format("DD-MM-YYYY")}{" "}
          </Typography.Text>
          to
          <Typography.Text code>
            {moment(story.workId.endDate).format("DD-MM-YYYY")}
          </Typography.Text>
        </Descriptions.Item>
        {/* <Descriptions.Item label="Description">
          {story.workId.description && parse(story.workId.description)}
        </Descriptions.Item> */}
      </Descriptions>
      {/* <ModuleCollaspe data={story} type={"Module"} tagColor={"success"} /> */}
      {/* <StoryListByWorkId data={story} /> */}
    </>
  );
};

export default ShowStoryDetailsForm;
