import { request } from "src/utils/services";

export const getAllActiveDepartment = async () => {
  const { data } = await request("admin.department.list.active");
  return data;
};

export const getAllDepartment = async () => {
  const { data } = await request("admin.department.list");
  return data;
};

export const getDepartmentById = async (id: string) => {
  const { data } = await request("admin.department.id", { id });
  return data;
};
