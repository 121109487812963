import { Button, Empty, Tag, Image, Typography } from "antd";
import moment from "moment";
import * as React from "react";

import type { ColumnsType } from "antd/lib/table";

import PersonnelManageModal from "../../Modal/ManagePersonnel";

import { Space, Table } from "antd";
import PersonnelViewModal from "../../Modal/ShowPersonnelDetails";

import AdditionalDetailModal from "../../Modal/AdditionalDetail";
import AddAssetsModal from "../../Modal/ModalAssets/AddAssets";
import { getAllPersonnel } from "../../Requests";
import { useQuery } from "react-query";
import { s3Path } from "src/utils/s3Config/s3upload";
import { getAccessAuthrorization } from "src/components/access";

interface DataType {
  //challanNo: any;
  emp: any;
  details: any;
  jobdetails: any;
  skills: any;
  status: any;

  //   job details: any;
  // skills: any;

  // render?:(_:any,record:any)
  //address: string;
}
const { Text } = Typography;
type DataIndex = keyof DataType;
const PersonnelList: React.FC = () => {
  const [page, setPage] = React.useState(1);
  const [size, setSize] = React.useState(100);
  const [searchText, setSearchText] = React.useState("");
  const [searchedColumn, setSearchedColumn] = React.useState("");
  // const searchInput = React.useRef<Input>(null);
  // console.log(CookiesService.getToken(), "token")
  let accessAuth = getAccessAuthrorization("personnel");

  const {
    isLoading,
    isError,
    data: response,
  } = useQuery(["personnel"], () => getAllPersonnel(), {
    refetchOnWindowFocus: false,
    // enabled: false,
    keepPreviousData: true,
    staleTime: 5000,
    // refetchOnMount: false,
  });

  // if (isLoading || isError) {
  //   return <div>Loading</div>;
  // }

  const column: ColumnsType<DataType> = [
    {
      title: "Profile/Logo ",
      dataIndex: "imageUrl",
      key: "imageUrl",
      render: (text: any) =>
        text ? (
          <Image width={100} height={100} src={s3Path + text} />
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ),
    },
    {
      title: "Name",
      dataIndex: "companyName",
      key: "companyName",
      render: (_: any, i: any) => (
        <Space>
          <Space.Compact direction="vertical">
            <Button>
              {i?.partyId?.personName}
              <Text code type="danger">
                {i?.partyId?.partyType}
              </Text>
            </Button>
            <Button>{i?.designationId?.designationName}</Button>
            <Button>{i?.departmentId?.departmentName}</Button>
          </Space.Compact>
        </Space>
      ),
    },
    {
      title: "Contacts",
      dataIndex: "personName",
      key: "personName",
      render: (_: any, i: any) => (
        <Space>
          <Space.Compact direction="vertical">
            <Text type="success">+91-{i?.partyId?.phoneNo}</Text>
            <Text type="warning">{i?.partyId?.email}</Text>
          </Space.Compact>
        </Space>
      ),
    },
    {
      title: "Job Detail",
      dataIndex: "id",
      key: "id",
      render: (_: any, i: any) => (
        <Space>
          <Space.Compact direction="vertical">
            <Text code>Shift Time: {i?.jobDetailId?.shiftTime}</Text>
            <Text code>Job Type: {i?.jobDetailId?.jobType}</Text>
            <Text code>Experience: {i?.jobDetailId?.experience} year</Text>
          </Space.Compact>
        </Space>
      ),
    },

    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      render: (text: any) =>
        text ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">Inactive</Tag>
        ),
    },

    {
      title: "ACTION",
      dataIndex: "id",
      key: "id",
      render: (id: any) => (
        <Space size={10}>
          <PersonnelViewModal id={id} />
          {/* <PersonnelManageModal id={id} /> */}
          {accessAuth[0].includes("Edit") && <PersonnelManageModal id={id} />}
        </Space>
      ),
    },
  ];

  return <Table size="small" columns={column} dataSource={response} />;
};

export default PersonnelList;
