import { path } from "./path";

export const purchaseOrderRoutes = [
  {
    method: "post",
    path: path + "/api/admin/createPurchaseOrder",
    module: "purchaseOrder",
    alias: "admin.purchaseOrder.create",
    isSubDomain: true,
  },

  {
    method: "put",
    path: path + "/api/admin/updatePurchaseOrder/:id",
    module: "purchaseOrder",
    alias: "admin.purchaseOrder.update",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllActivePurchaseOrder",
    module: "purchaseOrder",
    alias: "admin.purchaseOrder.list.active",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllPurchaseOrder",
    module: "purchaseOrder",
    alias: "admin.purchaseOrder.list",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findOnePurchaseOrder/:id",
    module: "purchaseOrder",
    alias: "admin.purchaseOrder.id",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findOnePurchaseOrderWithDetail/:id",
    module: "purchaseOrder",
    alias: "admin.purchaseOrder.id.with.detail",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findAllPurchaseOrderByPartyId/:id",
    module: "purchaseOrder",
    alias: "admin.purchaseOrder.party.id",
    isSubDomain: true,
  },
];
