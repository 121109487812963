import { TRoute } from "../../utils/routes";
import DashboardPageHome from "./Page/Home";

const DashboardRouter: TRoute[] = [
  {
    path: "/",
    name: "dashboard",
    title: "Dashboard",
    component: DashboardPageHome,
  },
];

export default DashboardRouter;
