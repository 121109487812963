import { TRoute } from "src/utils/routes";
import DepartmentPageHome from "./Page/Home";

const PurchaseOrderRouter: TRoute[] = [
  {
    path: "/purchaseOrder",
    name: "purchaseOrder",
    title: "purchaseOrder",
    component: DepartmentPageHome,
  },
];

export default PurchaseOrderRouter;
