import { path } from "./path";

export const localRoutes = [
  {
    method: "post",
    path: path + "/api/admin/saveLocal",
    module: "local",
    alias: "admin.local.create",
    isSubDomain: true,
  },

  {
    method: "put",
    path: path + "/api/admin/updateLocal/:id",
    module: "local",
    alias: "admin.local.update",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllActiveLocal",
    module: "local",
    alias: "admin.local.list.active",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllLocal",
    module: "local",
    alias: "admin.local.list",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findOneLocal/:id",
    module: "local",
    alias: "admin.local.id",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findAllLocalLocalId/:id",
    module: "local",
    alias: "admin.local.local.id",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findDataByPincode/:pinCode",
    module: "local",
    alias: "admin.local.pincode",
    isSubDomain: true,
  },
];
