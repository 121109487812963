import axios from "./axios";
import routes from "./route-list";

type Method =
  | "get"
  | "GET"
  | "delete"
  | "DELETE"
  | "head"
  | "HEAD"
  | "options"
  | "OPTIONS"
  | "post"
  | "POST"
  | "put"
  | "PUT"
  | "patch"
  | "PATCH"
  | "purge"
  | "PURGE"
  | "link"
  | "LINK"
  | "unlink"
  | "UNLINK";

export const request = (
  alias: string,
  params: object = {},
  data: any = {}
): Promise<{
  payload?: any;
  data?: any;
  flashMessages?: any;
  status?: boolean;
}> => {
  return new Promise((resolve, reject) => {
    const route = getRoute(alias, params);
    const path = `${route.path}${
      data.hasOwnProperty("query") ? `?${querify(data.query)}` : ""
    }`;

    axios
      .request({
        method: route.method,
        url: path,
        data,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getRoute = (
  alias: string,
  params: object
): {
  method: Method;
  path: string;
  module: string;
  alias: string;
  isSubDomain: boolean;
} => {
  return routes.route(alias, params);
};

export const querify = (params: any) => {
  let str = [];
  for (let p in params) {
    if (params.hasOwnProperty(p)) {
      str.push(`${encodeURIComponent(p)}=${encodeURIComponent(params[p])}`);
    }
  }

  return str.join("&");
};
