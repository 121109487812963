import { path } from "./path";

export const candidateRoutes = [
  {
    method: "post",
    path: path + "/api/admin/createCandidate",
    module: "candidate",
    alias: "admin.candidate.create",
    isSubDomain: true,
  },

  {
    method: "put",
    path: path + "/api/admin/updateCandidate/:id",
    module: "candidate",
    alias: "admin.candidate.update",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllActiveCandidate",
    module: "candidate",
    alias: "admin.candidate.list.active",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllCandidate",
    module: "candidate",
    alias: "admin.candidate.list",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findOneCandidate/:id",
    module: "candidate",
    alias: "admin.candidate.id",
    isSubDomain: true,
  },
];
