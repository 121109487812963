import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import CreateCompanyForm from "../Form/CreateCompanyForm";

const CompanyPageHome: React.FC<RouteComponentProps> = () => {
  return (
    <>
      <h2>User Profile</h2>
      <CreateCompanyForm id={" "} />
    </>
  );
};

export default withRouter(CompanyPageHome);
