import { request } from "../../../utils/services";

export const getAllActiveStory = async () => {
  const { data } = await request("admin.story.list.active");
  return data;
};

export const getAllStory = async () => {
  const { data } = await request("admin.story.list");
  return data;
};

export const getStoryById = async (id: string) => {
  const { data } = await request("admin.story.id", { id });
  return data;
};

export const getStoryByIdWithDetails = async (id: string) => {
  const { data } = await request("admin.story.details.id", { id });
  return data;
};
export const getStoryByWorkId = async (id: string) => {
  const { data } = await request("admin.story.by.workId", { id });
  return data;
};
