import * as React from "react";
import { Modal, Button, Tooltip } from "antd";
import ManageUserDetailsForm from "../Form/ManageUserDetailsForm";
import { PlusOutlined } from "@ant-design/icons";

export const ManageUserDetailsModalContext = React.createContext<{
  setIsModalVisible: (active: boolean) => void;
}>({
  setIsModalVisible: () => true,
});

const ManageUserDetailsModal: React.FC<{ id: string }> = ({ id }) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const showModal = () => setIsModalVisible(true);

  const handleOk = () => setIsModalVisible(false);

  const handleCancel = () => setIsModalVisible(false);

  return (
    <ManageUserDetailsModalContext.Provider value={{ setIsModalVisible }}>
      <Tooltip title="Add/Update User Details">
        <Button
          onClick={showModal}
          type="dashed"
          shape="round"
          icon={<PlusOutlined />}
        >
          User Details
        </Button>
      </Tooltip>
      <Modal
        title="Manage UserDetails"
        visible={isModalVisible}
        onOk={handleOk}
        width={1000}
        onCancel={handleCancel}
        footer={null}
      >
        <ManageUserDetailsForm id={id} />
      </Modal>
    </ManageUserDetailsModalContext.Provider>
  );
};

export default ManageUserDetailsModal;
