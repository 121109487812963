import * as React from "react";
import CreateFollowupForm from "../Form/CreateFollowupForm";
import { Modal, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { isMobile } from "react-device-detect";
import CreateMobileFollowupForm from "../Form/CreateMobileFollowupForm";

export const CreateFollowupModalContext = React.createContext<{
  setIsModalVisible: (active: boolean) => void;
}>({
  setIsModalVisible: () => true,
});

const CreateFollowupModal: React.FC<{ leadId: string }> = ({ leadId }) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <CreateFollowupModalContext.Provider value={{ setIsModalVisible }}>
      <Button
        size="small"
        onClick={showModal}
        type="dashed"
        shape="round"
        icon={<PlusOutlined />}
      >
        Followup
      </Button>
      <Modal
        title="Create Followup"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width={1000}
        centered
      >
        {isMobile ? (
          <CreateMobileFollowupForm leadId={leadId} />
        ) : (
          <CreateFollowupForm leadId={leadId} />
        )}
      </Modal>
    </CreateFollowupModalContext.Provider>
  );
};

export default CreateFollowupModal;
