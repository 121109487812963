import classNames from 'classnames';
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { routeByAlias } from '../utils/routes';

interface TLink {
    children?: React.ReactNode;
    disabled?: boolean;
    className?: string;
    title?: string;
    parameters?: any;
    name: string;
}

const NavRouteLink: React.FC<TLink> = ({
    name,
    parameters,
    disabled,
    children,
    ...rest
}: any) => {
    if (disabled) {
        const classes = classNames(rest.className, 'disabled');

        return (
            <a {...rest} className={classes}>
                {children}
            </a>
        );
    }

    const routeLink = routeByAlias(name, parameters);
    if (routeLink) {
        return (
            <NavLink to={routeLink.path} {...rest} exact={true}>
                {children}
            </NavLink>
        );
    }
    return <span>{children}</span>;
};

export default NavRouteLink;
