import { Button, message, Tag } from "antd";

import * as React from "react";

import { useMutation, useQuery } from "react-query";
import type { ColumnsType } from "antd/lib/table";
import RolesManageModal from "../../Modal/ManageRoles.";

import { Space, Table } from "antd";

import { getAllRoles } from "../../Requests";
import { request } from "src/utils/services";
import { queryClient } from "src";
import AccessManageModal from "src/modules/Access/Modal/ManageAccess";
import { getAccessAuthrorization } from "src/components/access";

const RolesList: React.FC = () => {
  const [page, setPage] = React.useState(1);
  const [size, setSize] = React.useState(100);
  const [searchText, setSearchText] = React.useState("");
  const [searchedColumn, setSearchedColumn] = React.useState("");
  let accessAuth = getAccessAuthrorization("roles");

  // const createAccessMutation = useMutation(
  //   (values: any) =>
  //     request("admin.access.create.roleId", { id: values }, values),
  //   {
  //     onSuccess: () => {
  //       queryClient.invalidateQueries("roles");
  //     },
  //   }
  // );

  // const createAccess = async (values: any) => {
  //   // setFormLoading(true);
  //   console.log(values);

  //   createAccessMutation
  //     .mutateAsync(values)
  //     .then(() => {
  //       // setFormLoading(false);
  //       message.success("The Access has been successfully created.");
  //       //modalContext.setIsModalVisible(false);
  //     })
  //     .catch((e: any) => {
  //       // setFormLoading(false);
  //       message.error(e);
  //     });
  // };

  const {
    isLoading,
    isError,
    data: response,
  } = useQuery(["roles"], () => getAllRoles(), {
    refetchOnWindowFocus: false,

    keepPreviousData: true,
    staleTime: 5000,
  });

  if (isLoading || isError) {
    return <div>Loading</div>;
  }
  interface DataType {
    // id: number;
    // name: any;
    title: any;
    status: any;
  }

  const column: ColumnsType<DataType> = [
    // {
    //   title: "Roles ID",
    //   dataIndex: "id",
    //   key: "id",

    //   render: (text: any) => <p>PR/Dept/{text}</p>,
    // },
    {
      title: "Title Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text: any) =>
        text === true ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">Inactive</Tag>
        ),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",

      render: (id: any) => (
        <Space>
          {/* <Button type="primary" onClick={() => createAccess(id)}>
            Create/Edit Access
          </Button> */}
          {/* <AccessManageModal id={id} /> */}
          {accessAuth[0].includes("Edit") && <AccessManageModal id={id} />}
        </Space>
      ),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",

      render: (id: any) => (
        <Space>
          {/* <RolesManageModal id={id} /> */}
          {accessAuth[0].includes("Edit") && <RolesManageModal id={id} />}
        </Space>
      ),
    },
  ];
  return <Table size="small" columns={column} dataSource={response} />;
};

export default RolesList;
