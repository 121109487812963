import * as React from "react";
import {
  Form,
  Button,
  message,
  Row,
  Typography,
  Col,
  Switch,
  Input,
  Divider,
  Upload,
  DatePicker,
  Select,
} from "antd";
import { request } from "src/utils/services";
import { useMutation } from "react-query";
import {
  InfoCircleOutlined,
  PlusOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { queryClient } from "src";
import { ManageDeploymentModalContext } from "../Modal/ManageDeployment";

import { useQuery } from "react-query";

import TextArea from "antd/lib/input/TextArea";

import moment from "moment";
import { getDeploymentById } from "../Requests";
import {
  RcFile,
  UploadChangeParam,
  UploadFile,
  UploadProps,
} from "antd/es/upload";
import ReactQuill from "react-quill";
import { getAllActiveWork } from "src/modules/Work/Requests";
import { s3Path, uploadFileToAWS } from "src/utils/s3Config/s3upload";
import LocalStorageService from "src/utils/local-storage";
const { Text } = Typography;

const ManageDeploymentForm: React.FC<{ id: string }> = (props) => {
  const modalContext = React.useContext(ManageDeploymentModalContext);
  const [formLoading, setFormLoading] = React.useState(false);
  const [galley, setGallery] = React.useState<string>();
  const [loading, setLoading] = React.useState(false);

  const [selectedFile, setSelectedFile] = React.useState<RcFile>();
  const manageDeploymentMutation = useMutation(
    (values: any) =>
      request("admin.deployment.update", { id: props.id }, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("deployment");
      },
    }
  );
  const {
    isLoading,
    data: deployment,
    error,
    isError,
  } = useQuery(
    ["deployment", props.id],
    async () => getDeploymentById(props.id),
    {
      refetchOnWindowFocus: false,
    }
  );
  const [imageUrl, setImageUrl] = React.useState<string>(
    s3Path + deployment?.logo
  );

  const { data: work } = useQuery(["work"], async () => getAllActiveWork(), {
    refetchOnWindowFocus: false,
  });
  const getBase64 = (img: RcFile, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    // if (info.file.status === "uploading") {
    //   setLoading(true);
    //   return;
    // }
    setSelectedFile(info.file.originFileObj);
    if (info.file.status === "uploading") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj as RcFile, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
  };
  const onFinish = async (values: any) => {
    setFormLoading(true);
    values.updatedBy = LocalStorageService.getAccount().id;
    values.logo = galley;

    manageDeploymentMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The Deployment has been successfully managed.");
        form.resetFields();
        modalContext.setIsModalVisible(false);
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const [form] = Form.useForm();
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  const modules = React.useMemo(
    () => ({
      toolbar: {
        container: [
          [{ font: [] }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ["bold", "italic", "underline", "strike"],
          [{ color: [] }, { background: [] }],
          [{ script: "sub" }, { script: "super" }],
          ["blockquote", "code-block"],
          [{ list: "ordered" }, { list: "bullet" }],

          [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
          [{ direction: "rtl" }],
          [{ size: ["small", false, "large", "huge"] }],
          ["link", "image", "video"],
          ["clean"],
        ],

        //  handlers: {
        //    image: handleClick,
        //  },
        history: {
          delay: 500,
          maxStack: 100,
          userOnly: true,
        },
      },
    }),
    []
  );

  deployment.domainExpiryDate = moment(
    deployment.domainExpiryDate,
    "YYYY-MM-DD"
  );
  deployment.serverExpiryDate = moment(
    deployment.serverExpiryDate,
    "YYYY-MM-DD"
  );
  deployment.maintainanceExpiryDate = moment(
    deployment.maintainanceExpiryDate,
    "YYYY-MM-DD"
  );

  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}
      initialValues={deployment}
    >
      <Row gutter={24}>
        <Col flex={3} span={12}>
          <Form.Item
            label="Project/Product"
            name="workId"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "work",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please select Product/Project",
              },
            ]}
            hasFeedback
          >
            <Select
              //  onSelect={OnWorkSelect}
              showSearch
              popupMatchSelectWidth
              // style={{ minHeight: 150 }}
              placeholder="Select Product/Project"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option!.children as unknown as string).includes(input)
              }
            >
              {work &&
                work?.map((item: any, i: number) => {
                  return (
                    <Select.Option value={item?.id} key={i}>
                      {item?.title}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Col>
        <Col flex={3} span={12}>
          <Form.Item
            label="Domain Name"
            name="domainName"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "Domain Name",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please enter domain name",
              },
              { min: 3 },
              { whitespace: true },
            ]}
            hasFeedback
          >
            <Input placeholder="Enter Domain Name" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col flex={3} span={12}>
          <Form.Item
            label="Short Name"
            name="shortName"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "shortName",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please Enter Short Name",
              },
              {
                min: 3,
                whitespace: true,
              },
            ]}
            hasFeedback
          >
            <Input placeholder="Please Enter Short Name" />
          </Form.Item>
        </Col>

        <Col flex={3} span={12}>
          <Form.Item
            label="Full Name"
            name="fullName"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "Full Name",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              // {
              //   required: true,
              //   message: "Please select Full Name",
              // },
              { min: 3 },
              { whitespace: true },
            ]}
            hasFeedback
          >
            <Input placeholder="Enter Full Name" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col flex={3} span={12}>
          <Form.Item
            label="Panel Url"
            name="pannel"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "Pannel",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              // {
              //   required: true,
              //   message: "Please Enter Pannel",
              // },
              {
                min: 3,
                whitespace: true,
              },
            ]}
            hasFeedback
          >
            <Input placeholder="Enter Pannel Link" />
          </Form.Item>
        </Col>

        <Col flex={3} span={12}>
          <Form.Item
            label="Server Name"
            name="serverName"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "Server Name",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please select Server Name",
              },
              { min: 3 },
              { whitespace: true },
            ]}
            hasFeedback
          >
            <Input placeholder="Enter server name" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col flex={3} span={8}>
          <Form.Item
            label="Domain Expiry Date"
            name="domainExpiryDate"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "Domain Expiry Date",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please enter Domain Expiry Date",
              },
            ]}
            hasFeedback
          >
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col flex={3} span={8}>
          <Form.Item
            label="Server Expiry Date"
            name="serverExpiryDate"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "Server Expiry Date",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please select Server Expiry Date",
              },
            ]}
            hasFeedback
          >
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col flex={3} span={8}>
          <Form.Item
            label="Maintainance Expiry Date"
            name="maintainanceExpiryDate"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "Maintainance Expiry Date",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please select Maintainance Expiry Date",
              },
            ]}
            hasFeedback
          >
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col flex={3} span={6}>
          <Form.Item
            label="DB Name"
            name="dbName"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "dbName",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please enter dbName",
              },
              {
                min: 3,
                whitespace: true,
              },
            ]}
            hasFeedback
          >
            <Input placeholder="Enter dbName" />
          </Form.Item>
        </Col>
        <Col flex={3} span={6}>
          <Form.Item
            label="DB Host"
            name="dbHost"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "db Host",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please Enter DB Host",
              },
            ]}
            hasFeedback
          >
            <Input placeholder=" Enter Database Host" />
          </Form.Item>
        </Col>
        <Col flex={3} span={6}>
          <Form.Item
            label="DB Username"
            name="dbUsername"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "DB Username",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please Enter Database Username",
              },
            ]}
            hasFeedback
          >
            <Input placeholder="Enter Database username" />
          </Form.Item>
        </Col>
        <Col flex={3} span={6}>
          <Form.Item
            label="DB Password"
            name="dbPassword"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "DB Password",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please Enter Database Password",
              },
              {
                min: 3,
                whitespace: true,
              },
            ]}
            hasFeedback
          >
            <Input placeholder="Enter Database Password" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col flex={3} span={12}>
          <Form.Item
            label="ios Link"
            name="iosLink"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "iosLink",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              // {
              //   required: true,
              //   message: "Please Enter ios Link",
              // },
              {
                min: 3,
                whitespace: true,
              },
            ]}
            hasFeedback
          >
            <Input placeholder="Enter iosL ink" />
          </Form.Item>
        </Col>
        <Col flex={3} span={12}>
          <Form.Item
            label="Android Link"
            name="androidLink"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "android Link",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              // {
              //   required: true,
              //   message: "Please Enter android Link",
              // },
              {
                min: 3,
                whitespace: true,
              },
            ]}
            hasFeedback
          >
            <Input placeholder="Enter android Link" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        {/* <Col flex={3} span={16}>
          {" "}
          <Form.Item
            label="Case Study"
            name="caseStudy"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "case Study",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please Enter case Study",
              },
              {
                min: 3,
                whitespace: true,
              },
            ]}
            hasFeedback
          >
           
            <ReactQuill theme="snow" modules={modules} />
          </Form.Item> 
        </Col>*/}
        <Col flex={2} span={4}>
          <Form.Item
            label="Upload Logo"
            style={{ fontWeight: "bold" }}
            name="logo"
            valuePropName="checked"
          >
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              accept="image/png, image/jpeg"
              action={async (file) => {
                const response: any = await uploadFileToAWS(
                  file,
                  "deployement"
                );
                setGallery(response.key);
                return response.key;
              }}
              onChange={handleChange}
            >
              {imageUrl ? (
                <img src={imageUrl} alt="avatar" style={{ width: "100%" }} />
              ) : (
                uploadButton
              )}
            </Upload>
          </Form.Item>
        </Col>
        <Col flex={2} span={4}>
          <Form.Item
            label="Status"
            style={{ fontWeight: "bold" }}
            name="isActive"
            tooltip={{
              title: "status",
              icon: <InfoCircleOutlined />,
            }}
            valuePropName="checked"
            // rules={[{ required: true }]}
          >
            <Switch
              checkedChildren="Active"
              unCheckedChildren="Inactive"
              defaultChecked
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ManageDeploymentForm;
