import { Tag, Typography } from "antd";

import * as React from "react";

import { useQuery } from "react-query";
import type { ColumnsType } from "antd/lib/table";

import AccessManageModal from "../../Modal/ManageAccess";

import { Space, Table } from "antd";
import AccessViewModal from "../../Modal/ShowAccessDetails";

import { getAllAccess } from "../../Requests";
import * as AntdIcons from "@ant-design/icons";
const { Text } = Typography;
interface DataType {
  id: number;
  name: string;
  email: string;
  designate: any;
  services: any;
  phone: number;
  status: any;
}
type DataIndex = keyof DataType;
const AccessList: React.FC = () => {
  const {
    isLoading,
    isError,
    data: access,
  } = useQuery(["access"], () => getAllAccess(), {
    refetchOnWindowFocus: false,

    keepPreviousData: true,
    staleTime: 5000,
  });

  if (isLoading || isError) {
    return <div>Loading</div>;
  }

  const column: ColumnsType<DataType> = [
    {
      title: "Role",
      dataIndex: "roleId",
      key: "roleId",
      render: (_: any, i: any) => <Text>{i.roleId.name}</Text>,
    },

    {
      title: "Collection",
      dataIndex: "collectionId",
      key: "collectionId",
      render: (_: any, i: any) => <Text>{i.collectionId.collectionTitle}</Text>,
    },
    {
      title: "Access Type",
      dataIndex: "mob",
      key: "mob",
      render: (_: any, i: any) => {
        return (
          <>
            {i.accessType.map((item: any) => {
              switch (item) {
                case "Create":
                  return (
                    <AntdIcons.CarryOutOutlined
                      style={{ fontSize: 30, margin: 5, color: "green" }}
                    />
                  );
                case "Edit":
                  return (
                    <AntdIcons.ScissorOutlined
                      style={{ fontSize: 30, margin: 5, color: "blueviolet" }}
                    />
                  );
                case "View":
                  return (
                    <AntdIcons.EyeOutlined
                      style={{ fontSize: 30, margin: 5, color: "pink" }}
                    />
                  );
                case "NoAccess":
                  return (
                    <AntdIcons.CloseSquareOutlined
                      style={{ fontSize: 30, margin: 5, color: "red" }}
                    />
                  );
              }
            })}
          </>
        );
      },
    },

    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   key: "status",
    //   render: (text: any) =>
    //     text === "Active" ? (
    //       <Tag color="green">{text}</Tag>
    //     ) : text === "Inactive" ? (
    //       <Tag color="red">{text}</Tag>
    //     ) : (
    //       <Tag color="cyan">{text}</Tag>
    //     ),
    // },

    // {
    //   title: "Action",
    //   dataIndex: "id",
    //   key: "id",
    //   render: (id: any) => (
    //     <Space size={10}>
    //       <AccessViewModal id={id} />
    //       <AccessManageModal id={id} />
    //     </Space>
    //   ),
    // },
  ];

  return <Table size="small" columns={column} dataSource={access} />;
};

export default AccessList;
