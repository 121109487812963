import { Avatar, Tag } from "antd";
import moment from "moment";
import * as React from "react";

// import { request } from 'src/utils/services';
import { useQuery } from "react-query";
import type { ColumnsType } from "antd/lib/table";
//import { Datatable } from "../../../../components/Datatable";
import UserManageModal from "../../Modal/ManageUser";
//import { getUser, getUserById } from "../../Requests";
//import { getInstrumentById } from 'src/modules/Instruments/Requests';
import LocalStorageService from "src/utils/local-storage";
import CookiesService from "src/utils/cookie";
import { Button, Tooltip, Space, Input, Table } from "antd";
import UserViewModal from "../../Modal/ShowUserDetails";
import {
  EyeOutlined,
  EditOutlined,
  DownloadOutlined,
  SearchOutlined,
  // CloseOutlined,
  // CheckOutlined,
} from "@ant-design/icons";
import { ColumnType, FilterConfirmProps } from "antd/lib/table/interface";
import { getAllUser } from "../../Requests";
import { getAccessAuthrorization } from "src/components/access";

interface DataType {
  firstname: string;
  lastname: string;
  email: any;
  roles: any;
}
type DataIndex = keyof DataType;
const UserList: React.FC = () => {
  const [page, setPage] = React.useState(1);
  const [size, setSize] = React.useState(100);
  const [searchText, setSearchText] = React.useState("");
  const [searchedColumn, setSearchedColumn] = React.useState("");
  let accessAuth = getAccessAuthrorization("user");

  const {
    isLoading,
    isError,
    data: user,
  } = useQuery(["user"], () => getAllUser(), {
    refetchOnWindowFocus: false,

    keepPreviousData: true,
    staleTime: 5000,
  });

  if (isLoading || isError) {
    return <div>Loading</div>;
  }

  const column: ColumnsType<DataType> = [
    {
      title: "User Name",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
    },

    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },

    {
      title: "Roles",
      dataIndex: "roles",
      key: "roles",
      render: (text) =>
        text.map((item: any, i: any) => {
          return <Tag>{item?.name}</Tag>;
        }),
    },

    // {
    //   title: "Action",
    //   dataIndex: "id",
    //   key: "id",
    //   render: (id: any) => (
    //     <Space size={10}>
    //       <UserViewModal id={id} />
    //       {/* <UserManageModal id={id} /> */}
    //       {accessAuth[0].includes("Edit") && <UserManageModal id={id} />}
    //     </Space>
    //   ),
    // },
  ];

  return <Table size="small" columns={column} dataSource={user} />;
};

export default UserList;
