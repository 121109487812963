import * as React from "react";
import { Modal, Button, Tooltip } from "antd";

import { EditOutlined } from "@ant-design/icons";
import ManageTestimonialForm from "../Form/ManageTestimonialForm";

export const ManageTestimonialModalContext = React.createContext<{
  setIsModalVisible: (active: boolean) => void;
}>({
  setIsModalVisible: () => true,
});

const ManageTestimonialModal: React.FC<{ id: string }> = ({ id }) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const showModal = () => setIsModalVisible(true);

  const handleOk = () => setIsModalVisible(false);

  const handleCancel = () => setIsModalVisible(false);

  return (
    <ManageTestimonialModalContext.Provider value={{ setIsModalVisible }}>
      <Tooltip title="Edit/Update">
        <Button
          onClick={showModal}
          type="primary"
          shape="circle"
          icon={<EditOutlined />}
        />
      </Tooltip>
      <Modal
        title="Manage Testimonial"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width={1000}
      >
        <ManageTestimonialForm id={id} />
      </Modal>
    </ManageTestimonialModalContext.Provider>
  );
};

export default ManageTestimonialModal;
