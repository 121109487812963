import * as React from "react";
import {
  Form,
  Button,
  message,
  Row,
  Typography,
  Input,
  Col,
  Upload,
  Switch,
  Divider,
  Card,
  Select,
  Table,
  Space,
  Tag,
  Image,
} from "antd";

import { request } from "../../../utils/services";
import { useMutation, useQuery } from "react-query";
import {
  InfoCircleOutlined,
  PlusOutlined,
  LoadingOutlined,
  FileExclamationOutlined,
} from "@ant-design/icons";
import { queryClient } from "src";

import LocalStorageService from "src/utils/local-storage";
import { CreateCredentialModalContext } from "../Modal/CreateCredential";
import {
  RcFile,
  UploadChangeParam,
  UploadFile,
  UploadProps,
} from "antd/es/upload";
import { s3Path, uploadFileToAWS } from "src/utils/s3Config/s3upload";
import ManageCredentialModal from "../Modal/ManageCredential";
import { ColumnsType } from "antd/es/table";
import { getAllCredential, getCredentialByWorkId } from "../Requests";
import {
  getAllActiveWork,
  getWorkById,
  getWorkByIdDetail,
} from "src/modules/Work/Requests";
import CredentialViewModal from "../Modal/ShowCredentialDetails";
import CredentialListByWorkId from "src/modules/Work/Component/CredentialListByWorkId";
import TextArea from "antd/lib/input/TextArea";
import useCopyToClipboard from "src/components/copytoClipboard";

interface DataType {
  id: string;
  name: any;
}
const CreateCredential: React.FC<{ id?: string }> = (props) => {
  // const modalContext = React.useContext(CreateCredentialModalContext);
  const [formLoading, setFormLoading] = React.useState(false);
  const [galley, setGallery] = React.useState<string>();
  const [loading, setLoading] = React.useState(false);

  const [imageUrl, setImageUrl] = React.useState<string>();
  const [selectedFile, setSelectedFile] = React.useState<RcFile>();
  // const [isWorkSelected, setIsWorkSelected] = React.useState(false);
  const [workData, setWorkData] = React.useState<Object | any>({});
  // const [selectedWorkId, setSelectedWorkId] = React.useState(undefined);

  const createCredentialMutation = useMutation(
    (values: any) => request("admin.credential.create", {}, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("credential");
      },
    }
  );

  const { data: work } = useQuery(["work"], async () => getAllActiveWork(), {
    refetchOnWindowFocus: false,
  });

  const getBase64 = (img: RcFile, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    // if (info.file.status === "uploading") {
    //   setLoading(true);
    //   return;
    // }
    setSelectedFile(info.file.originFileObj);
    if (info.file.status === "uploading") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj as RcFile, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
  };

  const onFinish = async (values: any) => {
    values.createdBy = LocalStorageService.getAccount().id;
    values.icon = galley;

    setFormLoading(true);

    createCredentialMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The Credential has been successfully created.");
        form.resetFields();
        // modalContext.setIsModalVisible(false);
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const { Text, Title } = Typography;
  const [form] = Form.useForm();
  const mutateWork = useMutation((id: string) => getWorkById(id));
  const OnWorkSelect = (i: any) => {
    // console.log(i);

    mutateWork
      .mutateAsync(i)
      .then((payload) => {
        setWorkData(payload);
      })
      .catch((e) => {
        console.log(e, "error");
      });
  };
  React.useEffect(() => {
    if (props.id) {
      form.setFieldsValue({ workId: props.id });
      OnWorkSelect(props.id);
    }
  }, []);

  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}
    >
      <Row gutter={22}>
        <Col flex={5} span={8}>
          <h3>Add Credential</h3>
          <Card style={{ backgroundColor: "whitesmoke" }} bordered hoverable>
            <Form.Item
              label="Project/Product"
              name="workId"
              style={{ fontWeight: "bold" }}
              tooltip={{
                title: "work",
                icon: <InfoCircleOutlined />,
              }}
              rules={[
                {
                  required: true,
                  message: "Please Select Project/Product",
                },
              ]}
              hasFeedback
            >
              <Select
                onSelect={OnWorkSelect}
                showSearch
                popupMatchSelectWidth
                // style={{ minHeight: 150 }}
                placeholder="Select Work"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option!.children as unknown as string).includes(input)
                }
              >
                {work &&
                  work?.map((item: any, i: number) => {
                    return (
                      <Select.Option value={item?.id} key={i}>
                        {item?.title}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>

            <Form.Item
              label="Platform Name"
              name="platformName"
              style={{
                fontWeight: "bold",
              }}
              tooltip={{
                title: "Platform Name",
                icon: <InfoCircleOutlined />,
              }}
              rules={[{ whitespace: true }, { min: 3 }]}
              hasFeedback
            >
              <Input placeholder="Enter Platform Name" />
            </Form.Item>

            <Form.Item
              label="Platform Link"
              name="platformLink"
              style={{
                fontWeight: "bold",
              }}
              tooltip={{
                title: "Platform Link",
                icon: <InfoCircleOutlined />,
              }}
              rules={[
                {
                  required: true,
                  message: "Please Enter Platform Link",
                },
                { whitespace: true },
                { min: 3 },
              ]}
              hasFeedback
            >
              <Input placeholder="Enter Platform Link" />
            </Form.Item>

            <Form.Item
              label="Username"
              name="username"
              style={{
                fontWeight: "bold",
              }}
              tooltip={{
                title: "User name",
                icon: <InfoCircleOutlined />,
              }}
              rules={[
                {
                  required: true,
                  message: "Please Enter username",
                },
                { whitespace: true },
                { min: 3 },
              ]}
              hasFeedback
            >
              <Input placeholder="Enter username" />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              style={{
                fontWeight: "bold",
              }}
              tooltip={{
                title: "password",
                icon: <InfoCircleOutlined />,
              }}
              rules={[
                {
                  required: true,
                  message: "Please Enter password",
                },
                { whitespace: true },
                { min: 3 },
              ]}
              hasFeedback
            >
              <Input placeholder="Enter password" />
            </Form.Item>
            <Form.Item
              label="Remarks"
              name="remarks"
              style={{
                fontWeight: "bold",
              }}
              tooltip={{
                title: "remarks",
                icon: <InfoCircleOutlined />,
              }}
              rules={[
                // {
                //   required: true,
                //   message: "Please Enter password",
                // },
                { whitespace: true },
                { min: 3 },
              ]}
              hasFeedback
            >
              <TextArea placeholder="Add Remarks(If Any)" />
            </Form.Item>
            <Form.Item style={{ textAlign: "center" }}>
              <Button
                size="large"
                loading={formLoading}
                type="primary"
                htmlType="submit"
                shape="round"
              >
                Submit
              </Button>
            </Form.Item>
          </Card>
        </Col>
        <Col flex={5} span={16}>
          <h3>Credential List</h3>

          <CredentialListByWorkId id={props.id} />
        </Col>
      </Row>
    </Form>
  );
};

export default CreateCredential;
