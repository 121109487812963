import * as React from "react";
import {
  Form,
  Button,
  message,
  Row,
  Typography,
  Input,
  Col,
  Upload,
  Switch,
  Divider,
  Select,
  DatePicker,
  TreeSelect,
} from "antd";

import { request } from "../../../../utils/services";
import { useMutation, useQuery } from "react-query";
import { InfoCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { queryClient } from "src";
import type { UploadFile } from "antd/es/upload/interface";
import type { RcFile, UploadProps } from "antd/es/upload";

import TextArea from "antd/lib/input/TextArea";

import LocalStorageService from "src/utils/local-storage";
import { CreateProjectModalContext } from "../Modal/CreateProject";
import { getAllActiveParty } from "src/modules/Party/Requests";
import { getAllActiveTechnology } from "../../../technology/Requests";

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
const { Text } = Typography;
const CreateProject: React.FC = () => {
  const modalContext = React.useContext(CreateProjectModalContext);
  const [formLoading, setFormLoading] = React.useState(false);
  const createProjectMutation = useMutation(
    (values: any) => request("admin.project.create", {}, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("project");
      },
    }
  );
  const { data: party } = useQuery(["party"], async () => getAllActiveParty(), {
    refetchOnWindowFocus: false,
  });
  const { data: technology } = useQuery(
    ["technology"],
    async () => getAllActiveTechnology(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const onFinish = async (values: any) => {
    values.createdBy = LocalStorageService.getAccount().id;
    // values.partyId = party[values.partyId].id;
    setFormLoading(true);

    createProjectMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The Project has been successfully created.");
        form.resetFields();
        modalContext.setIsModalVisible(false);
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const [form] = Form.useForm();
  const [previewOpen, setPreviewOpen] = React.useState(false);
  const [previewImage, setPreviewImage] = React.useState("");
  const [previewTitle, setPreviewTitle] = React.useState("");
  const [fileList, setFileList] = React.useState<UploadFile[]>([]);
  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };
  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) =>
    setFileList(newFileList);

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  const treeData =
    technology &&
    technology?.map((item: any, i: number) => {
      return {
        disabled: true,
        //value: item.type,
        title: item.type,
        children: item.technologyUses?.map((element: any, i: number) => {
          return {
            value: element._id,
            title: element.technology,
          };
        }),
      };
    });

  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}
    >
      {/* <Row gutter={20}>
        <Col flex={3} span={12}> */}
      <Form.Item
        label="Party"
        name="partyId"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "Party Name",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: "Please Select Party",
          },
        ]}
        hasFeedback
      >
        <Select
          // onChange={onPartySelect}
          showSearch
          placeholder="Select Party"
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option!.children as unknown as string).includes(input)
          }
        >
          {party &&
            party?.map((item: any, i: number) => {
              return (
                <Select.Option value={item.id} key={i}>
                  {item?.companyName} | {item?.personName}
                </Select.Option>
              );
            })}
        </Select>
      </Form.Item>
      {/* </Col>
        <Col flex={3} span={12}> */}
      <Form.Item
        label="Project Name"
        name="projectName"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "Name",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: "Please enter Project Name",
          },
          { whitespace: true },
          { min: 3 },
        ]}
        hasFeedback
      >
        <Input
        // addonBefore={<ToolOutlined className="site-form-item-icon" />}
        />
      </Form.Item>

      <Form.Item
        label="Technology"
        name="technologyId"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "Technology",
          icon: <InfoCircleOutlined />,
        }}
        // rules={[
        //   // {
        //   //   required: true,
        //   //   message: "Please Select Technology",
        //   // },
        //   { whitespace: true },
        //   { min: 3 },
        // ]}
        hasFeedback
      >
        {/* <Select
          // onChange={onPartySelect}
          showSearch
          placeholder="Select Technology"
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option!.children as unknown as string).includes(input)
          }
        >
          {technology &&
            technology?.map((item: any, i: number) => {
              return (
                <Select.Option value={item.id} key={i}>
                  <Row style={{ margin: "2" }}>
                    {item?.technologyUses?.map((res: any) => (
                      <>{res.technology}</>
                    ))}
                  </Row>
                </Select.Option>
              );
            })}
        </Select> */}
        <TreeSelect
          showSearch
          style={{ width: "100%" }}
          // value={value}
          dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
          placeholder="Please select"
          allowClear
          multiple
          treeDefaultExpandAll
          //onChange={onChange}
          treeData={treeData}
        />
      </Form.Item>
      <Form.Item
        label="Project Status"
        name="projectStatus"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "Project Status",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: "Please Select Project Status",
          },
          { whitespace: true },
          { min: 3 },
        ]}
        hasFeedback
      >
        <Select
          // onChange={onPartySelect}
          showSearch
          placeholder="Select Project Status"
          // optionFilterProp="children"
          // filterOption={(input, option) =>
          //   (option as unknown as string).includes(input)
          // }
          options={[
            { value: "New", label: "New" },
            { value: "In Progress", label: "In Progress" },
            { value: "On Hold", label: "On Hold" },
            { value: "Testing", label: "Testing" },
            { value: "Buged", label: "Buged" },
            { value: "Deployed", label: "Deployed" },
            { value: "Cancelled", label: "Cancelled" },
            { value: "Ticket Open", label: "Ticket Open" },
            { value: "Ticket Closed", label: "Ticket Closed" },
          ]}
        />
      </Form.Item>
      <Row gutter={24}>
        <Col flex={3} span={12}>
          {" "}
          <Form.Item
            label="Start Date"
            name="startDate"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "StartDate",
              icon: <InfoCircleOutlined />,
            }}
            hasFeedback
          >
            <DatePicker format={"DD-MMM-YYYY"} />
          </Form.Item>
        </Col>
        <Col flex={3} span={12}>
          {" "}
          <Form.Item
            label="Proposed End Date"
            name="proposedEndDate"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "proposedEndDate",
              icon: <InfoCircleOutlined />,
            }}
            hasFeedback
          >
            <DatePicker format={"DD-MMM-YYYY"} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col flex={2} span={12}>
          <Form.Item
            label="Status"
            style={{ fontWeight: "bold" }}
            name="isActive"
            tooltip={{
              title: "status",
              icon: <InfoCircleOutlined />,
            }}
            valuePropName="checked"
            // rules={[{ required: true }]}
          >
            <Switch
              checkedChildren="Active"
              unCheckedChildren="Inactive"
              defaultChecked
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreateProject;
