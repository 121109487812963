import { path } from "./path";

export const quotationRoutes = [
  {
    method: "post",
    path: path + "/api/admin/createQuotation",
    module: "quotation",
    alias: "admin.quotation.create",
    isSubDomain: true,
  },

  {
    method: "put",
    path: path + "/api/admin/updateQuotation/:id",
    module: "quotation",
    alias: "admin.quotation.update",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllActiveQuotation",
    module: "quotation",
    alias: "admin.quotation.list.active",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllQuotation",
    module: "quotation",
    alias: "admin.quotation.list",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findOneQuotation/:id",
    module: "quotation",
    alias: "admin.quotation.id",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findOneQuotationWithDetail/:id",
    module: "quotation",
    alias: "admin.quotation.id.with.detail",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findAllQuotationByPartyId/:id",
    module: "quotation",
    alias: "admin.quotation.by.partyId",
  },
];
