import * as React from "react";
import {
  Form,
  Button,
  message,
  Row,
  Typography,
  Col,
  Switch,
  Input,
  Divider,
  Select,
  DatePicker,
} from "antd";
import { request } from "src/utils/services";
import { useMutation } from "react-query";
import { InfoCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { queryClient } from "src";
import { ManageBugReportModalContext } from "../Modal/ManageBugReport";

import { useQuery } from "react-query";

import TextArea from "antd/lib/input/TextArea";

import moment from "moment";
import { getBugReportById } from "../Requests";
import Upload, { RcFile, UploadFile, UploadProps } from "antd/es/upload";
import { getAllActiveParty } from "src/modules/Party/Requests";
import { getAllActiveProject } from "../../Project/Requests";
const { Text } = Typography;
const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
const ManageBugReportForm: React.FC<{ id: string }> = (props) => {
  const modalContext = React.useContext(ManageBugReportModalContext);
  const [formLoading, setFormLoading] = React.useState(false);
  const manageBugReportMutation = useMutation(
    (values: any) =>
      request("admin.bugReport.update", { id: props.id }, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("bugReport");
      },
    }
  );
  const {
    isLoading,
    data: bugReport,
    error,
    isError,
  } = useQuery(
    ["bugReport", props.id],
    async () => getBugReportById(props.id),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: project } = useQuery(
    ["project"],
    async () => getAllActiveProject(),
    {
      refetchOnWindowFocus: false,
    }
  );
  const onFinish = async (values: any) => {
    setFormLoading(true);

    manageBugReportMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The BugReport has been successfully managed.");
        form.resetFields();
        modalContext.setIsModalVisible(false);
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const [form] = Form.useForm();
  const [previewOpen, setPreviewOpen] = React.useState(false);
  const [previewImage, setPreviewImage] = React.useState("");
  const [previewTitle, setPreviewTitle] = React.useState("");
  const [fileList, setFileList] = React.useState<UploadFile[]>([]);
  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };
  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) =>
    setFileList(newFileList);

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={bugReport}
      form={form}
    >
      {/* <Row gutter={20}>
        <Col flex={3} span={12}> */}
      <Form.Item
        label="Project Detail"
        name="projectId"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "Project Detail",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: "Please Select Project Detail",
          },
        ]}
        hasFeedback
      >
        <Select
          // onChange={onPartySelect}
          showSearch
          placeholder="Select Project"
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option!.children as unknown as string).includes(input)
          }
        >
          {project &&
            project?.map((item: any, i: number) => {
              return (
                <Select.Option value={item.id} key={i}>
                  {item?.projectName}
                </Select.Option>
              );
            })}
        </Select>
      </Form.Item>
      {/* </Col>
        <Col flex={3} span={12}> */}
      <Form.Item
        label="Bug Detail"
        name="bugDetail"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "Bug Detail",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: "Please enter Bug Detail",
          },
          { whitespace: true },
          { min: 3 },
        ]}
        hasFeedback
      >
        <TextArea placeholder="Enter Bug Detail" />
      </Form.Item>

      <Form.Item
        label="Status"
        name="status"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "Status",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: "Please Enter Status",
          },
          { whitespace: true },
          { min: 3 },
        ]}
        hasFeedback
      >
        <Input placeholder="Enter Status" />
      </Form.Item>

      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ManageBugReportForm;
