import { path } from "./path";

export const serviceRoutes = [
  {
    method: "post",
    path: path + "/api/admin/saveService",
    module: "service",
    alias: "admin.service.create",
    isSubDomain: true,
  },

  {
    method: "put",
    path: path + "/api/admin/updateService/:id",
    module: "service",
    alias: "admin.service.update",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllActiveService",
    module: "service",
    alias: "admin.service.list.active",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllService",
    module: "service",
    alias: "admin.service.list",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findOneService/:id",
    module: "service",
    alias: "admin.service.id",
    isSubDomain: true,
  },
];
