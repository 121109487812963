import { path } from "./path";

export const notificationRoutes = [
  {
    method: "post",
    path: path + "/api/admin/createNotification",
    module: "notification",
    alias: "admin.notification.create",
    isSubDomain: true,
  },

  {
    method: "put",
    path: path + "/api/admin/updateNotification/:id",
    module: "notification",
    alias: "admin.notification.update",
    isSubDomain: true,
  },

  {
    method: "put",
    path: path + "/api/admin/updateManyNotification/:id",
    module: "notification",
    alias: "admin.notification.update.many",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllActiveNotification",
    module: "notification",
    alias: "admin.notification.list.active",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllNotification",
    module: "notification",
    alias: "admin.notification.list",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findOneNotification/:id",
    module: "notification",
    alias: "admin.notification.id",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findNotificationByPersonnel/:id",
    module: "notification",
    alias: "admin.notification.personnel.id",
    isSubDomain: true,
  },
];
