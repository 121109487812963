import { TRoute } from "../../utils/routes";
import AccessPageHome from "./Page/Home";

const AccessRouter: TRoute[] = [
  {
    path: "/access",
    name: "access",
    title: "access",
    component: AccessPageHome,
  },
];

export default AccessRouter;
