import * as React from "react";
import {
  Form,
  Input,
  InputNumber,
  Button,
  message,
  Switch,
  Select,
  Col,
  Row,
  Checkbox,
  DatePicker,
  Card,
  Tag,
  Typography,
  Divider,
  Space,
  Avatar,
} from "antd";
import { request } from "../../../utils/services";
import { useMutation, useQuery } from "react-query";
import {
  ProfileOutlined,
  PhoneOutlined,
  InfoCircleOutlined,
  BankOutlined,
  HomeOutlined,
  MobileOutlined,
  GlobalOutlined,
  MailOutlined,
  AuditOutlined,
  UserOutlined,
  PushpinOutlined,
} from "@ant-design/icons";
import { queryClient } from "../../..";
import { CreateFollowupModalContext } from "../Modal/CreateFollowup";
import LocalStorageService from "../../../utils/local-storage";
import { getAllConcernedPerson } from "src/modules/Party/Requests";
import { getLeadByIdDetail } from "../Requests";
import Title from "antd/es/skeleton/Title";
import moment from "moment";
import FollowUpListByLead from "../Home/FollowupListByLeadId";
import TextArea from "antd/es/input/TextArea";
import { values } from "lodash";
import { s3Path } from "src/utils/s3Config/s3upload";
import imLogo from "src/assets/img/imlogo.png";
import { getAllPersonnel } from "src/modules/HR/Personnel/Requests";

const CreateMobileFollowupForm: React.FC<{ leadId: string }> = (props) => {
  const modalContext = React.useContext(CreateFollowupModalContext);
  const [formLoading, setFormLoading] = React.useState(false);
  const [user, setUser] = React.useState(LocalStorageService.getAccount());

  const createFollowupMutation = useMutation(
    (values: any) => request("admin.followup.create", {}, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("followup");
      },
    }
  );

  const { data: lead } = useQuery(
    ["lead", props.leadId],
    async () => getLeadByIdDetail(props.leadId),
    {
      refetchOnWindowFocus: false,

      staleTime: 5000,
    }
  );

  const { data: person } = useQuery(
    ["peronnnel"],
    async () => getAllPersonnel(),
    {
      refetchOnWindowFocus: false,
    }
  );
  const onFinish = async (values: any) => {
    setFormLoading(true);
    values.createdBy = LocalStorageService.getAccount().id;
    values.taskTitle = "Take next followup to -";
    values.taskType = "Lead";
    values.leadId = props.leadId;
    values.content = `${values.followupPurpose} to ${values.concernedPersonId}`;
    values.description = values.clientRemarks;
    values.notificationTitle = "Action Pending!You have to take Followup";
    values.notificationBody =
      "Be in touch with client!  Click to view & take Followup";
    values.receiverId = [values.assignedPersonId];
    values.senderId = LocalStorageService.getAccount().personnel.id;
    createFollowupMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The followup has been successfully created.");
        // modalContext.setIsModalVisible(false);
        form.resetFields();
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const [form] = Form.useForm();
  const { Text, Title } = Typography;
  // console.log(lead[0]?.partyId[0].concernedpersons[0]);
  React.useEffect(() => {
    let today = moment(new Date()).format("DD-MM-YYYY");
    form.setFieldsValue({
      followupDate: moment(today, "DD-MM-YYYY"),
    });
    form.setFieldsValue({
      assignedPersonId: user.personnel.id,
    });
  }, []);
  // var date = new Date().getDate();
  // var mon = new Date().getMonth() + 1;
  // var year = new Date().getFullYear();

  // console.log(user);

  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}
    >
      <h4>Add Followup</h4>

      <Form.Item
        label=" Followup Date"
        name="followupDate"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "followupDate",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: "Please Select Date",
          },
        ]}
      >
        <DatePicker showToday format={"DD-MM-YYYY"} />
      </Form.Item>

      <Form.Item
        label="Followup Purpose"
        style={{ fontWeight: "bold" }}
        name="followupPurpose"
        rules={[
          {
            required: true,
            message: "Please Select FollowUp Purpose",
          },
        ]}
        // valuePropName="checked"
      >
        <Select
          placeholder="Select Purpose"
          options={[
            { value: "Introduction", label: "Introduction" },
            { value: "Branding", label: "Branding" },
            { value: "Pitching", label: "Pitching" },
            { value: "Requirement", label: "Requirement" },
            { value: "Proposal", label: "Proposal" },
            { value: "Discussion", label: "Discussion" },
            { value: "Negotiation", label: "Negotiation" },
            { value: "Meeting", label: "Meeting" },
            { value: "Finalisation", label: "Finalisation" },
          ]}
        />
      </Form.Item>
      <Form.Item
        label="Type"
        style={{ fontWeight: "bold" }}
        name="type"
        rules={[
          {
            required: true,
            message: "Please Select type",
          },
        ]}
        // valuePropName="checked"
      >
        <Select
          placeholder="Select Type"
          options={[
            { value: "Voice Call", label: "Voice Call" },
            { value: "Video Call", label: "Video Call" },
            { value: "Messaging", label: "Messaging" },
            { value: "In Person", label: "In Person" },
            { value: "Email", label: "Email" },
            { value: "Other", label: "Other" },
          ]}
        />
      </Form.Item>
      <Form.Item
        label="Concerned Person"
        name="concernedPersonId"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "concernedPerson",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: "Please Select Concern Person",
          },
        ]}
      >
        <Select
          showSearch
          placeholder="Select Concerned Person"
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option!.children as unknown as string).includes(input)
          }
        >
          {lead &&
            lead[0].partyId[0].concernedpersons?.map((item: any, i: number) => {
              return (
                <Select.Option value={item._id} key={i}>
                  {item.name}
                </Select.Option>
              );
            })}
        </Select>
      </Form.Item>
      {/* </Row> */}
      <Form.Item
        label="Client Remarks"
        name="clientRemarks"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "clientRemarks",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: "Please Enter Remarks",
          },
        ]}
      >
        <TextArea placeholder="Enter Client Remarks" />
      </Form.Item>

      <Form.Item
        label="Next Followup Date"
        name="nextFollowupDate"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "nextFollowupDate",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: "Please Select Date",
          },
        ]}
      >
        <DatePicker />
      </Form.Item>
      <Form.Item
        label="Lead Status"
        style={{ fontWeight: "bold" }}
        name="leadStatus"
        rules={[
          {
            required: true,
            message: "Please Select Status",
          },
        ]}
        // valuePropName="checked"
      >
        <Select
          placeholder="Select Status"
          options={[
            { value: "Open", label: "Open" },
            { value: "Interested", label: "Interested" },
            { value: "Quoted", label: "Quoted" },
            { value: "Converted", label: "Converted" },
            { value: "Cancelled", label: "Cancelled" },
            { value: "On Hold", label: "On Hold" },
            { value: "Not Interested", label: "Not Interested" },
          ]}
        />
      </Form.Item>
      <Form.Item
        label="Next Assign To"
        style={{ fontWeight: "bold" }}
        name="assignedPersonId"
        tooltip={{
          title: "Person ",
          icon: <InfoCircleOutlined />,
        }}
      >
        <Select
          // onSelect={OnPersonSelect}
          showSearch
          size="large"
          popupMatchSelectWidth
          // style={{ minHeight: 150 }}
          placeholder="Select Person"
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option!.children as unknown as string).includes(input)
          }
        >
          {person &&
            person?.map((item: any, i: number) => {
              return (
                <Select.Option
                  value={item?.id}
                  key={i}
                  // disabled={item.availability === 0 ? true : false}
                >
                  <Row justify={"space-between"} align={"middle"}>
                    <Typography.Text strong>
                      {item?.partyId?.personName}
                    </Typography.Text>

                    <Avatar
                      src={
                        <img
                          src={item.imageUrl ? s3Path + item.imageUrl : imLogo}
                          alt={item?.partyId?.personName[0]}
                        />
                      }
                    />
                  </Row>
                </Select.Option>
              );
            })}
        </Select>
      </Form.Item>

      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Submit
        </Button>
      </Form.Item>

      <br />
      {/* <FollowUpListByLead id={props.leadId} /> */}
    </Form>
  );
};

export default CreateMobileFollowupForm;
