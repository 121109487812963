import { Tag } from "antd";

import * as React from "react";

import { useQuery } from "react-query";
import type { ColumnsType } from "antd/lib/table";
import DepartmentManageModal from "../../Modal/ManageDepartment";

import { Space, Table } from "antd";

import { getAllDepartment } from "../../Requests";
import { getAccessAuthrorization } from "src/components/access";

const DepartmentList: React.FC = () => {
  const [page, setPage] = React.useState(1);
  const [size, setSize] = React.useState(100);
  const [searchText, setSearchText] = React.useState("");
  const [searchedColumn, setSearchedColumn] = React.useState("");
  let accessAuth = getAccessAuthrorization("department");

  const {
    isLoading,
    isError,
    data: response,
  } = useQuery(["department"], () => getAllDepartment(), {
    refetchOnWindowFocus: false,

    keepPreviousData: true,
    staleTime: 5000,
  });

  if (isLoading || isError) {
    return <div>Loading</div>;
  }
  interface DataType {
    id: number;
    name: any;
    status: any;
  }

  const column: ColumnsType<DataType> = [
    {
      title: "Department ID",
      dataIndex: "id",
      key: "id",

      render: (text: any) => <p>IM/Dept/{text}</p>,
    },
    {
      title: "Department Name",
      dataIndex: "departmentName",
      key: "departmentName",
    },
    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      render: (text: any) =>
        text === true ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">Inactive</Tag>
        ),
    },

    {
      title: "Action",
      dataIndex: "id",
      key: "id",

      render: (id: any) => (
        <Space size={10}>
          {/* <DepartmentManageModal id={id} /> */}
          {accessAuth[0].includes("Edit") && <DepartmentManageModal id={id} />}
        </Space>
      ),
    },
  ];
  const data: DataType[] = [
    {
      id: 1,
      name: "Technical",
      status: "Active",
    },
    {
      id: 2,
      name: "Software",
      status: "Inactive",
    },
    {
      id: 3,
      name: "HR",
      status: "Active",
    },
  ];
  return <Table size="small" columns={column} dataSource={response} />;
};

export default DepartmentList;
