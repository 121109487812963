import {
  Avatar,
  Badge,
  Button,
  Card,
  Col,
  Collapse,
  CollapseProps,
  Modal,
  Row,
  Segmented,
  Space,
  Tabs,
  Tag,
  Typography,
} from "antd";

import moment from "moment";
import * as React from "react";
import { useQuery } from "react-query";
import { getAccessAuthrorization } from "src/components/access";

import {
  getTaskAssignedBy,
  getTaskByPersonnel,
} from "src/modules/Task/Requests";
import LocalStorageService from "src/utils/local-storage";

import { s3Path } from "src/utils/s3Config/s3upload";

import CreateTaskModal from "src/modules/Task/Modal/CreateTask";

import ReactTimeAgo from "react-time-ago";
import TaskStatusManageModal from "src/modules/Task/Modal/ManageTaskStatus";
import imLogo from "src/assets/img/imlogo.png";

import { ShowTaskData } from "src/modules/Task/Form/ShowTaskDetailsForm";
import ShowWorkDetailsForm from "src/modules/Work/Form/ShowWorkForm";
import { DeploymentUnitOutlined, FlagOutlined } from "@ant-design/icons";
import ShowStoryDetailsForm from "src/modules/Story/Form/ShowStoryForm";
import StoryDetailsViewModal from "../Modal/ShowStoryDetails";

const TodoList: React.FC<{ id: string }> = (props) => {
  const [user, setUser] = React.useState(LocalStorageService.getAccount());
  const [isReply, setIsReply] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [assignedByUser, setAssignedByUser] = React.useState(false);

  let accessAuth = getAccessAuthrorization("task");

  const {
    isLoading,
    isError,
    data: task,
  } = useQuery(["task"], () => getTaskByPersonnel(props.id), {
    refetchOnWindowFocus: true,

    keepPreviousData: true,
    staleTime: 100,
  });
  const {
    //  isLoading,
    //  isError,
    data: taskByPersonnel,
  } = useQuery(["taskByPersonnel"], () => getTaskAssignedBy(user.id), {
    refetchOnWindowFocus: true,

    keepPreviousData: true,
    staleTime: 100,
  });

  const genExtra = (id: any) => <TaskStatusManageModal id={id} />;

  const getItemList = (list: any, status?: any) => {
    let filteredList = list.filter((element: any) => element.status === status);
    let tempList = status ? filteredList : list;
    const ItemList: CollapseProps["items"] =
      tempList &&
      tempList.map((item: any, i: any) => {
        return {
          key: i,
          label: (
            <Badge.Ribbon
              color={item?.type[0]?.color}
              text={item?.type[0]?.title}
              style={{ marginRight: 50 }}
            >
              <Card size="small" style={{ marginRight: 50 }}>
                <div style={{ marginRight: 100 }}>
                  <Row justify={"space-between"} align={"middle"}>
                    <Col span={2}>
                      <Space.Compact direction="vertical">
                        <Typography.Text
                          style={{ fontSize: 24, fontWeight: "bold" }}
                        >
                          {moment(item?.dueDate).format("DD")}
                        </Typography.Text>
                        <Typography.Text
                          style={{
                            fontSize: 16,
                            fontWeight: "bold",
                            color: "teal",
                          }}
                        >
                          {moment(item?.dueDate).format("MMM")}
                        </Typography.Text>
                        <Typography.Text
                          style={{
                            fontSize: 16,
                            color: "teal",
                          }}
                        >
                          {moment(item?.dueDate).format("YYYY")}
                        </Typography.Text>
                      </Space.Compact>
                    </Col>
                    <Col span={18}>
                      {/* <Typography.Text code>
                        {moment(item?.dueDate).format("DD-MMM-YYYY")}
                      </Typography.Text>{" "} */}
                      <Row style={{ marginBottom: 5 }}>
                        <Button
                          size="small"
                          style={{
                            fontSize: 12,
                            fontWeight: "bold",
                            backgroundColor:
                              item?.priority === "High"
                                ? "tomato"
                                : item?.priority === "Medium"
                                ? "orange"
                                : "yellow",
                          }}
                          // type={
                          //   item?.priority === "High"
                          //     ? "danger"
                          //     : item?.priority === "Medium"
                          //     ? "warning"
                          //     : "success"
                          // }
                          // strong
                          // code
                        >
                          {item?.title}
                        </Button>

                        {/* <Tag
                        color={
                          item?.priority === "High"
                            ? "red-inverse"
                            : item?.priority === "Medium"
                            ? "orange-inverse"
                            : "yellow-inverse"
                        }
                      >
                        {item?.priority}
                      </Tag> */}
                        {item?.leadId?.length > 0 && (
                          <>
                            <Typography.Text code>
                              {item?.leadId[0]?.partyId[0].personName}
                            </Typography.Text>

                            {item?.leadId[0]?.partyId[0].companyName && (
                              <Typography.Text code>
                                {item?.leadId[0]?.partyId[0].companyName}
                              </Typography.Text>
                            )}
                            <Tag color="gold" style={{ marginLeft: 20 }}>
                              {item?.leadId[0]?.leadStatus}
                            </Tag>
                          </>
                        )}
                        <Tag
                          color={
                            item.status === "To Do"
                              ? "orange"
                              : item.status === "In Progress"
                              ? "blue"
                              : item.status === "On Hold"
                              ? "volcano"
                              : item.status === "In Review"
                              ? "cyan"
                              : "lime"
                          }
                          style={{ marginLeft: 20 }}
                        >
                          {item.status}
                        </Tag>
                        <ReactTimeAgo
                          date={item.createdAt}
                          locale="en-IN"
                          // timeStyle="twitter"
                        />
                      </Row>
                      <Row>
                        {" "}
                        {item.storyId.length > 0 && (
                          <>
                            <StoryDetailsViewModal data={item} />
                          </>
                        )}
                      </Row>
                    </Col>

                    {assignedByUser ? (
                      <Col>
                        <Avatar
                          src={
                            <img
                              src={
                                item?.assignedPersonId[0]?.party[0]?.imageUrl
                                  ? s3Path +
                                    item?.assignedPersonId[0]?.party[0]
                                      ?.imageUrl
                                  : imLogo
                              }
                              alt={
                                item?.assignedPersonId[0]?.party[0]?.personName
                              }
                            />
                          }
                        />
                        {/* <Tag>{item?.createdBy[0]?.party[0]?.personName}</Tag> */}
                      </Col>
                    ) : (
                      item?.createdBy.length > 0 && (
                        <Col>
                          <Avatar
                            src={
                              <img
                                src={
                                  item?.createdBy[0]?.party[0]?.imageUrl
                                    ? s3Path +
                                      item?.createdBy[0]?.party[0]?.imageUrl
                                    : imLogo
                                }
                                alt={item?.createdBy[0]?.party[0]?.personName}
                              />
                            }
                          />
                          {/* <Tag>{item?.createdBy[0]?.party[0]?.personName}</Tag> */}
                        </Col>
                      )
                    )}
                  </Row>
                </div>
              </Card>
            </Badge.Ribbon>
          ),
          children: <ShowTaskData data={item} />,
          // extra: genExtra(item._id),
        };
      });
    return ItemList;
  };

  let allList = assignedByUser
    ? getItemList(taskByPersonnel)
    : getItemList(task);
  let todoList = assignedByUser
    ? getItemList(taskByPersonnel, "To Do")
    : getItemList(task, "To Do");
  let inProcessList = assignedByUser
    ? getItemList(taskByPersonnel, "In Progress")
    : getItemList(task, "In Progress");
  let onHoldList = assignedByUser
    ? getItemList(taskByPersonnel, "On Hold")
    : getItemList(task, "On Hold");
  let inReviewList = assignedByUser
    ? getItemList(taskByPersonnel, "In Review")
    : getItemList(task, "In Review");
  let completedList = assignedByUser
    ? getItemList(taskByPersonnel, "Done")
    : getItemList(task, "Done");
  // let allList = getItemList(task);
  // const operations = <CreateTaskModal />;
  return (
    <>
      {/* <Row justify={"end"}>
        <CreateTaskModal />
      </Row> */}
      <Tabs
        tabBarExtraContent={
          <Space direction="vertical">
            <Segmented
              options={[
                { value: "For Me", label: "For Me" },

                { value: "By Me", label: "By Me" },
              ]}
              onChange={(value) =>
                setAssignedByUser(value === "By Me" ? true : false)
              }
            />
            <CreateTaskModal />
          </Space>
        }
        defaultActiveKey="To Do"
        tabPosition="right"
        items={[
          {
            key: "To Do",
            label: (
              <Badge count={todoList?.length} offset={[12, 0]} color="orange">
                To Do
              </Badge>
            ),
            children: (
              <div style={{ height: "70vh", overflow: "auto" }}>
                {" "}
                <Collapse accordion items={todoList} expandIconPosition="end" />
              </div>
            ),
          },
          {
            key: "In Progress",
            label: (
              <Badge
                count={inProcessList?.length}
                offset={[12, 0]}
                color="blue"
              >
                In Progress
              </Badge>
            ),
            children: (
              <div style={{ height: "70vh", overflow: "auto" }}>
                <Collapse
                  accordion
                  items={inProcessList}
                  expandIconPosition="end"
                />
              </div>
            ),
          },
          {
            key: "On Hold",
            label: (
              <Badge
                count={onHoldList?.length}
                offset={[12, 0]}
                color="volcano"
              >
                On Hold
              </Badge>
            ),
            children: (
              <div style={{ height: "70vh", overflow: "auto" }}>
                <Collapse
                  accordion
                  items={onHoldList}
                  expandIconPosition="end"
                />
              </div>
            ),
          },
          {
            key: "In Review",
            label: (
              <Badge count={inReviewList?.length} offset={[12, 0]} color="cyan">
                In Review
              </Badge>
            ),
            children: (
              <div style={{ height: "70vh", overflow: "auto" }}>
                <Collapse
                  accordion
                  items={inReviewList}
                  expandIconPosition="end"
                />
              </div>
            ),
          },
          {
            key: "Completed",
            label: (
              <Badge
                count={completedList?.length}
                offset={[12, 0]}
                color="lime"
              >
                Completed
              </Badge>
            ),
            children: (
              <div style={{ height: "70vh", overflow: "auto" }}>
                <Collapse
                  accordion
                  items={completedList}
                  expandIconPosition="end"
                />
              </div>
            ),
          },
          {
            key: "All Tasks",
            label: (
              <Badge count={allList?.length} offset={[12, 0]} color="purple">
                All Tasks
              </Badge>
            ),
            children: (
              <div style={{ height: "70vh", overflow: "auto" }}>
                <Collapse accordion items={allList} expandIconPosition="end" />
              </div>
            ),
          },
        ]}
      />
      {/* <Table size="small" columns={column} dataSource={task} /> */}
    </>
  );
};

export default TodoList;
