import { request } from "../../../utils/services";

export const getAllActiveTechnology = async () => {
  const { data } = await request("admin.technology.list.active");
  return data;
};

export const getAllTechnology = async () => {
  const { data } = await request("admin.technology.list");
  return data;
};

export const getTechnologyById = async (id: string) => {
  const { data } = await request("admin.technology.id", { id });
  return data;
};
export const getTechnologyByType = async (type: string) => {
  const { data } = await request("admin.technology.type", {
    type,
  });
  return data;
};
