import { request } from "../../../utils/services";

export const getAllActiveJob = async () => {
  const { data } = await request("admin.job.list.active");
  return data;
};

export const getAllJob = async () => {
  const { data } = await request("admin.job.list");
  return data;
};

export const getJobById = async (id: string) => {
  const { data } = await request("admin.job.id", { id });
  return data;
};
