import { request } from "../../../utils/services";

export const getAllActiveParty = async () => {
  const { data } = await request("admin.party.list.active");
  return data;
};

export const getAllParty = async () => {
  const { data } = await request("admin.party.list");
  return data;
};

export const getPartyById = async (id: string) => {
  const { data } = await request("admin.party.id", { id });
  return data;
};

export const getBankDetailsByPartyId = async (id: string) => {
  const { data } = await request("admin.bankDetails.get.partyId", { id });
  return data;
};
export const getConcernedPersonById = async (id: string) => {
  const { data } = await request("admin.concernedperson.id", { id });
  return data;
};
export const getAllConcernedPerson = async () => {
  const { data } = await request("admin.concernedperson.list");
  return data;
};
export const getAllConcernedPersonByPartyId = async (id: string) => {
  const { data } = await request("admin.concernedperson.partyId", { id });
  return data;
};
export const getPartyByType = async (partyType: string) => {
  const { data } = await request("admin.party.type", {
    partyType,
  });
  return data;
};
