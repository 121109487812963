import { TRoute } from "src/utils/routes";
import DepartmentPageHome from "./Page/Home";

const CaseStudyRouter: TRoute[] = [
  {
    path: "/caseStudy",
    name: "caseStudy",
    title: "caseStudy",
    component: DepartmentPageHome,
  },
];

export default CaseStudyRouter;
