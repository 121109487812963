import * as React from "react";

import { Modal, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import CreateDeployment from "../Form/CreateDeploymentForm";

export const CreateDeploymentModalContext = React.createContext<{
  setIsModalVisible: (active: boolean) => void;
}>({
  setIsModalVisible: () => true,
});

const CreateDeploymentModal: React.FC<{ id?: string }> = ({ id }) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <CreateDeploymentModalContext.Provider value={{ setIsModalVisible }}>
      <Button
        size="small"
        onClick={showModal}
        type="primary"
        shape="round"
        style={{
          fontSize: 12,
          backgroundColor: "darkcyan",
        }}
        icon={<PlusOutlined style={{ fontSize: 10 }} />}
      >
        Add Deployment
      </Button>
      <Modal
        title="Generate Deployment"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        centered
        width={"85%"}
      >
        <CreateDeployment id={id} />
      </Modal>
    </CreateDeploymentModalContext.Provider>
  );
};

export default CreateDeploymentModal;
