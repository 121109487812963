import { request } from "../../../utils/services";

export const getAllActiveTaskTemplate = async () => {
  const { data } = await request("admin.taskTemplate.list.active");
  return data;
};

export const getAllTaskTemplate = async () => {
  const { data } = await request("admin.taskTemplate.list");
  return data;
};

export const getTaskTemplateById = async (id: string) => {
  const { data } = await request("admin.taskTemplate.id", { id });
  return data;
};
export const getTaskTemplateByType = async (type: string) => {
  const { data } = await request("admin.taskTemplate.type", {
    type,
  });
  return data;
};
