import { request } from "../../../utils/services";

export const getAllActivePurchaseOrder = async () => {
  const { data } = await request("admin.purchaseOrder.list.active");
  return data;
};

export const getAllPurchaseOrder = async () => {
  const { data } = await request("admin.purchaseOrder.list");
  return data;
};

export const getPurchaseOrderById = async (id: string) => {
  const { data } = await request("admin.purchaseOrder.id", { id });
  return data;
};

export const getOnePurchaseOrderWithDetail = async (id: string) => {
  const { data } = await request("admin.purchaseOrder.id.with.detail", { id });
  return data;
};
export const getPurchaseOrderByPartyId = async (id: string) => {
  const { data } = await request("admin.purchaseOrder.party.id", { id });
  return data;
};
