import * as React from "react";
import {
  Row,
  Col,
  Steps,
  Descriptions,
  Button,
  Typography,
  Divider,
  Card,
  Tag,
} from "antd";
import { useQuery } from "react-query";

import { getAfterSalesByIddetail } from "../Requests";
import moment from "moment";

const { Text } = Typography;

const ShowAfterSalesDetailsForm: React.FC<{ id: string }> = (props) => {
  const { data: afterSales } = useQuery(
    ["afterSales", props.id],
    async () => getAfterSalesByIddetail(props.id),
    {
      refetchOnWindowFocus: false,

      staleTime: 5000,
    }
  );

  return (
    <>
      <Divider dashed orientation="center">
        Bug Detail
      </Divider>
      <Descriptions bordered>
        <Descriptions.Item label="Domain Name">
          {afterSales?.domainName}
        </Descriptions.Item>
        <Descriptions.Item label="Server Name">
          {afterSales?.serverName}
        </Descriptions.Item>
        <Descriptions.Item label="Status">
          {afterSales?.isActive ? (
            <Tag color="green">Active</Tag>
          ) : (
            <Tag color="red">Inactive</Tag>
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Domain Expiry Date">
          {moment(afterSales?.domainExpiryDate).format("DD-MMM-YYYY")}
        </Descriptions.Item>
        <Descriptions.Item label="Server Expiry Date">
          {moment(afterSales?.serverExpiryDate).format("DD-MMM-YYYY")}
        </Descriptions.Item>
        <Descriptions.Item label="Maintainance Expiry Date">
          {moment(afterSales?.maintainanceExpiryDate).format("DD-MMM-YYYY")}
        </Descriptions.Item>
      </Descriptions>
      <br />
      <Card>
        <Descriptions title="Project Detail :-">
          <Descriptions.Item label="Project Name">
            {afterSales?.projectId?.projectName}
            <Text code type="danger">
              {afterSales?.projectId?.projectStatus}
            </Text>
          </Descriptions.Item>
          <Descriptions.Item label="Start Date">
            {moment(afterSales?.projectId?.startDate).format("DD-MMM-YYYY")}
          </Descriptions.Item>
          <Descriptions.Item label="Proposed End Date">
            {moment(afterSales?.projectId?.proposedEndDate).format(
              "DD-MMM-YYYY"
            )}
          </Descriptions.Item>
        </Descriptions>
        <Descriptions title="Team Detail :-">
          <Descriptions.Item label="Team Name">
            {afterSales?.afterSales?.teamId?.partyId?.personName}
            <Text code type="success">
              {afterSales?.projectId?.teamId?.partyId?.partyType}
            </Text>
          </Descriptions.Item>
          <Descriptions.Item label="Email">
            {afterSales?.projectId?.teamId?.partyId?.email}
          </Descriptions.Item>
          <Descriptions.Item label="Phone No.">
            +91-{afterSales?.projectId?.teamId?.partyId?.phoneNo}
          </Descriptions.Item>
          <Descriptions.Item label="Address">
            {afterSales?.projectId?.teamId?.partyId?.address}
          </Descriptions.Item>
          <Descriptions.Item label="Department Name">
            {afterSales?.projectId?.teamId?.departmentId?.departmentName}
            <Text code type="warning">
              {afterSales?.projectId?.teamId?.designationId?.designationName}
            </Text>
          </Descriptions.Item>
        </Descriptions>
      </Card>
      <br />
    </>
  );
};

export default ShowAfterSalesDetailsForm;
