import { TRoute } from "../../utils/routes";
import RolesPageHome from "./Page/Home";

const RolesRouter: TRoute[] = [
  {
    path: "/roles",
    name: "roles",
    title: "Roles",
    component: RolesPageHome,
  },
];

export default RolesRouter;
