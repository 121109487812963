import * as React from "react";
import LoginAuth from "../Form/LoginForm";
import { LoginContainer, LoginContent } from "./Login.styled";

const AccountPageLogin: React.FC = () => {
  return (
    <>
      <LoginContainer>
        <LoginContent>
          <LoginAuth />
        </LoginContent>
      </LoginContainer>
    </>
  );
};

export default AccountPageLogin;
