import {
  Avatar,
  Badge,
  Card,
  Col,
  Collapse,
  CollapseProps,
  Modal,
  Row,
  Segmented,
  Select,
  Space,
  Tabs,
  Tag,
  Typography,
} from "antd";
import * as React from "react";
import { useMutation, useQuery } from "react-query";
import { imLogoIcon, logo } from "src/constants/logo";
import { getAllPersonnel } from "src/modules/HR/Personnel/Requests";
import { s3Path } from "src/utils/s3Config/s3upload";
import TodoList from "./TodoList";
import { ShowTaskData } from "src/modules/Task/Form/ShowTaskDetailsForm";
import ShowWorkDetailsForm from "src/modules/Work/Form/ShowWorkForm";
import moment from "moment";
import ReactTimeAgo from "react-time-ago";
import { DeploymentUnitOutlined, FlagOutlined } from "@ant-design/icons";
import CreateTaskModal from "src/modules/Task/Modal/CreateTask";
import { getTaskByPersonnel } from "src/modules/Task/Requests";
import ShowMobileTaskDetailsForm from "src/modules/Task/Form/ShowMobileTaskDetailsForm";

const MobileAdminDashboard: React.FC = () => {
  const [personnelId, setPersonnelId] = React.useState(undefined);
  const [open, setOpen] = React.useState(false);
  const [task, setTask] = React.useState<Object | any>([{}]);
  const [taskOpen, setTaskOpen] = React.useState(false);
  const [taskId, setTaskId] = React.useState(undefined);
  const [assignedByUser, setAssignedByUser] = React.useState(false);

  const { data: person } = useQuery(
    ["peronnnel"],
    async () => getAllPersonnel(),
    {
      refetchOnWindowFocus: false,
    }
  );
  // const genExtra = (id: any) => <TaskStatusManageModal id={id} />;
  const mutateTask = useMutation((id: string) => getTaskByPersonnel(id));
  const OnPersonnelSelect = (i: any) => {
    // console.log(i);

    mutateTask
      .mutateAsync(i)
      .then((payload) => {
        setTask(payload);
      })
      .catch((e) => {
        console.log(e.message, "error");
      });
  };
  const getItemList = (list: any, status?: any) => {
    let filteredList = list.filter((element: any) => element.status === status);
    let tempList = status ? filteredList : list;
    const ItemList =
      tempList &&
      tempList.map((item: any, i: any) => {
        const { color, title } = item?.type?.length > 0 && item?.type[0];

        return (
          <Badge.Ribbon color={color} text={title} style={{ marginRight: 8 }}>
            <Card
              size="small"
              style={{ paddingRight: 8 }}
              onClick={() => {
                setTaskOpen(true);
                setTaskId(item?._id);
              }}
            >
              <div>
                <Row justify={"space-between"} align={"stretch"}>
                  <Col span={4}>
                    <Space.Compact direction="vertical">
                      <Typography.Text
                        style={{ fontSize: 24, fontWeight: "bold" }}
                      >
                        {moment(item?.dueDate).format("DD")}
                      </Typography.Text>
                      <Typography.Text>
                        {moment(item?.dueDate).format("MMM")}
                      </Typography.Text>
                      <Typography.Text>
                        {moment(item?.dueDate).format("YYYY")}
                      </Typography.Text>
                    </Space.Compact>
                  </Col>
                  <Col span={20}>
                    <Row>
                      <Typography.Text strong style={{ width: "60vw" }}>
                        {item?.title}
                      </Typography.Text>{" "}
                    </Row>
                    <Row>
                      {" "}
                      {item?.storyId?.length > 0 && (
                        <>
                          {" "}
                          {/* View Story Details: */}
                          {/* <StoryViewModal id={data.storyId} /> */}
                          <Tag color="red" onClick={() => setOpen(!open)}>
                            <DeploymentUnitOutlined />{" "}
                            {item.storyId[0]?.workId[0]?.title}
                          </Tag>
                          --
                          <Tag color="green" onClick={() => setOpen(!open)}>
                            <FlagOutlined /> {item.storyId[0]?.title}
                          </Tag>
                          {/* <Modal
                            open={open}
                            width={"80%"}
                            onCancel={() => setOpen(!open)}
                            onOk={() => setOpen(!open)}
                          >
                            <ShowWorkDetailsForm
                              id={item.storyId[0]?.workId[0]._id}
                            />{" "}
                          </Modal> */}
                        </>
                      )}
                    </Row>
                    <Row justify={"space-between"} align={"bottom"}>
                      <Tag color="geekblue" style={{ marginLeft: 20 }}>
                        {item.status}
                      </Tag>
                      <ReactTimeAgo
                        date={item.createdAt}
                        locale="en-IN"
                        // timeStyle="twitter"
                      />
                      {assignedByUser ? (
                        <Col>
                          <Avatar
                            src={
                              <img
                                src={
                                  item?.assignedPersonId[0]?.party[0]?.imageUrl
                                    ? s3Path +
                                      item?.assignedPersonId[0]?.party[0]
                                        ?.imageUrl
                                    : imLogoIcon
                                }
                                alt={
                                  item?.assignedPersonId[0]?.party[0]
                                    ?.personName
                                }
                              />
                            }
                          />
                          {/* <Tag>{item?.createdBy[0]?.party[0]?.personName}</Tag> */}
                        </Col>
                      ) : (
                        item?.createdBy?.length > 0 && (
                          <Col>
                            <Avatar
                              src={
                                <img
                                  src={
                                    item?.createdBy[0]?.party[0]?.imageUrl
                                      ? s3Path +
                                        item?.createdBy[0]?.party[0]?.imageUrl
                                      : imLogoIcon
                                  }
                                  alt={item?.createdBy[0]?.party[0]?.personName}
                                />
                              }
                            />
                          </Col>
                        )
                      )}
                    </Row>
                  </Col>
                </Row>
              </div>
            </Card>
            {/* <Modal
              open={taskOpen}
              width={"100%"}
              onCancel={() => setTaskOpen(!taskOpen)}
              onOk={() => setTaskOpen(!taskOpen)}
              //   onClose={() => setOpen(!open)}
            >
              <ShowMobileTaskDetailsForm id={item._id} />
            </Modal> */}
          </Badge.Ribbon>
        );
      });

    return ItemList;
  };

  let allList = task && getItemList(task);
  let todoList = task && getItemList(task, "To Do");
  let inProcessList = task && getItemList(task, "In Progress");
  let onHoldList = task && getItemList(task, "On Hold");
  let inReviewList = task && getItemList(task, "In Review");
  let completedList = task && getItemList(task, "Done");
  // let allList = getItemList(task);
  // const operations = <CreateTaskModal />;
  return (
    <>
      {taskId && (
        <Modal
          open={taskOpen}
          width={"100%"}
          onCancel={() => setTaskOpen(!taskOpen)}
          onOk={() => setTaskOpen(!taskOpen)}
          //   onClose={() => setOpen(!open)}
        >
          <ShowMobileTaskDetailsForm id={taskId} />
        </Modal>
      )}
      <Row justify={"end"} align={"middle"}>
        <Space.Compact direction="vertical">
          <Typography.Text strong>Task View of Personnel: </Typography.Text>
          <Select
            allowClear
            onSelect={OnPersonnelSelect}
            showSearch
            size="large"
            popupMatchSelectWidth
            // style={{ minHeight: 150 }}
            placeholder="Select Personnel to View Task"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option!.children as unknown as string).includes(input)
            }
            style={{ width: "100%" }}
          >
            {person &&
              person?.map((item: any, i: number) => {
                return (
                  <Select.Option
                    value={item?.id}
                    key={i}
                    // disabled={item.availability === 0 ? true : false}
                  >
                    <Row justify={"space-between"} align={"middle"}>
                      <Typography.Text strong>
                        {item?.partyId?.personName}
                      </Typography.Text>

                      <Avatar
                        src={
                          <img
                            src={
                              item.imageUrl
                                ? s3Path + item.imageUrl
                                : imLogoIcon
                            }
                            alt={item?.partyId?.personName[0]}
                          />
                        }
                      />
                    </Row>
                  </Select.Option>
                );
              })}
          </Select>
        </Space.Compact>
      </Row>

      {/* <Row justify={"end"}>
        <CreateTaskModal />
      </Row> */}
      {task.length > 1 ? (
        <Tabs
          // tabBarExtraContent={
          //   <Space direction="vertical">
          //     <CreateTaskModal />
          //   </Space>
          // }
          defaultActiveKey="To Do"
          // tabPosition="right"
          items={[
            {
              key: "To Do",
              label: (
                <Badge count={todoList?.length} offset={[12, 0]} color="orange">
                  To Do
                </Badge>
              ),
              children: (
                <div style={{ height: "70vh", overflow: "auto" }}>
                  {todoList}
                </div>
              ),
            },
            {
              key: "In Progress",
              label: (
                <Badge
                  count={inProcessList?.length}
                  offset={[12, 0]}
                  color="blue"
                >
                  In Progress
                </Badge>
              ),
              children: (
                <div style={{ height: "70vh", overflow: "auto" }}>
                  {inProcessList}
                </div>
              ),
            },
            {
              key: "On Hold",
              label: (
                <Badge
                  count={onHoldList?.length}
                  offset={[12, 0]}
                  color="volcano"
                >
                  On Hold
                </Badge>
              ),
              children: (
                <div style={{ height: "70vh", overflow: "auto" }}>
                  {onHoldList}
                </div>
              ),
            },
            {
              key: "In Review",
              label: (
                <Badge
                  count={inReviewList?.length}
                  offset={[12, 0]}
                  color="cyan"
                >
                  In Review
                </Badge>
              ),
              children: (
                <div style={{ height: "70vh", overflow: "auto" }}>
                  {inReviewList}
                </div>
              ),
            },
            {
              key: "Completed",
              label: (
                <Badge
                  count={completedList?.length}
                  offset={[12, 0]}
                  color="lime"
                >
                  Completed
                </Badge>
              ),
              children: (
                <div style={{ height: "70vh", overflow: "auto" }}>
                  {completedList}
                </div>
              ),
            },
            {
              key: "All Tasks",
              label: (
                <Badge count={allList?.length} offset={[12, 0]} color="purple">
                  All Tasks
                </Badge>
              ),
              children: (
                <div style={{ height: "70vh", overflow: "auto" }}>
                  {allList}
                </div>
              ),
            },
          ]}
        />
      ) : (
        <img width="100%" src={logo} alt="R" />
      )}
    </>
  );
};
export default MobileAdminDashboard;
