import { message } from "antd";

export const InputRules = {
  phoneNo: [
    {
      pattern: new RegExp("([0-9]{3,10})"),
      message: "Accept Only Number",
    },
    {
      min: 10,
      message: "Value Should be 10 Digit",
    },
  ],
  number: [
    {
      pattern: new RegExp("([0-9])"),
      message: "Accept Only Number",
    },
  ],
  text: [
    {
      // type: "regexp",
      pattern: new RegExp("([a-zA-Z]\\s*)+"),
      message: "Accept Only Alphabet",
    },
    {
      min: 3,
      message: "Value Should be 3 Character",
    },
  ],
};
