import * as React from "react";
import {
  Row,
  Col,
  Steps,
  Descriptions,
  Button,
  Typography,
  Divider,
  Card,
} from "antd";
import { useQuery } from "react-query";

import { getBugReportByIddetail } from "../Requests";
import moment from "moment";

const { Text } = Typography;

const ShowBugReportDetailsForm: React.FC<{ id: string }> = (props) => {
  const { data: bugReport } = useQuery(
    ["bugReport", props.id],
    async () => getBugReportByIddetail(props.id),
    {
      refetchOnWindowFocus: false,

      staleTime: 5000,
    }
  );

  return (
    <>
      <Divider dashed orientation="center">
        Bug Detail
      </Divider>
      <Descriptions layout="vertical" bordered>
        <Descriptions.Item label="Bug Detail">
          {bugReport.bugDetail}
        </Descriptions.Item>
        <Descriptions.Item label="Status">{bugReport.status}</Descriptions.Item>
      </Descriptions>
      <br />
      <Card>
        <Descriptions title="Project Detail :-">
          <Descriptions.Item label="Project Name">
            {bugReport.projectId.projectName}
            <Text code type="danger">
              {bugReport.projectId.projectStatus}
            </Text>
          </Descriptions.Item>
          <Descriptions.Item label="Start Date">
            {moment(bugReport?.projectId.startDate).format("DD-MMM-YYYY")}
          </Descriptions.Item>
          <Descriptions.Item label="Proposed End Date">
            {moment(bugReport.projectId.proposedEndDate).format("DD-MMM-YYYY")}
          </Descriptions.Item>
        </Descriptions>
        <Descriptions title="Team Detail :-">
          <Descriptions.Item label="Team Name">
            {bugReport.projectId.teamId.partyId.personName}
            <Text code type="success">
              {bugReport.projectId.teamId.partyId.partyType}
            </Text>
          </Descriptions.Item>
          <Descriptions.Item label="Email">
            {bugReport.projectId.teamId.partyId.email}
          </Descriptions.Item>
          <Descriptions.Item label="Phone No.">
            +91-{bugReport.projectId.teamId.partyId.phoneNo}
          </Descriptions.Item>
          <Descriptions.Item label="Address">
            {bugReport.projectId.teamId.partyId.address}
          </Descriptions.Item>
          <Descriptions.Item label="Department Name">
            {bugReport.projectId.teamId.departmentId.departmentName}
            <Text code type="warning">
              {bugReport.projectId.teamId.designationId.designationName}
            </Text>
          </Descriptions.Item>
        </Descriptions>
      </Card>
      <br />
      {/* <Descriptions
          bordered
          title="Forward Problem(Team Detail)"
          // extra={<Button type="primary">Edit</Button>}
        >
          <Descriptions.Item label="Team Name">
            {dailyStandup.fowardProblemId.partyId.personName}
            <Text code type="secondary">
              {dailyStandup.fowardProblemId.partyId.partyType}
            </Text>
          </Descriptions.Item>
          <Descriptions.Item label="Address">
            {dailyStandup.fowardProblemId.partyId.address}
          </Descriptions.Item>
          <Descriptions.Item label="PhoneNo">
            {dailyStandup.fowardProblemId.partyId.phoneNo}
          </Descriptions.Item>
          <Descriptions.Item label="Department Name">
            {dailyStandup.fowardProblemId.departmentId.departmentName}
            <Text code type="warning">
              {dailyStandup.fowardProblemId.designationId.designationName}
            </Text>
          </Descriptions.Item>

          <Descriptions.Item label="Email">
            {dailyStandup.fowardProblemId.partyId.email}
          </Descriptions.Item>
        </Descriptions> */}
      {/* <Descriptions title="skill" layout="vertical">
          <Descriptions.Item>Zhou Maomao</Descriptions.Item>
          <Descriptions.Item>1810000000</Descriptions.Item>
          <Descriptions.Item>1810000000</Descriptions.Item>
          <Descriptions.Item>1810000000</Descriptions.Item>
          <Descriptions.Item>Hangzhou, Zhejiang</Descriptions.Item>
          <Descriptions.Item>
            No. 18, Wantang Road, Xihu District, Hangzhou, Zhejiang, China
          </Descriptions.Item>
        </Descriptions> */}
    </>
  );
};

export default ShowBugReportDetailsForm;
