import { path } from "./path";

export const seoRoutes = [
  {
    method: "post",
    path: path + "/api/admin/createSEO",
    module: "seo",
    alias: "admin.seo.create",
    isSubDomain: true,
  },

  {
    method: "put",
    path: path + "/api/admin/updateSEO/:id",
    module: "seo",
    alias: "admin.seo.update",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllActiveSEO",
    module: "seo",
    alias: "admin.seo.list.active",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllSEO",
    module: "seo",
    alias: "admin.seo.list",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findOneSEO/:id",
    module: "seo",
    alias: "admin.seo.id",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findSEOByWorkId/:id",
    module: "seo",
    alias: "admin.seo.by.workId",
    isSubDomain: true,
  },
];
