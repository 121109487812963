import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "src/components/Card";

import { Empty, Row } from "antd";
import CreateTechnologyModal from "../Modal/CreateTechnology";
import TechnologyList from "../Component/List";
import { getAccessAuthrorization } from "src/components/access";
import CreateTechnology from "../Form/CreateTechnologyForm";

const TechnologyPageHome: React.FC<RouteComponentProps> = () => {
  let accessAuth = getAccessAuthrorization("technology");

  return (
    <React.Fragment>
      <Card>
        <CreateTechnology />
      </Card>
    </React.Fragment>
  );
};

export default withRouter(TechnologyPageHome);
