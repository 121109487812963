import { path } from "./path";

export const departmentRoutes = [
  {
    method: "post",
    path: path + "/api/admin/saveDepartment",
    module: "department",
    alias: "admin.department.create",
    isSubDomain: true,
  },
  {
    method: "put",
    path: path + "/api/admin/updateDepartment/:id",
    module: "department",
    alias: "admin.department.update",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findAllActiveDepartment",
    module: "department",
    alias: "admin.department.list.active",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findAllDepartment",
    module: "department",
    alias: "admin.department.list",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findOneDepartment/:id",
    module: "department",
    alias: "admin.department.id",
    isSubDomain: true,
  },
];
