import { path } from "./path";

export const userRoutes = [
  {
    method: "post",
    path: path + "/api/admin/saveUser",
    module: "user",
    alias: "admin.user.create",
    isSubDomain: true,
  },

  {
    method: "put",
    path: path + "/api/admin/updateUser/:id",
    module: "user",
    alias: "admin.user.update",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllActiveUser",
    module: "user",
    alias: "admin.user.list.active",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllUser",
    module: "user",
    alias: "admin.user.list",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findOneUser/:id",
    module: "user",
    alias: "admin.user.id",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findUserByPartyId/:id",
    module: "user",
    alias: "admin.user.partyId",
    isSubDomain: true,
  },
];
