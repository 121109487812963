import * as React from "react";
import {
  Form,
  Button,
  message,
  Row,
  Typography,
  Input,
  Col,
  Upload,
  Switch,
  Divider,
  Select,
  DatePicker,
  Card,
  Space,
  Tag,
  Empty,
  Image,
  Tabs,
} from "antd";

import { request } from "../../../utils/services";
import { useMutation, useQuery } from "react-query";
import {
  InfoCircleOutlined,
  PlusOutlined,
  MinusCircleOutlined,
  UploadOutlined,
  LoadingOutlined,
  FileExclamationOutlined,
} from "@ant-design/icons";
import { queryClient } from "src";
import type { UploadChangeParam, UploadFile } from "antd/es/upload/interface";
import type { RcFile, UploadProps } from "antd/es/upload";

import TextArea from "antd/lib/input/TextArea";

import LocalStorageService from "src/utils/local-storage";
import { CreateStoryModalContext } from "../Modal/CreateStory";
import { getAllActiveParty } from "src/modules/Party/Requests";
import {
  deleteFileToAWS,
  s3Path,
  uploadFileToAWS,
} from "src/utils/s3Config/s3upload";
import { getAllStory, getStoryByWorkId } from "../Requests";
import ManageStoryModal from "../Modal/ManageStory";
import StoryViewModal from "../Modal/ShowStoryDetails";
import Table, { ColumnsType } from "antd/es/table";
import {
  getAllActiveWork,
  getWorkById,
  getWorkByIdDetail,
} from "src/modules/Work/Requests";
import StoryListByWorkId, {
  StoryTaskList,
} from "src/modules/Work/Component/StoryListByWorkId";
import CreateTaskForm from "src/modules/Task/Form/CreateTaskForm";
import Quill from "quill";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import CreateStoryTaskForm from "src/modules/Task/Form/CreateStoryTaskForm";
import { tinyMCE } from "src/utils/keys";
import { Editor } from "@tinymce/tinymce-react";
import parse from "html-react-parser";
import TaskList from "src/modules/Task/Component/Home/List";

// import ImageResize from "quill-image-resize-module-react";
// Quill.register("modules/imageResize", ImageResize);
interface DataType {
  id: number;
  name: any;
}
const { Text } = Typography;
const CreateStory: React.FC<{ id?: string | any }> = (props) => {
  const modalContext = React.useContext(CreateStoryModalContext);
  const [formLoading, setFormLoading] = React.useState(false);
  const [imageUrl, setImageUrl] = React.useState<string[]>([]);
  const [remarks, setRemarks] = React.useState<string>();
  const [loading, setLoading] = React.useState(false);
  const [isStory, SetIsStory] = React.useState(true);
  const [workData, setWorkData] = React.useState<Object | any>({});
  const [editorkey, seteditorkey] = React.useState(4);

  const createStoryMutation = useMutation(
    (values: any) => request("admin.story.create", {}, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("story");
        queryClient.invalidateQueries("storyByWork");
      },
    }
  );
  const {
    isLoading,
    isError,
    data: story,
  } = useQuery(["storyByWork", props.id], () => getStoryByWorkId(props.id), {
    refetchOnWindowFocus: false,

    keepPreviousData: true,
    staleTime: 5000,
  });
  const { data: party } = useQuery(["party"], async () => getAllActiveParty(), {
    refetchOnWindowFocus: false,
  });

  const { data: work } = useQuery(["work"], async () => getAllActiveWork(), {
    refetchOnWindowFocus: false,
  });
  const { data: res } = useQuery(
    ["workDetails", props.id],
    async () => getWorkByIdDetail(props.id),
    {
      refetchOnWindowFocus: false,
    }
  );

  const onFinish = async (values: any) => {
    values.createdBy = LocalStorageService.getAccount().id;
    // values.partyId = party[values.partyId].id;
    values.icon = imageUrl;
    values.description = remarks;

    setFormLoading(true);

    createStoryMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The Story has been successfully created.");
        form.resetFields();
        form.setFieldsValue({ workId: props.id });
        cleareditor();
        // modalContext.setIsModalVisible(false);
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const [form] = Form.useForm();
  const mutateWork = useMutation((id: string) => getWorkById(id));
  const OnWorkSelect = (i: any) => {
    // console.log(i);

    mutateWork
      .mutateAsync(i)
      .then((payload) => {
        setWorkData(payload);
      })
      .catch((e) => {
        console.log(e, "error");
      });
  };
  React.useEffect(() => {
    if (props.id) {
      form.setFieldsValue({ workId: props.id });
      OnWorkSelect(props.id);
    }
  }, []);

  const handleEditorChange = (content: any, editor: any) => {
    // console.log("Content was updated:", content);
    setRemarks(content);
  };

  const cleareditor = () => {
    const newkey = editorkey * 43;
    seteditorkey(newkey);
  };
  // let workTask: any = res[0].tasks;
  // console.log(res);
  return (
    <>
      <Row gutter={22}>
        <Col span={16}>
          {/* <h3>Story List</h3> */}

          <Tabs
            centered
            // tabPosition="left"
            defaultActiveKey="2"
            // type="card"
            items={[
              {
                key: "1",
                label: <>Description/Details</>,
                children: (
                  <Card style={{ width: "100%" }}>
                    {res[0]?.description && parse(res[0]?.description)}
                  </Card>
                ),
              },
              {
                key: "2",
                label: <>Story List</>,
                children: (
                  <Card style={{ width: "100%" }}>
                    {" "}
                    {story.length > 0 && <StoryListByWorkId data={story} />}
                    {/* {res[0]?.story && <StoryListByWorkId data={res[0].story} />} */}
                  </Card>
                ),
              },
              {
                key: "3",
                label: <>Tasks List</>,
                children: (
                  <Card style={{ width: "100%" }}>
                    <StoryTaskList data={res[0]?.tasks} />
                  </Card>
                ),
              },
            ]}
          />
          {/* {story.length > 0 && <StoryListByWorkId data={story} />}{" "} */}
          {/* <Table size="small" columns={column} dataSource={response} /> */}
        </Col>
        <Col span={8}>
          <Row justify={"center"} gutter={10}>
            <Col>
              {" "}
              <Text strong>Add Story</Text>
            </Col>
            <Col>
              {" "}
              <Switch
                // checkedChildren="Add Story"
                // unCheckedChildren="Add Task"
                defaultChecked
                onChange={() => SetIsStory(!isStory)}
              />
            </Col>
            <Col>
              {" "}
              <Text strong>Add Task</Text>
            </Col>{" "}
          </Row>
          {!isStory ? (
            <Form
              layout="vertical"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              form={form}
            >
              <Card
                style={{ overflow: "auto", height: "90vh" }}
                bordered
                hoverable
              >
                <h3>Add Story</h3>
                <Row gutter={24}>
                  <Col flex={3} span={12}>
                    <Form.Item
                      label="Parent Story"
                      name="storyId"
                      style={{ fontWeight: "bold" }}
                      tooltip={{
                        title: "storyId",
                        icon: <InfoCircleOutlined />,
                      }}
                      hasFeedback
                    >
                      <Select placeholder="Please Select Story Parent ">
                        {story &&
                          story?.map((item: any, i: number) => {
                            return (
                              <Select.Option value={item?._id} key={i}>
                                {item?.title}
                              </Select.Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col flex={3} span={12}>
                    <Form.Item
                      label="Project/Product/Other"
                      name="workId"
                      style={{
                        fontWeight: "bold",
                      }}
                      tooltip={{
                        title: "Project/Product/Other",
                        icon: <InfoCircleOutlined />,
                      }}
                      rules={[
                        {
                          required: true,
                          message: "Please Select Project/Product/Other",
                        },
                        { whitespace: true },
                        { min: 3 },
                      ]}
                      hasFeedback
                    >
                      <Select
                        onSelect={OnWorkSelect}
                        showSearch
                        popupMatchSelectWidth
                        // style={{ minHeight: 150 }}
                        placeholder="Select Product/Project"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option!.children as unknown as string).includes(
                            input
                          )
                        }
                      >
                        {work &&
                          work?.map((item: any, i: number) => {
                            return (
                              <Select.Option value={item?.id} key={i}>
                                {item?.title}
                              </Select.Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item
                  label="Title"
                  name="title"
                  style={{
                    fontWeight: "bold",
                  }}
                  tooltip={{
                    title: "title",
                    icon: <InfoCircleOutlined />,
                  }}
                  rules={[
                    {
                      required: true,
                      message: "Please Enter title",
                    },
                    { whitespace: true },
                    { min: 3 },
                  ]}
                  hasFeedback
                >
                  <Input placeholder="Enter Title" />
                </Form.Item>

                {/* <Form.Item
                  label="Description"
                  name="description"
                  style={{
                    fontWeight: "bold",
                  }}
                  tooltip={{
                    title: "description",
                    icon: <InfoCircleOutlined />,
                  }}
                  rules={[
                    {
                      required: true,
                      message: "Please Enter description",
                    },
                    { whitespace: true },
                    { min: 3 },
                  ]}
                  hasFeedback
                >
                  
                  <ReactQuill theme="snow" modules={modules} />
                </Form.Item> */}
                <Editor
                  key={editorkey}
                  apiKey={tinyMCE.apiKey}
                  // onInit={(evt, editor) => (editorRef.current = editor)}
                  init={{
                    plugins: tinyMCE.plugins,
                    toolbar: tinyMCE.toolbar,
                    tinycomments_mode: "embedded",
                    tinycomments_author: "Author name",
                    // mergetags_list: [
                    //   { value: "First.Name", title: "First Name" },
                    //   { value: "Email", title: "Email" },
                    // ],

                    paste_data_images: true,

                    ai_request: (request: any, respondWith: any) =>
                      respondWith.string(() =>
                        Promise.reject("See docs to implement AI Assistant")
                      ),
                  }}
                  // initialValue="Welcome to TinyMCE!"
                  onEditorChange={handleEditorChange}
                  // outputFormat="html"
                />
                <Row justify={"space-between"}>
                  <Form.Item
                    label="Attachment"
                    style={{ fontWeight: "bold" }}
                    name="attachment"
                    valuePropName="checked"
                  >
                    <Upload
                      accept="application/pdf,application/msword, image/png, image/jpeg, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      maxCount={4}
                      multiple
                      listType="picture"
                      action={async (file) => {
                        const response: any = await uploadFileToAWS(
                          file,
                          "task"
                        ).catch((err: any) => message.error(err?.toString()));
                        setImageUrl([...imageUrl, response.key]);
                        return response.key;
                      }}
                      beforeUpload={(fileList) => {
                        console.log("FILE", fileList.size);
                        return fileList.size / 1024 / 1024 <= 2 ? true : false;
                      }}
                      onRemove={(e) => {
                        if (e?.error?.url) {
                          deleteFileToAWS(e.error.url).then((value) =>
                            message.success(value)
                          );
                          var file = imageUrl.filter(
                            (item) => item !== e.error.url
                          );
                          setImageUrl(file);
                        }
                      }}
                    >
                      {imageUrl.length < 4 && (
                        <Button icon={<UploadOutlined />}>Attachment</Button>
                      )}
                    </Upload>
                  </Form.Item>
                  <Form.Item
                    label="Status"
                    name="isActive"
                    style={{ fontWeight: "bold" }}
                    valuePropName="checked"
                    required
                  >
                    <Switch
                      checkedChildren="Active"
                      unCheckedChildren="Inactive"
                      defaultChecked
                    />
                  </Form.Item>
                </Row>

                <Form.Item style={{ textAlign: "center" }}>
                  <Button
                    size="large"
                    loading={formLoading}
                    type="primary"
                    htmlType="submit"
                    shape="round"
                  >
                    Add Story
                  </Button>
                </Form.Item>
              </Card>
            </Form>
          ) : (
            <Card>
              <h3>Add task</h3>
              <CreateStoryTaskForm id={props.id} />
            </Card>
          )}
        </Col>
      </Row>
    </>
  );
};

export default CreateStory;
