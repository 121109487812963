import * as React from "react";

import { Modal, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import CreateDailyStandup from "../Form/CreateDailyStandupForm";

export const CreateDailyStandupModalContext = React.createContext<{
  setIsModalVisible: (active: boolean) => void;
}>({
  setIsModalVisible: () => true,
});

const CreateDailyStandupModal: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <CreateDailyStandupModalContext.Provider value={{ setIsModalVisible }}>
      <Button
        onClick={showModal}
        type="primary"
        shape="round"
        icon={<PlusOutlined />}
      >
        Add DailyStandup
      </Button>
      <Modal
        title="Generate DailyStandup"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        centered
      >
        <CreateDailyStandup />
      </Modal>
    </CreateDailyStandupModalContext.Provider>
  );
};

export default CreateDailyStandupModal;
