import { TRoute } from "src/utils/routes";
import DepartmentPageHome from "./Page/Home";

const TechnologyRouter: TRoute[] = [
  {
    path: "/technology",
    name: "technology",
    title: "technology",
    component: DepartmentPageHome,
  },
];

export default TechnologyRouter;
