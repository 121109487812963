import * as React from "react";
import {
  Row,
  Col,
  Steps,
  Descriptions,
  Button,
  Typography,
  Divider,
  Card,
  Image,
} from "antd";
import { useQuery } from "react-query";

import { getDailyStandupByIdDetail } from "../Requests";
import moment from "moment";
import { s3Path } from "src/utils/s3Config/s3upload";

const { Text } = Typography;

const ShowDailyStandupDetailsForm: React.FC<{ id: string }> = (props) => {
  const { data: dailyStandup } = useQuery(
    ["dailyStandup", props.id],
    async () => getDailyStandupByIdDetail(props.id),
    {
      refetchOnWindowFocus: false,

      staleTime: 5000,
    }
  );

  return (
    <Row>
      <Col>
        <Card>
          <Descriptions title="Project Detail :-">
            <Descriptions.Item label="Project Name">
              {dailyStandup.projectId.projectName}
              <Text code type="danger">
                {dailyStandup.projectId.projectStatus}
              </Text>
            </Descriptions.Item>
            <Descriptions.Item label="Start Date">
              {moment(dailyStandup?.projectId.startDate).format("DD-MMM-YYYY")}
            </Descriptions.Item>
            <Descriptions.Item label="Proposed End Date">
              {moment(dailyStandup.projectId.proposedEndDate).format(
                "DD-MMM-YYYY"
              )}
            </Descriptions.Item>
          </Descriptions>
          <Descriptions title="Team Detail :-">
            <Descriptions.Item label="Team Name">
              {dailyStandup.projectId.teamId.partyId.personName}
              <Text code type="success">
                {dailyStandup.projectId.teamId.partyId.partyType}
              </Text>
            </Descriptions.Item>
            <Descriptions.Item label="Email">
              {dailyStandup.projectId.teamId.partyId.email}
            </Descriptions.Item>
            <Descriptions.Item label="Phone No.">
              +91-{dailyStandup.projectId.teamId.partyId.phoneNo}
            </Descriptions.Item>
            <Descriptions.Item label="Address">
              {dailyStandup.projectId.teamId.partyId.address}
            </Descriptions.Item>
            <Descriptions.Item label="Department Name">
              {dailyStandup.projectId.teamId.departmentId.departmentName}
              <Text code type="warning">
                {dailyStandup.projectId.teamId.designationId.designationName}
              </Text>
            </Descriptions.Item>
          </Descriptions>
        </Card>
        <br />
        <Divider dashed orientation="center">
          Problem Detail
        </Divider>
        <Descriptions layout="vertical" bordered>
          <Descriptions.Item label="Module you work On">
            {dailyStandup.moduleWorkOn}
          </Descriptions.Item>
          <Descriptions.Item label="Problem Detail">
            {dailyStandup.problemFacedDetail}
          </Descriptions.Item>
          <Descriptions.Item label="Status">
            {dailyStandup.status}
          </Descriptions.Item>
          <Descriptions.Item label="Remark">
            {dailyStandup.remark}
          </Descriptions.Item>
        </Descriptions>
        <br />

        <Descriptions
          bordered
          title="Forward Problem(Team Detail)"
          // extra={<Button type="primary">Edit</Button>}
        >
          <Descriptions.Item label="Team Name">
            {dailyStandup.fowardProblemId.partyId.personName}
            <Text code type="secondary">
              {dailyStandup.fowardProblemId.partyId.partyType}
            </Text>
          </Descriptions.Item>
          <Descriptions.Item label="Address">
            {dailyStandup.fowardProblemId.partyId.address}
          </Descriptions.Item>
          <Descriptions.Item label="PhoneNo">
            {dailyStandup.fowardProblemId.partyId.phoneNo}
          </Descriptions.Item>
          <Descriptions.Item label="Department Name">
            {dailyStandup.fowardProblemId.departmentId.departmentName}
            <Text code type="warning">
              {dailyStandup.fowardProblemId.designationId.designationName}
            </Text>
          </Descriptions.Item>

          <Descriptions.Item label="Email">
            {dailyStandup.fowardProblemId.partyId.email}
          </Descriptions.Item>
        </Descriptions>
        <br />
        <Card title={"Problem Faced Image"}>
          <Image
            src={s3Path + dailyStandup.problemFacedUrl}
            height={150}
            width={150}
          />
        </Card>
        {/* <Descriptions title="skill" layout="vertical">
          <Descriptions.Item>Zhou Maomao</Descriptions.Item>
          <Descriptions.Item>1810000000</Descriptions.Item>
          <Descriptions.Item>1810000000</Descriptions.Item>
          <Descriptions.Item>1810000000</Descriptions.Item>
          <Descriptions.Item>Hangzhou, Zhejiang</Descriptions.Item>
          <Descriptions.Item>
            No. 18, Wantang Road, Xihu District, Hangzhou, Zhejiang, China
          </Descriptions.Item>
        </Descriptions> */}
      </Col>
    </Row>
  );
};

export default ShowDailyStandupDetailsForm;
