import {
  Button,
  Card,
  Modal,
  Popover,
  Row,
  Space,
  Tabs,
  Typography,
} from "antd";
import * as React from "react";
import { useQuery } from "react-query";
import {
  GlobalOutlined,
  FundProjectionScreenOutlined,
  AndroidOutlined,
  AppleOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { getAllWork, getAllWorkWithDetails } from "src/modules/Work/Requests";
import { s3Path } from "src/utils/s3Config/s3upload";
import ShowWorkAdminDetailsForm from "src/modules/Work/Form/ShowWorkAdminDetailsForm";

const WorkStats: React.FC = () => {
  const {
    isLoading,
    isError,
    data: response,
  } = useQuery(["work"], () => getAllWork(), {
    refetchOnWindowFocus: false,

    keepPreviousData: true,
    staleTime: 5000,
  });

  if (isLoading || isError) {
    return <div>Loading</div>;
  }
  let products = response.filter((item: any) => item.workType === "Product");
  let projects = response.filter((item: any) => item.workType === "Project");
  let others = response.filter((item: any) => item.workType === "Other");
  // console.log(response);
  return (
    <>
      <Tabs
        // tabPosition="left"
        defaultActiveKey="4"
        type="card"
        items={[
          {
            key: "1",
            label: (
              <>
                <AndroidOutlined /> Projects
              </>
            ),
            children: (
              <Card style={{ width: "100%" }}>
                <WorkLogo data={projects} />
              </Card>
            ),
          },
          {
            key: "2",
            label: (
              <>
                <AndroidOutlined /> Products
              </>
            ),
            children: (
              <Card style={{ width: "100%" }}>
                <WorkLogo data={products} />
              </Card>
            ),
          },
          {
            key: "3",
            label: (
              <>
                <AndroidOutlined /> Demos/Other
              </>
            ),
            children: (
              <Card style={{ width: "100%" }}>
                <WorkLogo data={others} />
              </Card>
            ),
          },
          {
            key: "4",
            label: (
              <>
                <AndroidOutlined /> Our Works
              </>
            ),
            children: (
              <Card style={{ width: "100%" }}>
                <WorkLogo data={response} key={response[0].id} />
              </Card>
            ),
          },
        ]}
      />
      {/* <WorkLogo data={response} key={response[0].id} /> */}
    </>
  );
};
export default WorkStats;

const WorkLogo: React.FC<{ data: any }> = ({ data }) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  return (
    <Row>
      {data &&
        data.map((item: any, i: any) => {
          return item.deploymentId ? (
            <Popover
              placement="bottom"
              content={
                <>
                  <Space.Compact>
                    <Button
                      icon={<GlobalOutlined />}
                      type="dashed"
                      target="_blank"
                      href={`https://${item.deploymentId.domainName}`}
                    />
                    {item.deploymentId.pannel && (
                      <Button
                        icon={<FundProjectionScreenOutlined />}
                        type="dashed"
                        target="_blank"
                        href={`https://${item.deploymentId.pannel}`}
                      />
                    )}
                    {item.deploymentId.androidLink && (
                      <Button
                        icon={<AndroidOutlined />}
                        type="dashed"
                        target="_blank"
                        href={`https://${item.deploymentId.androidLink}`}
                      />
                    )}
                    {item.deploymentId.iosLink && (
                      <Button
                        icon={<AppleOutlined />}
                        type="dashed"
                        target="_blank"
                        href={`https://${item.deploymentId.iosLink}`}
                      />
                    )}
                    <Button
                      icon={<InfoCircleOutlined />}
                      type="dashed"
                      // target="_blank"
                      // href={`https://${item.deploymentId.androidLink}`}
                      onClick={() => setIsModalVisible(!isModalVisible)}
                    />
                    <Modal
                      width={1000}
                      title=" View Work Details Form"
                      visible={isModalVisible}
                      onOk={() => setIsModalVisible(!isModalVisible)}
                      onCancel={() => setIsModalVisible(!isModalVisible)}
                    >
                      <ShowWorkAdminDetailsForm id={item.id} />
                    </Modal>
                  </Space.Compact>
                </>
              }
            >
              <Button
                style={{
                  height: 125,
                  margin: 10,
                }}
              >
                <Space.Compact
                  direction="vertical"
                  key={data.id}
                  style={{
                    margin: 10,
                  }}
                >
                  <img
                    src={s3Path + item.deploymentId.logo}
                    width={75}
                    height={75}
                    alt="t"
                    // style={{ alignContent: "center" }}
                  />
                  <Typography.Text strong code>
                    {item.deploymentId.shortName}
                  </Typography.Text>
                </Space.Compact>
              </Button>
            </Popover>
          ) : (
            <></>
          );
        })}
    </Row>
  );
};
