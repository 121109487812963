import { request } from "../../../utils/services";

export const getAllActiveTestimonial = async () => {
  const { data } = await request("admin.testimonial.list.active");
  return data;
};

export const getAllTestimonial = async () => {
  const { data } = await request("admin.testimonial.list");
  return data;
};

export const getTestimonialById = async (id: string) => {
  const { data } = await request("admin.testimonial.id", { id });
  return data;
};
