import moment from "moment";
import * as React from "react";
import type { Moment } from "moment";

import type { ColumnsType } from "antd/lib/table";

import { Table } from "antd";

import {
  EyeOutlined,
  EditOutlined,
  DownloadOutlined,
  SearchOutlined,
  // CloseOutlined,
  // CheckOutlined,
} from "@ant-design/icons";
import { ColumnType, FilterConfirmProps } from "antd/lib/table/interface";
import { forEach } from "lodash";
//import Highlighter from "react-highlight-words";

// import { queryClient } from 'src';
// import { message } from 'antd';

// const DeleteById = (id: string) => {
//     const {
//         isLoading,
//         error,
//         data: userData,
//     } = useQuery(['money', id], async () => deleteMoneyById(id));
//     const [formLoading, setFormLoading] = React.useState(false);
//     const deleteAdminMutation = useMutation(
//         (values) => request('admin.money.delete', { id }, values),
//         {
//             onSuccess: () => {
//                 queryClient.invalidateQueries('money');
//             },
//         }

//     );

//     if (isLoading || error) {
//         return <div>Wrong</div>;
//     }

//     const onFinish = async (values: any) => {
//         setFormLoading(true);

//         deleteAdminMutation
//             .mutateAsync(values)
//             .then(() => {
//                 setFormLoading(false);
//                 message.success(
//                     'The money methods has been successfully deleted.'
//                 );
//             })
//             .catch((e) => {
//                 setFormLoading(false);
//                 message.error(e);
//             });
//     };

//     const onFinishFailed = (errorInfo: any) => {
//         console.log('Failed:', errorInfo);
//     };
// };
// const columns = [
//     {
//         name: 'Payment',
//         selector: 'method',
//         sortable: true,
//         cell: (item: any) => (
//             <div className="d-flex align-items-center">
//                 <Avatar
//                     size={35}
//                     className="mr-5"
//                     src={item.avatar}
//                     alt={item.method}
//                 />
//                 <div className="d-flex flex-column">
//                     {/* <span className="font-weight-bold font-size-sm text-dark-75 text-hover-light-dark">
//                         {item.username}
//                     </span>
//                     <span className="font-weight-bold font-size-sm text-muted">
//                         {item.password}
//                     </span> */}
//                     <span className="font-weight-bold font-size-sm text-success">
//                         {item.method}
//                     </span>
//                     <span className="font-weight-bold font-size-sm text-dark-75 text-hover-light-dark">
//                         {item.isactive === 'true' ? 'Active' : 'Inactive'}
//                     </span>
//                 </div>
//             </div>
//         ),
//     },
//     {
//         name: 'Created at',
//         selector: 'createdAt',
//         right: true,
//         sortable: true,
//         cell: (item: any) => <span>{moment(item.createdAt).calendar()}</span>,
//     },
//     {
//         name: 'Actions',
//         selector: 'actions',
//         right: true,
//         cell: (item: any) => (
//             <React.Fragment>
//                 {/* <button
//                     className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
//                     onClick={() => deleteMoneyById(item.id)}
//                 >
//                     <i className="ki ki-solid-minus" />
//                 </button> */}
//                 <MoneyModalManageMoney id={item.id} />
//             </React.Fragment>
//         ),

//         // <MoneyModalManageMoney id={item.id} />,
//     },
// ];
interface DataType {
  name: any;
}
type DataIndex = keyof DataType;
const AttendanceForm: React.FC = () => {
  const [page, setPage] = React.useState(1);
  const [size, setSize] = React.useState(100);
  const [searchText, setSearchText] = React.useState("");
  const [searchedColumn, setSearchedColumn] = React.useState("");
  //const searchInput = React.useRef<Input>(null);

  // console.log(CookiesService.getToken(), "token")
  // const {
  //   isLoading,
  //   isError,
  //   data: response,
  // } = useQuery(
  //   ["party", LocalStorageService.getAccount().id, page, size],
  //   () => getAttendanceForm(),
  //   {
  //     refetchOnWindowFocus: false,
  //     // enabled: false,
  //     keepPreviousData: true,
  //     staleTime: 5000,
  //     // refetchOnMount: false,
  //   }
  // );

  // if (isLoading || isError) {
  //   return <div>Loading</div>;
  // }
  type RangeValue = [Moment | null, Moment | null] | null;
  const [dates, setDates] = React.useState<RangeValue>(null);
  console.log(moment().month(5).daysInMonth());

  let abc: any = [];
  for (let i: any = 0; i <= moment().month(0).daysInMonth(); i++) {
    console.log(i);
    abc.push({
      title: i,
      dataIndex: i,
      key: i,
    });
  }
  abc[0] = {
    title: "Employee",
    dataIndex: "name",
    key: "name",
  };
  console.log(abc);
  const column: ColumnsType<DataType> = abc;

  const data: DataType[] = [{ name: "manish" }];

  return <Table size="small" columns={column} dataSource={data} />;
};

export default AttendanceForm;
