import { path } from "./path";

export const afterSalesRoutes = [
  {
    method: "post",
    path: path + "/api/admin/createAfterSales",
    module: "afterSales",
    alias: "admin.afterSales.create",
    isSubDomain: true,
  },

  {
    method: "put",
    path: path + "/api/admin/updateAfterSales/:id",
    module: "afterSales",
    alias: "admin.afterSales.update",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllActiveAfterSales",
    module: "afterSales",
    alias: "admin.afterSales.list.active",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllAfterSales",
    module: "afterSales",
    alias: "admin.afterSales.list",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findOneAfterSales/:id",
    module: "afterSales",
    alias: "admin.afterSales.id",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findOneAfterSalesDetail/:id",
    module: "afterSales",
    alias: "admin.afterSales.id.detail",
    isSubDomain: true,
  },
];
