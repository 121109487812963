import * as React from "react";
import {
  Form,
  Button,
  message,
  Row,
  Typography,
  Col,
  Switch,
  Input,
  Divider,
  Select,
  DatePicker,
} from "antd";
import { request } from "src/utils/services";
import { useMutation } from "react-query";
import {
  InfoCircleOutlined,
  PlusOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { queryClient } from "src";
import { ManageTaskTypeModalContext } from "../Modal/ManageTaskType";

import { useQuery } from "react-query";

import { getTaskTypeById } from "../Requests";
import Upload, {
  RcFile,
  UploadChangeParam,
  UploadFile,
  UploadProps,
} from "antd/es/upload";
import LocalStorageService from "src/utils/local-storage";
import { uploadFileToAWS } from "src/utils/s3Config/s3upload";
import { ColorPicker } from "antd";
import type { ColorPickerProps, GetProp } from "antd";

type Color = Exclude<GetProp<ColorPickerProps, "value">, string>;

const ManageTaskTypeForm: React.FC<{ id: string }> = (props) => {
  const modalContext = React.useContext(ManageTaskTypeModalContext);
  const [formLoading, setFormLoading] = React.useState(false);
  const [galley, setGallery] = React.useState<string>();
  const [loading, setLoading] = React.useState(false);

  const [imageUrl, setImageUrl] = React.useState<string>();
  const [selectedFile, setSelectedFile] = React.useState<RcFile>();
  const manageTaskTypeMutation = useMutation(
    (values: any) => request("admin.taskType.update", { id: props.id }, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("taskType");
      },
    }
  );
  const {
    isLoading,
    data: taskType,
    error,
    isError,
  } = useQuery(["taskType", props.id], async () => getTaskTypeById(props.id), {
    refetchOnWindowFocus: false,
  });
  const getBase64 = (img: RcFile, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    // if (info.file.status === "uploading") {
    //   setLoading(true);
    //   return;
    // }
    setSelectedFile(info.file.originFileObj);
    if (info.file.status === "uploading") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj as RcFile, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
  };

  const onFinish = async (values: any) => {
    values.updatedBy = LocalStorageService.getAccount().id;
    values.icon = galley;
    setFormLoading(true);

    manageTaskTypeMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The TaskType has been successfully managed.");
        form.resetFields();
        modalContext.setIsModalVisible(false);
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const [form] = Form.useForm();
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={taskType}
      form={form}
    >
      <Form.Item
        label="Title"
        name="title"
        style={{
          fontWeight: "bold",
        }}
        tooltip={{
          title: "Type",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: "Please Select Type",
          },
          { whitespace: true },
          { min: 3 },
        ]}
        hasFeedback
      >
        <Input placeholder="Enter Title" />
      </Form.Item>
      <Form.Item
        label="Pic the Color"
        name="color"
        getValueFromEvent={(color: Color) => color.toHexString()}
        style={{
          fontWeight: "bold",
        }}
        tooltip={{
          title: "Color",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: "Please Select Color",
          },
        ]}
        hasFeedback
      >
        <ColorPicker defaultValue="#1677ff" />
      </Form.Item>
      <Form.Item
        label="Status"
        style={{ fontWeight: "bold" }}
        name="isActive"
        tooltip={{
          title: "status",
          icon: <InfoCircleOutlined />,
        }}
        valuePropName="checked"
        // rules={[{ required: true }]}
      >
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          defaultChecked
        />
      </Form.Item>

      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ManageTaskTypeForm;
