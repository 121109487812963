import * as React from "react";
import { Modal, Button, Tooltip } from "antd";
import ManageBankDetailsForm from "../Form/ManageBankDetailsForm";
import { PlusOutlined } from "@ant-design/icons";

export const ManageBankDetailsModalContext = React.createContext<{
  setIsModalVisible: (active: boolean) => void;
}>({
  setIsModalVisible: () => true,
});

const ManageBankDetailsModal: React.FC<{ id: string }> = ({ id }) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const showModal = () => setIsModalVisible(true);

  const handleOk = () => setIsModalVisible(false);

  const handleCancel = () => setIsModalVisible(false);

  return (
    <ManageBankDetailsModalContext.Provider value={{ setIsModalVisible }}>
      <Tooltip title="Add/Update Bank Details">
        <Button
          onClick={showModal}
          type="dashed"
          shape="round"
          icon={<PlusOutlined />}
        >
          Bank Details
        </Button>
      </Tooltip>
      <Modal
        title="Manage BankDetails"
        visible={isModalVisible}
        onOk={handleOk}
        width={1000}
        onCancel={handleCancel}
        footer={null}
      >
        <ManageBankDetailsForm id={id} />
      </Modal>
    </ManageBankDetailsModalContext.Provider>
  );
};

export default ManageBankDetailsModal;
