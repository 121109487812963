import * as React from "react";
import { Modal, Button, Tooltip } from "antd";
import ManageAccessForm from "../Form/ManageAccessForm";
import { EditOutlined } from "@ant-design/icons";

export const ManageAccessModalContext = React.createContext<{
  setIsModalVisible: (active: boolean) => void;
}>({
  setIsModalVisible: () => true,
});

const AccessManageModal: React.FC<{ id: string }> = ({ id }) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const showModal = () => setIsModalVisible(true);

  const handleOk = () => setIsModalVisible(false);

  const handleCancel = () => setIsModalVisible(false);

  return (
    <ManageAccessModalContext.Provider value={{ setIsModalVisible }}>
      <Tooltip title="Edit/Update">
        <Button
          onClick={showModal}
          type="dashed"
          //shape="circle"
          icon={<EditOutlined />}
        >
          Edit Access
        </Button>
      </Tooltip>
      <Modal
        title="Manage Access"
        visible={isModalVisible}
        onOk={handleOk}
        width={1000}
        onCancel={handleCancel}
        footer={null}
      >
        <ManageAccessForm id={id} />
      </Modal>
    </ManageAccessModalContext.Provider>
  );
};

export default AccessManageModal;
