import * as React from "react";
import { Modal, Button, Tooltip } from "antd";
import ManageReplyForm from "../Form/ManageReplyForm";
import { EditOutlined } from "@ant-design/icons";

export const ManageReplyModalContext = React.createContext<{
  setIsModalVisible: (active: boolean) => void;
}>({
  setIsModalVisible: () => true,
});

const ReplyManageModal: React.FC<{ id: string }> = ({ id }) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const showModal = () => setIsModalVisible(true);

  const handleOk = () => setIsModalVisible(false);

  const handleCancel = () => setIsModalVisible(false);

  return (
    <ManageReplyModalContext.Provider value={{ setIsModalVisible }}>
      <Tooltip title="Edit/Update">
        <Button
          onClick={showModal}
          type="dashed"
          //shape="circle"
          icon={<EditOutlined />}
        >
          Add Query/Comments
        </Button>
      </Tooltip>
      <Modal
        title="Manage Reply"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width={1000}
      >
        <ManageReplyForm taskId={id} />
      </Modal>
    </ManageReplyModalContext.Provider>
  );
};

export default ReplyManageModal;
