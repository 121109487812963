import { request } from "../../../utils/services";

export const getAllActiveSitePages = async () => {
  const { data } = await request("admin.sitePages.list.active");
  return data;
};

export const getAllSitePages = async () => {
  const { data } = await request("admin.sitePages.list");
  return data;
};

export const getSitePagesById = async (id: string) => {
  const { data } = await request("admin.sitePages.id", { id });
  return data;
};
