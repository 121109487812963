import LocalStorageService from "src/utils/local-storage";

export const getAccessAuthrorization = (route: string) => {
  const access = LocalStorageService.getAccess();
  let data = [];
  data = access
    .filter(function (item: any) {
      return item.collectionId.route === route;
    })
    .map(function ({ accessType }: any) {
      return accessType;
    });
  // console.log(data);

  return data;
};
