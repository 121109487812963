import { request } from "../../../utils/services";

export const getAllActiveGallery = async () => {
  const { data } = await request("admin.gallery.list.active");
  return data;
};

export const getAllGallery = async () => {
  const { data } = await request("admin.gallery.list");
  return data;
};

export const getGalleryById = async (id: string) => {
  const { data } = await request("admin.gallery.id", { id });
  return data;
};
