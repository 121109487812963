import * as React from "react";
import { Form, Input, Button, Checkbox, message, Tabs } from "antd";
import { request } from "../../../utils/services";
import { AuthContext } from "../Context/AuthContext";
import LocalStorageService from "../../../utils/local-storage";
import CookiesService from "../../../utils/cookie";
import Logo from "../../../assets/img/logo.png";
import {
  LockOutlined,
  UserOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from "@ant-design/icons";
import {
  FormContainer,
  FormTop,
  FormHeader,
  FormDescription,
  FormMain,
  FormTitle,
} from "./Form.styled";
import Lottie from "lottie-react";
import LoginAnim from "../../../assets/lottie/loginAnim.json";
import { Row, Col } from "antd";

const LoginAuth: React.FC = (props) => {
  const authContext = React.useContext(AuthContext);
  const [type, setType] = React.useState("login");
  const [isloading, setLoading] = React.useState(false);

  const onFinish = async (values: any) => {
    const authApi = values.email ? "user.signup" : "user.login";
    setLoading(true);
    try {
      const { payload } = await request(authApi, {}, values);
      setLoading(false);
      console.log(payload);

      message.success("User Authentication Successfully");
      CookiesService.storeToken(payload.accessToken);
      LocalStorageService.storeAccount(payload);
      LocalStorageService.storeAccess(payload.access);
      authContext.setToken(payload.accessToken);
    } catch (e) {
      setLoading(false);
      console.log("error", e);
      message.error("User Authentication Failed");
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <FormContainer>
        <Row justify={"space-around"} align={"middle"}>
          <Col>
            {" "}
            <Lottie
              animationData={LoginAnim}
              loop={true}
              // style={{ height: "70vh"}}
            />
          </Col>
          <Col>
            <FormTop>
              <FormHeader>
                {/* <FormTitle> */}
                <div
                  style={{
                    margin: 24,
                  }}
                >
                  {" "}
                  <img
                    width={250}
                    style={{
                      padding: 24,
                      margin: 50,
                    }}
                    src={Logo}
                    alt="Journey Starts Here"
                  />
                </div>

                {/* </FormTitle> */}
              </FormHeader>
              {/* <FormDescription>
            Best Design for Laboratory and Calibration
          </FormDescription> */}
            </FormTop>
            <FormMain>
              {/* <Tabs defaultActiveKey={type} onChange={setType}>
              <Tabs.TabPane
                key="login"
                tab="Login to Account"
                className="tab-nav-list"
              > */}
              <Form
                name="basic"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                layout="vertical"
              >
                <Form.Item
                  label="Username"
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: "Please input your username or email!",
                    },
                  ]}
                >
                  <Input prefix={<UserOutlined />} />
                </Form.Item>

                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input.Password
                    prefix={<LockOutlined />}
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                  />
                </Form.Item>
                <div
                  style={{
                    marginBottom: 24,
                  }}
                >
                  <Form.Item name="remember" valuePropName="checked">
                    <Checkbox>Remember me</Checkbox>
                    <a
                      style={{
                        float: "right",
                      }}
                      href="!#"
                    >
                      Forgot Password?
                    </a>
                  </Form.Item>
                </div>
                <Form.Item wrapperCol={{ offset: 0 }}>
                  <Button
                    type="primary"
                    size="large"
                    loading={isloading}
                    htmlType="submit"
                    block
                    // color="teal"
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Form>
              {/* </Tabs.TabPane> */}
              {/* <Tabs.TabPane
              key="signup"
              tab="Create New Account"
              className="tab-nav-list"
            >
              <Form
                name="basic"
                initialValues={{}}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                layout="vertical"
              >
                <Form.Item
                  label="First Name"
                  name="firstname"
                  rules={[
                    {
                      required: true,
                      message: "Please input your First Name!",
                    },
                  ]}
                >
                  <Input prefix={<UserOutlined />} />
                </Form.Item>
                <Form.Item
                  label="Last Name"
                  name="lastname"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Lastname!",
                    },
                  ]}
                >
                  <Input prefix={<UserOutlined />} />
                </Form.Item>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your email!",
                    },
                  ]}
                >
                  <Input prefix={<UserOutlined />} type="email" />
                </Form.Item>

                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    { required: true, message: "Please input your password!" },
                  ]}
                >
                  <Input.Password
                    prefix={<LockOutlined />}
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                  />
                </Form.Item>
                <Form.Item
                  label="Username"
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Username!",
                    },
                  ]}
                >
                  <Input prefix={<UserOutlined />} />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 0 }}>
                  <Button
                    type="primary"
                    size="large"
                    loading={isloading}
                    htmlType="submit"
                    block
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </Tabs.TabPane> */}
              {/* </Tabs> */}
            </FormMain>
          </Col>
        </Row>
      </FormContainer>
    </>
  );
};

export default LoginAuth;
