import { TRoute } from "src/utils/routes";
import DepartmentPageHome from "./Page/Home";

const DeploymentRouter: TRoute[] = [
  {
    path: "/deployment",
    name: "deployment",
    title: "deployment",
    component: DepartmentPageHome,
  },
];

export default DeploymentRouter;
