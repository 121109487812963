import { request } from "../../../utils/services";

export const getAllActivePerfomaInvoice = async () => {
  const { data } = await request("admin.perfomaInvoice.list.active");
  return data;
};

export const getAllPerfomaInvoice = async () => {
  const { data } = await request("admin.perfomaInvoice.list");
  return data;
};

export const getPerfomaInvoiceById = async (id: string) => {
  const { data } = await request("admin.perfomaInvoice.id", { id });
  return data;
};

export const getOnePerfomaInvoiceWithDetail = async (id: string) => {
  const { data } = await request("admin.perfomaInvoice.id.with.detail", { id });
  return data;
};
export const getPerfomaInvoiceByLeadId = async (id: string) => {
  const { data } = await request("admin.performaInvoice.by.leadId", { id });
  return data;
};
