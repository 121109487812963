import { TRoute } from "src/utils/routes";
import DepartmentPageHome from "./Page/Home";

const CategoryRouter: TRoute[] = [
  {
    path: "/category",
    name: "category",
    title: "category",
    component: DepartmentPageHome,
  },
];

export default CategoryRouter;
