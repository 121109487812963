import * as React from "react";
import {
  Form,
  Input,
  Button,
  message,
  Switch,
  Select,
  Row,
  Typography,
  Col,
  Avatar,
} from "antd";
import { request } from "src/utils/services";
import { useMutation } from "react-query";
import {
  InfoCircleOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { queryClient } from "src";
import { ManageReplyModalContext } from "../Modal/ManageReply";

import { useQuery } from "react-query";

import { getAllActiveReply, getReplyById } from "../Requests";
import { getAllPersonnel } from "src/modules/HR/Personnel/Requests";
import Upload, {
  RcFile,
  UploadChangeParam,
  UploadFile,
  UploadProps,
} from "antd/es/upload";
import LocalStorageService from "src/utils/local-storage";
import { s3Path, uploadFileToAWS } from "src/utils/s3Config/s3upload";
import TextArea from "antd/lib/input/TextArea";
import { getTaskById } from "src/modules/Task/Requests";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
const ManageReplyForm: React.FC<{ taskId: string; replyId?: string }> = (
  props
) => {
  const modalContext = React.useContext(ManageReplyModalContext);
  const [formLoading, setFormLoading] = React.useState(false);
  const [galley, setGallery] = React.useState<string>();
  const [loading, setLoading] = React.useState(false);
  const [imageUrl, setImageUrl] = React.useState<string>();
  const [selectedFile, setSelectedFile] = React.useState<RcFile>();

  const manageReplyMutation = useMutation(
    (values: any) => request("admin.reply.create", {}, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("reply");
        queryClient.invalidateQueries("TaskById");
        queryClient.invalidateQueries("task");
        queryClient.invalidateQueries("notification");
      },
    }
  );
  // const {
  //   isLoading,
  //   data: response,
  //   error,
  //   isError,
  // } = useQuery(["Task", props.taskId], async () => getTaskById(props.taskId), {
  //   refetchOnWindowFocus: false,
  // });
  // const {
  //   //isLoading,
  //   //isError,
  //   data: reply,
  // } = useQuery(["reply"], () => getAllActiveReply(), {
  //   refetchOnWindowFocus: false,

  //   keepPreviousData: true,
  //   staleTime: 5000,
  // });
  const { data: person } = useQuery(
    ["peronnnel"],
    async () => getAllPersonnel(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const onFinish = async (values: any) => {
    setFormLoading(true);
    values.taskId = props.taskId;
    values.replyId = props.replyId;
    values.attachment = galley;
    values.createdBy = LocalStorageService.getAccount().id;
    //Notification Values
    values.notificationTitle =
      LocalStorageService.getAccount().name + " mention You in the Task";
    values.notificationBody =
      "CheckOut what they are saying!  Click to view Task details";
    values.receiverId = values.tagTo;
    values.senderId = LocalStorageService.getAccount().personnel.id;

    manageReplyMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The reply has been successfully managed.");
        form.resetFields();
        modalContext.setIsModalVisible(false);
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const [form] = Form.useForm();
  const modules = React.useMemo(
    () => ({
      toolbar: {
        container: [
          [{ font: [] }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ["bold", "italic", "underline", "strike"],
          [{ color: [] }, { background: [] }],
          [{ script: "sub" }, { script: "super" }],
          ["blockquote", "code-block"],
          [{ list: "ordered" }, { list: "bullet" }],

          [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
          [{ direction: "rtl" }],
          [{ size: ["small", false, "large", "huge"] }],
          ["link", "image", "video"],
          ["clean"],
        ],

        //  handlers: {
        //    image: handleClick,
        //  },
        history: {
          delay: 500,
          maxStack: 100,
          userOnly: true,
        },
      },
      // imageResize: {
      //   parchment: Quill.import("parchment"),
      //   modules: ["Resize", "DisplaySize"],
      // },
    }),
    []
  );
  return (
    <>
      <Form
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        form={form}
      >
        <Row>
          <Col span={18} style={{ paddingRight: 10 }}>
            <Form.Item
              label="Comment/Query "
              name="comment"
              style={{ fontWeight: "bold" }}
              tooltip={{
                title: "comment",
                icon: <InfoCircleOutlined />,
              }}
              rules={[
                {
                  required: true,
                  message: "Please enter Reply Title",
                },
                { message: "Please enter Reply Title" },
              ]}
              hasFeedback
            >
              <ReactQuill theme="snow" modules={modules} />
            </Form.Item>
          </Col>

          <Col span={6} style={{ alignContent: "end" }}>
            <Form.Item
              label="Tag To"
              style={{ fontWeight: "bold" }}
              name="tagTo"
              tooltip={{
                title: "Person ",
                icon: <InfoCircleOutlined />,
              }}
            >
              <Select
                mode="multiple"
                // onSelect={OnPersonSelect}
                showSearch
                size="large"
                popupMatchSelectWidth
                // style={{ minHeight: 150 }}
                placeholder="Select Person"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option!.children as unknown as string).includes(input)
                }
              >
                {person &&
                  person?.map((item: any, i: number) => {
                    return (
                      <Select.Option
                        value={item?.id}
                        key={i}
                        // disabled={item.availability === 0 ? true : false}
                      >
                        <Row justify={"space-between"} align={"top"}>
                          <Typography.Text>
                            {item?.partyId?.personName}
                          </Typography.Text>
                          {/* <h4>{item?.partyId?.personName}</h4> */}

                          {/* <Typography.Text code>
                            {item.partyId?.phoneNo}
                          </Typography.Text> */}
                          <Avatar
                            src={
                              <img
                                src={s3Path + item.partyId.imageUrl}
                                alt={item?.partyId?.personName[0]}
                              />
                            }
                          />
                        </Row>
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>
            <Form.Item style={{ textAlign: "center" }}>
              <Button
                size="large"
                loading={formLoading}
                type="dashed"
                htmlType="submit"
                shape="round"
              >
                Comment
              </Button>
            </Form.Item>
          </Col>
        </Row>

        {/* <Form.Item
          label="Upload Image"
          style={{ fontWeight: "bold" }}
          name="imageUrl"
          valuePropName="checked"
        >
          <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            accept="image/png, image/jpeg"
            action={async (file) => {
              const response: any = await uploadFileToAWS(file, "query");
              setGallery(response.key);
              return response.key;
            }}
            onChange={handleChange}
          >
            {imageUrl ? (
              <img src={imageUrl} alt="avatar" style={{ width: "100%" }} />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item> */}
      </Form>
    </>
  );
};

export default ManageReplyForm;
