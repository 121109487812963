import moment from "moment";
import * as React from "react";

import { useQuery } from "react-query";
import type { ColumnsType } from "antd/lib/table";

import {
  Button,
  Descriptions,
  Space,
  Table,
  Tag,
  Typography,
  message,
} from "antd";

import { getAllCredential } from "../Requests";
import CredentialViewModal from "../Modal/ShowCredentialDetails";
import ManageCredentialModal from "../Modal/ManageCredential";
import { getAccessAuthrorization } from "src/components/access";
import {
  EyeTwoTone,
  EyeInvisibleOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import parse from "html-react-parser";
import useCopyToClipboard from "src/components/copytoClipboard";
import PwdTag from "src/components/pwdTag";

interface DataType {
  id: number;
  name: any;
  platformLink: any;
  remarks: any;
  platformName: any;
}
type DataIndex = keyof DataType;
const CredentialList: React.FC = () => {
  const [page, setPage] = React.useState(1);
  const [size, setSize] = React.useState(100);
  const [searchText, setSearchText] = React.useState("");
  const [searchedColumn, setSearchedColumn] = React.useState("");
  const [passwordVisible, setPasswordVisible] = React.useState(false);
  const [copyToClipboard, copyResult] = useCopyToClipboard();

  let accessAuth = getAccessAuthrorization("credential");

  const {
    isLoading,
    isError,
    data: response,
  } = useQuery(["credential"], () => getAllCredential(), {
    refetchOnWindowFocus: false,

    keepPreviousData: true,
    staleTime: 5000,
  });
  const handleClickCopy = (text: string) => {
    message.success("Copied Successfully!");

    // Copy the text from the input field into the clipboard
    copyToClipboard(text);
  };
  if (isLoading || isError) {
    return <div>Loading</div>;
  }
  const { Text } = Typography;
  const column: ColumnsType<DataType> = [
    {
      title: "Project/Product",
      dataIndex: "workId",
      key: "workId",
      render: (_: any, i: any) => <Tag>{i?.workId?.title}</Tag>,
    },
    {
      title: "Platform Name",
      dataIndex: "platformName",
      key: "platformName",
    },
    // {
    //   title: "Platform Link",
    //   dataIndex: "platformLink",
    //   key: "platformLink",
    // },

    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      render: (text) => (
        <Tag>
          {text}
          <Button
            type="text"
            icon={<CopyOutlined />}
            onClick={() => handleClickCopy(text)}
          />
        </Tag>
      ),
    },
    {
      title: "Password",
      dataIndex: "id",
      key: "id",
      render: (_: any, i: any) => (
        <PwdTag password={i.password} />

        // <Space>
        //   <Tag
        //     color="purple"
        //     icon={
        //       passwordVisible ? (
        //         <EyeTwoTone
        //           onClick={() => setPasswordVisible((prevState) => !prevState)}
        //         />
        //       ) : (
        //         <EyeInvisibleOutlined
        //           onClick={() => setPasswordVisible((prevState) => !prevState)}
        //         />
        //       )
        //     }
        //     key={i.id}
        //   >
        //     Pwd: {passwordVisible ? i.password : "************"}
        //     <Button
        //       type="text"
        //       icon={<CopyOutlined />}
        //       onClick={() => handleClickCopy(i.password)}
        //     />
        //   </Tag>
        // </Space>
      ),
    },

    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      render: (text: any) =>
        text ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">Inactive</Tag>
        ),
    },

    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (id: any) => (
        <Space size={10}>
          {/* <CredentialViewModal id={id} /> */}
          {/* <ManageCredentialModal id={id} /> */}
          {accessAuth[0].includes("Edit") && <ManageCredentialModal id={id} />}
        </Space>
      ),
    },
  ];

  return (
    <Table
      rowKey={(record) => record.id}
      size="small"
      columns={column}
      dataSource={response}
      expandable={{
        expandedRowRender: (record) => (
          <>
            <Descriptions
              bordered
              layout="horizontal"
              size="small"
              style={{ backgroundColor: "beige" }}
            >
              <Descriptions.Item label="Platform Link" span={3}>
                {record.platformLink}{" "}
                <Button
                  type="text"
                  icon={<CopyOutlined />}
                  onClick={() => handleClickCopy(record.platformLink)}
                />
              </Descriptions.Item>
              <Descriptions.Item label="Remarks">
                {record.remarks && parse(record.remarks)}
              </Descriptions.Item>
            </Descriptions>
          </>
        ),
        rowExpandable: (record) => record?.platformName !== "Not Expandable",
      }}
    />
  );
};

export default CredentialList;
