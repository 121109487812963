import * as React from "react";

import {
  Modal,
  Button,
  Tooltip,
  Drawer,
  Timeline,
  Space,
  Typography,
  Tag,
  Row,
} from "antd";

import { ClockCircleOutlined } from "@ant-design/icons";
import { getProjectTimelineByProjectId } from "../Requests";
import { useQuery } from "react-query";
import moment from "moment";
export const ShowProjectTimelineModalContext = React.createContext<{
  setOpenDrawer: (active: boolean) => void;
}>({
  setOpenDrawer: () => true,
});
const { Text, Link } = Typography;
const ProjectTimelineDrawer: React.FC<{ id: string }> = (props) => {
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const {
    isLoading,
    data: items,
    error,
    isError,
  } = useQuery(
    ["projectimeline", props.id],
    async () => getProjectTimelineByProjectId(props.id),
    {
      refetchOnWindowFocus: false,
    }
  );

  const showDrawer = () => {
    setOpenDrawer(true);
  };

  const onClose = () => {
    setOpenDrawer(false);
  };

  return (
    <ShowProjectTimelineModalContext.Provider value={{ setOpenDrawer }}>
      <Tooltip title="View Project Timeline">
        <Button
          onClick={showDrawer}
          type="primary"
          shape="circle"
          icon={<ClockCircleOutlined />}
        />
      </Tooltip>
      <Drawer
        width={500}
        title="Project Timeline"
        placement="right"
        onClose={onClose}
        open={openDrawer}
      >
        <>
          <Timeline mode="left">
            {items &&
              items?.map((item: any, i: any) => {
                return (
                  <Timeline.Item
                    label={moment(item?.createdAt).format(
                      "DD-MMM-YYYY hh:mm A"
                    )}
                  >
                    <>
                      <Row>
                        {" "}
                        <Text type="success" strong>
                          {item?.content}-{item?.createdBy?.username}
                        </Text>
                        <Tag color="orange">{item?.leadStatus}</Tag>
                      </Row>

                      <Text>{item?.description}</Text>
                    </>
                  </Timeline.Item>
                );
              })}
          </Timeline>
        </>
      </Drawer>
    </ShowProjectTimelineModalContext.Provider>
  );
};

export default ProjectTimelineDrawer;
