import { path } from "./path";

export const accessRoutes = [
  {
    method: "post",
    path: path + "/api/admin/saveAccess",
    module: "access",
    alias: "admin.access.create",
    isSubDomain: true,
  },
  {
    method: "post",
    path: path + "/api/admin/saveAccessByRoleId/:id",
    module: "access",
    alias: "admin.access.create.roleId",
    isSubDomain: true,
  },

  {
    method: "put",
    path: path + "/api/admin/updateAccess/:id",
    module: "access",
    alias: "admin.access.update",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllActiveAccess",
    module: "access",
    alias: "admin.access.list.active",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllAccess",
    module: "access",
    alias: "admin.access.list",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findOneAccess/:id",
    module: "access",
    alias: "admin.access.id",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllAccessByRoleId/:id",
    module: "access",
    alias: "admin.access.list.roleId",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findAllCollectionByRoleId/:id",
    module: "access",
    alias: "admin.access.collection.roleId",
    isSubDomain: true,
  },
];
