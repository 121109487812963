import { TRoute } from "../../../utils/routes";
import DepartmentPageHome from "./Page/Home";

const DesignationRouter: TRoute[] = [
  {
    path: "/designation",
    name: "designation",
    title: "Designation",
    component: DepartmentPageHome,
  },
];

export default DesignationRouter;
