import { TRoute } from "src/utils/routes";
import DepartmentPageHome from "./Page/Home";

const ReportRouter: TRoute[] = [
  {
    path: "/report",
    name: "report",
    title: "report",
    component: DepartmentPageHome,
  },
];

export default ReportRouter;
