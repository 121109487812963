import { request } from "../../../../utils/services";

export const getAllActiveBugReport = async () => {
  const { data } = await request("admin.bugReport.list.active");
  return data;
};

export const getAllBugReport = async () => {
  const { data } = await request("admin.bugReport.list");
  return data;
};

export const getBugReportById = async (id: string) => {
  const { data } = await request("admin.bugReport.id", { id });
  return data;
};

export const getBugReportByIddetail = async (id: string) => {
  const { data } = await request("admin.bugReport.id.detail", { id });
  return data;
};
