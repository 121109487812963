import { TRoute } from '../../utils/routes';
import {Logout} from '../../components/Logout';

const AccountRouter: TRoute[] = [
    {
        path: '/a/logout',
        name: 'logout',
        title: 'logout',
        component: Logout,
        isAuth: true,
    },
];

export default AccountRouter
