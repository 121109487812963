import { message } from "antd";
import axios from "axios";
// import LocalStorageService from "./local-storage";
import CookiesService from "./cookie";
let isRefreshing = false;
let failedQueue: any[] = [];

const statusError: any = {
  403: "Forbidden error.",
  404: "Not found error.",
  422: "Form validation error.",
  500: "Server error.",
  502: "Server error.",
};

axios.defaults.headers.common.timezone =
  Intl.DateTimeFormat().resolvedOptions().timeZone;
axios.interceptors.request.use(
  (config) => {
    if (CookiesService.isAuthenticated()) {
      config.headers.Authorization = `Bearer ${CookiesService.getToken()}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  (response) => response,
  (error: any) => {
    const {
      config,
      response: { status, data },
    } = error;
    if (config.hasOwnProperty("errorHandle") && config.errorHandle === false) {
      return Promise.reject(error);
    }

    if (error.response) {
      const originalRequest = config;
      originalRequest._retry = false;

      if (
        data.message === "refresh_expired" ||
        data.message === '401 - "Unauthorized"' ||
        data.message ===
          '403 - {"error":"authorization_code_used","error_description":"This authorization code has been used."}' ||
        data.message === '429 - "Too Many Requests"'
      ) {
        return Promise.reject(false);
      }

      const message =
        data.hasOwnProperty("message") && data.message.length > 0
          ? data.message
          : null;
      if (status === 400) {
        message.error(message || "Validation error.");
      }

      if (status === 401 && !originalRequest._retry) {
        if (isRefreshing) {
          return new Promise((resolve, reject) => {
            failedQueue.push({ resolve, reject });
          })
            .then((token) => {
              originalRequest.headers.Authorization = `Bearer ${token}`;
              return axios(originalRequest);
            })
            .catch((err) => Promise.reject(err));
        }

        isRefreshing = true;
      }

      if ([403, 404, 422, 500, 502].includes(status)) {
        message.error(message || statusError[status]);
      }
    } else if (error.request) {
      message.error("There was an error");
    } else {
      message.error(error.message);
    }

    return Promise.reject(error);
  }
);

export default axios;
