import { path } from "./path";

export const dailyStandupRoutes = [
  {
    method: "post",
    path: path + "/api/admin/createDailyStandup",
    module: "dailyStandup",
    alias: "admin.dailyStandup.create",
    isSubDomain: true,
  },

  {
    method: "put",
    path: path + "/api/admin/updateDailyStandup/:id",
    module: "dailyStandup",
    alias: "admin.dailyStandup.update",
    isSubDomain: true,
  },

  // {
  //   method: "get",
  //   path: path + "/api/admin/findAllActiveProject",
  //   module: "dailyStandup",
  //   alias: "admin.dailyStandup.list.active",
  //   isSubDomain: true,
  // },

  {
    method: "get",
    path: path + "/api/admin/findAllDailyStandup",
    module: "dailyStandup",
    alias: "admin.dailyStandup.list",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findOneDailyStandup/:id",
    module: "dailyStandup",
    alias: "admin.dailyStandup.id",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findOneDailyStandupDetail/:id",
    module: "dailyStandup",
    alias: "admin.dailyStandup.id.detail",
    isSubDomain: true,
  },
];
