import { path } from "./path";

export const caseStudyRoutes = [
  {
    method: "post",
    path: path + "/api/admin/createCaseStudy",
    module: "caseStudy",
    alias: "admin.caseStudy.create",
    isSubDomain: true,
  },

  {
    method: "put",
    path: path + "/api/admin/updateCaseStudy/:id",
    module: "caseStudy",
    alias: "admin.caseStudy.update",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllActiveCaseStudy",
    module: "caseStudy",
    alias: "admin.caseStudy.list.active",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllCaseStudy",
    module: "caseStudy",
    alias: "admin.caseStudy.list",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findOneCaseStudy/:id",
    module: "caseStudy",
    alias: "admin.caseStudy.id",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findAllCaseStudyByWorkId/:id",
    module: "caseStudy",
    alias: "admin.caseStudy.by.workId",
    isSubDomain: true,
  },
];
