import { request } from "src/utils/services";

export const getAllActiveCollection = async () => {
  const { data } = await request("admin.collection.list.active");
  return data;
};

export const getAllCollection = async () => {
  const { data } = await request("admin.collection.list");
  return data;
};

export const getCollectionById = async (id: string) => {
  const { data } = await request("admin.collection.id", { id });
  return data;
};
