import { path } from "./path";

export const categoryRoutes = [
  {
    method: "post",
    path: path + "/api/admin/saveCategories",
    module: "category",
    alias: "admin.category.create",
    isSubDomain: true,
  },

  {
    method: "put",
    path: path + "/api/admin/updateCategories/:id",
    module: "category",
    alias: "admin.category.update",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllActiveCategories",
    module: "category",
    alias: "admin.category.list.active",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllCategories",
    module: "category",
    alias: "admin.category.list",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findOneCategories/:id",
    module: "category",
    alias: "admin.category.id",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findCategoryByType/:type",
    module: "category",
    alias: "admin.category.type",
    isSubDomain: true,
  },
];
