import { TRoute } from "../../utils/routes";
import DepartmentPageHome from "./Page/Home";

const SitePagesRouter: TRoute[] = [
  {
    path: "/sitePages",
    name: "sitePages",
    title: "sitePages",
    component: DepartmentPageHome,
  },
];

export default SitePagesRouter;
