import { path } from "./path";

export const galleryRoutes = [
  {
    method: "post",
    path: path + "/api/admin/saveGallery",
    module: "gallery",
    alias: "admin.gallery.create",
    isSubDomain: true,
  },

  {
    method: "put",
    path: path + "/api/admin/updateGallery/:id",
    module: "gallery",
    alias: "admin.gallery.update",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllActiveGallery",
    module: "gallery",
    alias: "admin.gallery.list.active",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllGallery",
    module: "gallery",
    alias: "admin.gallery.list",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findOneGallery/:id",
    module: "gallery",
    alias: "admin.gallery.id",
    isSubDomain: true,
  },
];
