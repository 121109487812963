import { request } from "../../../utils/services";

export const getAllActiveCredential = async () => {
  const { data } = await request("admin.credential.list.active");
  return data;
};

export const getAllCredential = async () => {
  const { data } = await request("admin.credential.list");
  return data;
};

export const getCredentialById = async (id: string) => {
  const { data } = await request("admin.credential.id", { id });
  return data;
};

export const getCredentialByIdDetail = async (id: string) => {
  const { data } = await request("admin.credential.id.detail", { id });
  return data;
};
export const getCredentialByWorkId = async (id: string) => {
  const { data } = await request("admin.credential.by.workId", { id });
  return data;
};
