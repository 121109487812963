import React from 'react';

interface AuthContextType {
    token: string,
    setToken: (active: string) => void
}

export const AuthContext = React.createContext<AuthContextType>({
    setToken: (active: string) => active,
    token: ''
});
