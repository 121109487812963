import { TRoute } from "src/utils/routes";
import DepartmentPageHome from "./Page/Home";

const ProjectRouter: TRoute[] = [
  {
    path: "/project",
    name: "project",
    title: "project",
    component: DepartmentPageHome,
  },
];

export default ProjectRouter;
