import Cookies from "js-cookie";
export default class CookiesService {
  /**
   * @type {string}
   */
  public static COOKIES_AUTH_TOKEN: string = "im_token";
  public static COOKIES_ACCOUNT = "lims_account";
  public static COOKIES_COMPANY = "lims_company";

  static isAuthenticated(): boolean {
    return CookiesService.getToken() !== null;
  }

  /**
   * Store package
   * @param {string} token
   * @returns {void}
   */
  public static storeToken(token: string) {
    // localStorage.setItem(LocalStorageService.LOCAL_STORAGE_AUTH_TOKEN, token);
    Cookies.set(CookiesService.COOKIES_AUTH_TOKEN, token, {
      expires: 1,
      //   expires: 1 / 72, // set for 30 minutes
      path: "",
    });
  }

  /**
   * Store token
   * @returns {string}
   */
  public static getToken(): string {
    return Cookies.get(CookiesService.COOKIES_AUTH_TOKEN) as string;
  }

  /**
   * Remove token
   * @returns {string}
   */
  public static removeToken(): void {
    // return localStorage.removeItem(
    //   LocalStorageService.LOCAL_STORAGE_AUTH_TOKEN
    // );
    return Cookies.remove(CookiesService.COOKIES_AUTH_TOKEN, { path: "" });
  }

  /**
   * Remove account
   * @returns {string}
   */
  public static removeAccount(): void {
    return Cookies.remove(CookiesService.COOKIES_ACCOUNT, { path: "" });
  }

  /**
   * Store account
   * @param {object} accountData
   * @returns void
   */
  public static storeAccount(accountData: object) {
    Cookies.set(CookiesService.COOKIES_ACCOUNT, JSON.stringify(accountData), {
      expires: 1,
      path: "",
    });
  }

  /**
   * Get account
   * @returns {object}
   */
  public static getAccount(): any {
    const accountItem = Cookies.get(CookiesService.COOKIES_ACCOUNT);
    if (accountItem) {
      return JSON.parse(accountItem);
    }
  }
  /**
   * Store Company
   * @param {string} company
   * @returns {void}
   */
  public static storeCompany(company: string) {
    Cookies.set(CookiesService.COOKIES_COMPANY, company, {
      expires: 1,
      path: "",
    });
  }

  /**
   * Store company
   * @returns {string}
   */
  public static getCompany(): string {
    return Cookies.get(CookiesService.COOKIES_COMPANY) as string;
  }

  /**
   * Remove company
   * @returns {string}
   */
  public static removeCompany(): void {
    return Cookies.remove(CookiesService.COOKIES_COMPANY, { path: "" });
  }
}
