import { TRoute } from "../../utils/routes";
import DepartmentPageHome from "./Page/Home";

const LeadsRouter: TRoute[] = [
  {
    path: "/leads",
    name: "leads",
    title: "Leads",
    component: DepartmentPageHome,
  },
];

export default LeadsRouter;
