import * as React from "react";
import {
  Form,
  Button,
  message,
  Select,
  DatePicker,
  Row,
  Typography,
  Input,
  Col,
  Avatar,
  Upload,
} from "antd";
import { request } from "../../../utils/services";
import { useMutation } from "react-query";
import {
  InfoCircleOutlined,
  TagOutlined,
  PlusOutlined,
  DeleteOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { queryClient } from "../../../../src";
import { ManageLeadsModalContext } from "../Modal/ManageLeads";

import { useQuery } from "react-query";

import TextArea from "antd/lib/input/TextArea";
import { getAllActiveParty } from "../../Party/Requests";

import { getLeadById } from "../Requests";
import moment from "moment";
import CreatePartyModal from "src/modules/Party/Modal/CreateParty";
import { s3Path, uploadFileToAWS } from "src/utils/s3Config/s3upload";
import imLogo from "src/assets/img/imlogo.png";
import {
  RcFile,
  UploadChangeParam,
  UploadFile,
  UploadProps,
} from "antd/es/upload";
import { getAllPersonnel } from "src/modules/HR/Personnel/Requests";
const { Text } = Typography;
const ManageLeadsForm: React.FC<{ id: string }> = (props) => {
  const modalContext = React.useContext(ManageLeadsModalContext);
  const [formLoading, setFormLoading] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [imageUrl, setImageUrl] = React.useState<string>();
  const [galley, setGallery] = React.useState<string>();
  const [selectedFile, setSelectedFile] = React.useState<RcFile>();
  const manageLeadMutation = useMutation(
    (values: any) => request("admin.lead.update", { id: props.id }, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("lead");
      },
    }
  );
  const {
    isLoading,
    data: lead,
    error,
    isError,
  } = useQuery(["lead", props.id], async () => getLeadById(props.id), {
    refetchOnWindowFocus: false,
  });
  const { data: party } = useQuery(["party"], async () => getAllActiveParty(), {
    refetchOnWindowFocus: false,
  });
  const { data: person } = useQuery(
    ["peronnnel"],
    async () => getAllPersonnel(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const getBase64 = (img: RcFile, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    // if (info.file.status === "uploading") {
    //   setLoading(true);
    //   return;
    // }
    setSelectedFile(info.file.originFileObj);
    if (info.file.status === "uploading") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj as RcFile, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
  };

  const onFinish = async (values: any) => {
    setFormLoading(true);

    manageLeadMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The lead has been successfully managed.");
        form.resetFields();
        modalContext.setIsModalVisible(false);
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const [form] = Form.useForm();
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  // const onPartySelect = (i: any) => {
  //   form.setFieldsValue({ serviceAddress: party[i].address });
  // };
  let clientList = party.filter(
    (element: any) => element.partyType[0] === "Client"
  );
  let vendorList = party.filter(
    (element: any) => element.partyType[0] === "Vendor"
  );
  let leadList = party.filter(
    (element: any) => element.partyType[0] === "Lead Provider"
  );

  let partyList = [...clientList, ...vendorList, ...leadList];
  lead.enquiryDate = moment(lead.enquiryDate, "YYYY-MM-DD");
  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}
      initialValues={lead}
    >
      <Form.Item
        label="Enquiry Date"
        name="enquiryDate"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "Enquiry Date",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: "Please Select Enquiry Date",
          },
        ]}
        hasFeedback
      >
        <DatePicker />
      </Form.Item>
      <Form.Item
        label="Lead Title"
        name="title"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "Lead Title",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: "Please Enter Lead Title",
          },
        ]}
        hasFeedback
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Party"
        name="partyId"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "Party Name",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: "Please Select Party",
          },
        ]}
        hasFeedback
      >
        <Select
          // onChange={onPartySelect}
          showSearch
          placeholder="Select Party"
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option!.children as unknown as string).includes(input)
          }
          dropdownRender={(menu) => (
            <>
              <Row justify={"end"}>
                <CreatePartyModal />
              </Row>
              {menu}
            </>
          )}
        >
          {partyList &&
            partyList?.map((item: any, i: number) => {
              return (
                <Select.Option value={item.id} key={i}>
                  <Avatar
                    src={
                      <img
                        src={item.imageUrl ? s3Path + item.imageUrl : imLogo}
                        alt={item?.personName[0]}
                      />
                    }
                  />
                  <Typography.Text strong>
                    {item?.personName}-{item?.companyName}
                  </Typography.Text>
                </Select.Option>
              );
            })}
        </Select>
      </Form.Item>
      {/* <Form.Item
        label="Lead Status"
        name="leadStatus"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "Lead Status",
          icon: <InfoCircleOutlined />,
        }}
        hasFeedback
      >
        <Select
          placeholder="Select Status"
          options={[
            { value: "NewLead", label: "New Lead" },
            { value: "FollowedUp", label: "Followed Up" },
            { value: "Quoted", label: "Quoted" },
            { value: "Assigned", label: "Assigned" },
            { value: "Converted", label: "Converted" },
            { value: "Cancelled", label: "Cancelled" },
            { value: "NotInterested", label: "Not Interested" },
          ]}
        />
      </Form.Item> */}
      <Form.Item
        label="Source"
        name="source"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "Source",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: "Please Enter Source",
          },
        ]}
        hasFeedback
      >
        <Input placeholder="Enter Source" maxLength={50} />
      </Form.Item>
      <h3>Services</h3>
      <Form.List name="services">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field: any) => (
              <Row
                gutter={16}
                key={field.key}
                justify="space-between"
                align="top"
              >
                <Col span={23}>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.area !== curValues.area ||
                      prevValues.sights !== curValues.sights
                    }
                  >
                    {() => (
                      <Form.Item
                        {...field}
                        // name={[field.name, "title"]}
                        rules={[
                          {
                            required: true,
                            message: "Services Required",
                          },
                        ]}
                      >
                        <Input
                          addonBefore={
                            <TagOutlined className="site-form-item-icon" />
                          }
                          placeholder="Enter Services"
                          maxLength={100}
                        />
                      </Form.Item>
                    )}
                  </Form.Item>
                </Col>

                <DeleteOutlined onClick={() => remove(field.name)} />
              </Row>
            ))}

            <Form.Item>
              <Button onClick={() => add()} block icon={<PlusOutlined />}>
                Add Services
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>

      <Form.Item
        label="Remark"
        name="remarks"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "Remarks ",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: "Please enter remarks",
          },
          { whitespace: true },
          { min: 3 },
        ]}
        hasFeedback
      >
        <TextArea placeholder="Enter Remarks" maxLength={150} />
      </Form.Item>
      <Form.Item
        label="Upload Image"
        style={{ fontWeight: "bold" }}
        name="imageUrl"
        valuePropName="checked"
      >
        <Upload
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          accept="image/png, image/jpeg"
          action={async (file) => {
            const response: any = await uploadFileToAWS(file, "lead");
            setGallery(response.key);
            return response.key;
          }}
          onChange={handleChange}
        >
          {imageUrl ? (
            <img src={imageUrl} alt="avatar" style={{ width: "100%" }} />
          ) : (
            uploadButton
          )}
        </Upload>
      </Form.Item>
      <Form.Item
        label="Assigned Person"
        style={{ fontWeight: "bold" }}
        name="assignedPersonId"
        tooltip={{
          title: "Person ",
          icon: <InfoCircleOutlined />,
        }}
      >
        <Select
          // onSelect={OnPersonSelect}
          showSearch
          size="large"
          popupMatchSelectWidth
          // style={{ minHeight: 150 }}
          placeholder="Select Person"
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option!.children as unknown as string).includes(input)
          }
        >
          {person &&
            person?.map((item: any, i: number) => {
              return (
                <Select.Option
                  value={item?.id}
                  key={i}
                  // disabled={item.availability === 0 ? true : false}
                >
                  <Row justify={"space-between"} align={"middle"}>
                    <Typography.Text strong>
                      {item?.partyId?.personName}
                    </Typography.Text>

                    <Avatar
                      src={
                        <img
                          src={item.imageUrl ? s3Path + item.imageUrl : imLogo}
                          alt={item?.partyId?.personName[0]}
                        />
                      }
                    />
                  </Row>
                </Select.Option>
              );
            })}
        </Select>
      </Form.Item>

      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ManageLeadsForm;
