import { request } from "src/utils/services";

export const getAllDesignation = async () => {
  const { data } = await request("admin.designation.list", {});
  return data;
};
export const getDesignationById = async (id: any) => {
  const { data } = await request("admin.designation.id", { id });
  return data;
};
export const getAllDesignationByDeptId = async (id: any) => {
  const { data } = await request("admin.designation.department.id", { id });
  return data;
};
