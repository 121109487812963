import { path } from "./path";

export const timelineRoutes = [
  {
    method: "post",
    path: path + "/api/admin/createTimeline",
    module: "timeline",
    alias: "admin.timeline.create",
    isSubDomain: true,
  },

  {
    method: "put",
    path: path + "/api/admin/updateTimeline/:id",
    module: "timeline",
    alias: "admin.timeline.update",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllActiveTimeline",
    module: "timeline",
    alias: "admin.timeline.list.active",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllTimeline",
    module: "timeline",
    alias: "admin.timeline.list",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findOneTimeline/:id",
    module: "timeline",
    alias: "admin.timeline.id",
    isSubDomain: true,
  },
];
