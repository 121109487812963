import { path } from "./path";

export const partyRoutes = [
  {
    method: "post",
    path: path + "/api/admin/saveParty",
    module: "party",
    alias: "admin.party.create",
    isSubDomain: true,
  },

  {
    method: "put",
    path: path + "/api/admin/updateParty/:id",
    module: "party",
    alias: "admin.party.update",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllActiveParty",
    module: "party",
    alias: "admin.party.list.active",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllParty",
    module: "party",
    alias: "admin.party.list",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findOneParty/:id",
    module: "party",
    alias: "admin.party.id",
    isSubDomain: true,
  },

  //concerned Person
  {
    method: "post",
    path: path + "/api/admin/createConcernedPerson",
    module: "concernedperson",
    alias: "admin.concernedperson.create",
    isSubDomain: true,
  },
  {
    method: "put",
    path: path + "/api/admin/updateConcernedPerson/:id",
    module: "concernedperson",
    alias: "admin.concernedperson.update",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findAllConcernedPerson",
    module: "concernedperson",
    alias: "admin.concernedperson.list",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findOneConcernedPerson/:id",
    module: "concernedperson",
    alias: "admin.concernedperson.id",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findAllConcernedPersonByPartyId/:id",
    module: "concernedperson",
    alias: "admin.concernedperson.partyId",
    isSubDomain: true,
  },
  {
    method: "post",
    path: path + "/api/admin/addBankDetails",
    module: "party",
    alias: "admin.bankDetails.add",
    isSubDomain: true,
  },
  {
    method: "put",
    path: path + "/api/admin/updateBankDetails/:id",
    module: "party",
    alias: "admin.bankDetails.update",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findBankDetailsByPartyId/:id",
    module: "party",
    alias: "admin.bankDetails.get.partyId",
    isSubDomain: true,
  },
  // {
  //   method: "get",
  //   path: path + "/api/admin/findAllSocialByPartyId/:id",
  //   module: "party",
  //   alias: "admin.social.get.partyId",
  //   isSubDomain: true,
  // },
  {
    method: "get",
    path: path + "/api/admin/findPartyByType/:partyType",
    module: "party",
    alias: "admin.party.type",
    isSubDomain: true,
  },
];
