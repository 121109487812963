import { TRoute } from "src/utils/routes";
import DepartmentPageHome from "./Page/Home";

const AfterSalesRouter: TRoute[] = [
  {
    path: "/afterSales",
    name: "afterSales",
    title: "afterSales",
    component: DepartmentPageHome,
  },
];

export default AfterSalesRouter;
