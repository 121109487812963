import * as React from "react";

import {
  Modal,
  Button,
  Tooltip,
  Drawer,
  Timeline,
  Space,
  Typography,
  Tag,
  Row,
} from "antd";
//import ShowLeadTimelineForm from "../Form/ShowLeadTimelineForm";
import { ClockCircleOutlined } from "@ant-design/icons";
import { getLeadTimelineByLeadId } from "../Requests";
import { useQuery } from "react-query";
import moment from "moment";
export const ShowLeadTimelineModalContext = React.createContext<{
  setOpenDrawer: (active: boolean) => void;
}>({
  setOpenDrawer: () => true,
});
const { Text, Link } = Typography;
const LeadTimelineDrawer: React.FC<{ id: string }> = (props) => {
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const {
    isLoading,
    data: items,
    error,
    isError,
  } = useQuery(
    ["leadTimeline", props.id],
    async () => getLeadTimelineByLeadId(props.id),
    {
      refetchOnWindowFocus: false,
    }
  );

  const showDrawer = () => {
    setOpenDrawer(true);
  };

  const onClose = () => {
    setOpenDrawer(false);
  };

  return (
    <ShowLeadTimelineModalContext.Provider value={{ setOpenDrawer }}>
      <Tooltip title="View Lead Timeline">
        <Button
          onClick={showDrawer}
          type="primary"
          shape="circle"
          icon={<ClockCircleOutlined />}
        />
      </Tooltip>
      <Drawer
        width={500}
        title="Lead Timeline"
        placement="right"
        onClose={onClose}
        open={openDrawer}
      >
        <>
          <Timeline mode="left">
            {items &&
              items?.map((item: any, i: any) => {
                return (
                  <Timeline.Item
                    label={moment(item.createdAt).format("DD-MMM-YYYY hh:mm A")}
                  >
                    <>
                      <Row>
                        {" "}
                        <Text type="success" strong>
                          {item.content}-{item?.createdBy?.username}
                        </Text>
                        <Tag color="orange">{item.leadStatus}</Tag>
                      </Row>

                      <Text>{item.description}</Text>
                    </>
                  </Timeline.Item>
                );
              })}
          </Timeline>
        </>
      </Drawer>
    </ShowLeadTimelineModalContext.Provider>
  );
};

export default LeadTimelineDrawer;
