import { TRoute } from "src/utils/routes";
import DepartmentPageHome from "./Page/Home";

const DailyStandupRouter: TRoute[] = [
  {
    path: "/dailyStandup",
    name: "dailyStandup",
    title: "dailyStandup",
    component: DepartmentPageHome,
  },
];

export default DailyStandupRouter;
