import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "src/components/Card";

import { Empty, Row } from "antd";
import CreateDeploymentModal from "../Modal/CreateDeployment";
import DeploymentList from "../Component/List";
import { getAccessAuthrorization } from "src/components/access";

const DeploymentPageHome: React.FC<RouteComponentProps> = () => {
  let accessAuth = getAccessAuthrorization("deployment");

  return (
    <React.Fragment>
      <Card>
        <CardHeader>
          <CardHeaderToolbar>
            <Row justify="space-between">
              <h2>Deployment List</h2>
              {/* <CreateDeploymentModal /> */}
              {/* {accessAuth[0].includes("Create") && <CreateDeploymentModal />} */}
            </Row>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          {/* <DeploymentList /> */}
          {accessAuth[0].includes("View") ? (
            <DeploymentList />
          ) : (
            <Empty
              image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
              imageStyle={{ height: 60 }}
              description={<span>You are Not Authorised!</span>}
            />
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default withRouter(DeploymentPageHome);
