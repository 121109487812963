import { TRoute } from "../../../utils/routes";
import DepartmentPageHome from "./Page/Home";

const DepartmentRouter: TRoute[] = [
  {
    path: "/department",
    name: "department",
    title: "Department",
    component: DepartmentPageHome,
  },
];

export default DepartmentRouter;
