import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "src/components/Card";

import { Empty, Row } from "antd";
import CreateTaskTypeModal from "../Modal/CreateTaskType";
import TaskTypeList from "../Component/List";
import { getAccessAuthrorization } from "src/components/access";
import CreateTaskType from "../Form/CreateTaskTypeForm";

const TaskTypePageHome: React.FC<RouteComponentProps> = () => {
  let accessAuth = getAccessAuthrorization("taskType");

  return (
    <React.Fragment>
      <Card>
        <CreateTaskType />
      </Card>
    </React.Fragment>
  );
};

export default withRouter(TaskTypePageHome);
