import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../components/Card";

import { Empty, Row } from "antd";

import NotificationList from "../Component/List";
import { getAccessAuthrorization } from "src/components/access";

const NotificationPageHome: React.FC<RouteComponentProps> = () => {
  let accessAuth = getAccessAuthrorization("notification");
  return (
    <React.Fragment>
      <Card>
        <CardHeader>
          <CardHeaderToolbar>
            <Row justify="space-between">
              <h2>Notification List</h2>
              {/* <CreateNotificationModal /> */}
              {/* {accessAuth[0].includes("Create") && <CreateNotificationModal />} */}
            </Row>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <NotificationList />
          {/* {accessAuth[0].includes("View") ? (
            <NotificationList />
          ) : (
            <Empty
              image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
              imageStyle={{ height: 60 }}
              description={<span>You are Not Authorised!</span>}
            />
          )} */}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default withRouter(NotificationPageHome);
