import { request } from "../../../utils/services";

export const getAllActiveTimeline = async () => {
  const { data } = await request("admin.timeline.list.active");
  return data;
};

export const getAllTimeline = async () => {
  const { data } = await request("admin.timeline.list");
  return data;
};

export const getTimelineById = async (id: string) => {
  const { data } = await request("admin.timeline.id", { id });
  return data;
};
