import * as React from "react";

import { Modal, Button, Tooltip } from "antd";

import { EyeOutlined } from "@ant-design/icons";
import ShowStoryDetailsForm from "../Form/ShowStoryForm";
export const ShowStoryModalContext = React.createContext<{
  setIsModalVisible: (active: boolean) => void;
}>({
  setIsModalVisible: () => true,
});

const StoryViewModal: React.FC<{ id: string }> = ({ id }) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const showModal = () => setIsModalVisible(true);

  const handleOk = () => setIsModalVisible(false);

  const handleCancel = () => setIsModalVisible(false);

  return (
    <ShowStoryModalContext.Provider value={{ setIsModalVisible }}>
      <Tooltip title="View All Details">
        <Button
          onClick={showModal}
          type="primary"
          shape="circle"
          icon={<EyeOutlined />}
        />
      </Tooltip>
      <Modal
        width={1000}
        title=" View Story Request Form"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <ShowStoryDetailsForm id={id} />
      </Modal>
    </ShowStoryModalContext.Provider>
  );
};

export default StoryViewModal;
