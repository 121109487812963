import * as React from "react";
import {
  Form,
  Button,
  message,
  Row,
  Typography,
  Input,
  Col,
  Upload,
  Switch,
  Divider,
} from "antd";

import { request } from "../../../utils/services";
import { useMutation } from "react-query";
import {
  InfoCircleOutlined,
  PlusOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { queryClient } from "src";
import type { UploadChangeParam, UploadFile } from "antd/es/upload/interface";
import type { RcFile, UploadProps } from "antd/es/upload";

import TextArea from "antd/lib/input/TextArea";

import LocalStorageService from "src/utils/local-storage";
import { CreateTestimonialModalContext } from "../Modal/CreateTestimonial";
import { uploadFileToAWS } from "src/utils/s3Config/s3upload";

const { Text } = Typography;
const CreateTestimonial: React.FC = () => {
  const modalContext = React.useContext(CreateTestimonialModalContext);
  const [formLoading, setFormLoading] = React.useState(false);
  const [galley, setGallery] = React.useState<string>();
  const [loading, setLoading] = React.useState(false);

  const [imageUrl, setImageUrl] = React.useState<string>();
  const [selectedFile, setSelectedFile] = React.useState<RcFile>();
  const createTestimonialMutation = useMutation(
    (values: any) => request("admin.testimonial.create", {}, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("testimonial");
      },
    }
  );
  const getBase64 = (img: RcFile, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    // if (info.file.status === "uploading") {
    //   setLoading(true);
    //   return;
    // }
    setSelectedFile(info.file.originFileObj);
    if (info.file.status === "uploading") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj as RcFile, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
  };

  const onFinish = async (values: any) => {
    values.createdBy = LocalStorageService.getAccount().id;
    values.imageUrl = galley;
    setFormLoading(true);

    createTestimonialMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The Testimonial has been successfully created.");
        form.resetFields();
        modalContext.setIsModalVisible(false);
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const [form] = Form.useForm();

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}
    >
      <Row gutter={24}>
        <Col flex={3} span={8}>
          <Form.Item
            label="Name"
            name="name"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "Name",
              icon: <InfoCircleOutlined />,
            }}
            rules={[{ whitespace: true }, { min: 3 }]}
            hasFeedback
          >
            <Input
              placeholder="Enter Name"
              // addonBefore={<ToolOutlined className="site-form-item-icon" />}
            />
          </Form.Item>
        </Col>
        <Col flex={3} span={8}>
          <Form.Item
            label="Place"
            name="place"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "place",
              icon: <InfoCircleOutlined />,
            }}
            rules={[{ whitespace: true }, { min: 3 }]}
            hasFeedback
          >
            <Input
              placeholder="Enter Place"
              // addonBefore={<ToolOutlined className="site-form-item-icon" />}
            />
          </Form.Item>
        </Col>
        <Col flex={3} span={8}>
          <Form.Item
            label="Designation"
            name="designation"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "designation",
              icon: <InfoCircleOutlined />,
            }}
            rules={[{ whitespace: true }, { min: 3 }]}
            hasFeedback
          >
            <Input
              placeholder="Enter Designation"
              // addonBefore={<ToolOutlined className="site-form-item-icon" />}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col flex={3} span={12}>
          <Form.Item
            label="Video Url"
            name="videoURl"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "video Url",
              icon: <InfoCircleOutlined />,
            }}
            rules={[{ whitespace: true }, { min: 3 }]}
            hasFeedback
          >
            <TextArea placeholder="Enter Embeded Youtube Url" />
          </Form.Item>
        </Col>
        <Col flex={3} span={12}>
          <Form.Item
            label="Review"
            name="review"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "review",
              icon: <InfoCircleOutlined />,
            }}
            rules={[{ whitespace: true }, { min: 3 }]}
            hasFeedback
          >
            <TextArea placeholder="Enter Review" />
          </Form.Item>
        </Col>
      </Row>
      <Col flex={2} span={12}>
        <Form.Item
          label="Upload Image"
          style={{ fontWeight: "bold" }}
          name="imageUrl"
          valuePropName="checked"
        >
          <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            accept="image/png, image/jpeg"
            action={async (file) => {
              const response: any = await uploadFileToAWS(file, "testimonial");
              setGallery(response.key);
              return response.key;
            }}
            onChange={handleChange}
          >
            {imageUrl ? (
              <img src={imageUrl} alt="avatar" style={{ width: "100%" }} />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>
      </Col>
      <Form.Item
        label="Status"
        style={{ fontWeight: "bold" }}
        name="isActive"
        tooltip={{
          title: "status",
          icon: <InfoCircleOutlined />,
        }}
        valuePropName="checked"
        // rules={[{ required: true }]}
      >
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          defaultChecked
        />
      </Form.Item>

      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreateTestimonial;
