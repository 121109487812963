import { path } from "./path";

export const authRoutes = [
  {
    method: "post",
    path: path + "/api/auth/signup",
    module: "administration",
    alias: "user.signup",
    isSubDomain: true,
  },
  // login
  {
    method: "post",
    path: path + "/api/auth/signin",
    module: "administration",
    alias: "user.login",
    isSubDomain: true,
  },
];
