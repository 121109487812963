import { path } from "./path";

export const rolesRoutes = [
  {
    method: "post",
    path: path + "/api/admin/saveRoles",
    module: "roles",
    alias: "admin.roles.create",
    isSubDomain: true,
  },

  {
    method: "put",
    path: path + "/api/admin/updateRoles/:id",
    module: "roles",
    alias: "admin.roles.update",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllActiveRoles",
    module: "roles",
    alias: "admin.roles.list.active",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllRoles",
    module: "roles",
    alias: "admin.roles.list",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findOneRoles/:id",
    module: "roles",
    alias: "admin.roles.id",
    isSubDomain: true,
  },
];
