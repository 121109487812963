import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "src/components/Card";

import { Empty, Row } from "antd";
import CreateProposalModal from "../Modal/CreateProposal";
import ProposalList from "../Component/List";
import { getAccessAuthrorization } from "src/components/access";

const ProposalPageHome: React.FC<RouteComponentProps> = () => {
  let accessAuth = getAccessAuthrorization("proposal");

  return (
    <React.Fragment>
      <Card>
        <CardHeader>
          <CardHeaderToolbar>
            <Row justify="space-between">
              <h2>Proposal List</h2>
              {/* <CreateProposalModal /> */}
              {accessAuth[0].includes("Create") && <CreateProposalModal />}
            </Row>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          {/* <ProposalList /> */}
          {accessAuth[0].includes("View") ? (
            <ProposalList />
          ) : (
            <Empty
              image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
              imageStyle={{ height: 60 }}
              description={<span>You are Not Authorised!</span>}
            />
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default withRouter(ProposalPageHome);
