import { TRoute } from "../../utils/routes";
import CollectionPageHome from "./Page/Home";

const CollectionRouter: TRoute[] = [
  {
    path: "/collection",
    name: "collection",
    title: "Collection",
    component: CollectionPageHome,
  },
];

export default CollectionRouter;
