import * as React from 'react'

const NotFound: React.FC = () => (
    <div className="main-container not-found-page">
        <figure>
            <img src="/static/images/nothing-here.png" alt="Not found" />
        </figure>
        <h1>404</h1>
        <h4>
            Sorry, this page cannot be found
        </h4>
    </div>
);

export default NotFound;
