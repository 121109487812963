import { TRoute } from "../../utils/routes";
import ReplyPageHome from "./Page/Home";

const ReplyRouter: TRoute[] = [
  {
    path: "/reply",
    name: "reply",
    title: "Reply",
    component: ReplyPageHome,
  },
];

export default ReplyRouter;
