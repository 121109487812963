import { request } from "../../../utils/services";

export const getAllActivePayment = async () => {
  const { data } = await request("admin.payment.list.active");
  return data;
};

export const getAllPayment = async () => {
  const { data } = await request("admin.payment.list");
  return data;
};

export const getPaymentById = async (id: string) => {
  const { data } = await request("admin.payment.id", { id });
  return data;
};

export const getOnePaymentWithDetail = async (id: string) => {
  const { data } = await request("admin.payment.id.with.detail", { id });
  return data;
};
export const getAllPaymentByType = async (type: string) => {
  const { data } = await request("admin.payment.type", { type });
  return data;
};
