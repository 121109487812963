import * as React from "react";
import {
  Descriptions,
  Card,
  Divider,
  Row,
  Tag,
  Col,
  Typography,
  Space,
  Button,
  Avatar,
  Modal,
  message,
  Popover,
  Image,
  Tooltip,
} from "antd";
import {
  ProfileOutlined,
  PhoneOutlined,
  HomeOutlined,
  GlobalOutlined,
  MailOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useQuery } from "react-query";
import { getLeadById, getLeadByIdDetail } from "../Requests";
import Table, { ColumnsType } from "antd/es/table";
import moment from "moment";
import PersonnelViewModal from "src/modules/HR/Personnel/Modal/ShowPersonnelDetails";
import FollowUpListByLead from "../Home/FollowupListByLeadId";
import Meta from "antd/es/card/Meta";
import ShowPersonnelDetailsForm from "src/modules/HR/Personnel/Form/ShowPersonnelDetailsForm";
import { s3Path } from "src/utils/s3Config/s3upload";
const { Text, Title } = Typography;

const ShowLeadDetailsForm: React.FC<{ id: string }> = (props) => {
  const [view, setView] = React.useState(false);
  const [id, setId] = React.useState("");
  const { data: lead } = useQuery(
    ["lead", props.id],
    async () => getLeadByIdDetail(props.id),
    {
      refetchOnWindowFocus: false,

      staleTime: 5000,
    }
  );

  return (
    <>
      <Divider dashed orientation="center">
        Detail of {lead[0]?.partyId[0]?.personName}
      </Divider>
      <Card
        title={
          "Enquiry Date: " +
          moment(lead[0]?.enquiryDate).format("DD-MMM-YYYY") +
          "(" +
          lead[0]?.leadStatus +
          ")"
        }
      >
        <Row gutter={24} justify="space-between">
          {/* <Col flex={3} span={8}> */}
          <Image src={s3Path + lead[0].imageUrl} height={150} width={150} />
          {/* </Col> */}
          {/* <Col flex={3} span={8}> */}
          <Space>
            <Space.Compact>
              <UserOutlined /> {lead[0].partyId[0]?.personName}
              <Text code type="danger">
                {lead[0]?.partyId[0]?.partyType}
              </Text>
              {/* <Text type="warning" code>
              {party?.classification}
            </Text> */}
            </Space.Compact>
          </Space>
          {/* </Col> */}
          <Col flex={3} span={8}>
            <Space>
              <Space.Compact direction="vertical">
                <Text>
                  <HomeOutlined />
                  {lead[0]?.partyId[0]?.companyName}
                </Text>
                <Text>{lead[0]?.partyId[0]?.address}</Text>
                <Text>
                  <PhoneOutlined />
                  +91-{lead[0]?.partyId[0]?.phoneNo},
                </Text>
                <Text>
                  <MailOutlined /> {lead[0].partyId[0]?.email}
                </Text>
                <Text>
                  <GlobalOutlined /> {lead[0]?.partyId[0]?.website}
                </Text>
                <Text>
                  <ProfileOutlined /> {lead[0]?.partyId[0]?.gstInNo}
                </Text>
              </Space.Compact>
            </Space>
          </Col>
        </Row>
        {/* <Text
          style={{
            fontSize: 25,
          }}
        >
          <UserOutlined /> {lead[0].partyId[0]?.personName}
          <Button size="small" danger>
            {lead[0]?.partyId[0]?.partyType}
          </Button>
        </Text> */}

        {/* <Text
          style={{
            fontSize: 18,
          }}
        >
          <HomeOutlined /> {lead[0]?.partyId[0]?.companyName}
        </Text> */}

        {/* <Text
          style={{
            fontSize: 15,
            fontStyle: "italic",
          }}
        >
          {lead[0]?.partyId[0]?.address}
        </Text> */}

        {/* <Text
          style={{
            fontSize: 13,
            fontStyle: "italic",
          }}
        >
          <PhoneOutlined />
          +91-{lead[0]?.partyId[0]?.phoneNo}, <MailOutlined />{" "}
          {lead[0].partyId[0]?.email}
        </Text> */}

        {/* <Text
          style={{
            fontSize: 13,
            fontStyle: "italic",
          }}
        >
          <GlobalOutlined /> {lead[0]?.partyId[0]?.website}
        </Text> */}

        {/* <Text
          style={{
            fontSize: 12,
            fontStyle: "italic",
          }}
        >
          <ProfileOutlined /> {lead[0]?.partyId[0]?.gstInNo}
        </Text> */}
        <Divider orientation="center"> Concerned Person detail</Divider>

        <Row gutter={[16, 16]}>
          {lead[0] &&
            lead[0]?.partyId[0]?.concernedpersons.map(
              (item: any, i: number) => {
                return (
                  <Col span={12}>
                    <Card bordered={true}>
                      <Meta
                        avatar={<Avatar size="large" icon={<UserOutlined />} />}
                        title={item?.name}
                        description={
                          <Col>
                            {item.designation}
                            <br />
                            {item.mobile}
                            <br />
                            {item.email}
                          </Col>
                        }
                      />
                    </Card>
                  </Col>
                );
              }
            )}
        </Row>

        <br />
        <Tooltip title="View All Details">
          <Card
            title={"Assign Person Detail"}
            bordered={true}
            hoverable
            onClick={() => {
              setId(lead[0]?.assignedPersonId[0]?.partyId[0]._id);
              setView(!view);
            }}
            cover={
              <Modal
                width={1000}
                title=" View Occupant Details"
                open={view}
                onOk={() => setView(false)}
                onCancel={() => setView(false)}
              >
                <ShowPersonnelDetailsForm
                  id={lead[0]?.assignedPersonId[0]?._id}
                />
              </Modal>
            }
          >
            {/* <Row gutter={24}>
            <Col span={12}> */}
            <Meta
              avatar={<Avatar size="large" icon={<UserOutlined />} />}
              title={
                <Row>
                  {lead[0]?.assignedPersonId[0]?.partyId[0]?.personName}
                  <Text code type="danger">
                    {lead[0]?.assignedPersonId[0]?.partyId[0]?.partyType}
                  </Text>
                </Row>
              }
              description={
                <Col>
                  {lead[0]?.assignedPersonId[0]?.partyId[0]?.email}
                  <br />
                  {lead[0]?.assignedPersonId[0]?.partyId[0]?.phoneNo}
                  <br />
                </Col>
              }
            />
          </Card>
        </Tooltip>
        <br />
        <br />
        <Card hoverable title={"Services"}>
          {lead[0]?.services?.map((item: any, i: number) => (
            <>
              <Tag color="blue" style={{ margin: 2 }}>
                {i + 1 + ". " + item}
              </Tag>
            </>
          ))}
        </Card>
        <br />
        <Divider orientation="center">Follow Up list</Divider>
        <FollowUpListByLead id={props.id} />
      </Card>
    </>
  );
};

export default ShowLeadDetailsForm;
