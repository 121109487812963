import { request } from "../../../../utils/services";

export const getAllDailyStandup = async () => {
  const { data } = await request("admin.dailyStandup.list");
  return data;
};

export const getDailyStandupById = async (id: string) => {
  const { data } = await request("admin.dailyStandup.id", { id });
  return data;
};
export const getDailyStandupByIdDetail = async (id: string) => {
  const { data } = await request("admin.dailyStandup.id.detail", { id });
  return data;
};
