import * as React from "react";

import CreateLeadsForm from "../Form/CreateLeadsForm";
import { Modal, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";

export const CreateLeadsModalContext = React.createContext<{
  setIsModalVisible: (active: boolean) => void;
}>({
  setIsModalVisible: () => true,
});

const CreateLeadsModal: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <CreateLeadsModalContext.Provider value={{ setIsModalVisible }}>
      <Button
        onClick={showModal}
        type="primary"
        shape="round"
        icon={<PlusOutlined />}
      >
        Add Leads
      </Button>
      <Modal
        title="Generate Leads"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        centered
      >
        <CreateLeadsForm />
      </Modal>
    </CreateLeadsModalContext.Provider>
  );
};

export default CreateLeadsModal;
