import * as React from "react";

import { WhatsAppOutlined } from "@ant-design/icons";
import { Button } from "antd";

const WhatsappMessage: React.FC<{ phoneNumber: string; message: string }> = (
  props
) => {
  return (
    <Button
      type="link"
      href={
        "whatsapp://send/?phone=91-" +
        props.phoneNumber +
        "&text=" +
        props.message
      }
      icon={<WhatsAppOutlined style={{ fontSize: "22px", color: "#50C878" }} />}
    />
  );
};

export default WhatsappMessage;
