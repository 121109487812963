import { request } from "../../../../utils/services";

export const getAllActiveBlogComment = async () => {
  const { data } = await request("admin.blogComment.list.active");
  return data;
};

export const getAllBlogComment = async () => {
  const { data } = await request("admin.blogComment.list");
  return data;
};

export const getBlogCommentById = async (id: string) => {
  const { data } = await request("admin.blogComment.id", { id });
  return data;
};
