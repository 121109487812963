import moment from "moment";
import * as React from "react";

import { useQuery } from "react-query";
import type { ColumnsType } from "antd/lib/table";

import { Descriptions, Space, Table, Tag, Typography } from "antd";

import { getAllSEO } from "../Requests";
import SEOViewModal from "../Modal/ShowSEODetails";
import ManageSEOModal from "../Modal/ManageSEO";
import { getAccessAuthrorization } from "src/components/access";
import { EyeTwoTone, EyeInvisibleOutlined } from "@ant-design/icons";

interface DataType {
  id: number;
  name: any;
  platformLink: any;
  platformName: any;
  remarks: any;
}
type DataIndex = keyof DataType;
const SEOList: React.FC = () => {
  const [page, setPage] = React.useState(1);
  const [size, setSize] = React.useState(100);
  const [searchText, setSearchText] = React.useState("");
  const [searchedColumn, setSearchedColumn] = React.useState("");
  const [passwordVisible, setPasswordVisible] = React.useState(false);

  let accessAuth = getAccessAuthrorization("seo");

  const {
    isLoading,
    isError,
    data: response,
  } = useQuery(["seo"], () => getAllSEO(), {
    refetchOnWindowFocus: false,

    keepPreviousData: true,
    staleTime: 5000,
  });

  if (isLoading || isError) {
    return <div>Loading</div>;
  }
  const { Text } = Typography;
  const column: ColumnsType<DataType> = [
    {
      title: "Platform Name",
      dataIndex: "platformName",
      key: "platformName",
    },
    // {
    //   title: "Platform Link",
    //   dataIndex: "platformLink",
    //   key: "platformLink",
    // },

    {
      title: "Username",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Password",
      dataIndex: "password",
      key: "password",
      render: (_: any, i: any) => (
        <Space>
          <Tag
            color="purple"
            icon={passwordVisible ? <EyeTwoTone /> : <EyeInvisibleOutlined />}
            onClick={() => setPasswordVisible((prevState) => !prevState)}
          >
            Pwd: {passwordVisible ? i.password : "************"}
          </Tag>
        </Space>
      ),
    },

    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      render: (text: any) =>
        text ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">Inactive</Tag>
        ),
    },

    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (id: any) => (
        <Space size={10}>
          {/* <CredentialViewModal id={id} /> */}
          {/* <ManageSEOModal id={id} /> */}
          {accessAuth[0].includes("Edit") && <ManageSEOModal id={id} />}
        </Space>
      ),
    },
  ];

  return (
    <Table
      rowKey={(record) => record.id}
      size="small"
      columns={column}
      dataSource={response}
      expandable={{
        expandedRowRender: (record) => (
          <>
            <Descriptions
              bordered
              layout="horizontal"
              size="small"
              style={{ backgroundColor: "beige" }}
            >
              <Descriptions.Item label="Platform Link" span={3}>
                {record.platformLink}
              </Descriptions.Item>
              <Descriptions.Item label="Remarks">
                {record.remarks}
              </Descriptions.Item>
            </Descriptions>
          </>
        ),
        rowExpandable: (record) => record?.platformName !== "Not Expandable",
      }}
    />
  );
};

export default SEOList;
