import * as React from "react";
import { Layout } from "../components";
import routes from "../utils/routes";

import { Route, Switch, Redirect, RouteComponentProps } from "react-router-dom";
import NotFound from "../components/NotFound";

const Routes: React.FC<RouteComponentProps> = () => {
  // console.log(routes);
  return (
    <Layout>
      <Switch>
        {routes &&
          routes.map(({ component: Component, children, ...rest }, key) => {
            if (!children) {
              return (
                <Route
                  key={key}
                  {...rest}
                  render={(props) => <Component {...props} />}
                  exact={true}
                />
              );
            }
            return children.map(
              ({ component: ChildComponent, ...childRest }, childKey) => {
                return (
                  <Route
                    key={key + childKey}
                    {...childRest}
                    path={childRest.path}
                    render={(props) => (
                      <Component {...props}>
                        <ChildComponent />
                      </Component>
                    )}
                    exact={true}
                  />
                );
              }
            );
          })}
        <Route component={NotFound}>
          <Redirect to="/404" />
        </Route>
      </Switch>
    </Layout>
  );
};

export default Routes;
