import { request } from "src/utils/services";

export const getAllActiveAccess = async () => {
  const { data } = await request("admin.access.list.active");
  return data;
};

export const getAllAccess = async () => {
  const { data } = await request("admin.access.list");
  return data;
};

export const getAccessById = async (id: string) => {
  const { data } = await request("admin.access.id", { id });
  return data;
};

export const getAccessByRoleId = async (id: string) => {
  const { data } = await request("admin.access.list.roleId", { id });
  return data;
};

export const getCollectionByRoleId = async (id: string) => {
  const { data } = await request("admin.access.collection.roleId", { id });
  return data;
};
