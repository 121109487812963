import * as React from "react";
import {
  Descriptions,
  Badge,
  Card,
  Row,
  Image,
  Space,
  Typography,
  Col,
  Avatar,
  Divider,
  Tag,
  Button,
  Empty,
} from "antd";
import { useQuery } from "react-query";

import {
  ProfileOutlined,
  PhoneOutlined,
  HomeOutlined,
  GlobalOutlined,
  MailOutlined,
  UserOutlined,
} from "@ant-design/icons";

// import { getUserByProjectId } from "src/modules/User/Requests";
import Meta from "antd/es/card/Meta";
import { getProjectById, getProjectByIddetail } from "../Requests";
import { getPartyById } from "src/modules/Party/Requests";
import { s3Path } from "src/utils/s3Config/s3upload";
import moment from "moment";
// import { getUserByProjectId } from "../../User/Requests";
const { Text } = Typography;
const ShowProjectDetailsForm: React.FC<{ id: string }> = (props) => {
  const { data: project } = useQuery(
    ["project", props.id],
    async () => getProjectByIddetail(props.id),
    {
      refetchOnWindowFocus: false,

      staleTime: 5000,
    }
  );

  return (
    <Card>
      <Card hoverable>
        <Row gutter={24}>
          <Col flex={3} span={8}>
            <>
              {project?.partyId.imageUrl ? (
                <Image
                  width={100}
                  height={100}
                  src={s3Path + project?.partyId.imageUrl}
                />
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )}
            </>
          </Col>
          <Col flex={3} span={8}>
            <Text
              style={{
                fontSize: 20,
              }}
            >
              <UserOutlined /> {project.partyId.personName}
              <Button size="small" danger>
                {project.partyId.partyType}
              </Button>
            </Text>
          </Col>
          <Col flex={3} span={8}>
            <Text
              style={{
                fontSize: 15,
                fontStyle: "italic",
              }}
            >
              <HomeOutlined /> {project.partyId.address}
            </Text>
            <br />
            <Text
              style={{
                fontSize: 13,
                fontStyle: "italic",
              }}
            >
              <PhoneOutlined />
              +91-{project.partyId.phoneNo}
            </Text>
            <br />
            <Text
              style={{
                fontSize: 13,
                fontStyle: "italic",
              }}
            >
              <MailOutlined /> {project.partyId.email}
            </Text>
          </Col>
        </Row>
      </Card>
      <br />
      <Card>
        <Descriptions title="Project Details">
          <Descriptions.Item label=" Project Name:">
            {project.projectName}{" "}
            <Text code type="danger">
              {project.projectStatus}
            </Text>
          </Descriptions.Item>
          <Descriptions.Item label="Start Date:">
            {moment(project?.startDate).format("DD-MMM-YYYY")}
          </Descriptions.Item>
          <Descriptions.Item label="Proposed End Date:">
            {moment(project?.proposedEndDate).format("DD-MMM-YYYY")}
          </Descriptions.Item>
        </Descriptions>
      </Card>
    </Card>
  );
};

export default ShowProjectDetailsForm;
