import * as React from "react";

import CreateLeadsForm from "../Form/CreateLeadsForm";
import { Modal, Button } from "antd";
import { SnippetsOutlined } from "@ant-design/icons";
import CreateBulkLeadsForm from "../Form/BulkLeadForm";

export const BulkLeadsModalContext = React.createContext<{
  setIsModalVisible: (active: boolean) => void;
}>({
  setIsModalVisible: () => true,
});

const BulkLeadsModal: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <BulkLeadsModalContext.Provider value={{ setIsModalVisible }}>
      <Button
        onClick={showModal}
        type="primary"
        shape="round"
        icon={<SnippetsOutlined />}
        style={{ marginRight: 10 }}
      >
        Bulk Leads
      </Button>
      <Modal
        title="Generate Bulk Leads"
        open={isModalVisible}
        width={"95%"}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        centered
      >
        <CreateBulkLeadsForm />
      </Modal>
    </BulkLeadsModalContext.Provider>
  );
};

export default BulkLeadsModal;
