import { request } from "src/utils/services";

export const getAllActiveReply = async () => {
  const { data } = await request("admin.reply.list.active");
  return data;
};

export const getAllReply = async () => {
  const { data } = await request("admin.reply.list");
  return data;
};

export const getReplyById = async (id: string) => {
  const { data } = await request("admin.reply.id", { id });
  return data;
};

export const getReplyByPersonnel = async (id: string) => {
  const { data } = await request("admin.reply.personnel.id", { id });
  return data;
};

export const getReplyByTask = async (id: string) => {
  const { data } = await request("admin.reply.task.id", { id });
  return data;
};

export const getReplyByTag = async (id: string) => {
  const { data } = await request("admin.reply.tag.id", { id });
  return data;
};
