import { path } from "./path";

export const storyRoutes = [
  {
    method: "post",
    path: path + "/api/admin/createStory",
    module: "story",
    alias: "admin.story.create",
    isSubDomain: true,
  },

  {
    method: "put",
    path: path + "/api/admin/updateStory/:id",
    module: "story",
    alias: "admin.story.update",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllActiveStory",
    module: "story",
    alias: "admin.story.list.active",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllStory",
    module: "story",
    alias: "admin.story.list",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findOneStory/:id",
    module: "story",
    alias: "admin.story.id",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findOneStoryWithDetail/:id",
    module: "story",
    alias: "admin.story.details.id",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findOneStoryWithDetail/:id",
    module: "story",
    alias: "admin.story.id.detail",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findAllStoryByWorkId/:id",
    module: "story",
    alias: "admin.story.by.workId",
    isSubDomain: true,
  },
];
