import { request } from "../../../utils/services";

export const getAllActiveProfile = async () => {
  const { data } = await request("admin.profile.list.active");
  return data;
};

export const getAllProfile = async () => {
  const { data } = await request("admin.profile.list");
  return data;
};

export const getProfileById = async (id: string) => {
  const { data } = await request("admin.profile.id", { id });
  return data;
};

export const getBankByIFSC = async (ifsc: string) => {
  const data = await request("admin.profile.ifsc", { ifsc });
  return data;
};
