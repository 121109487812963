import { path } from "./path";

export const deploymentRoutes = [
  {
    method: "post",
    path: path + "/api/admin/createDeployment",
    module: "deployment",
    alias: "admin.deployment.create",
    isSubDomain: true,
  },

  {
    method: "put",
    path: path + "/api/admin/updateDeployment/:id",
    module: "deployment",
    alias: "admin.deployment.update",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllActiveDeployment",
    module: "deployment",
    alias: "admin.deployment.list.active",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllDeployment",
    module: "deployment",
    alias: "admin.deployment.list",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findOneDeployment/:id",
    module: "deployment",
    alias: "admin.deployment.id",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findOneDeploymentWithDetail/:id",
    module: "deployment",
    alias: "admin.deployment.id.detail",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findAllDeploymentByWorkId/:id",
    module: "deployment",
    alias: "admin.deployment.by.workId",
    isSubDomain: true,
  },
];
