import * as React from "react";
import { logo } from "src/constants/logo";
const UserDashboard: React.FC = () => {
  return (
    <>
      <img width="100%" src={logo} alt="R" />
    </>
  );
};
export default UserDashboard;
