import { path } from "./path";

export const jobRoutes = [
  {
    method: "post",
    path: path + "/api/admin/createJob",
    module: "job",
    alias: "admin.job.create",
    isSubDomain: true,
  },

  {
    method: "put",
    path: path + "/api/admin/updateJob/:id",
    module: "job",
    alias: "admin.job.update",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllActiveJob",
    module: "job",
    alias: "admin.job.list.active",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllJob",
    module: "job",
    alias: "admin.job.list",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findOneJob/:id",
    module: "job",
    alias: "admin.job.id",
    isSubDomain: true,
  },
];
