import * as React from "react";
import "./Layout.css";
import { Col, Layout, Menu, Row, Typography } from "antd";
import Icon, * as AntIcons from "@ant-design/icons";
import NavRouteLink from "../NavRouteLink";
import LocalStorageService from "src/utils/local-storage";
import LogoWhite from "../../assets/img/logoWhite.png";
import LogoShortWhite from "../../assets/img/logoShortWhite.png";
import ProfileCard from "../ProfileCard";
import DynamicIcon from "../dynamicIcon";
import { isMobile } from "react-device-detect";
import { useHistory, useLocation } from "react-router-dom";
import {
  AppOutline,
  UserOutline,
  UserContactOutline,
  UnorderedListOutline,
  BellOutline,
} from "antd-mobile-icons";
import { TabBar } from "antd-mobile";
const { SubMenu } = Menu;
const { Header, Sider, Content } = Layout;

const AuthLayout: React.FC<{ children?: React.ReactNode }> = (props) => {
  const [collapsed, setCollapsed] = React.useState(true);
  const [collection, setCollection] = React.useState<Object | any>(undefined);
  const [access, setAccess] = React.useState(LocalStorageService.getAccess());
  const toggle = () => {
    setCollapsed(!collapsed);
  };
  const history = useHistory();

  const location = useLocation();
  const { pathname } = location;
  //Important Use of Reduce Start
  const ArrangeParentChildCollection = (arr: any) => {
    var parentChildArray;
    arr?.reduce((acc: any, val: any, ind: any, array: any) => {
      const childs: any = [];
      array.forEach((el: any, i: any) => {
        if (
          childs.includes(el.collectionId.parentId) ||
          el.collectionId.parentId === val.collectionId._id
        ) {
          childs.push(el);
        }
      });

      parentChildArray = acc.concat({ ...val, childs });

      return acc.concat({ ...val, childs });
    }, []);
    return parentChildArray;
  };
  const { Text } = Typography;
  const setRouteActive = (value: string) => {
    history.push(value);
  };
  const tabs = [
    {
      key: "/",
      title: (
        <Text
          style={{
            color: pathname === "/" ? "black" : "#ffffff",
            fontWeight: pathname === "/" ? "bold" : "normal",
          }}
        >
          Dashboard
        </Text>
      ),
      icon: <AppOutline color={pathname === "/" ? "black" : "#ffffff"} />,
    },
    {
      key: "/party",
      title: (
        <Text
          style={{
            color: pathname === "/party" ? "black" : "#ffffff",
            fontWeight: pathname === "/party" ? "bold" : "normal",
          }}
        >
          Party
        </Text>
      ),
      icon: <UserOutline color={pathname === "/party" ? "black" : "#ffffff"} />,
    },
    {
      key: "/leads",
      title: (
        <Text
          style={{
            color: pathname === "/leads" ? "black" : "#ffffff",
            fontWeight: pathname === "/leads" ? "bold" : "normal",
          }}
        >
          Leads
        </Text>
      ),
      icon: (
        <UnorderedListOutline
          color={pathname === "/leads" ? "black" : "#ffffff"}
        />
      ),
    },
    {
      key: "/notification",
      title: (
        <Text
          style={{
            color: pathname === "/notification" ? "black" : "#ffffff",
            fontWeight: pathname === "/notification" ? "bold" : "normal",
          }}
        >
          Notifications
        </Text>
      ),
      icon: (
        <BellOutline
          color={pathname === "/notification" ? "black" : "#ffffff"}
        />
      ),
    },

    // {
    //   key: "/logout",
    //   title: "Gate Pass",
    //   icon: <FillinOutline />,
    // },
  ];

  React.useEffect(() => {
    // Run Arrange as per child stage
    var getChild: any = ArrangeParentChildCollection(access);
    var getChildofChild: any = ArrangeParentChildCollection(getChild);
    setCollection(getChildofChild);
  }, []);

  return (
    <>
      <Layout>
        {!isMobile && (
          <Sider trigger={null} collapsible collapsed={collapsed}>
            <div className="logo">
              {collapsed ? (
                <img
                  width={75}
                  // style={{
                  //   backgroundColor: "#fa07",
                  // }}
                  src={LogoShortWhite}
                  alt="Journey Starts Here"
                />
              ) : (
                <img
                  width={200}
                  // style={{
                  //   backgroundColor: "#fa07",
                  // }}
                  src={LogoWhite}
                  alt="Journey Starts Here"
                />
              )}
            </div>
            <Menu mode="vertical" style={{ height: "100vh" }}>
              <Menu.Item key="dashboard" icon={<AntIcons.PieChartOutlined />}>
                <NavRouteLink name="dashboard">Dashboard</NavRouteLink>
              </Menu.Item>
              {collection &&
                collection?.map((item: any, i: any) => {
                  // console.log(<DynamicIcon type={item.collectionId.icon} />);
                  return item.childs.length !== 0 &&
                    !item.collectionId.parentId &&
                    !item.accessType.includes("NoAccess") ? (
                    <SubMenu
                      key={item.collectionId.route}
                      icon={<DynamicIcon type={item.collectionId.icon} />}
                      title={item.collectionId.collectionTitle}
                    >
                      {item.childs &&
                        item.childs.map((info: any, i: any) => {
                          return info.childs.length !== 0 &&
                            !info.accessType.includes("NoAccess") ? (
                            <SubMenu
                              key={info.collectionId.route}
                              icon={
                                <DynamicIcon type={info.collectionId.icon} />
                              }
                              // icon={<AntIcons.UngroupOutlined />}
                              title={info.collectionId.collectionTitle}
                            >
                              {info.childs.map((element: any) => {
                                return (
                                  <>
                                    {!info.accessType.includes("NoAccess") && (
                                      <Menu.Item
                                        key={element.collectionId.route}
                                        icon={
                                          <DynamicIcon
                                            type={element.collectionId.icon}
                                          />
                                        }
                                      >
                                        <NavRouteLink
                                          name={element.collectionId.route}
                                        >
                                          {element.collectionId.collectionTitle}
                                        </NavRouteLink>
                                      </Menu.Item>
                                    )}
                                  </>
                                );
                              })}
                            </SubMenu>
                          ) : (
                            <>
                              {!info.accessType.includes("NoAccess") && (
                                <Menu.Item
                                  key={info.collectionId.route}
                                  icon={
                                    <DynamicIcon
                                      type={info.collectionId.icon}
                                    />
                                  }
                                >
                                  <NavRouteLink name={info.collectionId.route}>
                                    {info.collectionId.collectionTitle}
                                  </NavRouteLink>
                                </Menu.Item>
                              )}
                            </>
                          );
                        })}
                    </SubMenu>
                  ) : item.collectionId.parentId ? (
                    <></>
                  ) : (
                    <>
                      {!item.accessType.includes("NoAccess") && (
                        <Menu.Item
                          key={item.collectionId.route}
                          // icon={<AntIcons.PieChartOutlined />}
                          icon={<DynamicIcon type={item.collectionId.icon} />}
                        >
                          <NavRouteLink name={item.collectionId.route}>
                            {item.collectionId.collectionTitle}
                          </NavRouteLink>
                        </Menu.Item>
                      )}
                    </>
                  );
                })}

              <Menu.Item key="7" icon={<AntIcons.GlobalOutlined />}>
                <NavRouteLink name="dashboard">About Us</NavRouteLink>
              </Menu.Item>
            </Menu>
          </Sider>
        )}
        <Layout className="site-layout">
          <Header className="site-layout" style={{ padding: 0 }}>
            <Row justify="space-between">
              <Col>
                {React.createElement(
                  collapsed
                    ? AntIcons.MenuUnfoldOutlined
                    : AntIcons.MenuFoldOutlined,
                  {
                    className: "trigger",
                    onClick: toggle,
                  }
                )}
              </Col>

              <Col style={{ paddingRight: "2rem", fontWeight: "bold" }}>
                {/* <RouteLink name="logout">
                  <>
                    <AntIcons.LogoutOutlined />
                    Logout
                  </>
                </RouteLink> */}
                <ProfileCard />
              </Col>
            </Row>
          </Header>
          <Content
            // className={
            //   isMobile ? "site-mobile-background" : "site-layout-background "
            // }
            className="site-layout-background"
            style={{
              margin: isMobile ? "0" : "0",
              // padding: 24,
              padding: isMobile ? 0 : 24,

              height: "82vh",
              overflow: "auto",
            }}
          >
            {props.children}
          </Content>
          {isMobile && (
            <TabBar
              className="site-mobile-background"
              activeKey={pathname}
              onChange={(value) => setRouteActive(value)}
              style={{ backgroundColor: "darkcyan" }}
            >
              {tabs.map((item) => (
                <TabBar.Item
                  key={item.key}
                  icon={item.icon}
                  title={item.title}
                />
              ))}
            </TabBar>
          )}
        </Layout>
      </Layout>
    </>
  );
};

export default AuthLayout;
