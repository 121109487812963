import moment from "moment";
import * as React from "react";

import { useQuery } from "react-query";
import type { ColumnsType } from "antd/lib/table";

import { Space, Table, Tag, Typography, Image, Empty } from "antd";

import { getAllDailyStandup } from "../Requests";
import { getAccessAuthrorization } from "src/components/access";
import DailyStandupViewModal from "../Modal/ShowDailyStandupDetails";
import ManageDailyStandupModal from "../Modal/ManageDailyStandup";
import { s3Path } from "src/utils/s3Config/s3upload";

interface DataType {
  id: number;
  name: any;
}
type DataIndex = keyof DataType;
const DailyStandupList: React.FC = () => {
  const [page, setPage] = React.useState(1);
  const [size, setSize] = React.useState(100);
  const [searchText, setSearchText] = React.useState("");
  const [searchedColumn, setSearchedColumn] = React.useState("");
  let accessAuth = getAccessAuthrorization("dailyStandup");

  const {
    isLoading,
    isError,
    data: response,
  } = useQuery(["dailyStandup"], () => getAllDailyStandup(), {
    refetchOnWindowFocus: false,

    keepPreviousData: true,
    staleTime: 5000,
  });

  if (isLoading || isError) {
    return <div>Loading</div>;
  }
  const { Text } = Typography;
  const column: ColumnsType<DataType> = [
    {
      title: "ProblemFaced Image",
      dataIndex: "problemFacedUrl",
      key: "problemFacedUrl",
      render: (text: any) =>
        text ? (
          <Image width={100} height={100} src={s3Path + text} />
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ),
    },
    {
      title: "Project Name",
      dataIndex: "projectId",
      key: "projectId",
      render: (_: any, i: any) => (
        <Tag color="volcano-inverse">{i?.projectId?.projectName}</Tag>
      ),
    },
    {
      title: "Module Work On",
      dataIndex: "moduleWorkOn",
      key: "moduleWorkOn",
    },
    {
      title: "Problem Faced Detail",
      dataIndex: "problemFacedDetail",
      key: "problemFacedDetail",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: " Forward Problem (Team Name) ",
      dataIndex: "fowardProblemId",
      key: "fowardProblemId",
      render: (_: any, i: any) => (
        <Text code>{i?.fowardProblemId?.partyId.personName}</Text>
      ),
    },

    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (id: any) => (
        <Space size={10}>
          <DailyStandupViewModal id={id} />
          {/* <ManageDailyStandupModal id={id} /> */}
          {accessAuth[0].includes("Edit") && (
            <ManageDailyStandupModal id={id} />
          )}
        </Space>
      ),
    },
  ];

  return <Table size="small" columns={column} dataSource={response} />;
};

export default DailyStandupList;
