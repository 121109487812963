import * as React from "react";
import {
  Descriptions,
  Card,
  Divider,
  Row,
  Tag,
  Col,
  Typography,
  Space,
} from "antd";
import { useQuery } from "react-query";
import { getReportById } from "../Requests";
import Table, { ColumnsType } from "antd/es/table";
import moment from "moment";

const { Text } = Typography;
interface DataType {
  id: number;
  name: any;
}
type DataIndex = keyof DataType;
const ShowReportDetailsForm: React.FC<{ id: string }> = (props) => {
  const { data: report } = useQuery(
    ["report", props.id],
    async () => getReportById(props.id),
    {
      refetchOnWindowFocus: false,

      staleTime: 5000,
    }
  );
  const column: ColumnsType<DataType> = [
    {
      title: "Task",
      dataIndex: "task",
      key: "task",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    // {
    //   title: "What did Today?",
    //   dataIndex: "today",
    //   key: "today",
    //   render: (text) => (
    //     <>
    //       {text?.map((item: any, i: number) => {
    //         return (
    //           <Row>
    //             <Text code style={{ margin: 2 }} type="danger">
    //               {item?.task}{" "}
    //             </Text>
    //             <Text code style={{ margin: 2 }} type="danger">
    //               {item?.status}{" "}
    //             </Text>
    //           </Row>
    //         );
    //       })}
    //     </>
    //   ),
    // },
  ];
  return (
    <>
      <Divider dashed orientation="center">
        Report Detail of{" "}
        {report.name + "(" + moment(report.date).format("DD-MMM-YYYY") + ")"}
      </Divider>
      <Card title={"What did today?"}>
        <Table size="small" columns={column} dataSource={report.today} />
      </Card>
      <br />
      <Card title={"Plan For Tommorrow"}>
        <Descriptions.Item>
          <Row style={{ margin: "2" }}>
            {report?.tommorowPlan?.map((item: any, i: number) => (
              <>
                <Col>
                  <Text style={{ margin: 2, fontWeight: "normal" }}>
                    {i + 1 + "." + item}
                  </Text>
                </Col>
              </>
            ))}
          </Row>
        </Descriptions.Item>
      </Card>
      <br />
      <Card title={"Queries"}>
        <Descriptions.Item>
          <Row style={{ margin: "2" }}>
            {report?.queries?.map((item: any, i: number) => (
              <>
                <Col>
                  <Text style={{ margin: 2, fontWeight: "normal" }}>
                    {i + 1 + "." + item}
                  </Text>
                </Col>
              </>
            ))}
          </Row>
        </Descriptions.Item>
      </Card>
      <Card title={"Impeliments"}>
        <Descriptions.Item>
          <Row style={{ margin: "2" }}>
            {report?.impeliments?.map((item: any, i: number) => (
              <>
                <Col>
                  <Text style={{ margin: 2, fontWeight: "normal" }}>
                    {i + 1 + "." + item}
                  </Text>
                </Col>
              </>
            ))}
          </Row>
        </Descriptions.Item>
      </Card>
    </>
  );
};

export default ShowReportDetailsForm;
