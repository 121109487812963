import { path } from "./path";

export const bugReportRoutes = [
  {
    method: "post",
    path: path + "/api/admin/createBugReport",
    module: "bugReport",
    alias: "admin.bugReport.create",
    isSubDomain: true,
  },

  {
    method: "put",
    path: path + "/api/admin/updateBugReport/:id",
    module: "bugReport",
    alias: "admin.bugReport.update",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllActiveBugReport",
    module: "bugReport",
    alias: "admin.bugReport.list.active",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllBugReport",
    module: "bugReport",
    alias: "admin.bugReport.list",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findOneBugReport/:id",
    module: "bugReport",
    alias: "admin.bugReport.id",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findOneBugReportDetail/:id",
    module: "bugReport",
    alias: "admin.bugReport.id.detail",
    isSubDomain: true,
  },
];
