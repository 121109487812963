import * as React from "react";
import {
  Form,
  Button,
  message,
  Row,
  Typography,
  Col,
  Switch,
  Input,
  Divider,
  Select,
  DatePicker,
  Card,
} from "antd";
import { request } from "src/utils/services";
import { useMutation } from "react-query";
import {
  InfoCircleOutlined,
  PlusOutlined,
  LoadingOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { queryClient } from "src";
import { ManageStoryModalContext } from "../Modal/ManageStory";

import { useQuery } from "react-query";

import { getAllStory, getStoryById } from "../Requests";
import Upload, {
  RcFile,
  UploadChangeParam,
  UploadFile,
  UploadProps,
} from "antd/es/upload";
import LocalStorageService from "src/utils/local-storage";
import { deleteFileToAWS, uploadFileToAWS } from "src/utils/s3Config/s3upload";
import { Editor } from "@tinymce/tinymce-react";
import { tinyMCE } from "src/utils/keys";
import { getAllActiveWork, getWorkById } from "src/modules/Work/Requests";

const ManageStoryForm: React.FC<{ id: string }> = (props) => {
  const modalContext = React.useContext(ManageStoryModalContext);
  const [formLoading, setFormLoading] = React.useState(false);

  const [loading, setLoading] = React.useState(false);
  const [remarks, setRemarks] = React.useState<string>();
  const [imageUrl, setImageUrl] = React.useState<string[]>([]);
  const [selectedFile, setSelectedFile] = React.useState<RcFile>();
  const [workData, setWorkData] = React.useState<Object | any>({});

  const [editorkey, seteditorkey] = React.useState(4);
  const manageStoryMutation = useMutation(
    (values: any) => request("admin.story.update", { id: props.id }, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("story");
      },
    }
  );
  const {
    isLoading,
    data: story,
    error,
    isError,
  } = useQuery(["story", props.id], async () => getStoryById(props.id), {
    refetchOnWindowFocus: false,
  });
  const { data: work } = useQuery(["work"], async () => getAllActiveWork(), {
    refetchOnWindowFocus: false,
  });
  const { data: storylist } = useQuery(
    ["storylist", props.id],
    () => getAllStory(),
    {
      refetchOnWindowFocus: false,

      keepPreviousData: true,
      staleTime: 5000,
    }
  );

  const onFinish = async (values: any) => {
    values.updatedBy = LocalStorageService.getAccount().id;
    values.icon = imageUrl;
    values.description = remarks;
    setFormLoading(true);

    manageStoryMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The Story has been successfully managed.");
        form.resetFields();

        modalContext.setIsModalVisible(false);
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const handleEditorChange = (content: any, editor: any) => {
    // console.log("Content was updated:", content);
    setRemarks(content);
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const [form] = Form.useForm();

  const cleareditor = () => {
    const newkey = editorkey * 43;
    seteditorkey(newkey);
  };

  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}
      initialValues={story}
    >
      <Row gutter={24}>
        <Col flex={3} span={12}>
          <Form.Item
            label="Parent Story"
            name="storyId"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "storyId",
              icon: <InfoCircleOutlined />,
            }}
            hasFeedback
          >
            <Select placeholder="Please Select Story Parent ">
              {storylist &&
                storylist?.map((item: any, i: number) => {
                  return (
                    <Select.Option value={item?.id} key={i}>
                      {item?.title}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Col>
        <Col flex={3} span={12}>
          <Form.Item
            label="Project/Product/Other"
            name="workId"
            style={{
              fontWeight: "bold",
            }}
            tooltip={{
              title: "Project/Product/Other",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please Select Project/Product/Other",
              },
              { whitespace: true },
              { min: 3 },
            ]}
            hasFeedback
          >
            <Select
              showSearch
              popupMatchSelectWidth
              // style={{ minHeight: 150 }}
              placeholder="Select Product/Project"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option!.children as unknown as string).includes(input)
              }
            >
              {work &&
                work?.map((item: any, i: number) => {
                  return (
                    <Select.Option value={item?.id} key={i}>
                      {item?.title}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        label="Title"
        name="title"
        style={{
          fontWeight: "bold",
        }}
        tooltip={{
          title: "title",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: "Please Enter title",
          },
          { whitespace: true },
          { min: 3 },
        ]}
        hasFeedback
      >
        <Input placeholder="Enter Title" />
      </Form.Item>

      {/* <Form.Item
                  label="Description"
                  name="description"
                  style={{
                    fontWeight: "bold",
                  }}
                  tooltip={{
                    title: "description",
                    icon: <InfoCircleOutlined />,
                  }}
                  rules={[
                    {
                      required: true,
                      message: "Please Enter description",
                    },
                    { whitespace: true },
                    { min: 3 },
                  ]}
                  hasFeedback
                >
                  
                  <ReactQuill theme="snow" modules={modules} />
                </Form.Item> */}
      <Editor
        key={editorkey}
        apiKey={tinyMCE.apiKey}
        initialValue={story.description}
        // onInit={(evt, editor) => (editorRef.current = editor)}
        init={{
          plugins: tinyMCE.plugins,
          toolbar: tinyMCE.toolbar,
          tinycomments_mode: "embedded",
          tinycomments_author: "Author name",
          // mergetags_list: [
          //   { value: "First.Name", title: "First Name" },
          //   { value: "Email", title: "Email" },
          // ],

          paste_data_images: true,

          ai_request: (request: any, respondWith: any) =>
            respondWith.string(() =>
              Promise.reject("See docs to implement AI Assistant")
            ),
        }}
        // initialValue="Welcome to TinyMCE!"
        onEditorChange={handleEditorChange}
        // outputFormat="html"
      />
      <Row justify={"space-between"}>
        <Form.Item
          label="Attachment"
          style={{ fontWeight: "bold" }}
          name="attachment"
          valuePropName="checked"
        >
          <Upload
            accept="application/pdf,application/msword, image/png, image/jpeg, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            maxCount={4}
            multiple
            listType="picture"
            action={async (file) => {
              const response: any = await uploadFileToAWS(file, "task").catch(
                (err: any) => message.error(err?.toString())
              );
              setImageUrl([...imageUrl, response.key]);
              return response.key;
            }}
            beforeUpload={(fileList) => {
              console.log("FILE", fileList.size);
              return fileList.size / 1024 / 1024 <= 2 ? true : false;
            }}
            onRemove={(e) => {
              if (e?.error?.url) {
                deleteFileToAWS(e.error.url).then((value) =>
                  message.success(value)
                );
                var file = imageUrl.filter((item) => item !== e.error.url);
                setImageUrl(file);
              }
            }}
          >
            {imageUrl.length < 4 && (
              <Button icon={<UploadOutlined />}>Attachment</Button>
            )}
          </Upload>
        </Form.Item>
        <Form.Item
          label="Status"
          name="isActive"
          style={{ fontWeight: "bold" }}
          valuePropName="checked"
          required
        >
          <Switch
            checkedChildren="Active"
            unCheckedChildren="Inactive"
            defaultChecked
          />
        </Form.Item>
      </Row>

      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Add Story
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ManageStoryForm;
