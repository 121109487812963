import { Button, Space, Tag, message } from "antd";
import React from "react";
import {
  EyeTwoTone,
  EyeInvisibleOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import useCopyToClipboard from "./copytoClipboard";

const PwdTag: React.FC<{ password: string }> = ({ password }) => {
  const [visible, setVisible] = React.useState(false);
  const [copyToClipboard, copyResult] = useCopyToClipboard();
  const handleClickCopy = (text: string) => {
    message.success("Copied Successfully!");

    // Copy the text from the input field into the clipboard
    copyToClipboard(text);
  };
  return (
    <Space>
      <Tag
        color="purple"
        icon={
          visible ? (
            <EyeTwoTone onClick={() => setVisible(!visible)} />
          ) : (
            <EyeInvisibleOutlined onClick={() => setVisible(!visible)} />
          )
        }
      >
        Pwd: {visible ? password : "************"}
        <Button
          type="text"
          icon={<CopyOutlined />}
          onClick={() => handleClickCopy(password)}
        />
      </Tag>
    </Space>
  );
};

export default PwdTag;
