import { Avatar, Badge, Card, Row, Tag, Typography } from "antd";

import * as React from "react";

import { useQuery } from "react-query";
import type { ColumnsType } from "antd/lib/table";
import TaskManageModal from "../../Modal/ManageTask";

import { Space, Table } from "antd";

import { getAllTask } from "../../Requests";
import { getAccessAuthrorization } from "src/components/access";
import moment from "moment";
import TaskViewModal from "../../Modal/ShowTaskDetails";
import { s3Path } from "src/utils/s3Config/s3upload";
import imLogo from "src/assets/img/imlogo.png";

const { Text } = Typography;
const TaskList: React.FC = () => {
  const [page, setPage] = React.useState(1);
  const [size, setSize] = React.useState(100);
  const [searchText, setSearchText] = React.useState("");
  const [searchedColumn, setSearchedColumn] = React.useState("");
  let accessAuth = getAccessAuthrorization("task");

  const {
    isLoading,
    isError,
    data: response,
  } = useQuery(["tasklist"], () => getAllTask(), {
    refetchOnWindowFocus: false,

    keepPreviousData: true,
    staleTime: 5000,
  });

  if (isLoading || isError) {
    return <div>Loading</div>;
  }
  interface DataType {
    // id: number;
    name: any;
    route: any;
    parent: any;
    // status: any;
  }

  const column: ColumnsType<DataType> = [
    // {
    //   title: "Task ID",
    //   dataIndex: "id",
    //   key: "id",

    //   render: (text: any) => <p>PR/Dept/{text}</p>,
    // },
    {
      title: "Due Date",
      dataIndex: "dueDate",
      key: "dueDate",

      render: (text: any) => moment(text).format("DD-MMM-YYYY"),
    },
    {
      title: "Title ",
      dataIndex: "title",
      key: "title",
      width: "20%",
      render: (_: any, i: any) => (
        <Text style={{ fontWeight: "bold" }}>{i?.title}</Text>
        // <Badge.Ribbon text={i?.type}>
        //   <Card title={i?.title} size="small">
        //     {/* {i?.remarks} */}
        //   </Card>
        // </Badge.Ribbon>
      ),
    },

    {
      title: "Assigned Person",
      dataIndex: "assignedPersonId",
      key: "assignedPersonId",
      render: (_: any, i: any) => (
        <Space size={10}>
          <Avatar
            src={
              <img
                src={
                  i?.assignedPersonId?.partyId?.imageUrl
                    ? s3Path + i?.assignedPersonId?.partyId?.imageUrl
                    : imLogo
                }
                alt={i?.assignedPersonId?.partyId?.personName[0]}
              />
            }
          />
          <Tag>{i?.assignedPersonId?.partyId?.personName}</Tag>
        </Space>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => (
        <Tag
          color={
            text === "To Do"
              ? "orange"
              : text === "In Progress"
              ? "blue"
              : text === "On Hold"
              ? "volcano"
              : text === "In Review"
              ? "cyan"
              : "lime"
          }
        >
          {text}
        </Tag>
      ),
    },

    {
      title: "Action",
      dataIndex: "id",
      key: "id",

      render: (id: any) => (
        <Space size={10}>
          <TaskViewModal id={id} />
          {accessAuth[0].includes("Edit") && <TaskManageModal id={id} />}
        </Space>
      ),
    },
    {
      title: "Assigned By",
      dataIndex: "assignedPersonId",
      key: "assignedPersonId",
      render: (_: any, i: any) => (
        <Space size={10}>
          <Avatar
            src={
              <img
                src={
                  i?.createdBy?.partyId?.imageUrl
                    ? s3Path + i?.createdBy?.partyId?.imageUrl
                    : imLogo
                }
                alt={i?.createdBy?.partyId?.personName[0]}
              />
            }
          />
          <Tag>{i?.createdBy?.partyId?.personName}</Tag>
        </Space>
      ),
    },
  ];

  return <Table size="small" columns={column} dataSource={response} />;
};

export default TaskList;
