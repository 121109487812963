import { request } from "src/utils/services";

export const getAllActiveNotification = async () => {
  const { data } = await request("admin.notification.list.active");
  return data;
};

export const getAllNotification = async () => {
  const { data } = await request("admin.notification.list");
  return data;
};

export const getNotificationById = async (id: string) => {
  const { data } = await request("admin.notification.id", { id });
  return data;
};

export const getNotificationByPersonnel = async (id: string) => {
  const { data } = await request("admin.notification.personnel.id", { id });
  return data;
};

export const getNotificationByTask = async (id: string) => {
  const { data } = await request("admin.notification.task.id", { id });
  return data;
};

export const getNotificationByTag = async (id: string) => {
  const { data } = await request("admin.notification.tag.id", { id });
  return data;
};
