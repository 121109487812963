import { request } from "../../../../utils/services";

export const getAllActiveProject = async () => {
  const { data } = await request("admin.project.list.active");
  return data;
};

export const getAllProject = async () => {
  const { data } = await request("admin.project.list");
  return data;
};

export const getProjectById = async (id: string) => {
  const { data } = await request("admin.project.id", { id });
  return data;
};

export const getProjectByIddetail = async (id: string) => {
  const { data } = await request("admin.project.id.detail", { id });
  return data;
};
export const getProjectTimelineByProjectId = async (id: String) => {
  const { data } = await request("admin.project.timeline.id", { id });
  return data;
};
