import * as React from "react";
import {
  Form,
  Button,
  message,
  Row,
  Typography,
  Input,
  Col,
  Upload,
  Switch,
  Divider,
  Select,
  DatePicker,
  Card,
  Space,
  Tag,
  Empty,
  Image,
} from "antd";

import { request } from "../../../utils/services";
import { useMutation, useQuery } from "react-query";
import {
  InfoCircleOutlined,
  PlusOutlined,
  MinusCircleOutlined,
  UploadOutlined,
  LoadingOutlined,
  FileExclamationOutlined,
} from "@ant-design/icons";
import { queryClient } from "src";
import type { UploadChangeParam, UploadFile } from "antd/es/upload/interface";
import type { RcFile, UploadProps } from "antd/es/upload";

import TextArea from "antd/lib/input/TextArea";

import LocalStorageService from "src/utils/local-storage";
import { CreateTechnologyModalContext } from "../Modal/CreateTechnology";
import { getAllActiveParty } from "src/modules/Party/Requests";
import { s3Path, uploadFileToAWS } from "src/utils/s3Config/s3upload";
import { getAllTechnology } from "../Requests";
import ManageTechnologyModal from "../Modal/ManageTechnology";
import TechnologyViewModal from "../Modal/ShowTechnologyDetails";
import Table, { ColumnsType } from "antd/es/table";
interface DataType {
  id: number;
  name: any;
}
const { Text } = Typography;
const CreateTechnology: React.FC = () => {
  const modalContext = React.useContext(CreateTechnologyModalContext);
  const [formLoading, setFormLoading] = React.useState(false);
  const [galley, setGallery] = React.useState<string>();
  const [loading, setLoading] = React.useState(false);

  const [imageUrl, setImageUrl] = React.useState<string>();
  const [selectedFile, setSelectedFile] = React.useState<RcFile>();
  const createTechnologyMutation = useMutation(
    (values: any) => request("admin.technology.create", {}, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("technology");
      },
    }
  );
  // const { data: party } = useQuery(["party"], async () => getAllActiveParty(), {
  //   refetchOnWindowFocus: false,
  // });
  const {
    isLoading,
    isError,
    data: response,
  } = useQuery(["technology"], () => getAllTechnology(), {
    refetchOnWindowFocus: false,

    keepPreviousData: true,
    staleTime: 5000,
  });
  const getBase64 = (img: RcFile, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    // if (info.file.status === "uploading") {
    //   setLoading(true);
    //   return;
    // }
    setSelectedFile(info.file.originFileObj);
    if (info.file.status === "uploading") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj as RcFile, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
  };

  const onFinish = async (values: any) => {
    values.createdBy = LocalStorageService.getAccount().id;
    // values.partyId = party[values.partyId].id;
    values.icon = galley;

    setFormLoading(true);

    createTechnologyMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The Technology has been successfully created.");
        form.resetFields();
        modalContext.setIsModalVisible(false);
        setImageUrl(undefined);
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const [form] = Form.useForm();

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  const column: ColumnsType<DataType> = [
    {
      title: "Image",
      dataIndex: "icon",
      key: "icon",
      render: (text: any) =>
        text ? (
          <Image width={25} height={25} src={s3Path + text} />
        ) : (
          <Space.Compact direction="vertical">
            <FileExclamationOutlined
              style={{ marginLeft: 12, color: "GrayText" }}
            />
            <Typography.Text style={{ fontSize: 11, color: "GrayText" }}>
              No data
            </Typography.Text>
          </Space.Compact>

          // <Empty
          //   style={{ width: 25, height: 25 }}
          //   image={}
          // />
        ),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      render: (text: any) =>
        text ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">Inactive</Tag>
        ),
    },

    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (id: any) => (
        <Space size={10}>
          {/* <TechnologyViewModal id={id} /> */}
          <ManageTechnologyModal id={id} />
          {/* {accessAuth[0].includes("Edit") && <ManageTechnologyModal id={id} />} */}
        </Space>
      ),
    },
  ];
  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}
    >
      <Row gutter={22}>
        <Col flex={5} span={8}>
          <h3>Add Technology</h3>
          <Card style={{ backgroundColor: "whitesmoke" }} bordered hoverable>
            <Form.Item
              label="Type"
              name="type"
              style={{ fontWeight: "bold" }}
              tooltip={{
                title: "Type",
                icon: <InfoCircleOutlined />,
              }}
              rules={[
                {
                  required: true,
                  message: "Please Select Type",
                },
              ]}
              hasFeedback
            >
              <Select
                placeholder="Please Select Type"
                options={[
                  {
                    value: "UI/UX",
                    label: "UI/UX",
                  },
                  {
                    value: "Frontend",
                    label: "Frontend",
                  },
                  {
                    value: "Backend",
                    label: "Backend",
                  },
                  {
                    value: "Database",
                    label: "Database",
                  },
                  {
                    value: "Testing",
                    label: "Testing",
                  },
                  {
                    value: "Deployment",
                    label: "Deployment",
                  },
                ]}
              />
            </Form.Item>
            <Form.Item
              label="Title"
              name="title"
              style={{
                fontWeight: "bold",
              }}
              tooltip={{
                title: "Type",
                icon: <InfoCircleOutlined />,
              }}
              rules={[
                {
                  required: true,
                  message: "Please Select Type",
                },
                { whitespace: true },
                { min: 3 },
              ]}
              hasFeedback
            >
              <Input placeholder="Enter Title" />
            </Form.Item>

            <Row gutter={20}>
              <Col flex={2} span={12}>
                <Form.Item
                  label="Upload Icon"
                  style={{ fontWeight: "bold" }}
                  name="icon"
                  valuePropName="checked"
                >
                  <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    accept="image/png, image/jpeg"
                    action={async (file) => {
                      const response: any = await uploadFileToAWS(
                        file,
                        "technology"
                      );
                      setGallery(response.key);
                      return response.key;
                    }}
                    onChange={handleChange}
                  >
                    {imageUrl ? (
                      <img
                        src={imageUrl}
                        alt="avatar"
                        style={{ width: "100%" }}
                      />
                    ) : (
                      uploadButton
                    )}
                  </Upload>
                </Form.Item>
              </Col>
              <Col flex={2} span={12}>
                <Form.Item
                  label="Status"
                  style={{ fontWeight: "bold" }}
                  name="isActive"
                  tooltip={{
                    title: "status",
                    icon: <InfoCircleOutlined />,
                  }}
                  valuePropName="checked"
                  // rules={[{ required: true }]}
                >
                  <Switch
                    checkedChildren="Active"
                    unCheckedChildren="Inactive"
                    defaultChecked
                  />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item style={{ textAlign: "center" }}>
              <Button
                size="large"
                loading={formLoading}
                type="primary"
                htmlType="submit"
                shape="round"
              >
                Submit
              </Button>
            </Form.Item>
          </Card>
        </Col>
        <Col flex={5} span={16}>
          <h3>Technology List</h3>
          <Card style={{ backgroundColor: "whitesmoke" }} hoverable>
            <Table size="small" columns={column} dataSource={response} />
          </Card>
        </Col>
      </Row>
    </Form>
  );
};

export default CreateTechnology;
