import { path } from "./path";

export const blogRoutes = [
  {
    method: "post",
    path: path + "/api/admin/createBlog",
    module: "blog",
    alias: "admin.blog.create",
    isSubDomain: true,
  },

  {
    method: "put",
    path: path + "/api/admin/updateBlog/:id",
    module: "blog",
    alias: "admin.blog.update",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "//api/admin/findAllActiveBlog",
    module: "blog",
    alias: "admin.blog.list.active",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllBlog",
    module: "blog",
    alias: "admin.blog.list",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findOneBlog/:id",
    module: "blog",
    alias: "admin.blog.id",
    isSubDomain: true,
  },
];
