import { TRoute } from "../../utils/routes";
import JobPageHome from "./Page/Home";

const JobRouter: TRoute[] = [
  {
    path: "/job",
    name: "job",
    title: "job",
    component: JobPageHome,
  },
];

export default JobRouter;
