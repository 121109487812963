import moment from "moment";
import * as React from "react";

import { useQuery } from "react-query";
import type { ColumnsType } from "antd/lib/table";

import { Space, Table, Tag } from "antd";

import { getAllBugReport } from "../Requests";
import { getAccessAuthrorization } from "src/components/access";
import BugReportViewModal from "../Modal/ShowBugReportDetails";
import ManageBugReportModal from "../Modal/ManageBugReport";

interface DataType {
  id: number;
  name: any;
}
type DataIndex = keyof DataType;
const BugReportList: React.FC = () => {
  const [page, setPage] = React.useState(1);
  const [size, setSize] = React.useState(100);
  const [searchText, setSearchText] = React.useState("");
  const [searchedColumn, setSearchedColumn] = React.useState("");
  let accessAuth = getAccessAuthrorization("bugReport");

  const {
    isLoading,
    isError,
    data: response,
  } = useQuery(["bugReport"], () => getAllBugReport(), {
    refetchOnWindowFocus: false,

    keepPreviousData: true,
    staleTime: 5000,
  });

  if (isLoading || isError) {
    return <div>Loading</div>;
  }
  const column: ColumnsType<DataType> = [
    {
      title: "Project Name",
      dataIndex: "projectId",
      key: "projectId",
      render: (_: any, i: any) => (
        <Tag color="volcano-inverse">{i?.projectId?.projectName}</Tag>
      ),
    },
    {
      title: "Bug Detail",
      dataIndex: "bugDetail",
      key: "bugDetail",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },

    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (id: any) => (
        <Space size={10}>
          <BugReportViewModal id={id} />
          {/* <ManageBugReportModal id={id} /> */}
          {accessAuth[0].includes("Edit") && <ManageBugReportModal id={id} />}
        </Space>
      ),
    },
  ];

  return <Table size="small" columns={column} dataSource={response} />;
};

export default BugReportList;
