import NotFound from '../components/NotFound';

let routeLinks: TRoute[] = [{
    path: '/404',
    name: 'not_found',
    component: NotFound
}];

const routeContext = require.context('../modules', true, /Router\.tsx$/);
routeContext.keys().map((key: string) => {
    const routes = routeContext(key).default
    routes.forEach((route: any) => {
        if (route.children) {
            route.children = route.children.map((childItem: any) => {
                childItem.path = `${route.path.replace(/\/+$/, '')}/${childItem.path}`;
                return childItem
            });
        }
    })
    routeLinks = routeLinks.concat(routes);
    return key;
});

export interface TRoute {
    path: string,
    name?: string,
    title?: string,
    component: any,
    isAuth?: boolean,
    children?: Array<{
        path: string,
        title?: string,
        name: string,
        component: any
    }>
}

export const routeByAlias = (alias: string, parameters?: any): TRoute | undefined => {
    const availableRoute = routeLinks.filter(item => {
        if (item.children) {
            return item.children.find(childItem => childItem.name === alias)
        }
        return item.name === alias
    });
    if (!availableRoute[0]) {
        throw new Error(`Route with ${alias} alias not found.`)
    }
    const [routeLink] = !availableRoute[0].children ? availableRoute : availableRoute[0].children.filter(item => item.name === alias)
    let { path } = routeLink;

    if (parameters) {
        for (const param in parameters) {
            if (parameters.hasOwnProperty(param) && param) {
                if (!path.includes(param)) {
                    throw new Error(`Route parameter: ${param} is not allowed in route path: ${routeLink.path}`);
                }

                if (path.includes(param) && parameters.hasOwnProperty(param)) {
                    path = path.replace(`:${param}`, parameters[param]);
                }
            }
        }
    }

    return {
        ...routeLink,
        path
    }
};
export const routeByPath = (providedPath: string, parameters?: any): TRoute | undefined => {
    const availableRoute = routeLinks.filter(item => {
        if (item.children) {
            return item.children.find(childItem => childItem.path === providedPath)
        }
        return item.path === providedPath
    });
    if (!availableRoute[0]) {
        throw new Error(`Route with ${providedPath} alias not found.`);
    }
    const [routeLink] = !availableRoute[0].children
        ? availableRoute : availableRoute[0].children.filter(item => item.path === providedPath)
    let { path } = routeLink;

    if (parameters) {
        for (const param in parameters) {
            if (parameters.hasOwnProperty(param) && param) {
                if (!path.includes(param)) {
                    throw new Error(`Route parameter: ${param} is not allowed in route path: ${routeLink.path}`);
                }

                if (path.includes(param) && parameters.hasOwnProperty(param)) {
                    path = path.replace(`:${param}`, parameters[param]);
                }
            }
        }
    }

    return {
        ...routeLink,
        path
    }
};

export default routeLinks;
