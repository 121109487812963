import { TRoute } from "src/utils/routes";
import DepartmentPageHome from "./Page/Home";

const TaskTypeRouter: TRoute[] = [
  {
    path: "/taskType",
    name: "taskType",
    title: "taskType",
    component: DepartmentPageHome,
  },
];

export default TaskTypeRouter;
