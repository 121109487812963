import * as React from "react";
import {
  Form,
  Input,
  InputNumber,
  Button,
  message,
  Switch,
  Select,
  Col,
  Row,
  Checkbox,
  DatePicker,
  Card,
  Tag,
  Typography,
  Divider,
  Space,
} from "antd";
import parse from "html-react-parser";

import { request } from "../../../utils/services";
import { useMutation, useQuery } from "react-query";
import {
  ProfileOutlined,
  PhoneOutlined,
  InfoCircleOutlined,
  BankOutlined,
  HomeOutlined,
  MobileOutlined,
  GlobalOutlined,
  MailOutlined,
  AuditOutlined,
  UserOutlined,
  PushpinOutlined,
} from "@ant-design/icons";
import { queryClient } from "../../..";
import { CreateWorkFollowupModalContext } from "../Modal/CreateWorkFollowup";
import LocalStorageService from "../../../utils/local-storage";
import Title from "antd/es/skeleton/Title";
import moment from "moment";

import TextArea from "antd/es/input/TextArea";
import { values } from "lodash";
import { getWorkByIdDetail } from "../Requests";
import FollowUpListByWork from "../Component/FollowupListByWorkId";

const CreateWorkFollowupForm: React.FC<{ workId: string }> = (props) => {
  const modalContext = React.useContext(CreateWorkFollowupModalContext);
  const [formLoading, setFormLoading] = React.useState(false);

  const createWorkFollowupMutation = useMutation(
    (values: any) => request("admin.followup.create", {}, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("followup");
      },
    }
  );

  const { data: work } = useQuery(
    ["work", props.workId],
    async () => getWorkByIdDetail(props.workId),
    {
      refetchOnWindowFocus: false,

      staleTime: 5000,
    }
  );

  const onFinish = async (values: any) => {
    setFormLoading(true);
    values.createdBy = LocalStorageService.getAccount().id;

    values.workId = props.workId;
    values.content = `${values.followupPurpose} to ${values.concernedPersonId}`;
    values.description = values.clientRemarks;
    createWorkFollowupMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The followup has been successfully created.");
        // modalContext.setIsModalVisible(false);
        form.resetFields();
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const [form] = Form.useForm();
  const { Text, Title } = Typography;
  // console.log(lead[0]?.partyId[0].concernedpersons[0]);
  // React.useEffect(() => {}, []);
  // var date = new Date().getDate();
  // var mon = new Date().getMonth() + 1;
  // var year = new Date().getFullYear();
  // form.setFieldsValue({
  //   followupDate: moment(`${date + "/" + mon + "/" + year}`, "DD-MM-YYYY"),
  // });

  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}
    >
      <Card>
        <Row gutter={20}>
          <Col flex={5} span={12}>
            <Space.Compact direction="vertical">
              <Title level={5}>
                {work[0]?.title}
                {/* Enquiry Date {moment(work[0].enquiryDate).format(
                  "DD-MMM-YYYY"
                )}{" "} */}
                <Button size="small" danger>
                  {work[0].workStatus}
                </Button>
              </Title>
              <Row>
                <Text code style={{ color: "darkmagenta" }}>
                  {moment(work[0].startDate).format("DD-MMM-YYYY")}
                </Text>{" "}
                to{" "}
                <Text code style={{ color: "darkmagenta" }}>
                  {moment(work[0].endDate).format("DD-MMM-YYYY")}
                </Text>
              </Row>
            </Space.Compact>
            {/* <h3>Work Detail of {work[0].partyId[0]?.personName}</h3> */}
            <Card hoverable>
              <Divider orientation="left" plain>
                Party Info
              </Divider>
              <Space>
                <Space.Compact direction="vertical">
                  <Text style={{ fontWeight: "bold" }}>
                    <UserOutlined /> {work[0].partyId[0]?.personName}
                    <Text type="danger" code>
                      {work[0].partyId[0]?.partyType}
                    </Text>
                  </Text>
                  <Text>
                    <HomeOutlined /> {work[0].partyId[0]?.companyName}
                  </Text>
                  <Text
                    style={{
                      fontSize: 11.5,
                    }}
                  >
                    {work[0].partyId[0]?.address}
                  </Text>
                  <Text
                    style={{
                      fontSize: 11.5,
                    }}
                  >
                    <PhoneOutlined />
                    +91-{work[0].partyId[0]?.phoneNo}, <MailOutlined />{" "}
                    {work[0].partyId[0]?.email}
                  </Text>
                  <Text
                    style={{
                      fontSize: 11.5,
                      fontStyle: "italic",
                    }}
                  >
                    <GlobalOutlined /> {work[0].partyId[0]?.website}
                  </Text>
                  <Text
                    style={{
                      fontSize: 11.5,
                    }}
                  >
                    <ProfileOutlined /> {work[0].partyId[0]?.gstInNo}
                  </Text>
                  <Divider orientation="left" plain>
                    Work Description
                  </Divider>
                  {work[0].description && parse(work[0].description)}
                </Space.Compact>
              </Space>
            </Card>
          </Col>
          <Col flex={5} span={12}>
            <h4>Add Followup</h4>
            <Card hoverable>
              <Form.Item
                label=" Followup Date"
                name="followupDate"
                style={{ fontWeight: "bold" }}
                tooltip={{
                  title: "followupDate",
                  icon: <InfoCircleOutlined />,
                }}
                rules={[
                  {
                    required: true,
                    message: "Please Select Date",
                  },
                ]}
              >
                <DatePicker showToday format={"DD-MM-YYYY"} />
              </Form.Item>

              <Form.Item
                label="Followup Purpose"
                style={{ fontWeight: "bold" }}
                name="followupPurpose"
                rules={[
                  {
                    required: true,
                    message: "Please Select FollowUp Purpose",
                  },
                ]}
                // valuePropName="checked"
              >
                <Select
                  placeholder="Select Purpose"
                  options={[
                    { value: "Introduction", label: "Introduction" },
                    { value: "Branding", label: "Branding" },
                    { value: "Pitching", label: "Pitching" },
                    { value: "Requirement", label: "Requirement" },
                    { value: "Proposal", label: "Proposal" },
                    { value: "Discussion", label: "Discussion" },
                    { value: "Negotiation", label: "Negotiation" },
                    { value: "Meeting", label: "Meeting" },
                    { value: "Finalisation", label: "Finalisation" },
                  ]}
                />
              </Form.Item>
              <Form.Item
                label="Concerned Person"
                name="concernedPersonId"
                style={{ fontWeight: "bold" }}
                tooltip={{
                  title: "concernedPerson",
                  icon: <InfoCircleOutlined />,
                }}
                rules={[
                  {
                    required: true,
                    message: "Please Select Concern Person",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select Concerned Person"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option!.children as unknown as string).includes(input)
                  }
                >
                  {work &&
                    work[0].partyId[0]?.concernedpersons?.map(
                      (item: any, i: number) => {
                        return (
                          <Select.Option value={item._id} key={i}>
                            {item.name}
                          </Select.Option>
                        );
                      }
                    )}
                </Select>
              </Form.Item>
              {/* </Row> */}
              <Form.Item
                label="Client Remarks"
                name="clientRemarks"
                style={{ fontWeight: "bold" }}
                tooltip={{
                  title: "clientRemarks",
                  icon: <InfoCircleOutlined />,
                }}
                rules={[
                  {
                    required: true,
                    message: "Please Enter Remarks",
                  },
                ]}
              >
                <TextArea placeholder="Enter Client Remarks" />
              </Form.Item>

              <Form.Item
                label="Next Followup Date"
                name="nextFollowupDate"
                style={{ fontWeight: "bold" }}
                tooltip={{
                  title: "nextFollowupDate",
                  icon: <InfoCircleOutlined />,
                }}
                rules={[
                  {
                    required: true,
                    message: "Please Select Date",
                  },
                ]}
              >
                <DatePicker />
              </Form.Item>

              <Form.Item style={{ textAlign: "center" }}>
                <Button
                  size="large"
                  loading={formLoading}
                  type="primary"
                  htmlType="submit"
                  shape="round"
                >
                  Submit
                </Button>
              </Form.Item>
            </Card>
          </Col>
        </Row>
      </Card>
      <br />
      <FollowUpListByWork id={props.workId} />
    </Form>
  );
};

export default CreateWorkFollowupForm;
