import { path } from "./path";

export const projectRoutes = [
  {
    method: "post",
    path: path + "/api/admin/createProject",
    module: "project",
    alias: "admin.project.create",
    isSubDomain: true,
  },

  {
    method: "put",
    path: path + "/api/admin/updateProject/:id",
    module: "project",
    alias: "admin.project.update",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllActiveProject",
    module: "project",
    alias: "admin.project.list.active",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllProject",
    module: "project",
    alias: "admin.project.list",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findOneProject/:id",
    module: "project",
    alias: "admin.project.id",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findOneProjectDetail/:id",
    module: "project",
    alias: "admin.project.id.detail",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findProjectTimelineByProjectId/:id",
    module: "project",
    alias: "admin.project.timeline.id",
  },
];
