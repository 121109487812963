import { TRoute } from "../../utils/routes";
import NotificationPageHome from "./Page/Home";

const NotificationRouter: TRoute[] = [
  {
    path: "/notification",
    name: "notification",
    title: "notification",
    component: NotificationPageHome,
  },
];

export default NotificationRouter;
