import { TRoute } from "src/utils/routes";
import DepartmentPageHome from "./Page/Home";

const BugReportRouter: TRoute[] = [
  {
    path: "/bugReport",
    name: "bugReport",
    title: "bugReport",
    component: DepartmentPageHome,
  },
];

export default BugReportRouter;
