import { path } from "./path";

export const taskTypeRoutes = [
  {
    method: "post",
    path: path + "/api/admin/createTaskType",
    module: "taskType",
    alias: "admin.taskType.create",
    isSubDomain: true,
  },

  {
    method: "put",
    path: path + "/api/admin/updateTaskType/:id",
    module: "taskType",
    alias: "admin.taskType.update",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllActiveTaskType",
    module: "taskType",
    alias: "admin.taskType.list.active",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllTaskType",
    module: "taskType",
    alias: "admin.taskType.list",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findOneTaskType/:id",
    module: "taskType",
    alias: "admin.taskType.id",
    isSubDomain: true,
  },
];
