import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const localesContext = require.context(
  "../modules",
  true,
  /Locales\/i18n\.ts$/
);
export const resources: { [key: string]: { translation: object } } = {
  en: {
    translation: {},
  },
};

localesContext.keys().map((module: string) => {
  const translation = localesContext(module).default;
  for (const key in resources) {
    if (translation.hasOwnProperty(key)) {
      resources[key].translation = {
        ...resources[key].translation,
        ...{
          [module
            .replace("./", "")
            .replace("/Locales/i18n.ts", "")
            .toLowerCase()]: translation[key],
        },
      };
    }
  }
  return module;
});

// console.log(resources)
i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
