import { path } from "./path";

export const testimonialRoutes = [
  {
    method: "post",
    path: path + "/api/admin/createTestimonial",
    module: "testimonial",
    alias: "admin.testimonial.create",
    isSubDomain: true,
  },

  {
    method: "put",
    path: path + "/api/admin/updateTestimonial/:id",
    module: "testimonial",
    alias: "admin.testimonial.update",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllActiveTestimonial",
    module: "testimonial",
    alias: "admin.testimonial.list.active",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllTestimonial",
    module: "testimonial",
    alias: "admin.testimonial.list",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findOneTestimonial/:id",
    module: "testimonial",
    alias: "admin.testimonial.id",
    isSubDomain: true,
  },
];
