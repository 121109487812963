import * as React from "react";
import {
  Form,
  Button,
  message,
  Row,
  Typography,
  Input,
  Col,
  Upload,
  Switch,
  Divider,
  Select,
  Avatar,
  DatePicker,
  InputNumber,
  Space,
  Card,
} from "antd";

import { request } from "../../../utils/services";
import { useMutation, useQuery } from "react-query";
import {
  InfoCircleOutlined,
  PlusOutlined,
  LoadingOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import { queryClient } from "src";
import type { UploadChangeParam, UploadFile } from "antd/es/upload/interface";
import type { RcFile, UploadProps } from "antd/es/upload";

import TextArea from "antd/lib/input/TextArea";

import LocalStorageService from "src/utils/local-storage";
import { CreateProposalModalContext } from "../Modal/CreateProposal";
import { s3Path, uploadFileToAWS } from "src/utils/s3Config/s3upload";
import CreatePartyModal from "src/modules/Party/Modal/CreateParty";
import {
  getAllActiveParty,
  getAllConcernedPerson,
  getAllConcernedPersonByPartyId,
} from "src/modules/Party/Requests";
import { imLogoIcon } from "src/constants/logo";
import CreateConcernedPersonModal from "src/modules/Party/Modal/concernedPerson";
import { getAllLead } from "src/modules/Leads/Requests";
import CreateLeadsModal from "src/modules/Leads/Modal/CreateLeads";
import { Editor } from "@tinymce/tinymce-react";
import { tinyMCE } from "src/utils/keys";
import { getAllTechnology } from "src/modules/technology/Requests";

const { Text, Title } = Typography;
const CreateProposal: React.FC = () => {
  const modalContext = React.useContext(CreateProposalModalContext);
  const [formLoading, setFormLoading] = React.useState(false);

  const [editorkey, seteditorkey] = React.useState(4);
  const [cover, setCover] = React.useState<string>();
  const [scope, setScope] = React.useState<string>();

  const [totalAmount, setTotalAmount] = React.useState(0);

  const createProposalMutation = useMutation(
    (values: any) => request("admin.proposal.create", {}, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("proposal");
      },
    }
  );

  const { data: party } = useQuery(["party"], async () => getAllActiveParty(), {
    refetchOnWindowFocus: false,
  });

  const { data: lead } = useQuery(["lead"], async () => getAllLead(), {
    refetchOnWindowFocus: false,
  });
  const { data: technology } = useQuery(
    ["technology"],
    async () => getAllTechnology(),
    {
      refetchOnWindowFocus: false,
    }
  );
  const onFinish = async (values: any) => {
    values.createdBy = LocalStorageService.getAccount().id;
    values.scope = scope;
    values.cover = cover;

    setFormLoading(true);

    createProposalMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The Proposal has been successfully created.");
        form.resetFields();
        cleareditor();
        modalContext.setIsModalVisible(false);
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const [form] = Form.useForm();

  let clientList = party.filter(
    (element: any) => element.partyType[0] === "Client"
  );
  let vendorList = party.filter(
    (element: any) => element.partyType[0] === "Vendor"
  );
  let leadList = party.filter(
    (element: any) => element.partyType[0] === "Lead Provider"
  );

  let partyList = [...clientList, ...vendorList, ...leadList];

  const handleEditorChange = (content: any, editor: any) => {
    // console.log("Content was updated:", content);
    setCover(content);
  };
  const handleEditorChange2 = (content: any, editor: any) => {
    // console.log("Content was updated:", content);

    setScope(content);
  };

  const cleareditor = () => {
    const newkey = editorkey * 43;
    seteditorkey(newkey);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      // onValuesChange={handleTotal}
    >
      <Form.Item
        label="Title"
        style={{ fontWeight: "bold" }}
        name="title"
        tooltip={{
          title: "title",
          icon: <InfoCircleOutlined />,
        }}
        hasFeedback
      >
        <Input placeholder="Enter Title" />
      </Form.Item>

      <Form.Item
        label=" Select Party"
        name="partyId"
        // style={{ fontWeight: "bold" }}
        tooltip={{
          title: "party",
          icon: <InfoCircleOutlined />,
        }}
      >
        <Select
          //onChange={onPartySelect}
          showSearch
          placeholder="Select party"
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option!.children as unknown as string).includes(input)
          }
          dropdownRender={(menu) => (
            <>
              <Row justify={"end"}>
                <CreatePartyModal />
              </Row>
              {menu}
            </>
          )}
        >
          {partyList &&
            partyList?.map((item: any, i: number) => {
              return (
                <Select.Option value={item.id} key={i}>
                  <Avatar
                    src={
                      <img
                        src={
                          item.imageUrl ? s3Path + item.imageUrl : imLogoIcon
                        }
                        alt={item?.personName[0]}
                      />
                    }
                  />
                  <Typography.Text strong>
                    {item?.personName}-{item?.companyName}
                  </Typography.Text>
                </Select.Option>
              );
            })}
        </Select>
      </Form.Item>
      <Form.Item
        label="Select lead"
        name="leadId"
        // style={{ fontWeight: "bold" }}
        tooltip={{
          title: "lead",
          icon: <InfoCircleOutlined />,
        }}
      >
        <Select
          //onChange={onPartySelect}
          showSearch
          placeholder="Select lead"
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option!.children as unknown as string).includes(input)
          }
          dropdownRender={(menu) => (
            <>
              <Row justify={"end"}>
                <CreateLeadsModal />
              </Row>
              {menu}
            </>
          )}
        >
          {lead &&
            lead?.map((item: any, i: number) => {
              return (
                <Select.Option value={item.id} key={i}>
                  <Typography.Text strong>
                    {item?.seq}. {item?.title}
                  </Typography.Text>
                </Select.Option>
              );
            })}
        </Select>
      </Form.Item>
      <Form.Item
        label="Select Technology"
        name="technologyId"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "technology",
          icon: <InfoCircleOutlined />,
        }}
        hasFeedback
      >
        <Select mode="multiple" placeholder="Please Select technology">
          {technology &&
            technology?.map((item: any, i: number) => {
              return (
                <Select.Option value={item?.id} key={i}>
                  {item?.title}
                </Select.Option>
              );
            })}
        </Select>
      </Form.Item>
      <h3>Add Cover</h3>
      <Editor
        key={editorkey}
        apiKey={tinyMCE.apiKey}
        // onInit={(evt, editor) => (editorRef.current = editor)}
        init={{
          plugins: tinyMCE.plugins,
          toolbar: tinyMCE.toolbar,
          tinycomments_mode: "embedded",
          tinycomments_author: "Author name",
          // mergetags_list: [
          //   { value: "First.Name", title: "First Name" },
          //   { value: "Email", title: "Email" },
          // ],

          paste_data_images: true,

          ai_request: (request: any, respondWith: any) =>
            respondWith.string(() =>
              Promise.reject("See docs to implement AI Assistant")
            ),
        }}
        // initialValue="Welcome to TinyMCE!"
        onEditorChange={handleEditorChange}
        // outputFormat="html"
      />
      <h3>Add Scope</h3>

      <Editor
        key={editorkey}
        apiKey={tinyMCE.apiKey}
        // onInit={(evt, editor) => (editorRef.current = editor)}
        init={{
          plugins: tinyMCE.plugins,
          toolbar: tinyMCE.toolbar,
          tinycomments_mode: "embedded",
          tinycomments_author: "Author name",
          // mergetags_list: [
          //   { value: "First.Name", title: "First Name" },
          //   { value: "Email", title: "Email" },
          // ],

          paste_data_images: true,

          ai_request: (request: any, respondWith: any) =>
            respondWith.string(() =>
              Promise.reject("See docs to implement AI Assistant")
            ),
        }}
        // initialValue="Welcome to TinyMCE!"
        onEditorChange={handleEditorChange2}
        // outputFormat="html"
      />

      <br />
      {/* <Card> */}
      {/* {" "}
        <Space.Compact>
          <Input
            style={{ fontWeight: "bold", width: "10%" }}
            disabled
            placeholder="Sno."
          />
          <Input
            style={{ fontWeight: "bold", width: "70%" }}
            disabled
            placeholder="Particular"
          />

          <Input
            style={{ fontWeight: "bold", width: "50%" }}
            disabled
            placeholder="Amount"
          />
          <Input
            style={{ fontWeight: "bold", width: "2%" }}
            disabled
            placeholder=""
          />
        </Space.Compact> */}

      <Form.List name="charges">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field: any, index: number) => (
              <Row>
                <Col span={1}>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.area !== curValues.area ||
                      prevValues.sights !== curValues.sights
                    }
                  >
                    {() => (
                      <Form.Item
                        {...field}
                        initialValue={index + 1}
                        style={{ marginBottom: 0 }}
                        name={[field.name, "sno"]}
                      >
                        <Input placeholder="Sno. " disabled />
                      </Form.Item>
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.area !== curValues.area ||
                      prevValues.sights !== curValues.sights
                    }
                  >
                    {() => (
                      <Form.Item
                        {...field}
                        name={[field.name, "particular"]}
                        style={{ marginBottom: 0 }}
                      >
                        <Input placeholder="Enter Paticular" />
                      </Form.Item>
                    )}
                  </Form.Item>
                </Col>
                <Col span={10}>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.area !== curValues.area ||
                      prevValues.sights !== curValues.sights
                    }
                  >
                    {() => (
                      <Form.Item
                        {...field}
                        name={[field.name, "amount"]}
                        style={{ marginBottom: 0 }}
                      >
                        <Input placeholder="Amount " />
                      </Form.Item>
                    )}
                  </Form.Item>
                </Col>
                <Col span={1}>
                  <MinusCircleOutlined onClick={() => remove(field.name)} />
                </Col>
              </Row>
            ))}

            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                Add Charges
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>

      <Form.List name="paymentTerms">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field: any, index: number) => (
              <Row>
                <Col span={1}>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.area !== curValues.area ||
                      prevValues.sights !== curValues.sights
                    }
                  >
                    {() => (
                      <Form.Item
                        {...field}
                        initialValue={index + 1}
                        style={{ marginBottom: 0 }}
                        name={[field.name, "sno"]}
                      >
                        <Input placeholder="Sno. " disabled />
                      </Form.Item>
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.area !== curValues.area ||
                      prevValues.sights !== curValues.sights
                    }
                  >
                    {() => (
                      <Form.Item
                        {...field}
                        name={[field.name, "milestone"]}
                        style={{ marginBottom: 0 }}
                      >
                        <Input placeholder="Enter milestone" />
                      </Form.Item>
                    )}
                  </Form.Item>
                </Col>
                <Col span={10}>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.area !== curValues.area ||
                      prevValues.sights !== curValues.sights
                    }
                  >
                    {() => (
                      <Form.Item
                        {...field}
                        name={[field.name, "payment"]}
                        style={{ marginBottom: 0 }}
                      >
                        <Input placeholder="payment " />
                      </Form.Item>
                    )}
                  </Form.Item>
                </Col>

                <Col span={1}>
                  <MinusCircleOutlined onClick={() => remove(field.name)} />
                </Col>
              </Row>
            ))}

            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                Add Payment Terms
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>

      <Form.List name="timeline">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field: any, index: number) => (
              <Row>
                <Col span={1}>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.area !== curValues.area ||
                      prevValues.sights !== curValues.sights
                    }
                  >
                    {() => (
                      <Form.Item
                        {...field}
                        initialValue={index + 1}
                        style={{ marginBottom: 0 }}
                        name={[field.name, "sno"]}
                      >
                        <Input placeholder="Sno. " disabled />
                      </Form.Item>
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.area !== curValues.area ||
                      prevValues.sights !== curValues.sights
                    }
                  >
                    {() => (
                      <Form.Item
                        {...field}
                        name={[field.name, "scopeTitle"]}
                        style={{ marginBottom: 0 }}
                      >
                        <Input placeholder="Enter Scope Title" />
                      </Form.Item>
                    )}
                  </Form.Item>
                </Col>
                <Col flex={10}>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.area !== curValues.area ||
                      prevValues.sights !== curValues.sights
                    }
                  >
                    {() => (
                      <Form.Item
                        {...field}
                        name={[field.name, "duration"]}
                        style={{ marginBottom: 0 }}
                      >
                        <Input placeholder="Duration " />
                      </Form.Item>
                    )}
                  </Form.Item>
                </Col>
                <Col span={1}>
                  <MinusCircleOutlined onClick={() => remove(field.name)} />
                </Col>
              </Row>
            ))}

            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                Add Project Timeline
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
      {/* </Card> */}

      {/* <h3>Personnel List</h3> */}

      {/* <Row justify="space-between">
            <Form.Item
              label="Reverse Charge"
              style={{ fontWeight: "bold" }}
              name="reverseCharge"
              tooltip={{
                title: "reverseCharge",
                icon: <InfoCircleOutlined />,
              }}
              valuePropName="checked"
              // rules={[{ required: true }]}
            >
              <Switch
                checkedChildren="Yes"
                unCheckedChildren="No"
                defaultChecked
              />
            </Form.Item>
            <Form.Item
              label="Is IGST"
              style={{ fontWeight: "bold" }}
              name="isIGST"
              tooltip={{
                title: "isIGST",
                icon: <InfoCircleOutlined />,
              }}
              valuePropName="checked"
              // rules={[{ required: true }]}
            >
              <Switch
                checkedChildren="Yes"
                unCheckedChildren="No"
                defaultChecked
              />
            </Form.Item>
          </Row> */}
      <Form.Item
        label="Scope of AMC"
        style={{ fontWeight: "bold" }}
        name="amc"
        tooltip={{
          title: "amc",
          icon: <InfoCircleOutlined />,
        }}
        hasFeedback
      >
        <TextArea placeholder="Enter Scope of AMC" maxLength={150} />
      </Form.Item>

      <Form.Item
        label="Terms & Condition"
        style={{ fontWeight: "bold" }}
        name="termsCondition"
        tooltip={{
          title: "termsCondition",
          icon: <InfoCircleOutlined />,
        }}
        hasFeedback
      >
        <TextArea placeholder="Enter Terms & Condition" maxLength={150} />
      </Form.Item>
      <Form.Item
        label="Remarks"
        style={{ fontWeight: "bold" }}
        name="remarks"
        tooltip={{
          title: "remarks",
          icon: <InfoCircleOutlined />,
        }}
        hasFeedback
      >
        <TextArea placeholder="Enter Terms & Condition" maxLength={150} />
      </Form.Item>
      <Form.Item
        label="Status"
        style={{ fontWeight: "bold" }}
        name="isActive"
        tooltip={{
          title: "status",
          icon: <InfoCircleOutlined />,
        }}
        valuePropName="checked"
        // rules={[{ required: true }]}
      >
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          defaultChecked
        />
      </Form.Item>

      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreateProposal;
