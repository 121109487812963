import moment from "moment";
import * as React from "react";

import { useQuery } from "react-query";
import type { ColumnsType } from "antd/lib/table";

import { Space, Table, Tag, Image, Empty } from "antd";

import { getAllBlog } from "../Requests";

import BlogViewModal from "../Modal/ShowBlogDetails";
import ManageBlogModal from "../Modal/ManageBlog";

import { getAccessAuthrorization } from "src/components/access";
import { s3Path } from "src/utils/s3Config/s3upload";

interface DataType {
  id: number;
  name: any;
}
type DataIndex = keyof DataType;
const BlogList: React.FC = () => {
  const [page, setPage] = React.useState(1);
  const [size, setSize] = React.useState(100);
  const [searchText, setSearchText] = React.useState("");
  const [searchedColumn, setSearchedColumn] = React.useState("");
  let accessAuth = getAccessAuthrorization("blog");

  const {
    isLoading,
    isError,
    data: response,
  } = useQuery(["blog"], () => getAllBlog(), {
    refetchOnWindowFocus: false,

    keepPreviousData: true,
    staleTime: 5000,
  });
  // const { data: blogCategory } = useQuery(
  //   ["blogCategory"],
  //   async () => getAllblogCategories(),
  //   {
  //     refetchOnWindowFocus: false,
  //   }
  // );

  if (isLoading || isError) {
    return <div>Loading</div>;
  }
  const column: ColumnsType<DataType> = [
    {
      title: "Image",
      dataIndex: "fileUrl",
      key: "fileUrl",
      render: (text: any) =>
        text ? (
          <Image width={100} height={100} src={s3Path + text} />
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ),
    },
    {
      title: " Title",
      dataIndex: "title",
      key: "title",
    },
    // {
    //   title: "Category",
    //   dataIndex: "blogCatogiriesId",
    //   key: "blogCatogiriesId",
    //   render: (text: any) => <p>{text.title}</p>,
    // },
    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      render: (text: any) =>
        text ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">Inactive</Tag>
        ),
    },

    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (id: any) => (
        <Space size={10}>
          <BlogViewModal id={id} />
          {/* <ManageBlogModal id={id} /> */}
          {accessAuth[0].includes("Edit") && <ManageBlogModal id={id} />}
        </Space>
      ),
    },
  ];

  return <Table size="small" columns={column} dataSource={response} />;
};

export default BlogList;
