import * as React from "react";

import { Modal, Button, FloatButton } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import CreateStory from "../Form/CreateStoryForm";
import { FlowFlags } from "typescript";

export const CreateStoryModalContext = React.createContext<{
  setIsModalVisible: (active: boolean) => void;
}>({
  setIsModalVisible: () => true,
});

const CreateStoryModal: React.FC<{ id?: string }> = ({ id }) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <CreateStoryModalContext.Provider value={{ setIsModalVisible }}>
      <Button
        size="small"
        onClick={showModal}
        type="primary"
        shape="round"
        style={{
          fontSize: 12,
          backgroundColor: "darkcyan",
        }}
        icon={<PlusOutlined />}
        // style={{
        //   backgroundColor: "darkolivegreen",
        // }}
      >
        Add Story
      </Button>

      {/* </Button> */}
      <Modal
        title="Generate Story for Work"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        centered
        width={"100%"}
      >
        <CreateStory id={id} />
      </Modal>
    </CreateStoryModalContext.Provider>
  );
};

export default CreateStoryModal;
