import {
  Avatar,
  Badge,
  Card,
  Popover,
  Row,
  Typography,
  Button,
  Space,
  Divider,
  Tag,
  Col,
  Modal,
  Drawer,
  message,
} from "antd";
import * as React from "react";
import {
  LogoutOutlined,
  NotificationOutlined,
  ReadOutlined,
  LockOutlined,
  UserOutlined,
} from "@ant-design/icons";
import LocalStorageService from "src/utils/local-storage";
import { s3Path } from "src/utils/s3Config/s3upload";
import { Logout } from "./Logout";
import { Redirect } from "react-router-dom";
import RouteLink from "./RouteLink";
import { useMutation, useQuery } from "react-query";
import { getNotificationByPersonnel } from "src/modules/Notification/Requests";
import ReactTimeAgo from "react-time-ago";
import moment from "moment";
import { request } from "src/utils/services";
import { queryClient } from "src";
import ShowWorkDetailsForm from "src/modules/Work/Form/ShowWorkForm";
import CreateFollowupModal from "src/modules/Leads/Modal/CreateFollowup";
import TaskViewModal from "src/modules/Task/Modal/ShowTaskDetails";
import ShowTaskDetailsForm from "src/modules/Task/Form/ShowTaskDetailsForm";
import { isMobile } from "react-device-detect";

const ProfileCard: React.FC = () => {
  const [user, setUser] = React.useState(LocalStorageService.getAccount());
  // console.log(user);
  const {
    isLoading,
    isError,
    data: response,
  } = useQuery(
    ["notification", user.personnel.id],
    () => getNotificationByPersonnel(user.personnel.id),
    {
      refetchOnWindowFocus: true,

      keepPreviousData: true,
      staleTime: 100,
    }
  );
  const manageNotificationMutation = useMutation(
    (values: any) =>
      request("admin.notification.update.many", {
        id: user.personnel.id,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("notification");
      },
    }
  );
  let notification = response.filter((data: any) => data.isRead === false);
  const content = (
    <Space
      direction="vertical"
      // style={{ maxHeight: "80vh", minWidth: "18vw", overflow: "auto" }}
    >
      <Row justify={"end"}>
        <Avatar
          size={"large"}
          src={<img src={s3Path + user.partyId.imageUrl} alt={"R"} />}
        />
        <Divider type="vertical" />
        <Space.Compact direction="vertical">
          <Typography.Text strong>{user.partyId.personName}</Typography.Text>{" "}
          <Typography.Text code>{user.roles[0]}</Typography.Text>{" "}
        </Space.Compact>
        <Divider type="vertical" />
        <Button size="small" icon={<UserOutlined />} />
      </Row>
      <Row justify={"end"}>
        <Space.Compact>
          <Button size="small" icon={<LockOutlined />}>
            {" "}
            Change Password
          </Button>
          {/* <Button size="small" icon={<UserOutlined />}>
            {" "}
            Profile
          </Button> */}
          <Button size="small">
            {" "}
            <RouteLink name="logout">
              Logout <LogoutOutlined />
            </RouteLink>
          </Button>
        </Space.Compact>
      </Row>
      {!isMobile && (
        <Row justify={"space-between"}>
          <Typography.Text strong>
            <NotificationOutlined />
            Notifications
          </Typography.Text>
          <Button
            size="small"
            icon={<ReadOutlined />}
            onClick={
              () =>
                manageNotificationMutation

                  .mutateAsync({})
                  .then(() => {
                    message.success("Notification mark read");
                  })
                  .catch((e: any) => {
                    message.error(e);
                  })

              // notification.forEach((element: any) =>
              //   manageNotificationMutation.mutateAsync({ id: element.id })
              // )
            }
          >
            {" "}
            Mark All Read
          </Button>
        </Row>
      )}

      {!isMobile && (
        <Space.Compact
          direction="vertical"
          style={{ maxHeight: "70vh", width: "30vw", overflow: "auto" }}
        >
          <NotificationList data={response} />
        </Space.Compact>
      )}
    </Space>
  );
  return (
    // <Card bordered={false} style={{ width: 180 }}>
    <Space>
      <Popover trigger={"click"} content={content} placement="bottomRight">
        <Row justify={"space-between"} gutter={20} align={"middle"}>
          <Typography.Text>{user.name}</Typography.Text>
          <Badge count={notification.length}>
            <Avatar
              src={<img src={s3Path + user.partyId.imageUrl} alt={"R"} />}
            />
          </Badge>
        </Row>
      </Popover>
    </Space>
    // </Card>
  );
};

export default ProfileCard;

const NotificationList: React.FC<{ data: any }> = ({ data }) => {
  const [open, setOpen] = React.useState(false);
  const [taskId, setTaskId] = React.useState(undefined);
  const [workId, setWorkId] = React.useState(undefined);
  const [leadId, setLeadId] = React.useState(undefined);

  const manageNotificationMutation = useMutation(
    (values: any) =>
      request("admin.notification.update", { id: values.id }, { isRead: true }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("notification");
      },
    }
  );

  return (
    <>
      {data &&
        data.map((item: any, i: any) => {
          return (
            <>
              <Card
                size="small"
                style={{
                  backgroundColor: item?.isRead ? "ButtonShadow" : "beige",
                  // width: "100%",
                  marginBottom: 2,
                }}
                onClick={() => {
                  item?.isRead === false &&
                    manageNotificationMutation.mutateAsync({ id: item.id });
                  setOpen(true);
                  setTaskId(item?.taskId);
                  setWorkId(item?.workId);
                  setLeadId(item?.leadId);
                }}
              >
                <Row align={"top"} gutter={5}>
                  <Col span={3}>
                    {" "}
                    <Avatar
                      size={"large"}
                      shape="square"
                      src={
                        <img src={s3Path + item?.senderId.imageUrl} alt={"R"} />
                      }
                    />
                  </Col>
                  <Col span={16}>
                    {" "}
                    <Space.Compact direction="vertical">
                      <Badge
                        dot={
                          moment(item.createdAt).format("DD-MM-YYYY") ===
                          moment().format("DD-MM-YYYY")
                        }
                      >
                        <Typography.Text strong>
                          {item?.notificationTitle}
                        </Typography.Text>
                      </Badge>
                      <Typography.Text>{item.notificationBody}</Typography.Text>{" "}
                    </Space.Compact>
                  </Col>
                  {/* <Col span={1}></Col> */}
                  <Col span={5} style={{ paddingLeft: 5 }}>
                    {" "}
                    <Tag>
                      <ReactTimeAgo
                        date={item.createdAt}
                        locale="en-IN"
                        // timeStyle="twitter"
                      />
                    </Tag>
                  </Col>

                  {/* <Divider type="vertical" />

              <Divider type="vertical" /> */}
                </Row>
              </Card>

              {/* <Divider /> */}
            </>
          );
        })}
      {workId && (
        <Drawer
          open={open}
          width={"60%"}
          placement="left"
          // onCancel={() => setOpen(!open)}
          // onOk={() => setOpen(!open)}
          onClose={() => setOpen(!open)}
        >
          <ShowWorkDetailsForm id={workId} />{" "}
        </Drawer>
      )}
      {leadId && (
        <Drawer
          open={open}
          width={"60%"}
          placement="left"
          // onCancel={() => setOpen(!open)}
          // onOk={() => setOpen(!open)}
          onClose={() => setOpen(!open)}
        >
          <CreateFollowupModal leadId={leadId} />{" "}
        </Drawer>
      )}
      {taskId && (
        <Drawer
          open={open}
          width={"60%"}
          placement="left"
          // onCancel={() => setOpen(!open)}
          // onOk={() => setOpen(!open)}
          onClose={() => setOpen(!open)}
        >
          <ShowTaskDetailsForm id={taskId} />{" "}
        </Drawer>
      )}
    </>
  );
};
