import { path } from "./path";

export const sitePagesRoutes = [
  {
    method: "post",
    path: path + "/api/admin/createSitePage",
    module: "sitePages",
    alias: "admin.sitePages.create",
    isSubDomain: true,
  },

  {
    method: "put",
    path: path + "/api/admin/updateSitePage/:id",
    module: "sitePages",
    alias: "admin.sitePages.update",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllActiveSitePage",
    module: "sitePages",
    alias: "admin.sitePages.list.active",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllSitePage",
    module: "sitePages",
    alias: "admin.sitePages.list",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findOneSitePage/:id",
    module: "sitePages",
    alias: "admin.sitePages.id",
    isSubDomain: true,
  },
];
