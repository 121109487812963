import * as React from "react";
import {
  Form,
  Button,
  message,
  Row,
  Typography,
  Input,
  Col,
  Upload,
  Switch,
  Divider,
  Select,
  Avatar,
  DatePicker,
  InputNumber,
  Space,
  Card,
} from "antd";

import { request } from "../../../utils/services";
import { useMutation, useQuery } from "react-query";
import {
  InfoCircleOutlined,
  PlusOutlined,
  LoadingOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import { queryClient } from "src";
import type { UploadChangeParam, UploadFile } from "antd/es/upload/interface";
import type { RcFile, UploadProps } from "antd/es/upload";

import TextArea from "antd/lib/input/TextArea";

import LocalStorageService from "src/utils/local-storage";
import { CreateQuotationModalContext } from "../Modal/CreateQuotation";
import { s3Path, uploadFileToAWS } from "src/utils/s3Config/s3upload";
import CreatePartyModal from "src/modules/Party/Modal/CreateParty";
import {
  getAllActiveParty,
  getAllConcernedPerson,
  getAllConcernedPersonByPartyId,
} from "src/modules/Party/Requests";
import { imLogoIcon } from "src/constants/logo";
import CreateConcernedPersonModal from "src/modules/Party/Modal/concernedPerson";
import CreateLeadsModal from "src/modules/Leads/Modal/CreateLeads";
import { getAllLead } from "src/modules/Leads/Requests";

const { Text, Title } = Typography;
const CreateQuotation: React.FC = () => {
  const modalContext = React.useContext(CreateQuotationModalContext);
  const [formLoading, setFormLoading] = React.useState(false);
  const [galley, setGallery] = React.useState<string>();
  const [loading, setLoading] = React.useState(false);
  const [attention, setAttention] = React.useState([]);

  const [imageUrl, setImageUrl] = React.useState<string>();
  const [selectedFile, setSelectedFile] = React.useState<RcFile>();
  const [totalAmount, setTotalAmount] = React.useState(0);
  const [quantity, setQuantity] = React.useState(1);
  const [rate, setRate] = React.useState(0);
  const [tax, setTax] = React.useState(0);
  const [taxable, setTaxableAmount] = React.useState(0);
  const createQuotationMutation = useMutation(
    (values: any) => request("admin.quotation.create", {}, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("quotation");
      },
    }
  );

  const { data: party } = useQuery(["party"], async () => getAllActiveParty(), {
    refetchOnWindowFocus: false,
  });
  // const { data: concernedPerson } = useQuery(
  //   ["concernedPerson"],
  //   async () => getAllConcernedPerson(),
  //   {
  //     refetchOnWindowFocus: false,
  //   }
  // );
  const { data: lead } = useQuery(["lead"], async () => getAllLead(), {
    refetchOnWindowFocus: false,
  });
  const getBase64 = (img: RcFile, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    // if (info.file.status === "uploading") {
    //   setLoading(true);
    //   return;
    // }
    setSelectedFile(info.file.originFileObj);
    if (info.file.status === "uploading") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj as RcFile, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
  };

  const onFinish = async (values: any) => {
    values.createdBy = LocalStorageService.getAccount().id;
    values.imageUrl = galley;
    setFormLoading(true);

    createQuotationMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The Quotation has been successfully created.");
        form.resetFields();
        modalContext.setIsModalVisible(false);
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const [form] = Form.useForm();
  const mutateAttention = useMutation((id: string) =>
    getAllConcernedPersonByPartyId(id)
  );
  const getAttention = async (id: string) => {
    console.log(id, "parameters");

    mutateAttention
      .mutateAsync(id)
      .then((payload) => {
        setAttention(payload);
      })
      .catch((e: any) => {
        console.log("error", e);
      });
  };
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  let clientList = party.filter(
    (element: any) => element.partyType[0] === "Client"
  );
  let vendorList = party.filter(
    (element: any) => element.partyType[0] === "Vendor"
  );
  let leadList = party.filter(
    (element: any) => element.partyType[0] === "Lead Provider"
  );

  let partyList = [...clientList, ...vendorList, ...leadList];
  const handleTotal = (_: any, values: any) => {
    // console.log(values, "===>");
    // const rowsCopy = [...values.gr]
    // let tmpQty: number = 0; //pkg;
    //let tmpRate: number = 0; //weight;
    let tmptaxableAmt: number = 0; //paidAmt;
    let tmpTotalTaxableAmt: number = 0; //paidAmt;
    let tmpTax: number = 0; //paidAmt;
    let tmpTotaltax: number = 0; //paidAmt;
    let tmpAmt: number = 0;
    let totalAmt: number = 0; //toPayAmt;
    const particular = values.particular;

    particular?.forEach((fieldGroup: any, index: number) => {
      if (
        fieldGroup &&
        (fieldGroup.quantity ||
          fieldGroup.rate ||
          fieldGroup.tax ||
          fieldGroup.amount)
      ) {
        tmptaxableAmt = fieldGroup.quantity * fieldGroup.rate;
        tmpTax = fieldGroup.rate ? tmptaxableAmt * 0.18 : 0;
        tmpAmt = fieldGroup.rate ? tmptaxableAmt + tmpTax : 0;

        /**Set Form Fields**/
        // invoiceItems[index].quantity = quantity;
        particular[index].amount = tmpAmt;
        particular[index].tax = tmpTax;
        particular[index].sno = index + 1;
        form.setFieldsValue({ particular: particular });
        /**Set Form Fields**/
        tmpTotalTaxableAmt = fieldGroup.tax
          ? tmptaxableAmt + +fieldGroup.quantity * fieldGroup.rate
          : 0;
        tmpTotaltax = fieldGroup.tax ? tmpTax + +fieldGroup.tax : tax;
        totalAmt = fieldGroup.amount
          ? totalAmt + +fieldGroup.amount
          : totalAmount;
      }
    });
    setTax(tmpTotaltax);
    setTaxableAmount(tmpTotalTaxableAmt);
    setTotalAmount(totalAmt);

    return totalAmount;
  };
  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onValuesChange={handleTotal}
    >
      <Space.Compact block>
        <Card style={{ width: "30%" }}>
          <Form.Item
            label="Date."
            style={{ fontWeight: "bold" }}
            name="date"
            tooltip={{
              title: "date",
              icon: <InfoCircleOutlined />,
            }}
            hasFeedback
          >
            <DatePicker format={"DD-MMM-YYYY"} />
          </Form.Item>
          <Form.Item
            label="lead"
            style={{ fontWeight: "bold" }}
            name="leadId"
            tooltip={{
              title: "Select Lead",
              icon: <InfoCircleOutlined />,
            }}
            hasFeedback
          >
            <Select
              //onChange={onPartySelect}
              showSearch
              placeholder="Select lead"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option!.children as unknown as string).includes(input)
              }
              dropdownRender={(menu) => (
                <>
                  <Row justify={"end"}>
                    <CreateLeadsModal />
                  </Row>
                  {menu}
                </>
              )}
            >
              {lead &&
                lead?.map((item: any, i: number) => {
                  return (
                    <Select.Option value={item.id} key={i}>
                      <Typography.Text strong>
                        {item?.seq}. {item?.title}
                      </Typography.Text>
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Card>
        <Card style={{ width: "70%" }}>
          <Form.Item
            label=" Select Party"
            name="partyId"
            // style={{ fontWeight: "bold" }}
            tooltip={{
              title: "party",
              icon: <InfoCircleOutlined />,
            }}
          >
            <Select
              onSelect={getAttention}
              //onChange={onPartySelect}
              showSearch
              placeholder="Select party"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option!.children as unknown as string).includes(input)
              }
              dropdownRender={(menu) => (
                <>
                  <Row justify={"end"}>
                    <CreatePartyModal />
                  </Row>
                  {menu}
                </>
              )}
            >
              {partyList &&
                partyList?.map((item: any, i: number) => {
                  return (
                    <Select.Option value={item.id} key={i}>
                      <Avatar
                        src={
                          <img
                            src={
                              item.imageUrl
                                ? s3Path + item.imageUrl
                                : imLogoIcon
                            }
                            alt={item?.personName[0]}
                          />
                        }
                      />
                      <Typography.Text strong>
                        {item?.personName}-{item?.companyName}
                      </Typography.Text>
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
          <Form.Item
            label="Attention"
            name="attention"
            // style={{ fontWeight: "bold" }}
            tooltip={{
              title: "attention",
              icon: <InfoCircleOutlined />,
            }}
          >
            <Select
              //onChange={onPartySelect}
              showSearch
              placeholder="Select attention"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option!.children as unknown as string).includes(input)
              }
              // dropdownRender={(menu) => (
              //   <>
              //     <Row justify={"end"}>
              //       <CreatePartyModal />
              //     </Row>
              //     {menu}
              //   </>
              // )}
            >
              {attention &&
                attention?.map((item: any, i: number) => {
                  return (
                    <Select.Option value={item.id} key={i}>
                      <Typography.Text strong>{item?.name}</Typography.Text>
                      <Typography.Text type="success" code>
                        +91-{item?.mobile}
                      </Typography.Text>
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Card>
      </Space.Compact>
      <Card>
        {" "}
        <Space.Compact>
          <Input
            style={{ fontWeight: "bold", width: "5%" }}
            disabled
            placeholder="Sno."
          />
          <Input
            style={{ fontWeight: "bold", width: "33%" }}
            disabled
            placeholder="Particular"
          />
          {/* <Input
            style={{ fontWeight: "bold", width: "13%" }}
            disabled
            placeholder="HSN/SAC"
          /> */}
          <Input
            style={{ fontWeight: "bold", width: "8%" }}
            disabled
            placeholder="Qty"
          />
          <Input
            style={{ fontWeight: "bold", width: "13%" }}
            disabled
            placeholder="Rate"
          />
          {/* <Input
          style={{ fontWeight: "bold", width: 100 }}
          disabled
          placeholder="Discount"
        /> */}
          <Input
            style={{ fontWeight: "bold", width: "13%" }}
            disabled
            placeholder="Tax"
          />
          <Input
            style={{ fontWeight: "bold", width: "13%" }}
            disabled
            placeholder="gst"
          />
          <Input
            style={{ fontWeight: "bold", width: "13%" }}
            disabled
            placeholder="Amount"
          />
          <Input
            style={{ fontWeight: "bold", width: "2%" }}
            disabled
            placeholder=""
          />
        </Space.Compact>
        <Form.List name="items">
          {(fields, { add, remove }) => (
            <>
              <Space.Compact direction="vertical">
                {fields.map((field: any, index: number) => (
                  <Space.Compact>
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, curValues) =>
                        prevValues.area !== curValues.area ||
                        prevValues.sights !== curValues.sights
                      }
                    >
                      {() => (
                        <Form.Item
                          {...field}
                          initialValue={index + 1}
                          style={{ marginBottom: 0, width: "6%" }}
                          name={[field.name, "sno"]}
                        >
                          <Input placeholder="Sno. " disabled />
                        </Form.Item>
                      )}
                    </Form.Item>
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, curValues) =>
                        prevValues.area !== curValues.area ||
                        prevValues.sights !== curValues.sights
                      }
                    >
                      {() => (
                        <Form.Item
                          {...field}
                          name={[field.name, "particular"]}
                          style={{ marginBottom: 0, width: "35%" }}
                        >
                          <TextArea placeholder="Enter Paticular" />
                        </Form.Item>
                      )}
                    </Form.Item>
                    {/* <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, curValues) =>
                        prevValues.area !== curValues.area ||
                        prevValues.sights !== curValues.sights
                      }
                    >
                      {() => (
                        <Form.Item
                          initialValue="xxxx"
                          {...field}
                          name={[field.name, "hsnCode"]}
                          style={{ marginBottom: 0, width: "14%" }}
                        >
                          <Input
                            maxLength={10}
                            placeholder="HSN/SAC"
                            // disabled
                          />
                        </Form.Item>
                      )}
                    </Form.Item> */}
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, curValues) =>
                        prevValues.area !== curValues.area ||
                        prevValues.sights !== curValues.sights
                      }
                    >
                      {() => (
                        <Form.Item
                          initialValue={quantity}
                          {...field}
                          name={[field.name, "quantity"]}
                          style={{ marginBottom: 0, width: "8%" }}
                        >
                          <InputNumber placeholder="Qty" min={1} max={99} />
                        </Form.Item>
                      )}
                    </Form.Item>
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, curValues) =>
                        prevValues.area !== curValues.area ||
                        prevValues.sights !== curValues.sights
                      }
                    >
                      {() => (
                        <Form.Item
                          {...field}
                          name={[field.name, "rate"]}
                          style={{ marginBottom: 0, width: "14%" }}
                        >
                          <Input placeholder="Rate " />
                        </Form.Item>
                      )}
                    </Form.Item>
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, curValues) =>
                        prevValues.area !== curValues.area ||
                        prevValues.sights !== curValues.sights
                      }
                    >
                      {() => (
                        <Form.Item
                          {...field}
                          name={[field.name, "taxable"]}
                          style={{ marginBottom: 0, width: "14%" }}
                        >
                          <Input placeholder="tax " />
                        </Form.Item>
                      )}
                    </Form.Item>
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, curValues) =>
                        prevValues.area !== curValues.area ||
                        prevValues.sights !== curValues.sights
                      }
                    >
                      {() => (
                        <Form.Item
                          {...field}
                          name={[field.name, "gst"]}
                          style={{ marginBottom: 0, width: "14%" }}
                        >
                          <Select
                            placeholder="gst"
                            options={[
                              {
                                label: "5%",
                                value: "5%",
                              },
                              {
                                label: "18%",
                                value: "18%",
                              },
                            ]}
                          />
                        </Form.Item>
                      )}
                    </Form.Item>
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, curValues) =>
                        prevValues.area !== curValues.area ||
                        prevValues.sights !== curValues.sights
                      }
                    >
                      {() => (
                        <Form.Item
                          {...field}
                          name={[field.name, "amount"]}
                          style={{ marginBottom: 0, width: "14%" }}
                        >
                          <Input placeholder="Amount " />
                        </Form.Item>
                      )}
                    </Form.Item>

                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                  </Space.Compact>
                ))}

                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add Item
                  </Button>
                </Form.Item>
              </Space.Compact>
            </>
          )}
        </Form.List>
      </Card>

      {/* <h3>Personnel List</h3> */}

      <Space.Compact block>
        <Card style={{ width: "50%" }}>
          {/* <Row justify="space-between">
            <Form.Item
              label="Reverse Charge"
              style={{ fontWeight: "bold" }}
              name="reverseCharge"
              tooltip={{
                title: "reverseCharge",
                icon: <InfoCircleOutlined />,
              }}
              valuePropName="checked"
              // rules={[{ required: true }]}
            >
              <Switch
                checkedChildren="Yes"
                unCheckedChildren="No"
                defaultChecked
              />
            </Form.Item>
            <Form.Item
              label="Is IGST"
              style={{ fontWeight: "bold" }}
              name="isIGST"
              tooltip={{
                title: "isIGST",
                icon: <InfoCircleOutlined />,
              }}
              valuePropName="checked"
              // rules={[{ required: true }]}
            >
              <Switch
                checkedChildren="Yes"
                unCheckedChildren="No"
                defaultChecked
              />
            </Form.Item>
          </Row> */}
          <Form.Item
            label="Terms & Condition"
            style={{ fontWeight: "bold" }}
            name="terms"
            tooltip={{
              title: "terms",
              icon: <InfoCircleOutlined />,
            }}
            hasFeedback
          >
            <TextArea placeholder="Enter Terms & Condition" maxLength={150} />
          </Form.Item>
          <Form.Item
            label="Status"
            style={{ fontWeight: "bold" }}
            name="isActive"
            tooltip={{
              title: "status",
              icon: <InfoCircleOutlined />,
            }}
            valuePropName="checked"
            // rules={[{ required: true }]}
          >
            <Switch
              checkedChildren="Active"
              unCheckedChildren="Inactive"
              defaultChecked
            />
          </Form.Item>
        </Card>
        <Card style={{ width: "50%" }}>
          <Row justify="end">
            <Space direction="vertical">
              <Title level={5}>Taxable Amount: ₹{taxable} </Title>
              <Title level={5}>GST/Cess: ₹. {tax} </Title>
              <Title level={5}>Discount(-): ₹ 0 </Title>
              <Divider />
              <Title level={3}>Grand Total: ₹ {totalAmount}</Title>
            </Space>
          </Row>
        </Card>
      </Space.Compact>

      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreateQuotation;
